/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React components
import Popup from "assets/theme/components/popup";
import SuiBox from "components/Default/SuiBox";
import DashboardLayout from "components/examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import CustomPopup from "./components/custom_pop_up";

export default function General() {
  //pop up pages
  const [page, setPage] = useState(0);
  const [openPopUp, setOpenPopup] = useState(false);
  const [popUpName, setPopUpName] = useState("");
  const [currentGeneral, setCurrentGeneral] = useState();
  const [type, setType] = useState();
  const handlePopUp = (pName, id, type) => {
    setPopUpName(pName);
    setOpenPopup(true);
    setPage(1);
    setType(type);
    if(id) setCurrentGeneral(id);
  };

  useEffect(() => {
  }, []);

  return (  
    <div>
      {
        page > 0 ?
          <Popup
            openPopup={openPopUp}
            setOpenPopup={() => { setOpenPopup(false) }}
          >
              <CustomPopup name={popUpName} id={currentGeneral} type={type}></CustomPopup>
          </Popup> : <></>
      }
      
    <DashboardLayout>
      <DashboardNavbar />
        <SuiBox py={3}>
          <SuiBox mb={3} px={3} py={3} backgroundColor="white" onClick={() => handlePopUp("ServiceFeeSettingPopup", "", 0)}>
            Service Fee Setting
          </SuiBox>
          <SuiBox mb={3} px={3} py={3} backgroundColor="white" onClick={() => /* handlePopUp("ServiceAgreementPopup") */{ window.location = "https://www.bithon.com.hk/service-agreement"}}>
            Service Agreement
          </SuiBox>
          <SuiBox mb={3} px={3} py={3} backgroundColor="white" onClick={() => handlePopUp("FAQPopup")}>
            FAQ
          </SuiBox>
          <SuiBox mb={3} px={3} py={3} backgroundColor="white" onClick={() => handlePopUp("ADManagementPopup")}>
            ADs Management
          </SuiBox>
          <SuiBox mb={3} px={3} py={3} backgroundColor="white" onClick={() => handlePopUp("AppNotificationPopup")}>
            App Notification
          </SuiBox>
          <SuiBox mb={3} px={3} py={3} backgroundColor="white" onClick={() => handlePopUp("SavingPlanPopup")}>
            Saving Plan
          </SuiBox>
          <SuiBox mb={3} px={3} py={3} backgroundColor="white" onClick={() => handlePopUp("CrisisMgtPopup")}>
            Crisis Management
          </SuiBox>
          <SuiBox mb={3} px={3} py={3} backgroundColor="white" onClick={() => handlePopUp("AccessCodeMgtPopup")}>
            Access Code Management
          </SuiBox>
          <SuiBox mb={3} px={3} py={3} backgroundColor="white" onClick={() => handlePopUp("VersionAnnouncePopup")}>
            Version
          </SuiBox>
          <SuiBox mb={3} px={3} py={3} backgroundColor="white" onClick={() => handlePopUp("EmailTemplatePopup")}>
            Email Template
          </SuiBox>
        </SuiBox>
      
    </DashboardLayout>
    </div>
  );
}
