import '../styles.css';

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import UpdateIcon from '@mui/icons-material/Update';
import { Grid, ToggleButton, ToggleButtonGroup } from '@mui/material';
import Typography from '@mui/material/Typography';
import GeneralApi from 'api/general';
import { Loader } from 'components/Custom';
import { Accordion, AccordionDetails, AccordionSummary } from 'components/Custom/accordion';
import Editor from 'components/Custom/editor';
import HTMLContainer from 'components/Custom/html_container';
import { SuiBox, SuiButton } from 'components/Default';
import * as React from 'react';
import { useEffect, useState } from "react";
import { useAlert } from 'react-alert';
import AuthCodeHelper from "util/AuthCodeHelper";
import { GENERAL_PERMISSION } from 'config/permission';

export default function EmailTemplatePopup () {
    const alert = useAlert();

    const [emailTemplate, setEmailTemplate] = useState([]);
    const [auth, setAuth] = useState(false);
    const [inputs, setInputs] = useState({}); // inputs
    const [disable, setDisable] = useState([]);
    const [expanded, setExpanded] = React.useState('panel0');
    const [count, setCount] = useState(0);

    const setContent = (index, newContent) => {
      setInputs(prevState => ({ ...prevState, [index]: newContent }))
    }
    const editItem = (index) => {
      setDisable(prevState => ({ ...prevState, [index]: !disable[index] }))
    }

    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };

    const handleAuthCode = (resp) => {
        // console.log(resp);

        if(resp){
            if(resp.isAuth){
                setAuth(true);
            } else{
                alert.error(resp.message);
            }
        }
        else{
            alert.error(ERROR.callback);
        }
    }

    const handleSendEmailByTemplateId = async (id) => {
      await GeneralApi.sendEmailByTemplateId(id)
        .then((res) => {
            if(res.status === 200){
              alert.success(`Email #${id} Sent!`);
            }
        }).catch((err) => {
            console.log(err);
            alert.error(err.message);
        });
    }

    const updateItem = async (id) => {
      await GeneralApi.editEmailTemplateById({
        id: id,
        body: [inputs[id]]
      })
        .then((res) => {
            if(res.status === 200){
              alert.success(`Email #${id} Changed!`);
            }
        }).catch((err) => {
            console.log(err);
            alert.error(err.message);
        });
    }

    const removeItem = async (id) => {
      if(id){
        await GeneralApi.removeEmailTemplateById(id)
          .then((res) => {
              if(res.status === 200){
                alert.success(`Email #${id} Deleted!`);
              }
          }).catch((err) => {
              console.log(err);
              alert.error(err.message);
          });
      } else {

      }
    }

    const actionEdit = e => {
      let newData = emailTemplate;
        newData.push({
            content: ""
        });
      setCount(count + 1);
      setEmailTemplate(newData);
    }

    const fetchData = async () => {
      await GeneralApi.getEmailTemplate()
        .then((res) => {
            if(res.status === 200){
              console.log(res.data.data);
              setEmailTemplate(res.data.data);
            }
        }).catch((err) => {
            console.log(err);
            alert.error(err.message);
        });
    }

    useEffect(()=>{
      fetchData();
    }, []);

    return (
        <>
          {
            !auth ?
            <AuthCodeHelper title="Admin Authentication" btnName="Confirm" permission={GENERAL_PERMISSION.EDIT} callback={handleAuthCode}/>
            :   
            <div id="popup1" className="popup-wrapper">
              <div id="" className="popup1-flex-column" style={{width: "40rem"}}>
                  <h4>Email Template</h4>
                  <div className="popup-content">
                    {
                      emailTemplate.length >= 1?
                        emailTemplate.map((data, index) => {
                          return (
                            <Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
                              <AccordionSummary aria-controls={`panel${index}d-content`} id={`panel${index}d-header`}>
                                <Typography>{`Email Template #${index+1}`}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                {
                                  disable[data.id] === false ?
                                    <Editor content={inputs[data.id] || data.content} onChange={(newContent) => setContent(data.id, newContent)}/>
                                    : <HTMLContainer html={inputs[data.id] || data.content}/>
                                }

                                <SuiBox
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  >
                                    <ToggleButtonGroup
                                      color="primary"
                                      exclusive
                                      onChange={handleChange}>
                                      {
                                          disable[data.id] === false ?
                                              <ToggleButton value="update" onClick={() => updateItem(data.id)}>
                                                  <UpdateIcon></UpdateIcon>
                                              </ToggleButton>
                                              :
                                              <ToggleButton value="edit" onClick={() => editItem(data.id)}>
                                                  <EditIcon></EditIcon>
                                              </ToggleButton>
                                      }
                                      <ToggleButton value="delete" onClick={() => removeItem(data.id)}>
                                          <DeleteIcon></DeleteIcon>
                                      </ToggleButton>
    
                                    </ToggleButtonGroup>
                                    <SuiButton
                                      buttonColor="secondary"
                                      variant="outlined"
                                      onClick={() => handleSendEmailByTemplateId(data.id)}
                                    >
                                      Send
                                    </SuiButton>
                                </SuiBox>
                              </AccordionDetails>
                            </Accordion>
                          )
                        })
                        : <Loader/>
                    }
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs={6} sm={6} md={6} xl={6}>
                          <SuiButton variant="outlined" buttonColor="secondary" onClick={(e) => actionEdit(e.value)} style={{ width: '100%'}}>
                              <AddIcon/>
                          </SuiButton>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} xl={6}>
                          <SuiButton buttonColor="secondary" /* onClick={() => updateSavingPlan( BTC, ETH, USDC, USDT )} */ style={{ width: '100%'}}>
                              Update All
                          </SuiButton>
                      </Grid>
                    </Grid>
                  </div>
              </div>

          </div>
          }
        </>
    );
}