/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO Material-UI components
import SuiBox from "components/Default/SuiBox";
import SuiTypography from "components/Default/SuiTypography";

// Soft UI Dashboard PRO Material-UI example components
import TimelineItem from "components/examples/Timeline/TimelineItem";
import { useState, useEffect } from "react";
import GeneralApi from "api/general";
import { DateFormatUtils } from "util/index";

export default function AdminActionOverview() {
  const [logs, setLogs] = useState([]);

  const fetchData = () => {
    GeneralApi.getLogs()
      .then(res => {
        if (res.status === 200 || res.data.data) setLogs(res.data.data.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Card className="h-100">
      <SuiBox pt={3} px={3}>
        <SuiTypography variant="h6" fontWeight="medium">
          Actions overview
        </SuiTypography>
        <SuiBox mt={1} mb={2}>
          <SuiTypography variant="button" textColor="text" fontWeight="regular">
            <SuiTypography
              variant="button"
              textColor="text"
              fontWeight="medium"
            >
              {logs.length} Actions
            </SuiTypography>{" "}
            this month
          </SuiTypography>
        </SuiBox>
      </SuiBox>
      <SuiBox p={2}>
        {
          logs.length >= 1?
            logs.map((log, index) => {
              return (
                <TimelineItem
                  key={index}
                  color="info" // error, success, warning, primary, dark
                  icon="mail"
                  title={log.content}
                  dateTime={DateFormatUtils(log.created_at, "DD MMMM h:mm a")}
                />
              )
            })
            : <></>
        }
      </SuiBox>
    </Card>
  );
}
