/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { WorkspacePremium as WorkspacePremiumIcon } from '@mui/icons-material';
import { Card, Chip, Pagination } from "@mui/material";
import Popup from "assets/theme/components/popup";
import { DataTable, Loader } from "components/Custom";
import "components/Custom/common.css";
import table_columns_size from "components/Custom/dataTable/column_size";
import { SuiBox } from "components/Default";
import DashboardLayout from "components/examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/examples/Navbars/DashboardNavbar";
import CustomerGateway from 'entities/customerGateway';
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Link } from 'react-router-dom';
import { NullFormatUtils } from "util/index";
import LabelFilterPanel from "util/LabelFilterPanel";
import status from "util/LabelFilterPanel/base";
import { handleFilterByKeyword, handleHighlightKeywordInTable } from "util/SearchFunction";
import { CustomPopup } from "./components";

let customerGateway = new CustomerGateway();

export default function User() {
  const alert = useAlert();

  // Pop up pages
  const [page, setPage] = useState(0);
  const [openPopUp, setOpenPopup] = useState(false);
  const [popUpName, setPopUpName] = useState("");
  const [currentUser, setCurrentUser] = useState();
  const [mySqlId, setMySQLId] = useState();

  const handlePopUp = (pName, id, sql_id) => {
    setPopUpName(pName);
    setOpenPopup(true);
    setPage(1);
    if (id) setCurrentUser(id);
    if (sql_id) setMySQLId(sql_id);
  };

  const [rows, setRows] = useState([]);
  const [filterRows, setFilterRows] = useState([]);
  const [allStatusWithCount, setAllStatusWithCount] = useState([]);
  const [currentFilter, setCurrentFilter] = useState(0); // # of chip label filter
  const [statusStatement, setStatusStatement] = useState(null);
  const [searchStatement, setSearchStatement] = useState(null);
  const [loading, setLoading] = useState(true);

  const [tbPage, setTbPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const handlePageChange = (event, value) => {
    if (value <= totalPage) {
      setTbPage(value);
      fetchAllData(value);
    }
  };

  const handleSearch = (searchValue) => {
    fetchAllData(1, "", searchValue);
  }

  const fetchTestAuthCode = async () => {
    await customerGateway.testAuthorizationCodes({
      code: "<string>"
    })
      .then(res => {
        if (res) {
          console.log(res.authorization_code_details);
        }
      })
      .catch(err => {
        console.log(err.message);
      })
  }

  const fetchAllData = async (page, _statusStatement, searchValue) => {
    if (_statusStatement) {
      setStatusStatement(_statusStatement);
    }
    setLoading(true);

    const data_length = await customerGateway.getAllData({
      page: page ?? tbPage,
      statusStatement: _statusStatement ?? (statusStatement ?? null),
      searchValue: searchValue
    })
      .then(res => {
        if (res) {
          if (page === 0) {
            return res.total;
          }
          setRows(res.data);
          setFilterRows(res.data);
          setTotalPage(res.last_page);
          // setRowPerPage(res.per_page);
          setLoading(false);
        } else {
          alert.error("Gateway response error");
        }
      });

    if (page === 0) return data_length
  };

  const fetchStatusCount = () => {
    let allStatus = [];
    status?.user?.map((status, key) => {
      let _status = status;
      fetchAllData(0, `${status?.param?.name}=${status?.param?.value}`)
        .then((res) => {
          _status.count = res;
        })
      allStatus.push(_status);
    })
    setStatusStatement(null);
    setAllStatusWithCount(allStatus);
  }

  useEffect(
    () => {
      if (rows.length < 1) {
        fetchAllData();
        fetchStatusCount();
        fetchTestAuthCode();
      }
    },
    [rows]
  );

  const dt_columns = [
    {
      headerName: 'User ID',
      field: 'account_id',
      renderCell: ({ row }) =>
        row.account_id ?
          <Link to='#'
            className="text-secondary"
            onClick={() =>
              handlePopUp("AccountDetailsPopup", row.id, row.mysql_id)}>

            {handleHighlightKeywordInTable(row.SearchValue, row.account_id)}
          </Link>
          : "-",
      sortable: true
    },
    {
      headerName: 'User Name',
      field: 'username',
      renderCell: ({ row }) => handleHighlightKeywordInTable(row.SearchValue, NullFormatUtils(row.username)),
      sortable: true,
      width: table_columns_size.s
    },
    {
      headerName: 'Email',
      field: 'email',
      width: table_columns_size.xm,
      renderCell: ({ row }) => handleHighlightKeywordInTable(row.SearchValue, NullFormatUtils(row.email)),
      sortable: true,
    },
    {
      headerName: 'Phone',
      field: 'phone',
      width: table_columns_size.m,
      renderCell: ({ row }) => handleHighlightKeywordInTable(row.SearchValue, NullFormatUtils(row.phone)),
      sortable: true,
    },
    {
      headerName: 'Id Verify',
      field: 'idVerifyStatus',
      width: table_columns_size.m,
      renderCell: ({ row }) =>
        <Link to='#' className="text-secondary" onClick={() => handlePopUp("IdVerifyPopup", row.id)}>
          {handleHighlightKeywordInTable(row.SearchValue, NullFormatUtils(row.idVerifyStatus))}
          {row.idVerifyStatus === "Approved" ? <WorkspacePremiumIcon /> : <></>}
        </Link>,
      sortable: true,
    },
    {
      headerName: 'Shop Verify',
      field: 'shopVerifyStatus',
      width: table_columns_size.m,
      renderCell: ({ row }) =>
        <Link to='#' className="text-secondary" onClick={() => handlePopUp("ShopVerifyPopup", row.id)}>
          {handleHighlightKeywordInTable(row.SearchValue, NullFormatUtils(row.shopVerifyStatus))}
          {row.shopVerifyStatus === "Approved" ? <WorkspacePremiumIcon /> : <></>}
        </Link>,
      sortable: true,
    },
    {
      headerName: 'Income Declaration',
      field: 'income',
      width: 200,
      renderCell: ({ row }) =>
        <Link to='#' className="text-secondary" onClick={() => handlePopUp("IncomeDeclaration", row.id)}>
          View
        </Link>,
      sortable: true,
    },
    {
      headerName: 'Referral',
      field: 'referral',
      width: 200,
      renderCell: ({ row }) =>
        row.referralCount >= 1 ?
          <Link to='#' className="text-secondary" onClick={() => handlePopUp("Referral", row.mysql_id)}>
            View
          </Link>
          : "None",
      sortable: true,
    },
    {
      headerName: 'Status',
      field: 'accountStatus',
      width: 200,
      renderCell: ({ row }) =>
        <Chip label={handleHighlightKeywordInTable(row.SearchValue, NullFormatUtils(row.accountStatus))}
          className={handleFilterByKeyword(status.user, "label", row.accountStatus)} />,
      sortable: true,
    }
  ];

  const handleChangeRows = async (statusParam, statusValue) => {
    setCurrentFilter(statusValue);
    fetchAllData(1, `${statusParam}=${statusValue}`)
  };

  const handleClearAndRefresh = () => {
    setCurrentFilter(0);
    setStatusStatement(null);
    setRows([]);
  };

  return (
    <div>
      {page > 0 ?
        <Popup
          openPopup={openPopUp}
          setOpenPopup={() => { setOpenPopup(false) }}>

          <CustomPopup name={popUpName} id={currentUser} mySqlId={mySqlId} />
        </Popup>
        : <></>
      }

      <DashboardLayout>
        <DashboardNavbar />
        <Loader loading={loading}>
          <SuiBox p={0}>
            <LabelFilterPanel
              currentFilter={currentFilter}
              handleClearAndRefresh={handleClearAndRefresh}
              fetchAllData={fetchAllData}
              allStatus={allStatusWithCount}
              handleChangeRows={handleChangeRows}
            />
            <Card>
              <DataTable dt_columns={dt_columns} dt_rows={filterRows} handleSearch={handleSearch} />
              <Pagination color={"secondary"} count={totalPage} page={tbPage} onChange={handlePageChange} />
            </Card>
          </SuiBox>
        </Loader>
      </DashboardLayout>
      {/* <LabelBottomNavigation /> */}
    </div>
  );
}
