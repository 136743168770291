import Form from "template/base/form";
import Column from "template/base/column";

function IncomeDeclarationTemplate(props) {
  const {
    Title,
    UserID,
    WorkingStatus,
    MaritalStatus,
    SourceOfIncome,
    CryptoExperience,
    PersonalAssetHKD,
    JobTitle,
    Industry
  } = props;
  
  return (
    <Form>
      <Column>
        <Title />
        <UserID />
        <WorkingStatus />
        <MaritalStatus />
        <SourceOfIncome />
        <CryptoExperience />
        <PersonalAssetHKD />
        <JobTitle />
        <Industry />
      </Column>
    </Form>
  );
}

export default IncomeDeclarationTemplate;
