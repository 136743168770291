import { Grid } from "@material-ui/core";
import FireStorage from "api/firestorage";
import StaffApi from 'api/staff';
import BasicSelect from 'components/Custom/custom_select';
import InputWrapper from 'components/Custom/input_wrapper';
import SuiBox from "components/Default/SuiBox";
import SuiButton from "components/Default/SuiButton";
import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import AuthCodeHelper from "util/AuthCodeHelper";
import { STAFF_PERMISSION } from 'config/permission';
import './styles.css';

export default function Profile ({ id, action }){
    let testMode = false;
    const alert = useAlert();

    const [page, setPage] = useState(action > 0 ? 1 : 0);
    const [auth, setAuth] = useState(false);
    const [adminCodeInput, setAdminCodeInput] = useState("");

        const handleAuthCode = (resp) => {
            if(resp){
                if(resp.isAuth){
                    setAuth(true);
                    setPage(1);
                } else{
                    alert.error(resp.message);
                }
            }
            else{
                alert.error(ERROR.callback);
            }
        }
    
    const handleAddStaff = async (inputs, gender) => {
        if(!auth){
            setPage(0);
        } else{
            await StaffApi.add({
                    name: inputs["eng_name"],
                    nick_name: inputs["nickname"],
                    job_title: inputs["job_title"],
                    gender: gender,
                    phone: inputs["phone"],
                    email: inputs["email"]
                })
                .then((res) => {
                    if(res.status === 200){
                        alert.success(`Create Staff ${inputs["eng_name"]} Success`);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    alert.error("call api error");
                })
        }
    };

    const handleUpdateStaff = async (inputs, gender) => {
        if(!auth){
            setPage(0);
        } else{
            //call api here
            await StaffApi.update({
                    id: id,
                    phone: inputs["phone"],
                    job_title: inputs["job_title"],
                    gender: gender,
                    name: inputs["eng_name"],
                    nick_name: inputs["nickname"]
                })
                .then((res) => {
                    if(res && res.status === 200){
                        alert.success("Update Staff Success");
                    }
                })
                .catch((err) => {
                    console.log(err);
                    alert.error("call api error");
                })
        }
    };

    if(page === 0){
        return (<AuthCodeHelper title="Admin Authentication" btnName="Confirm" permission={STAFF_PERMISSION.DEFAULT} callback={handleAuthCode}/>);
    }
    else if(page === 1){
        return (<ProfilePopup id={id} action={action} handleAddStaff={handleAddStaff} handleUpdateStaff={handleUpdateStaff}/>);
    }
}

function ProfilePopup ({ id, action, handleAddStaff, handleUpdateStaff }) {
    const alert = useAlert();

    const [staffData, setStaffData] = useState([]);
    const [inputs, setInputs] = useState({}); // inputs
    const [selectedValue, setSelectedValue] = useState();
    const [gender, setGender] = useState();
    const [changePassword, setChangePassword] = useState(false);

    //const handleChange = (event) => { setSelectedValue(event.target.value) };
    const handleChange = e => setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));

    const rowRadio = [
        {
            value: 0,
            label: "Hourly"
        },
        {
            value: 1,
            label: "Daily"
        },
        {
            value: 2,
            label: "Monthly"
        }
    ];

    const Gender = [
        {
            label : 'Male',
            value : 1
        },
        {
            label : 'Female',
            value : 2
        }
    ]

    function showActionButton(action){
        if(action === 1){
            return (
                <SuiButton buttonColor="secondary" onClick={() => handleAddStaff(inputs, gender)}>Create</SuiButton>
            )
        } else if (action === 2){
            return (
                <SuiButton buttonColor="secondary" onClick={() => handleUpdateStaff(inputs, gender)}>Save</SuiButton>
            )
        } else return (<></>);
    }
    
    function showEditOrNot(action){
        // disable true => not show
        //　disable false =>　show
        //console.log(action);
        switch (action) {
            case 1:
                return false;
            case 2:
                return false;
            case 3:
                return true;
            default:
                return true;
        }
    }

    const fetchOneStaffData = async (id) =>{
        await StaffApi.list()
            .then((res) => {    
                if(res.status === 200){
                    const data = res.data.data.data.filter(d => d.id === id)
                    setStaffData(data[0]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleChangePassword = async () => {
        await FireStorage.idToken();

        setTimeout(async() => {
            const id_token = localStorage.getItem("id_token");
            //console.log(id_token);

            await StaffApi.changePassword({
                password: inputs["new_password"],
                id_token: id_token
            })
                .then((res) => {    
                    if(res.status === 200){
                        alert.success("Password changed");
                    }
                })
                .catch((err) => {
                    console.log(err);
                    alert.error(err.message);
                });
        }, 3000);
    }

    useEffect(()=>{
        const user = localStorage.getItem("user");
        if(user && staffData.length < 1 && action === 3){
            //console.log(JSON.parse(user));
            setStaffData(JSON.parse(user));
        }
        
        console.log(id);
        console.log(action);

        if(id && action === 2){
            fetchOneStaffData(id);
        }
    }, [])

    return (
        <div id="popup1" className="popup-wrapper">
            <div id="" className="popup1-flex-column">
                <h4>Profile</h4>
                <div className="popup-content">

                    <SuiBox xs={2} lg={3} xl={6}>
                        <InputWrapper attr="Staff ID" size="sm" value={staffData?.id}/> 
                    </SuiBox>

                    <Grid container alignItems="center">
                        <Grid item xs={12} sm={8} md={6} xl={6}>
                            <InputWrapper attr="English Name" name="eng_name" value={inputs["eng_name"] ?? staffData?.name} onChange={handleChange} size="lg" disable={showEditOrNot(action)}/>
                        </Grid>
                        <Grid item xs={12} sm={8} md={6} xl={6}>
                            <InputWrapper attr="Nickname" name="nickname" value={inputs["nickname"] ?? staffData?.nick_name} onChange={handleChange} size="lg" disable={showEditOrNot(action)}/>
                        </Grid>
                    </Grid>

                    <Grid container alignItems="center">
                        <Grid item xs={12} sm={8} md={6} xl={5}>
                            <InputWrapper attr="Job Title" name="job_title" value={inputs["job_title"] ?? staffData?.job_title} onChange={handleChange} size="sm" disable={showEditOrNot(action)}/>
                        </Grid>
                        <Grid item xs={12} sm={8} md={6} xl={6}>
                            {
                                staffData?.gender ?
                                    <InputWrapper attr="Gender" name="gender" value={inputs["gender"] ?? staffData?.gender} onChange={handleChange} size="sm" disable={showEditOrNot(action)}/>       
                                    : <BasicSelect title="Gender" items={Gender} onChange={(e)=>{ e ? setGender(e.label) : setGender(null)}}/>
                            }
                        </Grid>
                    </Grid>

                    <Grid container alignItems="center">
                        <Grid item xs={12} sm={8} md={6} xl={6}>
                            <InputWrapper attr="Phone" name="phone" value={inputs["phone"] ?? staffData?.phone} onChange={handleChange} size="sm" disable={showEditOrNot(action)}/>
                        </Grid>
                        <Grid item xs={12} sm={8} md={6} xl={6}>
                            <InputWrapper attr="Email" name="email" value={inputs["email"] ?? staffData?.email} onChange={handleChange} size="sm" disable={showEditOrNot(action)}/> 
                        </Grid>
                    </Grid>

                    {
                        action === 3 ?
                            <>
                                <SuiButton buttonColor="secondary" onClick={() => { setChangePassword(!changePassword); }}>Change Password</SuiButton>
                                {
                                    changePassword ?
                                        <Grid container>
                                            <Grid item xs={10} sm={10} md={10} xl={10}>
                                                <InputWrapper attr="New Password" name="new_password" value={inputs["new_password"] ?? ""} onChange={handleChange} size="xl" disable={false} type="password"/> 
                                            </Grid>
                                            <Grid item xs={2} sm={2} md={2} xl={2}>
                                                <SuiButton buttonColor="secondary" variant="outlined" onClick={() => handleChangePassword()}>Submit</SuiButton>
                                            </Grid>
                                        </Grid>
                                        : <></>
                                }
                            </>
                            : <></>
                    }
                    
                    {   showActionButton(action)    }
                </div>
            </div>
        </div>
    );
}