/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import { CardContent } from "@mui/material";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SuiBox from "components/Default/SuiBox";
import SuiTypography from "components/Default/SuiTypography";

// Custom styles for the CurrencyList
import styles from "layouts/dashboard/components/CurrencyList/styles";

function CurrencyList({ listName, BasicVal, IDVal, ShopVal }) {
  const classes = styles();

  return (
    <Card>
      <CardContent>
        <SuiBox p={2}>
          <SuiTypography variant="h5" fontWeight="bold" gutterBottom mb={3}>
            {listName}
          </SuiTypography>
          <Grid container spacing={3} mb={3}>
            <Grid item xs={12} sm={6} md={6} xl={6}>
              Basic
            </Grid>
            <Grid item xs={12} sm={6} md={6} xl={6}>
              {BasicVal}
            </Grid>
          </Grid>
          <Grid container spacing={3} mb={3}>
            <Grid item xs={12} sm={6} md={6} xl={6}>
              ID
            </Grid>
            <Grid item xs={12} sm={6} md={6} xl={6}>
              {IDVal}
            </Grid>
          </Grid>
          <Grid container spacing={3} mb={3}>
            <Grid item xs={12} sm={6} md={6} xl={6}>
              Shop
            </Grid>
            <Grid item xs={12} sm={6} md={6} xl={6}>
              {ShopVal}
            </Grid>
          </Grid>
        </SuiBox>
      </CardContent>
    </Card>
  );
}

export default CurrencyList;
