import { Grid } from "@mui/material";
import { SuiBox } from "components/Default";
import CardForm from "template/base/cardForm";
import CardFormActions from "template/base/cardFormActions";
import CardFormContent from "template/base/cardFormContent";
import Column from "template/base/column";
import ControlPanel from "template/base/controlPanel";
import Form from "template/base/form";
import FormContent from "template/base/formContent";
import Row from "template/base/row";

function OfferDetailsTemplate(props) {
  const {
    Title,
    OfferId,
    SellerId,
    AccountLevel,
    OfferTitle,
    Price,
    Crypto,
    Inventory,
    Likes,
    Category,
    Sales,
    Delivery,
    ProductCondition,
    HeaderImage,
    BackgroundImage,
    ContentImage,
    ActionButton,
    Title2,
    Description,
    Status,
    Handler,
    ExpandMoreController
  } = props;

  function Lefthandside() {
    return (
      <Column>
        <Title />
        <FormContent>
          <SuiBox xs={2} lg={3} xl={6} width="50%">
            <OfferId />
          </SuiBox>

          <Grid container alignItems="center">
            <Grid item xs={12} sm={8} md={6} xl={5}>
              <SellerId />
            </Grid>
            <Grid item xs={12} sm={8} md={6} xl={7}>
              <AccountLevel />
            </Grid>
          </Grid>

          <SuiBox xs={2} lg={3} xl={6} width="100%">
            <OfferTitle />
          </SuiBox>

          <Grid container alignItems="center">
            <Grid item xs={12} sm={8} md={6} xl={6}>
              <Price />
            </Grid>
            <Grid item xs={12} sm={8} md={6} xl={6}>
              <Crypto />
            </Grid>
          </Grid>

          <Grid container alignItems="center">
            <Grid item xs={12} sm={8} md={6} xl={6}>
              <Inventory />
            </Grid>
            <Grid item xs={12} sm={8} md={6} xl={6}>
              <Likes />
            </Grid>
          </Grid>

          <Grid container alignItems="center">
            <Grid item xs={12} sm={8} md={6} xl={6}>
              <Category />
            </Grid>
            <Grid item xs={12} sm={8} md={6} xl={6}>
              <Sales />
            </Grid>
          </Grid>

          <Grid container alignItems="center">
            <Grid item xs={12} sm={8} md={6} xl={6}>
              <Delivery />
            </Grid>
            <Grid item xs={12} sm={8} md={6} xl={6}>
              <ProductCondition />
            </Grid>
          </Grid>

          <Grid container alignItems="center">
            <Grid item xs={12} sm={8} md={6} xl={6}>
              <HeaderImage />
            </Grid>
            <Grid item xs={12} sm={8} md={6} xl={6}>
              <BackgroundImage />
            </Grid>
          </Grid>
          <ContentImage />
        </FormContent>
      </Column>
    );
  }

  function Righthandside() {
    return (
      <Column>
        <Title2 />
        <FormContent>
          <Description />
          <Row>
            <Status />
            <Handler />
          </Row>
        </FormContent>
      </Column>
    );
  }

  return (
    <CardForm>
      <CardFormContent>
        <Form>
          <Lefthandside />
          <Righthandside />
        </Form>
      </CardFormContent>

      <CardFormActions>
        <ExpandMoreController />
      </CardFormActions>

      <ControlPanel>
        <ActionButton />
      </ControlPanel>
    </CardForm>
  );
}

export default OfferDetailsTemplate;
