import '../styles.css';

import { useState, useEffect } from "react";
import SuiButton from "components/Default/SuiButton";
import SuiBox from "components/Default/SuiBox";
import InputWrapper from 'components/Custom/input_wrapper';
import CommentWrapper from 'components/Custom/comment_wrapper';
import UploadImage from 'components/Custom/upload_image';
import { BasicDateTimePicker } from "components/Custom";

import { GENERAL_PERMISSION } from 'config/permission';
import { useAlert } from 'react-alert';
import { Grid } from "@material-ui/core";
import AuthCodeHelper from "util/AuthCodeHelper";
// Call API
import GeneralApi from "api/general";
import AuthApi from 'api/auth';

export default function VersionPopup () {
    const alert = useAlert();

    const [update, setUpdate] = useState(false);
    const [auth, setAuth] = useState(false);

    const [inputs, setInputs] = useState({}); // inputs
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [headerImages, setHeaderImages] = useState([]);

    const handleChange = e => setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    const header_onChange = (imageList, addUpdateIndex) => {  // upload image
        //console.log(imageList, addUpdateIndex);
        setHeaderImages(imageList);
    };

  async function updateVersionAnnounce({
    android_link,
    android_version,
    content_data,
    end_at,
    ios_link,
    ios_version,
    photo,
    start_at,
    status
  }) {
      if(auth){
        await GeneralApi.addVersionUpdate({  
          android_link: android_link,
          android_version: android_version,
          content_data: content_data,
          end_at: end_at,
          ios_link: ios_link,
          ios_version: ios_version,
          photo: photo ?? "default",
          start_at: start_at,
          status: status ?? "Active"
        })
          .then((res) => {
            if(res.status === 200){
              alert.success("Edit data Successful");
            }
          })
          .catch((err) => {
            alert.error("Please fill in all the fields");
          });

      } else{
        setUpdate(true);
      }
    }

      const handleAuthCode = (resp) => {
        // console.log(resp);

        if(resp){
            if(resp.isAuth){
                setAuth(true);
            } else{
                alert.error(resp.message);
            }
        }
        else{
            alert.error(ERROR.callback);
        }
    }

    useEffect(()=>{
    }, []);

    return (
        <>
          {
            (!auth && update) ?
            <AuthCodeHelper title="Admin Authentication" btnName="Confirm" permission={GENERAL_PERMISSION.EDIT} callback={handleAuthCode}/>
            :   
            <div id="popup1" className="popup-wrapper">
              <div id="" className="popup1-flex-column" style={{width: "40rem"}}>
                  <h4>Version Announcemnet</h4>
                  <div className="popup-content">
                        <div className="text-sm">One Image for Header</div>
                        <UploadImage images={headerImages} onChange={header_onChange} multiple={false}/>

                        <div className="text-sm">Description</div>
                        <CommentWrapper name="description" rows="5" value={inputs["description"] ?? ""}  disable={false} onChange={handleChange}/>

                        <Grid container alignItems="center">
                          <Grid item xs={12} sm={8} md={6} xl={6}>
                            <InputWrapper attr="Android Version" name="android_version" value={inputs["android_version"] ?? ""}  onChange={handleChange} fullWidth={false} disable={false} size="xl" />
                          </Grid>
                          <Grid item xs={12} sm={8} md={6} xl={6}>
                            <InputWrapper attr="IOS Version" name="ios_version" value={inputs["ios_version"] ?? ""} onChange={handleChange} fullWidth={false} disable={false} size="xl" />
                          </Grid>
                        </Grid>

                        <SuiBox xs={2} lg={6} xl={12}>
                            <InputWrapper attr="Link (Android)" name="link_android" value={inputs["link_android"] ?? ""} onChange={handleChange} disable={false} size="xl"/>
                        </SuiBox>

                        <SuiBox xs={2} lg={6} xl={12}>
                            <InputWrapper attr="Link (IOS)" name="link_ios" value={inputs["link_ios"] ?? ""} onChange={handleChange} disable={false} size="xl"/>
                        </SuiBox>

                        <SuiBox xs={2} lg={6} xl={12}>
                            <div className="text-sm">Start Date</div>
                            <BasicDateTimePicker value={startDate} onChange={(newValue) => { setStartDate(newValue) }}/>
                        </SuiBox>

                        <SuiBox xs={2} lg={6} xl={12}>
                            <div className="text-sm">End Date</div>
                            <BasicDateTimePicker value={endDate} onChange={(newValue) => { setEndDate(newValue) }}/>
                        </SuiBox>
                  </div>

                  <div className="popup-form-row">
                      <SuiButton buttonColor="secondary" 
                        onClick={() => 
                          updateVersionAnnounce({
                            android_link: inputs["link_android"],
                            android_version: inputs["android_version"],
                            content_data: inputs["description"],
                            ios_link: inputs["link_ios"],
                            ios_version: inputs["ios_version"],
                            //photo: headerImages,
                            start_at: startDate,
                            end_at: endDate
                          })
                        }>
                          Release
                      </SuiButton>
                  </div>
                  
              </div>

          </div>
          }
        </>
    );
}