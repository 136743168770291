import axios from "./index";

class GameApi {
  static refundSum = data => {
    return axios.get(`${base}/count_participants`, {
      params: data
    });
  };
}

let base = "competitions";

export default GameApi;
