import "chartjs-adapter-date-fns";

const chartType = {
  Bar: 0,
  Line: 1,
  Pie: 2,
  Box: 3
};

function singleAxisBarOptions({ indexAxis = "x" }) {
  return {
    indexAxis: indexAxis,
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        formatter: function(value, context) {
          return context.chart.data.labels[context.dataIndex];
        }
      }
    },
    scales: {
      //display: false,
      y: {
        display: true
      },
      x: {
        grid: {
          display: false
        }
      }
    }
  };
}

const stackedBarOptions = {
  responsive: true,
  scales: {
    x: {
      stacked: true,
      type: "time",
      position: "bottom",
      time: {
        displayFormats: { day: "MM/YY" },
        tooltipFormat: "dd/MM/yy",
        unit: "month"
      }
    },
    y: {
      stacked: true
    }
  }
};

function singleAxisLineOptions({ unit = "month" }){
  return {
    scales: {
      xAxes: {
        type: "time",
        position: "bottom",
        time: {
          displayFormats: { hour: "HH:mm", day: "dd/MM"/* , month: 'MM/yy' */ },
          tooltipFormat: "dd/MM/yy",
          unit: unit
        }
      }
    }
  }
};

const multiAxisLineOptions = {
  scales: {
    xAxes: {
      type: "time",
      position: "bottom",
      time: {
        displayFormats: { day: "MM/YY" },
        tooltipFormat: "dd/MM/yy",
        unit: "month"
      }
    },
    y1: {
      type: "linear",
      position: "left"
    },
    y2: {
      type: "linear",
      position: "right"
    }
  }
};

const simplePieOptions = {
  responsive: true
};

export {
  chartType,
  singleAxisBarOptions,
  stackedBarOptions,
  singleAxisLineOptions,
  multiAxisLineOptions,
  simplePieOptions
};
