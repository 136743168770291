const className = {
    success: 'css-18udauo-MuiChip-root',
    error: 'css-v945hf-MuiChip-root',
    warning: 'css-6bvs8j-MuiChip-root',
    default: 'css-12nrnta-MuiButtonBase-root-MuiChip-root'
};

const status = [
    {
      label: "申訴中",
      value: 1,
      color: "default",
      class: className.default
    },
    {
      label: "待付款",
      value: 2,
      color: "warning",
      class: className.warning
    },
    {
      label: "待放行",
      value: 2,
      color: "warning",
      class: className.warning
    },
    {
      label: "已完成",
      value: 2,
      color: "success",
      class: className.success
    },
    {
      label: "已取消",
      value: 2,
      color: "error",
      class: className.error
    }
];

const table_columns_size = {
    xs: 140,
    s: 150,
    m: 200,
    xm: 250,
    xxm: 300
};

export { status, table_columns_size };