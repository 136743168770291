/**
=========================================================
* Soft UI Dashboard React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { Box, Grid, Tab, Tabs } from "@mui/material";
import Popup from "assets/theme/components/popup";
import DatePanel from "components/Custom/date_panel";
import SuiButton from "components/Default/SuiButton";
import { useSoftUIController } from "context";
import Footer from "components/examples/Footer";
import DashboardLayout from "components/examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import AuthCodeHelper from "util/AuthCodeHelper";
import { DASHBOARD_PERMISSION } from "config/permission";
import GameTab from "./components/game";
import OfferTab from "./components/offer";
import OrderTab from "./components/order";
import SavingPlanTab from "./components/savingplan";
import UserTab from "./components/user";
import WalletTab from "./components/wallet";
import "./style.css";

function AnalaysisBoard() {
  const alert = useAlert();
  //pop up pages
  const [page, setPage] = useState(1);
  const [openPopUp, setOpenPopup] = useState(true);
  const [popUpName, setPopUpName] = useState();
  const handlePopUp = pName => {
    setPopUpName(pName);
    setOpenPopup(true);
  };

  const handleBack = () => {
    setPage(0);
  };

  const [subPage, setSubPage] = useState(0);
  const [fullScreen, setFullScreen] = useState(false);
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav } = controller;
  const handleMiniSidenav = () => dispatch({ type: "MINI_SIDENAV", value: !miniSidenav });
  const handleChange = (event, newValue) => {
    setSubPage(newValue);
  };

  const handleAuthCode = resp => {
    if (resp) {
      if (resp.isAuth) {
        handleBack();
      } else {
        alert.error(resp.message);
      }
    } else {
      alert.error(ERROR.callback);
    }
  };

  useEffect(() => {
    handlePopUp("AuthoizedToEditPopup");
  }, []);

  const columns = [
    {
      name: "User",
      subPage: 0,
      tab: UserTab(),
      id: 0
    },
    {
      name: "Wallet",
      subPage: 1,
      tab: WalletTab(),
      id: 1
    },
    {
      name: "Offer",
      subPage: 3,
      tab: OfferTab(),
      id: 2
    },
    {
      name: "Game",
      subPage: 6,
      tab: GameTab(),
      id: 3
    },
    {
      name: "Order",
      subPage: 4,
      tab: OrderTab(),
      id: 4
    },
    {
      name: "Saving Plan",
      subPage: 7,
      tab: SavingPlanTab(),
      id: 5
    }
    /* {
      "name": "Shop",
      "subPage": 2,
      "tab": RegistrationTab()
    },
    {
      "name": "Adv",
      "subPage": 5,
      "tab": RegistrationTab()
    }, */
  ];

  return (
    <div>
      {page !== 0
        ? <Popup
            openPopup={openPopUp}
            setOpenPopup={() => {
              setOpenPopup(false);
            }}
          >
            <AuthCodeHelper
              title="Admin Authentication"
              btnName="Confirm"
              permission={DASHBOARD_PERMISSION.VIEWS}
              callback={handleAuthCode}
            />
          </Popup>
        : <DashboardLayout>
            {
              !fullScreen ? 
                <DashboardNavbar />
                : <></>
            }

            {
              !fullScreen ? 
                <DatePanel/>
                : <></>
            }
            
            <Grid container alignItems="center">
              {
                !fullScreen ? 
                <Grid item xs={11} sm={11} md={11} xl={11}>
                  <Box>
                    <Tabs
                      value={subPage}
                      onChange={handleChange}
                      variant="scrollable"
                      scrollButtons="auto"
                      textColor="inherit"
                      //indicatorColor="inherit"
                      aria-label="scrollable auto tabs example"
                    >
                      <Tab value={0} label="User" />
                      <Tab value={1} label="Wallet" />
                      <Tab value={2} label="Shop" />
                      <Tab value={3} label="Offer" />
                      <Tab value={4} label="Order" />
                      <Tab value={5} label="Adv" />
                      <Tab value={6} label="Game" />
                      <Tab value={7} label="Saving Plan" />
                    </Tabs>
                  </Box>
                </Grid>
                  : <></>
              }
              <Grid item xs={1} sm={1} md={1} xl={1}>
                <SuiButton
                  buttonColor="secondary"
                  variant="outlined"
                  onClick={() => {
                    handleMiniSidenav();
                    setFullScreen(!fullScreen);
                  }}
                >
                  <FullscreenIcon />
                </SuiButton>
              </Grid>
            </Grid>

            {columns.map((c, key) => {
              if (c.subPage === subPage) return c.tab;
            })}
            
          </DashboardLayout>}
    </div>
  );
}

export default AnalaysisBoard;
