/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Card, Chip, Pagination } from '@mui/material';
import Popup from "assets/theme/components/popup";
import { DataTable, Loader } from "components/Custom";
import { SuiBox } from "components/Default";
import DashboardLayout from "components/examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/examples/Navbars/DashboardNavbar";
import OrderGateway from "entities/orderGateway";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Link } from 'react-router-dom';
import { HTMLContentUtils, NullFormatUtils, NumberFormatUtils } from "util/index";
import LabelFilterPanel from "util/LabelFilterPanel";
import status from "util/LabelFilterPanel/base";
import { CustomPopup } from "./components";
import "./style.css";

let orderGateway = new OrderGateway();

export default function Order() {
  const alert = useAlert();

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);

  const [tbPage, setTbPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const [currentOrder, setCurrentOrder] = useState();
  const [openPopUp, setOpenPopup] = useState(false);
  const [popUpName, setPopUpName] = useState("");

  const [rows, setRows] = useState([]);
  const [filterRows, setFilterRows] = useState([]);
  const [currentFilter, setCurrentFilter] = useState(0);
  const [allStatusWithCount, setAllStatusWithCount] = useState([]);
  const [statusStatement, setStatusStatement] = useState(null);

  const handlePopUp = (pName, id) => {
    setPopUpName(pName);
    setOpenPopup(true);
    setPage(1);
    if (id) setCurrentOrder(id);
  };

  const handlePageChange = (event, value) => {
    if (value <= totalPage) {
      setTbPage(value);
      fetchAllData(value);
    }
  };

  const handleSearch = (searchValue) => {
    fetchAllData(1, "", searchValue);
  }

  const fetchAllData = async (page, _statusStatement, searchValue) => {
    setLoading(true);

    const data_length = await orderGateway.getAllData({
      page: page ?? tbPage,
      statusStatement: _statusStatement ?? (statusStatement ?? null),
      searchValue: searchValue ?? null
    })
      .then((res) => {
        if (res) {
          if (page === 0) {
            return res.total;
          } else {
            setRows(res.data);
            setFilterRows(res.data);
            setTotalPage(res.last_page);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        alert.error(err.message);
      })

    if (page === 0) return data_length
  };

  const handleClearAndRefresh = () => {
    setCurrentFilter(0);
    setStatusStatement(null);
  };

  const handleChangeRows = async (statusParam, statusValue) => {
    setCurrentFilter(statusValue);
    setStatusStatement({
      [statusParam]: statusValue
    });
    fetchAllData(1, {
      [statusParam]: statusValue
    });
    setLoading(false);
  };

  const fetchStatusCount = () => {
    let allStatus = [];
    status?.order?.map((status, key) => {
      let _status = status;
      fetchAllData(0, {
        [status?.param?.name]: status?.param?.value
      })
        .then((res) => {
          _status.count = res;
        })
      allStatus.push(_status);
    })
    setAllStatusWithCount(allStatus);
  }

  useEffect(() => {
    if (rows.length < 1) {
      fetchAllData();
      fetchStatusCount();
    }
  }, []);

  const selectColor = {
    /* "退款處理中": "primary",
    "退款失敗": "error",
    "人工退款失敗": "error",
    "退款成功": "success",
    "人工退款成功": "success",
    "客服處理中": "warning", */
    "待確認": "warning",
    "待發貨": "warning",
    "待收貨": "warning",
    "待付款": "warning",
    "已完成": "success",
    "已取消": "default",
    "已拒絕": "error"
  };

  const handleSearchKeyword = (SearchValue, rowData) => {
    if(SearchValue && rowData){
      //console.log(SearchValue);
      const new_rowData = rowData.replace(
        new RegExp(SearchValue, 'gi'),
        match => {
          if (match) {
            return `<mark style="background: #8D8DAA; color: white;">${match}</mark>`;
          }
        }
      );
      return HTMLContentUtils(new_rowData);
    }
    return rowData;
  };

  const dt_columns = [
    {
      headerName: 'Order ID',
      field: 'shortId',
      width: 100,
      renderCell: ({ row }) =>
        row.shortId ?
          <Link to="#" className="text-secondary" onClick={() => handlePopUp("OrderDetailsPopup", row.shortId)}>
            {handleSearchKeyword(row.SearchValue, row.shortId)}
          </Link>
          : "-",
      sortable: true,
    },
    {
      headerName: 'Seller ID',
      field: 'sellerId',
      width: 100,
      renderCell: ({ row }) =>
        row.sellerId ?
          <Link to="#" className="text-secondary" onClick={() => handlePopUp("AccountDetailsPopup", row.sellerId)}>
            {handleSearchKeyword(row.SearchValue, row.sellerId)}
          </Link>
          : "-",
      sortable: true
    },
    {
      headerName: 'Buyer ID',
      field: 'buyerId',
      width: 100,
      renderCell: ({ row }) =>
        row.buyerId ?
          <Link to="#" className="text-secondary" onClick={() => handlePopUp("AccountDetailsPopup", row.buyerId)}>
            {handleSearchKeyword(row.SearchValue, row.buyerId)}
          </Link>
          : "-",
      sortable: true,
    },
    {
      headerName: 'Offer ID',
      field: 'shopOfferId',
      width: 200,
      renderCell: ({ row }) =>
        row.shopOfferId ?
          <Link to="#" className="text-secondary" onClick={() => handlePopUp("OfferDetailsPopup", row.shopOfferId)}>
            {handleSearchKeyword(row.SearchValue, row.shopOfferId)}
          </Link>
          : "-",
      sortable: true,
    },
    {
      headerName: 'Quantity',
      field: 'quantity',
      renderCell: ({ row }) =>
        handleSearchKeyword(row.SearchValue, NullFormatUtils(row.quantity)),
      sortable: true,
    },
    {
      headerName: 'Price',
      field: 'price',
      renderCell: ({ row }) =>
        '$' + handleSearchKeyword(row.SearchValue, NumberFormatUtils(row.price, 8)),
      sortable: true,
      width: 200
    },
    {
      headerName: 'Crypto',
      field: 'cryptoType',
      renderCell: ({ row }) =>
        handleSearchKeyword(row.SearchValue, NullFormatUtils(row.cryptoType)),
      sortable: true,
    },
    {
      headerName: 'Create Date',
      field: 'createAt',
      width: 200,
      renderCell: ({ row }) =>
        handleSearchKeyword(row.SearchValue, NullFormatUtils(row.createAt)),
      sortable: true,
      type: 'date',
    },
    {
      headerName: 'Update At',
      field: 'updateAt',
      width: 200,
      renderCell: ({ row }) =>
        handleSearchKeyword(row.SearchValue, NullFormatUtils(row.updateAt)),
      sortable: true,
      type: 'date',
    },
    {
      headerName: 'Order Type',
      field: 'orderType',
      renderCell: ({ row }) =>
        handleSearchKeyword(row.SearchValue, NullFormatUtils(row.orderType)),
      sortable: true,
    },
    {
      headerName: 'Review',
      field: 'reviews',
      renderCell: ({ row }) =>
        row.reviews ?
          <Link to="#" className="text-secondary" onClick={() => handlePopUp("ReviewPopup", row.shortId)}>
            Review
          </Link>
          : "-",
      sortable: true,
    },
    {
      headerName: 'Refund',
      field: 'refund',
      renderCell: ({ row }) =>
        row.refund ?
          <Link to="#" className="text-secondary" onClick={() => handlePopUp("RefundPopup", row.shortId)}>
            Refund
          </Link>
          : "-",
      sortable: true,
    },
    {
      headerName: 'Status',
      field: 'status',
      renderCell: ({ row }) => <Chip label={handleSearchKeyword(row.SearchValue, NullFormatUtils(row.status))} color={selectColor[row.status] ?? "default"} />,
      sortable: true,
    }
  ];

  const changeRows = (filter) => {
    setLoading(true);
    let new_row = filterRows;
    if (["Online", "Offline"].includes(filter)) {
      new_row = rows.filter(row => row.orderType === filter)
    }
    else if (["客服處理中"].includes(filter)) {
      new_row = rows.filter(row => row.refund === filter)
    }
    else {
      new_row = rows.filter(row => row.status === filter);
    }

    setFilterRows(new_row);
    setLoading(false)
  };

  const countRows = (filter) => {
    let new_row = filterRows;
    if (["Online", "Offline"].includes(filter)) {
      new_row = rows.filter(row => row.orderType === filter)
    }
    else if (["客服處理中"].includes(filter)) {
      new_row = rows.filter(row => row.refund === filter)
    }
    else {
      new_row = rows.filter(row => row.status === filter);
    }
    return new_row.length;
  }

  const DataTablePanel = () => {
    if (filterRows) {
      return (
        <SuiBox py={3}>
          <SuiBox mb={3}>
            <Card>
              <DataTable dt_columns={dt_columns} dt_rows={filterRows} handleSearch={handleSearch} />
              <div className="">
                <Pagination color={"secondary"} count={totalPage} page={tbPage} onChange={handlePageChange} />
              </div>
            </Card>
          </SuiBox>
        </SuiBox>
      );
    }
    return <Loader />;
  }

  return (
    <div>
      {
        page > 0 ?
          <Popup
            openPopup={openPopUp}
            setOpenPopup={() => { setOpenPopup(false) }}
          >
            <CustomPopup name={popUpName} id={currentOrder}></CustomPopup>
          </Popup> : <></>
      }
      <DashboardLayout>
        <DashboardNavbar />
        <Loader loading={loading}>
          <SuiBox mb={3}>
            <LabelFilterPanel
              currentFilter={currentFilter}
              handleClearAndRefresh={handleClearAndRefresh}
              fetchAllData={fetchAllData}
              allStatus={allStatusWithCount}
              handleChangeRows={handleChangeRows}
            />
          </SuiBox>
          <DataTablePanel />
        </Loader>
        
      </DashboardLayout>
    </div>
  );
}
