import CryptoApi from "api/crypto";
import WalletApi from "api/wallet";
import initalValue from "entities/base/initalValue";
import FirebaseGateway from "./firebaseGateway";

const Scheme = {
    id: initalValue.String,
    shortName: initalValue.String,
    cryptoName: initalValue.String,
    chineseName: initalValue.String,
    exchangeRate: initalValue.String,
    networks: initalValue.String,
    totalCrypto: initalValue.Double
}

export default class CryptoGateway {
    constructor() { }

    getScheme() {
        return Scheme;
    };

    async getAllData() {
        let firebaseGateway = new FirebaseGateway();
        let latestToken = await firebaseGateway.getToken();

        return await CryptoApi.list({
            id_token: latestToken
        })
            .then(response => {
                if (response.status === 200) {
                    return {
                        data: this.formatCryptosData(response.data.data)
                    };
                }
            })
    }

    async getExchangeRate(fromCryptoType, toCryptoType) {
        return await WalletApi.getExchangeRateFromCoinBaseByCurrency(fromCryptoType)
            .then((res) => {
                const rates = res.data.data.rates;
                if (rates) {
                    return rates[toCryptoType];
                }
            })
            .catch((err) => {
                console.log(err);
                return 0;
            });
    }

    formatCryptoData(apiData, index) {
        const {
            name, full_name, chinese_name, networks, total_amount
        } = apiData;

        return {
            id: index,
            shortName: name,
            cryptoName: full_name,
            chineseName: chinese_name,
            exchangeRate: this.getExchangeRate(name, "HKD"),
            networks: networks.length >= 1 ? networks.map((data) => data.network + " ") : "",
            totalCrypto: total_amount
        };
    }

    formatCryptosData(apiData) {
        console.log(apiData);
        return apiData.map((data, index) => {
            return this.formatCryptoData(data, index);
        });
    }
}