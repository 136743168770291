/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Soft UI Dashboard React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Soft UI Dashboard React layouts
import Adv1 from "layouts/adv1";
import Adv2 from "layouts/adv2";
import AnalysisBoard from "layouts/analysis";
import SignIn from "layouts/authentication/sign-in";
import SignOut from "layouts/authentication/sign-out";
import Crypto from "layouts/crypto";
import Dashboard from "layouts/dashboard";
import Game from "layouts/game";
import General from "layouts/general";
import Offer from "layouts/offer";
import Official from "layouts/official";
import Order from "layouts/order";
import OTC from "layouts/otc";
import OTCADV from "layouts/otc_adv";
import OTCREGTRANS from "layouts/otc_regular_trans";
import OTCPRIVATETRANS from "layouts/otc_private_trans";
import Staffs from "layouts/staffs";
import User from "layouts/user";
import Shop from "layouts/shop";
import WalletBalance from "layouts/wallet_balance";
import WalletExchange from "layouts/wallet_exchange";
import WalletSavingPlan from "layouts/savingPlan_regular";
import WalletFixedSavingPlan from "layouts/savingPlan_fixed";
import MonthlySavingPlan from "layouts/savingPlan_monthly";
import ExtraRewardSavingPlan from "layouts/savingPlan_extraReward";
import WalletTopUp from "layouts/wallet_topup";
import WalletTransfer from "layouts/wallet_transfer";
import WalletWelcomeGift from "layouts/wallet_welcomeGift";
import WalletWithDraw from "layouts/wallet_withdraw";
import Document from "components/examples/Icons/Document";
import OTCUser from "layouts/otc_user";

// @mui icons
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import WalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FeaturedVideoIcon from '@mui/icons-material/FeaturedVideo';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import GroupIcon from '@mui/icons-material/Group';
import OfferIcon from '@mui/icons-material/LocalOffer';
import LogoutIcon from '@mui/icons-material/Logout';
import PaidIcon from '@mui/icons-material/Paid';
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import QuizIcon from '@mui/icons-material/Quiz';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SavingsIcon from '@mui/icons-material/Savings';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import TransformIcon from '@mui/icons-material/Transform';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PushPinIcon from '@mui/icons-material/PushPin';
import RedeemIcon from '@mui/icons-material/Redeem';
import StoreIcon from '@mui/icons-material/Store';
import SecurityIcon from '@mui/icons-material/Security';
import PublicIcon from '@mui/icons-material/Public';

const otc = {
  version: 2,
  v1: {
    type: "collapse",
    name: "OTC",
    key: "otc1",
    route: "/otc",
    icon: <StoreMallDirectoryIcon size="12px" />,
    component: OTC,
    noCollapse: true,
    protected: true,
  },
  v2: {
    type: "listItem",
    name: "OTC",
    key: "otc2",
    route: "/otc",
    routes: [
      {
        type: "collapse",
        name: "OTC",
        key: "OTC",
        route: "/otc",
        icon: <StoreIcon size="12px" />,
        noCollapse: true,
      },
      {
        type: "collapse",
        name: "Advertisement",
        key: "OTCAdvertisement",
        route: "/otcAdvertisement",
        icon: <FeaturedVideoIcon size="12px" />,
        noCollapse: true,
      },
      {
        type: "collapse",
        name: "RegularTransaction",
        key: "OTCRegularTransaction",
        route: "/otcRegularTransaction",
        icon: <PublicIcon size="12px" />,
        noCollapse: true,
      },
      {
        type: "collapse",
        name: "PrivateTransaction",
        key: "OTCPrivateTransaction",
        route: "/otcPrivateTransaction",
        icon: <SecurityIcon size="12px" />,
        noCollapse: true,
      }
    ],
    icon: <StoreMallDirectoryIcon size="12px" />,
    noCollapse: true,
    protected: true,
  }
};

const switchVersion = (name) =>{
  const version = name.version;
  return name['v'+version];
}

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <DashboardIcon size="12px" />,
    component: Dashboard,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    route: "/users",
    icon: <PersonOutlineIcon size="12px" />,
    component: User,
    noCollapse: true,
    protected: true,
  },
  {
    type: "collapse",
    name: "Shops",
    key: "shops",
    route: "/shops",
    icon: <StoreIcon size="12px" />,
    component: Shop,
    noCollapse: true,
    protected: true,
  },
  {
    type: "collapse",
    name: "Offers",
    key: "offers",
    route: "/offers",
    icon: <OfferIcon size="12px" />,
    component: Offer,
    noCollapse: true,
    protected: true,
  },
  {
    type: "collapse",
    name: "Orders",
    key: "orders",
    route: "/orders",
    icon: <ReceiptLongIcon size="12px" />,
    component: Order,
    noCollapse: true,
    protected: true,
  },
  {
    route: "/walletExchange",
    component: WalletExchange,
    key: "_walletExchange",
    protected: true
  },
  {
    route: "/walletTopUp",
    component: WalletTopUp,
    key: "_walletTopUp",
    protected: true
  },
  {
    route: "/walletBalance",
    component: WalletBalance,
    key: "_walletBalance",
    protected: true
  },
  {
    route: "/walletWithDraw",
    component: WalletWithDraw,
    key: "_walletWithDraw",
    protected: true
  },
  {
    route: "/savingPlan",
    component: WalletSavingPlan,
    key: "_savingPlan",
    protected: true
  },
  {
    route: "/fixedSavingPlan",
    component: WalletFixedSavingPlan,
    key: "_fixedSavingPlan",
    protected: true
  },
  {
    route: "/monthlySavingPlan",
    component: MonthlySavingPlan,
    key: "_monthlySavingPlan",
    protected: true
  },
  {
    route: "/extraRewardSavingPlan",
    component: ExtraRewardSavingPlan,
    key: "_extraReward",
    protected: true
  },
  {
    route: "/walletTransfer",
    component: WalletTransfer,
    key: "_WalletTransfer",
    protected: true
  },
  {
    route: "/walletWelcomeGift",
    component: WalletWelcomeGift,
    key: "_walletWelcomeGift",
    protected: true
  },
  {
    type: "listItem",
    name: "Wallets",
    key: "wallets",
    route: "/wallets",
    routes: [
      {
        type: "collapse",
        name: "Balance",
        key: "walletBalance",
        route: "/walletBalance",
        icon: <AccountBalanceIcon size="12px" />,
        noCollapse: true,
      //  protected: true,
      },
      {
        type: "collapse",
        name: "WithDraw",
        key: "walletWithDraw",
        route: "/walletWithDraw",
        icon: <FileUploadIcon size="12px" />,
        noCollapse: true,
     //   protected: true,
      },
      {
        type: "collapse",
        name: "TopUp",
        key: "walletTopUp",
        route: "/walletTopUp",
        icon: <FileDownloadIcon size="12px" />,
        noCollapse: true,
     //   protected: true,
      },
      {
        type: "collapse",
        name: "Exchange",
        key: "walletExchange",
        route: "/walletExchange",
        icon: <CurrencyExchangeIcon size="12px" />,
        noCollapse: true,
     //   protected: true,
      },
      {
        type: "collapse",
        name: "Transfer",
        key: "WalletTransfer",
        route: "/walletTransfer",
        icon: <TransformIcon size="12px" />,
        noCollapse: true,
     //   protected: true,
      },
      {
        type: "collapse",
        name: "Welcome Gifts",
        key: "walletWelcomeGift",
        route: "/walletWelcomeGift",
        icon: <CardGiftcardIcon size="12px" />,
        noCollapse: true,
     //   protected: true,
      }
    ],
    icon: <WalletIcon size="12px" />,
    noCollapse: true,
    protected: true,
  },
  {
    type: "listItem",
    name: "SavingPlan",
    key: "savingplan",
    route: "/savingplan",
    routes: [
      {
        type: "collapse",
        name: "Current Saving Plan",
        key: "SavingPlan",
        route: "/savingPlan",
        icon: <SavingsIcon size="12px" />,
        noCollapse: true,
     //   protected: true,
      },
      {
        type: "collapse",
        name: "Fixed Saving Plan",
        key: "FixedSavingPlan",
        route: "/fixedSavingPlan",
        icon: <PushPinIcon size="12px" />,
        noCollapse: true,
     //   protected: true,
      },
      { 
        type: "collapse",
        name: "Monthly Saving Plan",
        key: "MonthlySavingPlan",
        route: "/monthlySavingPlan",
        icon: <CalendarTodayIcon size="12px" />,
        noCollapse: true,
    //   protected: true,
      },
      { 
        type: "collapse",
        name: "Extra Reward",
        key: "ExtraReward",
        route: "/extraRewardSavingPlan",
        icon: <RedeemIcon size="12px" />,
        noCollapse: true,
    //   protected: true,
      }
    ],
    icon: <SavingsIcon size="12px" />,
    noCollapse: true,
    protected: true,
  },
  {
    route: "/otc",
    component: OTCUser,
    key: "OTC",
    protected: false
  },
  {
    route: "/otcAdvertisement",
    component: OTCADV,
    key: "OTCAdvertisement",
    protected: true
  },
  {
    route: "/otcRegularTransaction",
    component: OTCREGTRANS,
    key: "OTCRegularTransaction",
    protected: true
  },
  {
    route: "/otcPrivateTransaction",
    component: OTCPRIVATETRANS,
    key: "OTCPrivateTransaction",
    protected: true
  },
    switchVersion(otc),
  {
    type: "collapse",
    name: "Crypto",
    key: "crypto",
    route: "/crypto",
    icon: <PaidIcon size="12px" />,
    component: Crypto,
    noCollapse: true,
    protected: true,
  },
  {
    route: "/adv1",
    component: Adv1,
    key: "_adv1"
  },
  {
    route: "/adv2",
    component: Adv2,
    key: "_adv2"
  },
  {
    route: "/official",
    component: Official,
    key: "_official"
  },
  {
    type: "listItem",
    name: "Advertisement",
    key: "advs",
    route: "/advs",
    routes: [
      {
        type: "collapse",
        name: "Adv1",
        key: "adv1",
        route: "/adv1",
        icon: <FeaturedVideoIcon size="12px" />,
        component: Adv1,
        noCollapse: true,
        protected: true,
      },
      {
        type: "collapse",
        name: "Adv2",
        key: "adv2",
        route: "/adv2",
        icon: <FeaturedVideoIcon size="12px" />,
        component: Adv2,
        noCollapse: true,
        protected: true,
      },
      {
        type: "collapse",
        name: "Official",
        key: "official",
        route: "/official",
        icon: <FeaturedVideoIcon size="12px" />,
        component: Official,
        noCollapse: true,
        protected: true,
      }
    ],
    icon: <FeaturedVideoIcon size="12px" />,
    noCollapse: true,
    protected: true,
  },
  {
    type: "collapse",
    name: "Staffs",
    key: "staffs",
    route: "/staffs",
    icon: <GroupIcon size="12px" />,
    component: Staffs,
    noCollapse: true,
    protected: true,
  },
  { type: "title", title: "Game", key: "game" },
  {
    type: "collapse",
    name: "45s Competition",
    key: "competition",
    route: "/game",
    icon: <QuizIcon size="12px" />,
    component: Game,
    noCollapse: true,
    protected: true,
  },
  { type: "title", title: "Analysis", key: "analysis" },
  {
    type: "collapse",
    name: "Analysis",
    key: "analysisboard",
    route: "/analysis",
    icon: <AutoGraphIcon size="12px" />,
    component: AnalysisBoard,
    noCollapse: true,
    protected: true,
  },
  {
    type: "collapse",
    name: "General",
    key: "general",
    route: "/general",
    icon: <QuestionAnswerIcon size="12px" />,
    component: General,
    noCollapse: true,
    protected: true,
  },
  {
    type: "none",
    name: "Sign In",
    key: "sign-in",
    route: "/authentication/sign-in",
    icon: <Document size="12px" />,
    component: SignIn,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Logout",
    key: "sign-out",
    route: "/authentication/sign-out",
    icon: <LogoutIcon size="12px" />,
    component: SignOut,
    noCollapse: true,
  },
];

export default routes;
