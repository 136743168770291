import '../styles.css';

import { useState, useEffect } from "react";

import SuiButton from "components/Default/SuiButton";
import SuiBox from "components/Default/SuiBox";

import InputWrapper from 'components/Custom/input_wrapper';
import SimpleSnackbar from "components/Custom/snack_bar";
import AuthCodeHelper from "util/AuthCodeHelper";

import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import UpdateIcon from '@mui/icons-material/Update';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { GENERAL_PERMISSION } from 'config/permission';
import { useAlert } from 'react-alert';
import "./style.css";
// Call API
import GeneralApi from "api/general";
import AuthApi from 'api/auth';
import { sizeof } from 'stylis';

export default function ServiceFeeSetting ({ type }) {
    const alert = useAlert();

    const [page, setPage] = useState(type ?? 0);
    const [editedValue, setEditValue] = useState([]);
    const [auth, setAuth] = useState(false);

    const [inputs, setInputs] = useState({});    // control all inputs
    const [disable, setDisable] = useState({});
    const [action, setAction] = useState(-1);

    const handleChange = e => setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));

    const handleBack = (...args) => {
        if(editedValue){
            setEditValue(editedValue => ({ ...editedValue, [args[0]]: args[1] }));
        } else{
            setEditValue({ [args[0]]: args[1] });
        }
        setPage(0);
    } 

    const handleEdit = () => {
        setPage(1);
    } 

    const handleRemoveItem = (...args) => {
        //console.log(args[0]);
        if(editedValue.length < 2){
            setEditValue([]);
        }else{
            //console.log(editedValue);
            let new_arr = [...editedValue];
            new_arr.splice(args[0], 1);
            //console.log(new_arr);
            setEditValue(new_arr);
        }
    }

        const handleAuthCode = (resp) => {
            // console.log(resp);
    
            if(resp){
                if(resp.isAuth){
                    setAuth(true);
                    setPage(0);
                } else{
                    alert.error(resp.message);
                }
            }
            else{
                alert.error(ERROR.callback);
            }
        }

    const handleAuth = () => {
        setPage(2);
    }

    const editItem = (key) => {
        setAction(1);
        if(!auth){
            setPage(2);
        } else{
            setDisable(prevState => ({ ...prevState, [key]: disable[key] ? false : true }));
        }
    }
    /* const removeItem = (key) => {
        setInputs(prevState => ({ ...prevState, [key]: null }));
    } */

    const updateItem = async (key, newValue, oldValue) => {
        setAction(2);
        //console.log(newValue);
        if(!auth){
            setPage(2);
        } else{
            if(newValue !== oldValue){

                await GeneralApi.editServiceFee(
                    {
                        "Key": key,
                        "Value": newValue
                    }
                ).then((res) => {
                    if(res && res.status === 200){
                        alert.success("Edit Data Success");
                    }
    
                }).catch((err) => {
                    alert.error(err.response.message);
                });
    
            } else{
                alert.error("Data not changed");
            }
        }
    }

    const addItem = async (key, newValue) => {
        setAction(0);
        if(!auth){
            setPage(2);
        } else{
            if(!isNaN(newValue)){
                await GeneralApi.addServiceFee(
                    {
                        "Key": key,
                        "Value": newValue
                    }
                ).then((res) => {
                    if(res && res.status === 200){
                        alert.success("Add Data Success");
                    }
                }) .catch((err) => {
                    alert.error("Data Not Changed");
                });
    
            } else{
                alert.error("Data is not a number");
            }
        }
    }

    function ServiceFeeSettingPopup ({ actionEdit, editParmas, removeItem }) {
        const [serviceFeeData, setServiceFeeData] = useState([]); 
    
        const fetchServiceFeeData = async () => {
    
            await GeneralApi.list()
                .then((res) => {
                    if(res && res.status === 200){
                        setServiceFeeData(res.data.data.service_fees);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        };
        
        useEffect(()=>{
            if(editParmas.length !== 0 && serviceFeeData.length >= 1){
                    //console.log(editParmas);
                    //console.log(serviceFeeData);
                    setServiceFeeData(prevState => ({ ...prevState, editParmas}));
                    //console.log(serviceFeeData);
                } else {
                    fetchServiceFeeData();
                }
        }, [])
    
        return (
            <>
                <div id="popup1" className="popup-wrapper">
                        <div id="" className="popup1-flex-column">
                            <h4>Service Fee Setting</h4>
                            <div className="popup-content">
                                {
                                    serviceFeeData ? Object.entries(serviceFeeData).map(([key, value]) => {
                                        return (
                                        <div className="popup-form-row">
                                            <SuiBox xs={2} lg={3} xl={6} width="100%">
                                                <InputWrapper attr={key} name={key} value={inputs[key] ?? value} size="lg" disable={!disable[key]} onChange={handleChange}/> 
                                            </SuiBox>
                                            
                                            <ToggleButtonGroup
                                                color="primary"
                                                exclusive
                                                onChange={handleChange}>
                                                
                                                {
                                                    disable[key] ?
                                                        <ToggleButton value="update" onClick={() => updateItem(key, inputs[key], value)}>
                                                            <UpdateIcon></UpdateIcon>
                                                        </ToggleButton>
                                                        :
                                                        <ToggleButton value="edit" onClick={() => editItem(key)}>
                                                            <EditIcon></EditIcon>
                                                        </ToggleButton>
                                                }
    
                                                <ToggleButton value="delete" onClick={() => removeItem(key)}>
                                                    <DeleteIcon></DeleteIcon>
                                                </ToggleButton>
    
                                            </ToggleButtonGroup>
    
                                        </div>)
                                    }) : <></>
                                }
    
                                {
                                    editParmas ? Object.entries(editParmas).map(([key, value]) => {
                                        return (
                                        <div className="popup-form-row">
                                            <SuiBox xs={2} lg={3} xl={6} width="100%">
                                                <InputWrapper attr={key} name={key} value={inputs[key] ?? value} size="lg" disable={false} onChange={handleChange}/> 
                                            </SuiBox>
    
                                            <SuiButton onClick={() => addItem(key, inputs[key] ?? value)}>
                                                <UpdateIcon></UpdateIcon>
                                            </SuiButton>
                                            
    
                                            {/* <SuiButton onClick={() => removeItem(key)}>
                                                <DeleteIcon></DeleteIcon>
                                            </SuiButton> */}
                                        </div>)
                                    }) : <></>
                                }
                            </div>
    
                            <SuiButton onClick={actionEdit}>
                                <AddCircleIcon></AddCircleIcon>
                            </SuiButton>
    
                            {/* <div className="popup-form-row">
                                <SuiButton buttonColor="secondary" onClick={auth ? () => EditServiceFee(serviceFeeData) : actionAuth}>Update</SuiButton>
                            </div> */}
                            
                        </div>
                    </div>
            </>
        );
    }

    if (page === 0){
        return <ServiceFeeSettingPopup
            actionEdit={handleEdit} 
            editParmas={editedValue} 
            removeItem={handleRemoveItem} 
            handleAuth={handleAuth}
            />
    } else if(page === 1){
        return <EditServiceFeeSetting
            actionBack={handleBack}/>;
    } else if(page === 2){
        return <AuthCodeHelper title="Admin Authentication" btnName="Confirm" permission={GENERAL_PERMISSION.EDIT} callback={handleAuthCode}/>;
    }
}

/* async function EditServiceFee(inputs) {

    try {
        let response = await GeneralApi.editServiceFee(inputs);
            if (response.data && response.data.success === false) {
            }
            let res = response.data;
          
            if(res.message === "success"){
                console.log("update success");
            }

    } catch (err) {
      console.log(err);
    }
  } */

function EditServiceFeeSetting ({ actionBack }) {

    const [serviceFeeName, setServiceFeeName] = useState("");
    const [serviceFeeVal, setServiceFeeValue] = useState("");

    return (
        <div id="popup1" className="popup-wrapper">
            <div id="" className="popup1-flex-column">
                <h4>Service Fee Setting</h4>
                <div className="popup-content">
                    <SuiBox xs={2} lg={3} xl={6} width="100%">
                        <InputWrapper attr="Fee Name" value={serviceFeeName} 
                            onChange={(event) => {setServiceFeeName(event.target.value)}} disable={false} size="lg"/> 
                    </SuiBox>
                    <SuiBox xs={2} lg={3} xl={6} width="100%"> 
                        <InputWrapper attr="Value" value={serviceFeeVal} 
                            onChange={(event) => {setServiceFeeValue(event.target.value)}} disable={false} size="lg"/> 
                    </SuiBox>
                </div>

                <div className="popup-form-row">
                    <SuiButton buttonColor="secondary" onClick={() => actionBack(serviceFeeName, serviceFeeVal)}>Add</SuiButton>
                </div>
                
            </div>
        </div>
    );
}
