import axios from "./index";

class AdvsApi {

  static list = () => {
    const id_token = localStorage.getItem("id_token");
    return axios.get(`${base}`, { headers: {"Authorization" : `Bearer ${id_token}`} });
  };

  static getListByPosition = (data) => {
    const id_token = localStorage.getItem("id_token");
    if(data){
      return axios.get(`${base}?position=${data.position}&page=${data.page}&${data.statusStatement}`, { headers: {"Authorization" : `Bearer ${id_token}`} });
    }
    throw "Position is missing";
  };

  static getOneAdvByFid = (id) => {
    const id_token = localStorage.getItem("id_token");
    if(id){
      return axios.get(`${base}/${id}`, { headers: {"Authorization" : `Bearer ${id_token}`} });
    }
    throw "Adv id is missing";
  };

  static acceptAdv = (id) => {
    const id_token = localStorage.getItem("id_token");
    if(id){
      return axios.put(`${base}/${id}/accept`, { headers: {"Authorization" : `Bearer ${id_token}`} });
    }
    throw "Adv id is missing";
  };

  static rejectAdv = (data) => {
    const id_token = localStorage.getItem("id_token");
    if(data.id && data.rejected_reason){
      return axios.put(`${base}/${data.id}/reject`, { rejected_reason: data.rejected_reason }, { headers: {"Authorization" : `Bearer ${id_token}`} });
    }
    throw "Adv id or reject reason is missing";
  };

  static blockAdv = (data) => {
    const id_token = localStorage.getItem("id_token");
    if(data.id){
      return axios.put(`${base}/${data.id}/block`, { headers: {"Authorization" : `Bearer ${id_token}`} });
    }
    throw "Adv id is missing";
  };

  static unblockAdv = (id) => {
    const id_token = localStorage.getItem("id_token");
    if(id){
      return axios.put(`${base}/${id}/unblock`, { headers: {"Authorization" : `Bearer ${id_token}`} });
    }
    throw "Adv id is missing";
  };

  static addAdv = (data) => {
    const id_token = localStorage.getItem("id_token");
    if(data){
      return axios.post(`${base}`, data, { headers: {"Authorization" : `Bearer ${id_token}`} });
    }
    throw "Request data is not found";
  }
}

let base = "/advertisements";

export default AdvsApi;
