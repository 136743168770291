import * as React from 'react';
import Viewer from 'react-viewer';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import Box from '@mui/material/Box';

const fakeImg = 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/70/Checkerboard_pattern.svg/1200px-Checkerboard_pattern.svg.png';

const openViewer = (type, src) => {
    switch (type) {
        case 0:
            let singleSrc = [{ src: src }];
            return singleSrc;
        case 1:
            let multiSrc = Array.isArray(src) ? src.map(image => ({ src: image }) ) : [{ src: fakeImg }];
            return multiSrc;
        case 2:
            let doubleSrc = [
                { src: src[0] ?? fakeImg },
                { src: src[1] ?? fakeImg }
            ];
            return doubleSrc;
        default:
            break;
    }
}

const image = (src) => {
    if(src && src !== ""){
        //console.log(Array.isArray(src));
        if(Array.isArray(src)){         
            return <Box sx={{  display: 'flex', flexDirection: 'row', width: '35rem', flexWrap: 'wrap' }}> 
                    {
                        src.map(
                            (data, index) => <Card.Img className="card-img-top" style={{ marginRight: 5 }} height={100} width={100} src={data} key={index} /> )
                    }
                    </Box>
        }
        return <Card.Img className="card-img-top" height={100} width={100} src={src} />
    }
    return <Card.Img className="card-img-top" height={100} width={100} src={fakeImg} />
}

const ImageViewer = ({ headerText, clickText, type, src, title }) => {
    const [ visible, setVisible ] = React.useState(false);

    const images = openViewer(type, src);

    return (
        <div>
            <Card>
                {
                    title ?
                        <>
                            <Card.Text className="font-weight-bold">{headerText}</Card.Text>
                            <Card.Body>
                                { image(src) }
                            </Card.Body>
                        </> : null
                }
                <a href='#' onClick={(event) => { event.preventDefault(); setVisible(true); } }><h6 style={{ color: '#D1D1D1', fontWeight: '400' }}>{clickText}</h6></a>
            </Card>

            <Viewer
                visible={visible}
                onClose={() => { setVisible(false); } }
                images={images}
                zIndex="10001"
            />
        </div>
    );
}

export default ImageViewer;

ImageViewer.propTypes = {
    headerText: PropTypes.string.isRequired,
    clickText: PropTypes.string.isRequired
}

ImageViewer.defaultProps = {
    headerText: '',
    clickText: ''
}