/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React components
import { CachedOutlined } from "@material-ui/icons";
import ClearIcon from '@mui/icons-material/Clear';
import { Card, Chip, IconButton, Pagination } from "@mui/material";
import GameApi from "api/game";
import Popup from "assets/theme/components/popup";
import { DataTable, Loader } from "components/Custom";
import "components/Custom/common.css";
import { SuiBox, SuiButton } from "components/Default";
import DashboardLayout from "components/examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/examples/Navbars/DashboardNavbar";
import { constant } from 'config/constant';
import CompetitionGateway from "entities/competitionGateway";
import moment from "moment";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Link } from 'react-router-dom';
import { NullFormatUtils } from "util/index";
import { CustomPopup } from "./components";
import "./style.css";
import { status } from './variable';

let competitionGateway = new CompetitionGateway();

function Game() {
  const alert = useAlert();
  //pop up pages
  const [page, setPage] = useState(0);
  const [openPopUp, setOpenPopup] = useState(false);
  const [popUpName, setPopUpName] = useState("");
  const [currentStaff, setCurrentStaff] = useState();
  const [title, setTitle] = useState("");
  const handlePopUp = (pName, id, title) => {
    setPopUpName(pName);
    setOpenPopup(true);
    setPage(1);
    setTitle(title);
    if (id) setCurrentStaff(id);
  };

  const [rows, setRows] = useState([]);
  const [currentFilter, setCurrentFilter] = useState(0);
  const [filterRows, setFilterRows] = useState([]);
  const [statusStatement, setStatusStatement] = useState(null);
  const [loading, setLoading] = useState(false);

  const [tbPage, setTbPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  let testMode = constant.DEBUG;

  const handlePageChange = (event, value) => {
    console.log(value);
    if (value <= totalPage) {
      setTbPage(value);
      fetchAllData(value);
    }
  };

  const fetchAllData = async (page, _statusStatement, searchValue) => {
    setLoading(true);

    const data_length = await competitionGateway.getAllData({
      page: page ?? tbPage,
      statusStatement: _statusStatement ?? (statusStatement ?? null),
      searchValue: searchValue ?? null
    })
      .then((res) => {
        if (res) {
          if (page === 0) {
            return res.total;
          }
          const row_sort = res.data.sort(function (a, b) {
            var dateA = new Date(a.start_at), dateB = new Date(b.start_at);
            return dateB - dateA;
          });
          setRows(row_sort);
          setFilterRows(row_sort);
          setTotalPage(res.last_page);
        }
        setLoading(false);
      })
      .catch((err) => {
        alert.error(err.message);
      })

    if (page === 0) return data_length
  };

  const handleCompleteResult = async () => {
    await GameApi.updateCompetitionResult()
      .then((res) => {
        if (res.status === 200) {
          alert.success("update result success, please refresh");
        }
      })
      .catch((err) => {
        alert.error(err.message);
      });
  }

  const handleCalAvgPrice = (total_pool, total_winners) => {
    if (total_pool && total_winners) {
      if (total_pool > 0 && total_winners > 0) {
        return total_pool / total_winners;
      }
      else if (total_pool > 0 && total_winners === 0) {
        // No users is winner
        return total_pool;
      }
      return 0;
    }
    return 0;
  }

  const handleCalBalance = (avg_prize, total_prize_receive, total_pool) => {
    if (avg_prize && total_prize_receive) {
      if (avg_prize > 0 && total_prize_receive > 0) {
        return avg_prize * total_prize_receive - total_pool;
      }
      else if (avg_prize > 0 && total_prize_receive === 0) {
        // No users receive prize
        return total_pool;
      }
      return 0;
    }
    return 0;
  }

  useEffect(() => {
    fetchAllData();
  }, []);

  const dt_columns = [
    {
      headerName: 'Date',
      field: 'start_at',
      width: 350,
      renderCell: ({ row }) => NullFormatUtils(moment(row.startAt).format('YYYY/MM/DD h:mm a')) + " - " + NullFormatUtils(moment(row.endAt).format('YYYY/MM/DD h:mm a')),
      sortable: true,
    },
    /* {
      headerName: 'End Date',
      field: 'end_at',
      width: 200,
      renderCell: ({ row }) => NullFormatUtils(row.end_at),
      sortable: true,
    }, */
    {
      headerName: 'Challenge ID',
      field: 'id',
      width: 120,
      renderCell: ({ row }) => NullFormatUtils(row.id),
      sortable: true,
    },
    {
      headerName: 'Registration',
      field: 'registrant_amount',
      width: 120,
      renderCell: ({ row }) => row.statsRegistrantAmount ? <Link to="#" className="text-secondary" onClick={() => handlePopUp("ShowUsersPopup", row.id, "Registration")}>{NullFormatUtils(row.statsRegistrantAmount)}</Link> : "-",
      sortable: true,
    },
    /* {
      headerName: 'Participation',
      field: 'numOfPeople',
      width: 200,
      renderCell: ({ row }) => row.stats?.participant_amount ? <Link to="#" className="text-secondary" onClick={() => handlePopUp("ShowUsersPopup", row.id, "Participation")}>{NullFormatUtils(row.stats?.participant_amount)}</Link> : "-",
      sortable: true,
    }, */
    {
      headerName: 'Prize Received',
      field: 'claimed_amount',
      width: 150,
      renderCell: ({ row }) => row.statsClaimedAmount ?? null,
      sortable: true,
    },
    {
      headerName: 'Prize Pool (USDT)',
      field: 'prizePool',
      width: 150,
      renderCell: ({ row }) => '$' + NullFormatUtils(row.statsTotalPoolShare),
      sortable: true,
    },
    /* {
      headerName: 'Winners',
      field: 'winners',
      width: 200,
      renderCell: ({ row }) => row.stats?.winner_amount ? <Link to="#" className="text-secondary" onClick={() => handlePopUp("ShowUsersPopup", row.id, "Winners")}>{NullFormatUtils(row.stats?.winner_amount)}</Link> : "-",
      sortable: true,
    }, */
    {
      headerName: 'Average Prize (USDT)',
      field: 'avgPrize',
      width: 200,
      renderCell: ({ row }) => '$' + handleCalAvgPrice(row.statsTotalPoolShare, row.statsWinnerAmount),
      sortable: true,
    },
    {
      headerName: 'Balance (USDT)',
      field: 'balance',
      width: 150,
      renderCell: ({ row }) => '$' +
        handleCalBalance(
          handleCalAvgPrice(row.statsTotalPoolShare, row.statsWinnerAmount),
          row.stats?.total_prize_receive, // TODO: not found
          row.statsTotalPoolShare
        ),
      sortable: true,
    },
    {
      headerName: 'Status',
      field: 'status',
      width: 150,
      renderCell: ({ row }) => row.statsEvaluated || row.status !== "FINISHED" ?
        NullFormatUtils(row.status)
        : <>
          <SuiButton
            buttonColor="error"
            onClick={() => { handleCompleteResult() }}>
            Need evaluate
          </SuiButton>
        </>,
      sortable: true,
    },
    {
      headerName: 'Question List',
      field: 'questions',
      width: 180,
      renderCell: ({ row }) =>
        row.status ?
          <Link to="#" className={row.status === "NOT_YET_STARTED" ? "text-error" : "text-secondary"} onClick={() => handlePopUp("QuestionPopup", row.id, 3)}>
            {row.status === "NOT_YET_STARTED" ? "Add Questions" : "View"}
          </Link> : "-",
      sortable: true,
    }
  ];

  const StatusPanel = () => {
    return (
      status.map((row, key) => {
        return (
          <Chip
            label={row.label + " (" + countRows(row) + ")"}
            key={key}
            className={currentFilter !== row.value ? "muiChip " + row.class : "muiChip " + row.class + "-selected"}
            style={{ width: '12rem', marginRight: '1rem' }}
            onClick={() => changeRows(row)} />
        )
      })
    );
  }

  const changeRows = (filter) => {
    let new_row = filterRows;
    if (rows.length >= 1) {
      new_row = rows.filter(row => row.status === filter.label);
    }

    setCurrentFilter(filter.value);
    setFilterRows(new_row);
  };

  const countRows = (filter) => {
    let new_row = filterRows;

    if (rows.length >= 1) {
      new_row = rows.filter(row => row.status === filter.label);
    }
    return new_row.length;
  }

  return (
    <div>
      {
        page > 0 ?
          <Popup
            openPopup={openPopUp}
            setOpenPopup={() => { setOpenPopup(false) }}
          >
            <CustomPopup name={popUpName} id={currentStaff} title={title} />
          </Popup> : <></>
      }
      <DashboardLayout>
        <DashboardNavbar />

        <Loader loading={loading}>
          <>
            <Card sx={{ p: 3, flexDirection: 'row', columnGap: '50' }}>
              {
                currentFilter === 0 ?
                  <></> :
                  <IconButton color="error" onClick={() => { setFilterRows(rows); setCurrentFilter(0) }}>
                    <ClearIcon />
                  </IconButton>
              }
              <SuiButton
                style={{ marginRight: '1rem' }}
                buttonColor="secondary"
                onClick={() => handlePopUp("QuestionPopup", null, 1)}>
                Question Poll
              </SuiButton>

              <SuiButton
                style={{ marginRight: '1rem' }}
                buttonColor="secondary"
                onClick={() => handlePopUp("QuestionPopup", null, 4)}>
                Game Mgt
              </SuiButton>

              <SuiButton
                style={{ marginRight: '1rem' }}
                buttonColor="secondary"
                onClick={() => fetchAllData()}>
                <CachedOutlined />
                Refresh
              </SuiButton>

              <StatusPanel />
            </Card>

            <SuiBox py={3}>
              <SuiBox sx={{ display: { xs: 'none', md: 'block' } }}>
                <Card>
                  <DataTable dt_columns={dt_columns} dt_rows={filterRows} />
                  <Pagination color={"secondary"} count={totalPage} page={tbPage} onChange={handlePageChange} />
                </Card>
              </SuiBox>
            </SuiBox>
          </>
        </Loader>

      </DashboardLayout>
    </div>
  );
}

export default Game;
