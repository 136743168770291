import { Grid } from "@material-ui/core";
import '../styles.css';

import { useState, useEffect } from "react";
import InputWrapper from 'components/Custom/input_wrapper';

import { ImageURLExistHelper } from 'util/index';
import { NullFormatUtils } from "util/index";

import OrderApi from 'api/order';

export function OrderDetailsPopup ({ id }) {
    const [orderData, setOrderData] = useState([]); 
    //console.log(id);

    const fetchOneOrderData = async (id) => {

        await OrderApi.getOneOrderByFid(id)
            .then((res) => {
                if (res && res.status === 200){
                    setOrderData(res.data.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(()=>{
        fetchOneOrderData(id);
    }, []);

    const HistoryPanel = () => {
        return (
            <div className="popup-content">
                { orderData.audits ? 
                    orderData.audits.length > 0 ? 
                        orderData.audits.map((history, key) => 
                            <InputWrapper key={key} 
                                attr={  typeof history.status === 'object' ? 
                                            history.status?.new
                                            : "-"} 
                                value={history.created_at} 
                                size="lg"/>) 
                        : <></> 
                    : <></>}
            </div>
        );
    };

    return (
        <div id="popup1" className="popup-wrapper">
            <div id="" className="popup1-flex-column">
                <h4>Order Details</h4>
                <div className="popup-content">

                    <InputWrapper attr="Order ID" value={NullFormatUtils(orderData.short_id)} size="sm"/>

                    <Grid container alignItems="center">
                        <Grid item xs={12} sm={8} md={6} xl={6}>
                            <InputWrapper attr="Seller ID" value={orderData.buyer?.account_id ?? "NULL"} size="sm"/>
                        </Grid>
                        <Grid item xs={12} sm={8} md={6} xl={6}>
                            <InputWrapper attr="Buyer ID" value={orderData.seller?.account_id ?? "NULL"} size="sm"/>
                        </Grid>
                    </Grid>

                    <InputWrapper attr="Offer ID" value={NullFormatUtils(orderData.offer_id)} size="sm"/>

                    <Grid container alignItems="center">
                        <Grid item xs={12} sm={8} md={6} xl={6}>
                            <InputWrapper attr="Price" value={NullFormatUtils(orderData.price)} size="sm"/>
                        </Grid>
                        <Grid item xs={12} sm={8} md={6} xl={6}>
                            <InputWrapper attr="Crypto" value={NullFormatUtils(orderData.crypto_type)} size="sm"/>
                        </Grid>
                    </Grid>

                    <Grid container alignItems="center">
                        <Grid item xs={12} sm={8} md={6} xl={6}>
                            <InputWrapper attr="Quantity" value={NullFormatUtils(orderData.quantity)} size="sm"/>
                        </Grid>
                        <Grid item xs={12} sm={8} md={6} xl={6}>
                            <InputWrapper attr="Order Type" value={NullFormatUtils(orderData.order_type)} size="lg"/>
                        </Grid>
                    </Grid>

                    <InputWrapper attr="Create Date" value={NullFormatUtils(orderData.created_at)} size="lg"/>
                    <h4>Status Tracking</h4>
                    <HistoryPanel/>
                </div>
                
            </div>
        </div>
    );
}