import { useState, useEffect } from "react";
import { InputWrapper } from "components/Custom";
import ListView from "./components/list_view";
import { SuiButton, SuiBox } from "components/Default";

import { DateFormatUtils, NullFormatUtils } from "util/index";
import GameApi from 'api/game';
import "./style.css";

export function ShowUsersPopup ({ title, id }) {
    const [gameData, setGameData] = useState([]); 
    const [filterGameData, setFilterGameData] = useState([]);
    const [answerData, setAnswerData] = useState([]);
    const [questionData, setQuestionData] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [page, setPage] = useState(0);
    //console.log(id);

    const handleChangePage = (uid) => {
        console.log(uid);
        //console.log(gameData.length);
        if(uid && page === 0 && gameData.length >= 1){
            const userData = gameData.filter(d => d.account_id === uid);
            //console.log(userData);
            if(answerData.length < 1){
                setAnswerData(userData[0].submit_answer);
            }
            
            if(questionData.length < 5){
                fetchCompetitionById(id);
            }
            
            setPage(1);
        }
        else{
            setPage(0);
        }
    };

    const fillNullDateFormat = (date) => {
        if(date){
            return DateFormatUtils(date, "YYYY-MM-DD hh:mm:ss");
        }
        return "YYYY-MM-DD hh:mm:ss";
    };

    const fetchCompetitionById = async (id) => {
        await GameApi.getOneGameByFid(id)
            .then((res) => {
                if (res.status === 200){
                    console.log(res.data.data);
                    for (let index = 0; index < 5; index++) {
                        const element = res.data.data["question"+(index+1)]
                        fetchQuestionsById(element);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const fetchQuestionsById = async (id) => {
        await GameApi.getQuestionsById(id)
            .then((res) => {
                if (res.status === 200){
                    console.log(res.data.data);
                    const temp = questionData;
                    console.log(questionData);
                    temp.push(res.data.data);
                    setQuestionData(temp);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
    
    const fetchUserData = async (id) => {

        await GameApi.getUsersByGameIdAndPage({
            id: id,
            type: 
                title === "Participation"
                    ? "participated"
                    :
                title === "Winners"
                    ? "is_winner"
                    : 
                title === "Prize Received"
                    ? "reward_claimed"
                    : "",
            page: page
        })
            .then((res) => {
                if (res.status === 200){
                    console.log(res.data.data.data);
                    let users = [];
                    res.data.data.data
                        .map(d => {
                            users.push({
                                account_id: d.account_id,
                                registration: fillNullDateFormat(d.created_at),
                                participation: fillNullDateFormat(d.start_at),
                                submit_time: fillNullDateFormat(d.submission ? d.submission.created_at : null),
                                correct_answer: d.correctly_answered ? d.correctly_answered : "--",
                                prize_received: fillNullDateFormat(d.claimed_at),
                                submit_answer: d.submission
                            });
                        });
                    console.log(users);
                    setGameData(users);
                    setFilterGameData(users);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const UserIdDisplay = () => {
        if(filterGameData && Array.isArray(filterGameData)){
            const title = [
                {
                    name: "Account ID",
                    size: 2
                },
                {
                    name: "Registration",
                    size: 2
                },
                {
                    name: "Participation",
                    size: 2
                },
                {
                    name: "Submited",
                    size: 2
                },
                {
                    name: "Correct Answer",
                    size: 2
                },
                {
                    name: "Prize Received",
                    size: 2
                },
            ];
            return <ListView title={title} data={filterGameData} handleChangePage={handleChangePage}/>
            //return <div> {gameData.map(data => <InputWrapper value={data} width={100}/> )} </div>
        }
        return <></>
    };

    // Search Reg Exp
    function escapeRegExp(value) {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const handleSearchHelper = e  => {
        setSearchValue(e.target.value);
        const searchRegex = new RegExp(escapeRegExp(e.target.value), 'i');
        const new_data = gameData.filter(data => searchRegex.test(data.account_id));
        setFilterGameData(new_data);
    };

    useEffect(()=>{
        fetchUserData(id);
    }, []);

    if(page === 0){
        return (
            <div id="popup1" className="popup-wrapper pop-up-window-size-m">
                <div id="" className="popup1-flex-column">
                    <h4>{title}</h4>
                    <InputWrapper attr="Search" disable={false} value={searchValue} onChange={handleSearchHelper} size="m"/>
                    <div className="popup-content" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                        {filterGameData ? <UserIdDisplay/> : <></>}
                    </div>
                </div>
            </div>
        );
    }
    else if(page === 1){
        return (
            <>
                {
                    answerData ? 
                        <>
                            <div id="popup1" className="popup-wrapper">
                                <div id="" className="popup1-flex-column">
                                    <h4>Answered</h4>
                                    {
                                        questionData.length >=1 ? questionData.map((q, index) => {
                                            return (
                                                <InputWrapper attr={q.title} value={NullFormatUtils(answerData["answer"+(index+1)])} size="col" />
                                            );
                                        }) : <></>
                                    }
                                </div>
                            </div>

                            {/* TODO: Add details */}
                            <SuiBox mb={2}/>
                            
                            <SuiButton
                                buttonColor="secondary"
                                onClick={handleChangePage}>
                                    BACK
                            </SuiButton>
                        </>
                        : <></>
                }
            </>
        );
    }
}