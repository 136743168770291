import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import Pagination from '@mui/material/Pagination';
import WalletApi from "api/wallet";
import { NumberFormatUtils } from "util/index";
function ReceivedTabRow(props){

    return(
        <div className="tab-row-wrapper">
            <div className="row-start">
                <p>
                    {props.currency}
                </p>
                <p>
                    {props.dateTime}
                </p>
            </div>
            <div className="row-end">
                <p>
                    {props.receivedValue}
                </p>
                <p>
                    {props.receivedFrom}
                </p>
            </div>
        </div>
    );
}

ReceivedTabRow.propTypes = {
    currency: PropTypes.string.isRequired,
    dateTime: PropTypes.string.isRequired,
    receivedValue: PropTypes.number.isRequired,
    receivedFrom: PropTypes.string.isRequired
};

export default function ReceivedTab(id){
    const [page, setPage] = useState(1);
    const [record, setRecord] = useState();
    const [loading, setLoading] = useState(true);
    const [totalPage, setTotalPage] = useState(1);
    const [rowPerPage, setRowPerPage] = useState(5);

    const handlePageChange = (event, value) => {
        if (value <= totalPage) {
            setPage(value);
        }
    };

    const handleRowPerPage = (v) => {
        setRowPerPage(v);
        let t = record.length / rowPerPage;
        if (record.length % rowPerPage > 0) t += 1;
        setTotalPage(t);
    };

    useEffect(() => {
        WalletApi.getReceiveDetails(id)
            .then((res) => {
                if (res.status === 200) {
                    let d = res?.data?.data?.data;

                    d = d.filter(d => d.status == "待收貨")

                    setRecord(d)
                    setLoading(false);

                    let t = parseInt(d.length / rowPerPage);
                    if (d.length % rowPerPage > 0) t += 1;
                    setTotalPage(t)
                }
            })
            .catch((e) => {
                console.log(e)
            })
    }, [])

    return(
        <div className="tab-wrapper">
            {
                record? 
                    record.map((r, key) => {
                        if (key >= rowPerPage * (page - 1) && key < rowPerPage * page)
                            return (
                                <ReceivedTabRow key={key} currency={r?.order?.crypto_type} dateTime={r?.order?.created_at} receivedValue={NumberFormatUtils(r?.order?.price, 8)} receivedFrom={r?.buyer?.UserName} />
                            )
                    })
                :<></>
            }
            <div className="center">
                    <Pagination color={"secondary"} count={totalPage} page={page} onChange={handlePageChange} 
                    />
            </div>
        </div>
    );
}