import axios from "./index";

class UserApi {
  static increaseCount = data => {
    return axios.get(`${base}/increase_count`, {
      params: data
      /* params: {
        date1: '2022-07-01 00:00:00',
        date2: '2022-12-31 23:59:59'
      } */
    });
  };

  static noOfReferral = data => {
    return axios.get(`${base}/referral`, {
      params: data
    });
  };

  static userReferMostPeople = data => {
    return axios.get(`${base}/user_refer_most_people`, {
      params: data
    });
  };

  static referralRatio = data => {
    return axios.get(`${base}/referral_ratio`, {
      params: data
    });
  };
}

let base = "users";

export default UserApi;
