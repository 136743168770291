import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PushPinIcon from '@mui/icons-material/PushPin';
import { Card, CardContent, Grid, IconButton, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { Tips } from "components/Custom";
import 'components/Custom/resize_box.css';
import { SuiBox, SuiButton } from "components/Default";
import { useState } from "react";
import Draggable from "react-draggable";
import { ResizableBox } from 'react-resizable';
import { DateFormatUtils } from "util/index";

const resize_box_size = {
  default: {
    height: 490,
    width: 750
  },
  xs_12_sm_4_md_4_xl_4: {
    height: 490,
    width: 400
  },
};

export default function DraggableCardChart({ title = "", size, tipsTitle, tipsContent, showTimeUnit = true, /* setTimeUnit = () => {}, */ children, fetchData = () => {} }) {
  const [toggleValue, setToggleValue] = useState("year");
  const [pin, setPin] = useState(true);

  const handleChange = (event, newValue) => {
    setToggleValue(newValue);
  };

  const handlePin = () => {
    setPin(!pin);
  }

  const fetchAllDataByTime = (time) =>{
    var new_date = new Date();
    var date_format = "YYYY-MM-DD HH:mm:ss";
    var now_format = DateFormatUtils(new_date, date_format);
    var today = DateFormatUtils(new_date, "YYYY-MM-DD");

    switch (time) {
      case "last hour":
        var hourago = new Date(new_date.getTime() - (1000*60*60));
        var hourago_format = DateFormatUtils(hourago, date_format);
        fetchData(hourago_format, now_format);
        break;
      case "last day":
        var yesterday = new Date(new_date.getTime() - (1000*60*60*24));
        var yesterday_format = DateFormatUtils(yesterday, "YYYY-MM-DD");
        fetchData(yesterday_format+" 00:00:00", yesterday_format+" 23:59:59");
        break;
      case "last 12 month":
        var last12month = new Date(new_date.getTime() - (1000*60*60*365*24));
        var last12month_format = DateFormatUtils(last12month, "YYYY-MM-DD");
        fetchData(last12month_format+" 00:00:00", today+" 23:59:59");
        break;
      case "last 5 year":
        var this_year = DateFormatUtils(new_date, "YYYY");
        fetchData((this_year - 5)+" 01-01 00:00", today);
        break;
    }
  }

  return (
    <Grid item xs={size?.xs ?? 12} sm={size?.sm ?? 6} md={size?.md ?? 6} xl={size?.xl ?? 6}>
      <ResizableBox
        height={size ? resize_box_size.xs_12_sm_4_md_4_xl_4.height : resize_box_size.default.height}
        width={size ? resize_box_size.xs_12_sm_4_md_4_xl_4.width : resize_box_size.default.width}
        resizeHandles={[/* "sw", */ "se", /* "nw", "ne", */ /* "w", "e", "n", "s" */]}
      >
        <Draggable disabled={pin}>
          <Card>
            <CardContent>
              <SuiBox>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    <SuiBox py={2}>
                      <Grid container>
                        <Grid item xs={showTimeUnit ? 4 : 10} sm={showTimeUnit ? 4 : 10} md={showTimeUnit ? 4 : 10} xl={showTimeUnit ? 4 : 10}>
                          <Grid container>
                            <Grid item>
                              <Typography variant="body2" color="text.secondary">
                                {title}
                              </Typography>
                            </Grid>
                            {tipsTitle && tipsContent?
                              <Grid item>
                                <Tips title={tipsTitle} content={tipsContent} type="HtmlTooltip"/>
                              </Grid>
                              : <></>
                            }
                          </Grid>
                        </Grid>

                        {
                            showTimeUnit ?
                              <Grid item xs={6} sm={6} md={6} xl={6} textAlign="right">
                                <ToggleButtonGroup
                                  color="secondary"
                                  exclusive
                                  value={toggleValue}
                                  onChange={handleChange}
                                  >
                                  <ToggleButton value="hour" onClick={() => /* setTimeUnit(title, "hour") */fetchAllDataByTime("last hour")}>
                                    Last Hour
                                  </ToggleButton>
                                  <ToggleButton value="day" onClick={() => /* setTimeUnit(title, "day") */fetchAllDataByTime("last day")}>
                                    Last Day
                                  </ToggleButton>
                                  <ToggleButton value="month" onClick={() => /* setTimeUnit(title, "month") */fetchAllDataByTime("last 12 month")}>
                                    Last 12 Month
                                  </ToggleButton>
                                  <ToggleButton value="year" onClick={() => /* setTimeUnit(title, "year") */fetchAllDataByTime("last 5 year")}>
                                    Last 5 Year
                                  </ToggleButton>
                                </ToggleButtonGroup>
                              </Grid>
                              : <></>
                        }

                        <Grid item xs={2} sm={2} md={2} xl={2} alignItems="right">
                          <Grid container>
                            <Grid item>
                              <IconButton>
                                <MoreHorizIcon />
                              </IconButton>
                            </Grid>
                            <Grid item>
                              <SuiButton buttonColor="dark" variant={pin ? "contained" : "outlined"} onClick={handlePin}>
                                <PushPinIcon/>
                              </SuiButton>
                            </Grid>
                          </Grid>
                        </Grid>

                      </Grid>
                    </SuiBox>
                  </Grid>
                </Grid>
                {children}
              </SuiBox>
            </CardContent>
          </Card>
        </Draggable>
      </ResizableBox>
    </Grid>
  );
}
