import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import "./style.css";

function RowRadioButtonsGroup ({ values, disable, selectValue, onChange }){
  return (
    <FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group">

        {values ? 
          values.map((v,index) => 
            <FormControlLabel
              value={v.value}
              disabled={disable}
              control={<Radio />}
              label={v.label }
              checked={ selectValue == v.value}
              onChange={onChange}
            />
          ) : null}
          
      </RadioGroup>
    </FormControl>
  );
}

export default RowRadioButtonsGroup;