require("dotenv").config();

const Managers = {
  Admin: {
    id: 1,
    details: "Permission ALL"
  },
  AcManager: {
    id: 2,
    details: "Manage normal case"
  },
  WalletManager: {
    id: 3,
    details: "Manage Wallet"
  },
  AdvManager: {
    id: 4,
    details: "Manage Advertisement"
  },
  HrManager: {
    id: 5,
    details: "Manage Staff"
  }
};

export const constant = {
  API_SERVER: process.env.REACT_APP_API,
  GOOGLE_ADMIN_SIGN_OUT: process.env.REACT_APP_GOOGLE_SIGN_OUT,
  SIGN_OUT_URL: process.env.REACT_APP_SIGN_OUT,
  GOOGLE_AUTH_URL: process.env.REACT_APP_AUTH_PATH,
  GOOGLE_AUTH_CALLBACK_URL: process.env.REACT_APP_AUTH_CALLBACK_PATH,
  CSRF_PATH: process.env.REACT_APP_CSRF,
  USERINFO: process.env.REACT_APP_USER_INFO,
  ANALYSIS_SERVER: process.env.REACT_APP_ANALYSIS_API,
  SERVICE_AGREEMENT: "https://www.bithon.com.hk/service-agreement",
  COINBASE_URL: "https://api.coinbase.com/v2/exchange-rates?currency=",
  FIREBASE: {
    API_KEY: process.env.REACT_APP_FB_APIKEY,
    AUTH_DOMAIN: process.env.REACT_APP_FB_AUTHDOMAIN,
    DATABASE_URL: process.env.REACT_APP_FB_DATABASEURL,
    PROJECT_ID: process.env.REACT_APP_FB_PROJECTID,
    STORAGE_BUCKET: process.env.REACT_APP_FB_STORAGEBUCKET,
    MESSAGING_SENDER_ID: process.env.REACT_APP_FB_MESSAGINGSENDERID,
    APP_ID: process.env.REACT_APP_FB_APPID,
    MEASUREMENT_ID: process.env.REACT_APP_FB_MEASUREMENTID
  },
  DEBUG: false,
  ERROR_MESSAGE: {
    NETWORK: "Cannot connect to API"
  },
  PAGE: {
    DASHBOARD: {
      VIEWS: [Managers.Admin.id]
    },
    USER: {
      DELETE_ACC: [Managers.Admin.id, Managers.AcManager.id],
      ID_VERIFY: [Managers.Admin.id, Managers.AcManager.id],
      SHOP_VERIFY: [Managers.Admin.id, Managers.AcManager.id],
      INCOME_DECLAR: [Managers.Admin.id, Managers.AcManager.id]
    },
    OFFERS: {
      BLOCK: [Managers.Admin.id, Managers.AcManager.id],
      UNBLOCK: [Managers.Admin.id, Managers.AcManager.id]
    },
    ORDERS: {
      REFUND: {
        ARTIFICAL_REFUND: [Managers.Admin.id, Managers.AcManager.id],
        REJECT: [Managers.Admin.id, Managers.AcManager.id]
      },
      REVIEW: {
        ON: [Managers.Admin.id, Managers.AcManager.id],
        HIDDEN: [Managers.Admin.id, Managers.AcManager.id]
      }
    },
    WALLETS: {
      BALANCE_EDIT: [Managers.Admin.id, Managers.WalletManager.id],
      WITHDRAW_COMPLETED: [Managers.Admin.id, Managers.WalletManager.id]
    },
    OTC: {
      CREATE: [Managers.Admin.id, Managers.AdvManager.id],
      VIEW: [Managers.Admin.id, Managers.AdvManager.id]
    },
    CRYPTO: {
      EDIT: [Managers.Admin.id]
    },
    ADVS: {
      DEFAULT: [Managers.Admin.id, Managers.AdvManager.id],
      BLOCK: [Managers.Admin.id, Managers.AdvManager.id],
      UNBLOCK: [Managers.Admin.id, Managers.AdvManager.id],
      RELEASE: [Managers.Admin.id, Managers.AdvManager.id],
      REJECT: [Managers.Admin.id, Managers.AdvManager.id],
      CREATE_OFFICIAL: [Managers.Admin.id, Managers.AdvManager.id]
    },
    STAFFS: {
      DEFAULT: [Managers.Admin.id, Managers.HrManager.id],
      CREATE: [Managers.Admin.id, Managers.HrManager.id],
      EDIT: [Managers.Admin.id, Managers.HrManager.id],
      VIEW_GOOGLE_AUTH: [Managers.Admin.id, Managers.HrManager.id]
    },
    GENERAL: {
      EDIT: [Managers.Admin.id],
      CRISIS_MGT: {
        VIEW: [Managers.Admin.id]
      },
      ACCESS_CODE_MGT: {
        VIEW: [Managers.Admin.id]
      }
    },
    OTHERS: {
      EXPORT_EXCEL: [Managers.Admin.id]
    }
  }
};
