import OfferApi from "api/offer";
import { Loader } from 'components/Custom';
import CommentWrapper from "components/Custom/comment_wrapper";
import "components/Custom/common.css";
import { FieldsNotFoundError } from "components/Custom/custom_container";
import ImageViewer from "components/Custom/image_viewer";
import InputWrapper from "components/Custom/input_wrapper";
import { useEffect, useState } from "react";
import OfferReportTemplate from "template/offerReportTemplate";
import { ImageURLExistHelper, NullFormatUtils } from "util/index";
import { useAlert } from "react-alert";

export default function ReportPopup({ id }) {
  const alert = useAlert();
  const fields = [id];
  const [offerData, setOfferData] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchOneOfferData = async id => {
    await OfferApi.getOneOfferByFid(id)
      .then(res => {
        if (res.message === "success" || res.data) {
          const data = res.data.data;
          setOfferData(data);

          const reportData = Object.entries(res.data.data.Report).map(a => ({
            id: Object.keys(a[1])[0],
            ...Object.entries(a[1])[0][1]
          }));
          setReportData(reportData);

          setLoading(false);
        }
      })
      .catch(e => {
        console.log(e);
        alert.error(e.message);
      });
  };

  useEffect(() => {
    if (offerData.length < 1) {
      fetchOneOfferData(id);
    }
  }, []);

  /* 
    Components
  */

  const Title = () => <h4>Report</h4>
  const SubTitle = ({ index }) => <span className='text-secondary'>Report {index + 1}</span>
  const SubmitDate = ({ createAt }) => <InputWrapper attr="SubmitDate" size="m" value={NullFormatUtils(createAt)} />
  const UserId = ({ userId }) => <InputWrapper attr="User ID" size="sm" value={NullFormatUtils(userId)} />
  const ReportReason = ({ reportReason }) => <InputWrapper attr="Reason" size="sm" value={NullFormatUtils(reportReason)} />
  const ReportDetails = ({ reportDetails }) => <CommentWrapper attr="Details" value={NullFormatUtils(reportDetails)} />
  const ReportProofs = ({ reportProof1, reportProof2 }) => <ImageViewer headerText="Report Proofs" clickText="Click to View" type={2} src={[ImageURLExistHelper(reportProof1), ImageURLExistHelper(reportProof2)]} title={true}/>

  return (
    <FieldsNotFoundError data={fields}>
      <Loader loading={loading}>
        <OfferReportTemplate 
            Title={Title} 
            ReportData={reportData}
            SubTitle={SubTitle}
            SubmitDate={SubmitDate}
            UserId={UserId}
            ReportReason={ReportReason}
            ReportDetails={ReportDetails}
            ReportProofs={ReportProofs}
        />
      </Loader>
    </FieldsNotFoundError>
  );
}
