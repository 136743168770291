import Questions from "./questions";
import { ShowUsersPopup } from "./show_users";

function CustomPopup({ name, id, title }) {
    if(name === "QuestionPopup"){
        return <Questions id={id} action={title}/>;
    } else if(name === "ShowUsersPopup"){
        return <ShowUsersPopup id={id} title={title}/>;
    } else return <></>
}

export default CustomPopup;