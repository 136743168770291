import className from "./style";

const orderStatus = [
    /* {
      label: "退款處理中",
      value: 1,
      color: "primary",
      disable: true,
      class: '',
      param: {
        name: "refund_status",
        value: "退款處理中"
      },
      count: 0
    },
    {
      label: "退款失敗",
      value: 2,
      disable: true,
      class: '',
      param: {
        name: "refund_status",
        value: "退款失敗"
      },
      count: 0
    },
    {
      label: "人工退款失敗",
      value: 3,
      color: "error",
      disable: true,
      class: '',
      param: {
        name: "refund_status",
        value: "人工退款失敗"
      },
      count: 0
    },
    {
      label: "退款成功",
      value: 4,
      disable: true,
      class: '',
      param: {
        name: "refund_status",
        value: "退款成功"
      },
      count: 0
    }, */
    {
      label: "客服處理中",
      value: 5,
      color: "warning",
      disable: false,
      class: '',
      param: {
        name: "refund_status",
        value: "客服處理中"
      },
      count: 0
    },
    /* {
      label: "人工退款成功",
      value: 6,
      color: "success",
      disable: true,
      class: '',
      param: {
        name: "refund_status",
        value: "人工退款成功"
      },
      count: 0
    }, */
    {
      label: "待確認",
      value: 7,
      color: "warning",
      disable: false,
      class: className.warning,
      param: {
        name: "status",
        value: "待確認"
      },
      count: 0
    },
    {
      label: "待發貨",
      value: 8,
      color: "warning",
      disable: false,
      class: className.warning,
      param: {
        name: "status",
        value: "待發貨"
      },
      count: 0
    },
    {
      label: "待收貨",
      value: 9,
      color: "warning",
      disable: false,
      class: className.warning,
      param: {
        name: "status",
        value: "待收貨"
      },
      count: 0
    },
    {
      label: "待付款",
      value: 10,
      color: "warning",
      disable: false,
      class: className.warning,
      param: {
        name: "status",
        value: "待付款"
      },
      count: 0
    },
    {
      label: "已完成",
      value: 11,
      color: "success",
      disable: false,
      class: className.success,
      param: {
        name: "status",
        value: "已完成"
      },
      count: 0
    },
    {
      label: "已取消",
      value: 12,
      disable: false,
      param: {
        name: "status",
        value: "已取消"
      },
      count: 0
    },
    {
      label: "已拒絕",
      value: 13,
      color: "error",
      disable: false,
      class: className.error,
      param: {
        name: "status",
        value: "已拒絕"
      },
      count: 0
    },
    {
      label: "Online",
      value: 14,
      color: "default",
      disable: false,
      class: className.default,
      param: {
        name: "order_type",
        value: "Online"
      },
      count: 0
    },
    {
      label: "Offline",
      value: 15,
      color: "default",
      disable: false,
      class: className.default,
      param: {
        name: "order_type",
        value: "Offline"
      },
      count: 0
    }
];

export default orderStatus;