import { Switch } from '@mui/material';
import UserApi from 'api/user';
import { FieldsNotFoundError } from 'components/Custom/custom_container';
import SuiButton from 'components/Default/SuiButton';
import { useState } from 'react';
import SecurityEditTemplate from 'template/securityEditTemplate';

export default function SecurityEditPopup({ userData, action, code, id }) {
    const fields = [userData?.Security, action, code, id];

    //check if ok to edit (disable)
    const [editBio] = useState(userData.Security?.Biometrics?.IsBioAuth ? true : false);
    const [editSms] = useState(userData.Security?.Sms?.IsPermit ? true : false);
    const [editGoogleAuth] = useState(userData.Security?.GoogleAuth?.IsPermit ? true : false);

    //can only disable (set false) the security param
    const [disableBio, setDisableBio] = useState(false);
    const [disableSms, setDisableSms] = useState(false);
    const [disableGoogleAuth, setDisableGoogleAuth] = useState(false);

    console.log(userData);

    const handleEditBio = () => {
        console.log("edit bio");

        UserApi.disableSecurity({
            id: id,
            code: code,
            type: "Biometrics"
        })
            .then((res) => {
                if (res.status === 200) {
                    action(true);
                }
            })
            .catch((e) => {
                console.log(e);
            });

    };

    const handleEditSms = () => {
        console.log("edit sms");

        UserApi.disableSecurity({
            id: id,
            code: code,
            type: "Sms"
        })
            .then((res) => {
                if (res.status === 200) {
                    action(true);
                }
            })
            .catch((e) => {
                console.log(e);
            });

    };

    const handleEditGoogleAuth = () => {
        console.log("edit google auth");

        UserApi.disableSecurity({
            id: id,
            code: code,
            type: "GoogleAuth"
        })
            .then((res) => {
                if (res.status === 200) {
                    action(true);
                }
            })
            .catch((e) => {
                console.log(e);
            });

    };

    const handleConfirmSubmit = () => {
        if (editBio && disableBio) handleEditBio();
        if (editSms && disableSms) handleEditSms();
        if (editGoogleAuth && disableGoogleAuth) handleEditGoogleAuth();

        if (action) action(true);
    };

    /* 
        Components
     */

    const Title = () => {
        return (
            <h4>Edit Security</h4>
        );
    }

    const SubTitle1 = () => {
        return (
            <div className="popup-subtitle"> Biometric authentication </div>
        );
    }

    const BiometricAuthSwitch = () => {
        return (
            <Switch disabled={!editBio} color="secondary" defaultChecked={editBio} onChange={(e) => { setDisableBio(!e.target.checked) }} />
        );
    }

    const SubTitle2 = () => {
        return (
            <div className="popup-subtitle">SMS Code</div>
        );
    }

    const SMSCodeSwitch = () => {
        return (
            <Switch disabled={!editSms} color="secondary" defaultChecked={editSms} onChange={(e) => { setDisableSms(!e.target.checked) }} />
        );
    }

    const SubTitle3 = () => {
        return (
            <div className="popup-subtitle"> Google Authentication </div>      
        );
    }

    const GoogleAuthSwitch = () => {
        return (
            <Switch disabled={!editGoogleAuth} color="secondary" defaultChecked={editGoogleAuth} onChange={(e) => { setDisableGoogleAuth(!e.target.checked) }} />
        );
    }

    const ActionButton = () => {
        return (
            <SuiButton buttonColor={"secondary"} onClick={handleConfirmSubmit}>Confirm Edit</SuiButton>
        );
    }

    return (
        <FieldsNotFoundError data={fields}>
            <SecurityEditTemplate
                Title={Title}
                SubTitle1={SubTitle1}
                BiometricAuthSwitch={BiometricAuthSwitch}
                SubTitle2={SubTitle2}
                SMSCodeSwitch={SMSCodeSwitch}
                SubTitle3={SubTitle3}
                GoogleAuthSwitch={GoogleAuthSwitch}
                ActionButton={ActionButton}
            />
        </FieldsNotFoundError>
    );
}