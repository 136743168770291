import React, { useState } from 'react'

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from '../tab_panel';
import Box from "@mui/material/Box";

import ReceivedTab from './components/received';
import TopupTab from './components/topup';
import WithdrawTab from './components/withdraw';
import PaidTab from './components/paid';
import ExchangeTab from './components/exchange';
import TransferTab from './components/transfer';

export default function HistoryPopup({ id }) {

    if (id) {
        const columns = [
            {
                "name": "Topup",
                "tab": TopupTab(id)
            },
            {
                "name": "Withdraw",
                "tab": WithdrawTab(id)
            },
            {
                "name": "Paid",
                "tab": PaidTab(id)
            },
            {
                "name": "Received",
                "tab": ReceivedTab(id)
            },
            {
                "name": "Exchange",
                "tab": ExchangeTab(id)
            },
            {
                "name": "Transfer",
                "tab": TransferTab(id)
            }
        ];

        const [currentTab, setCurrentTab] = useState(0);

        const handleTabChange = (event, newValue) => {
            setCurrentTab(newValue);
        };

        return (
            <div className="popup-wrapper">
                <div className="popup-content">
                    <h4>History</h4>
                    <div style={{ height: 400, width: 600 }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs aria-label="wrapped tabs example" value={currentTab} onChange={handleTabChange} color={"secondary"}>
                                {
                                    columns.map((c, key) => {
                                        return (
                                            <Tab value={key} key={key} label={c.name} />
                                        );
                                    })
                                }
                            </Tabs>
                        </Box>

                        {
                            columns.map((c, key) => {
                                return (
                                    <TabPanel value={currentTab} key={key} index={key}>
                                        {c.tab}
                                    </TabPanel>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        );
    } else return (
        <div>

        </div>
    )


}