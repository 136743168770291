import axios from "./index";

const id_token = localStorage.getItem("id_token");

class CryptoApi {
  static list = () => {
    const id_token = localStorage.getItem("id_token");
    return axios.get(`${base}`, { headers: {"Authorization" : `Bearer ${id_token}`} });
  };

  static getOneCryptoByFid = (id) => {
    const id_token = localStorage.getItem("id_token");
    if(id){
      return axios.get(`${base}/${id}/networks`, { headers: {"Authorization" : `Bearer ${id_token}`} });
    }
    throw "crypto id is missing";
  };

  static getOneCryptoById = (id) => {
    const id_token = localStorage.getItem("id_token");
    if(id){
      return axios.get(`${base}/${id}`, { headers: {"Authorization" : `Bearer ${id_token}`} });
    }
    throw "crypto id is missing";
  };

  static editCryptoTypesTitle = (data) => {
    const id_token = localStorage.getItem("id_token");
    if(data){
      const requestData = {
        CryptoName: data.CryptoName,
        CryptoChineseName: data.CryptoChineseName
      };
      return axios.put(`${base}/${data.CryptoType}`, requestData, { headers: {"Authorization" : `Bearer ${id_token}`} });
    }
    throw "request data is missing";
  };

  static editCryptoTypeNetworks = (data) => {
    const id_token = localStorage.getItem("id_token");
    if(data){
      const requestData = {
        LowerLimit: data.LowerLimit,
        TopupRules: data.TopUpRules,
        UpperLimit: data.UpperLimit,
        WithdrawFee: data.WithdrawFee,
        WithdrawRules: data.WithdrawRules
      };
      return axios.put(`${base}/${data.CryptoType}/networks/${data.Networks}`, requestData, { headers: {"Authorization" : `Bearer ${id_token}`} });
    }
    throw "request data is missing";
  };
}

let base = "/crypto_types";

export default CryptoApi;
