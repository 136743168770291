import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import WalletApi from "api/wallet";
import { CircularProgress, Pagination } from "@mui/material";
import moment from "moment";
import { NumberFormatUtils } from "util/index";
function ExchangeTabRow(props){

    return(
        <div className="tab-row-wrapper">
            <div className="row-start">
                <p>
                    {props.fromCurrency+" > "+props.toCurrency}
                </p>
                <p>
                    {props.dateTime}
                </p>
            </div>
            <div className="row-end">
                <p>
                    {NumberFormatUtils(props.fromValue, 8)+" > "+NumberFormatUtils(props.toValue, 8)}
                </p>
                <p style={{ float: 'right' }}>
                    {props.status}
                </p>
            </div>
        </div>
    );
}

ExchangeTabRow.propTypes = {
    fromCurrency: PropTypes.string.isRequired,
    toCurrency: PropTypes.string.isRequired,
    dateTime: PropTypes.string.isRequired,
    fromValue: PropTypes.number.isRequired,
    toValue: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired
};

export default function ExchangeTab(id){

    const [page, setPage] = useState(1);
    const [record, setRecord] = useState();
    const [loading, setLoading] = useState(true);
    const [totalPage, setTotalPage] = useState(1);
    const [rowPerPage, setRowPerPage] = useState(5);

    const [getWalletId, setGetWalletId] = useState(false);

    const handlePageChange = (event, value) => {
        if (value <= totalPage) {
            setPage(value);
        }
    };

    const handleRowPerPage = (v) => {
        setRowPerPage(v);
        let t = record.length / rowPerPage;
        if (record.length % rowPerPage > 0) t += 1;
        setTotalPage(t);
    };

    useEffect(() => {
        WalletApi.getExchangeDetails(id)
            .then((res) => {
                if (res.status === 200) {
                    let d = res?.data?.data?.data;
                    setRecord(d)
                    setLoading(false);

                    let t = parseInt(d.length / rowPerPage);
                    if (d.length % rowPerPage > 0) t += 1;
                    setTotalPage(t)
                }
            })
            .catch((e) => {
                console.log(e)
            })
    }, [])


    return(
        <div className="tab-wrapper">
            {
                loading ?
                <CircularProgress />
                : <></>
            }
            {
                record? 
                    record.map((r, key) => {
                        if (key >= rowPerPage * (page - 1) && key < rowPerPage * page)
                            return (
                                <ExchangeTabRow key={key} fromCurrency={r?.from?.crypto_type} toCurrency={r?.to?.crypto_type} dateTime={moment(r?.created_at).format("YYYY/MM/DD HH:mm")} fromValue={r?.from?.amount} toValue={r?.to?.amount} status={r?.status}/>
                            )
                    })
                :<></>
            }
            <div className="center">
                    <Pagination color={"secondary"} count={totalPage} page={page} onChange={handlePageChange} />
            </div>
        </div>
    );
}

