import { Grid, Chip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import "./style.css";

const ExpandMore = styled((props) => {
    const { expand, hiddenChipLabel, ...other } = props;
    return (
        <>
            <Grid container spacing={1}>
            {
                hiddenChipLabel && hiddenChipLabel.length >= 1 ?
                    hiddenChipLabel.map((label, index) => 
                        <Grid item key={index}>
                            <Chip label={label} color="secondary" variant="outlined" className="hidden-chip"/>
                        </Grid>
                    )
                    : <></>
            }
            </Grid>
            <IconButton {...other} />
        </>
    );
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
    })
}));

export default ExpandMore;