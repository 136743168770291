import className from "./style";

const advstatus = [
    {
      label: "不獲批",
      value: 1,
      color: "default",
      class: className.default,
      param: {
        name: "status",
        value: "不獲批"
      }
    },
    {
      label: "進行中",
      value: 2,
      color: "success",
      class: className.success,
      param: {
        name: "status",
        value: "進行中"
      }
    },
    {
      label: "審核中",
      value: 3,
      color: "warning",
      class: className.warning,
      param: {
        name: "status",
        value: "審核中"
      }
    },
    {
        label: "被封鎖",
        value: 4,
        color: "error",
        class: className.error,
        param: {
          name: "status",
          value: "被封鎖"
        }
    },
    {
      label: "已完成",
      value: 5,
      color: "default",
      class: className.default,
      param: {
        name: "status",
        value: "已完成"
      }
    }
];

export default advstatus;