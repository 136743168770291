const difficulty = [
    {
        label: "Easy",
        value: 1
    },
    {
        label: "Normal",
        value: 2
    },
    {
        label: "Difficult",
        value: 3
    }
];

export { difficulty };