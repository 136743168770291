import FireStorage from "api/firestorage";

export default class FirebaseGateway {
    constructor() { }

    async getToken() {
        return await FireStorage.idToken()
            .then((response) => {
                let token = this.getLocalStorageToken(response)
                return token;
            })
    }

    getLocalStorageToken(response) {
        return localStorage.getItem("id_token");
    }

    async fireStorageHelper({
        image,
        place = "images/ad/",
        prefix,
        customName
    }) {
        if (image && place) {
            let message = "";
            let signSuccess = false;

            let token = localStorage.getItem("token");

            signSuccess = await FireStorage.signInFBWithToken({ token: token })
                .then(res => {
                    console.log(res);
                    return res;
                })
                .catch(err => {
                    return false;
                });

            if (signSuccess) {
                console.log(image);
                console.log(prefix + image[0].file.name);

                return await FireStorage.saveImageToFB({
                    Place: place,
                    Name: prefix ? prefix + customName : image[0].file.name,
                    File: image[0].file
                })
                    .then((res) => {
                        console.log(res);
                        if (res) {
                            return {
                                state: "Upload Image Success",
                                message: res?.message,
                                data: res
                            };
                        }
                    })
                    .catch((err) => {
                        return {
                            state: "Upload Image to Firebase Error",
                            message: err.message
                        };
                    });
            } else {
                return {
                    state: "SignIn Firebase Error",
                    message: message
                };
            }
        }
    }

}