import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Grid, Link } from '@mui/material';
import UserApi from 'api/user';
import { Loader } from 'components/Custom';
import { FieldsNotFoundError, PageController } from 'components/Custom/custom_container';
import InputWrapper from 'components/Custom/input_wrapper';
import { SuiButton } from 'components/Default';
import AccountDetails from 'components/Page/account_details';
import { constant } from "config/constant";
import { USER_PERMISSION } from 'config/permission';
import CustomerGateway from 'entities/customerGateway';
import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import ReferralTemplate from 'template/referralTemplate';
import AuthCodeHelper from "util/AuthCodeHelper";
import { ERROR } from 'util/ErrorHelper';
import { DateFormatUtils, NullFormatUtils } from "util/index";
import './styles.css';

let customerGateway = new CustomerGateway();

export default function ReferralPopup({ id }) {
    const fields = [id];
    const alert = useAlert();
    const [auth, setAuth] = useState(false);
    const [page, setPage] = useState(0);
    const [referralData, setReferralData] = useState();
    const [friends, setFriends] = useState([]);
    const [loading, setLoading] = useState(true);
    const [referralID, setReferralID] = useState();

    let testMode = constant.DEBUG;

    const handleAuthCode = (resp) => {
        if(resp){
            if(resp.isAuth){
                setAuth(true);
                setPage(0);
            } else{
                alert.error(resp.message);
            }
        }
        else{
            alert.error(ERROR.callback);
        }
    }

    const fetchOneUserData = async (id) => {
        console.log(id);
        let user_data = [];

        await UserApi.getOneUserByFid(id)
            .then((res) => {
                if(res.status === 200){
                    user_data = res.data.data;
                }
            })
            .catch((err) => {
                console.log(err);
            });
        
        if(user_data.length < 1){
            await UserApi.getOneUserByAid(id)
                .then((res) => {
                    if(res.status === 200){
                        user_data = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        return user_data;
    };

    const fetchData = async () => {
        await customerGateway.getOneReferralData(id)
                .then((res) => {
                    if (res) {
                        setReferralData(res?.data);
                        setFriends(res?.data?.friends);
                        setLoading(false);
                    }
                }).catch((e) => {
                    console.log(e);
                    alert.error(e.message);
                })
    }

    useEffect(() => {
        if(!auth){
            setPage(1);
        } 
        else {
            fetchData();
        }
    }, [auth]);

    /* 
        Component
    */
    const Title = () => {
        return (
            <h4>Referral</h4>
        );
    }

    const SubTitle1 = () => {
        return (
            <div className="popup-subtitle bold">Cumulative Total Reward</div>
        );
    }

    const SubTitle2 = () => {
        return (
            <div className="popup-subtitle bold">Friends ({friends.length})</div>
        );
    }

    const BTC = () => {
        return (
            <InputWrapper attr="BTC" value={NullFormatUtils(referralData?.BTC)} size="xl" />
        );
    }

    const ETH = () => {
        return (
            <InputWrapper attr="ETH" value={NullFormatUtils(referralData?.ETH)} size="xl" />
        );
    }

    const USDC = () => {
        return (
            <InputWrapper attr="USDC" value={NullFormatUtils(referralData?.USDC)} size="xl" />
        );
    }

    const USDT = () => {
        return (
            <InputWrapper attr="USDT" value={NullFormatUtils(referralData?.USDT)} size="xl" />
        );
    }

    const InvitedBy = () => {
        return (
            <>
                <div className="popup-subtitle bold">My Referrer</div>
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={3} md={3} xl={3}>
                        <AccountID accountId={referralData?.invitedBy?.account_id}/>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3} xl={3}>
                        <UserName userName={referralData?.invitedBy?.username}/>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3} xl={3}>
                        <Email email={referralData?.invitedBy?.email}/>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3} xl={3}>
                        <CreateAt createAt={ DateFormatUtils(referralData?.invitedBy?.createAt, "DD-MM-YYYY") }/>
                    </Grid>
                </Grid>
            </>
        );
    }

    const AccountID = ({ accountId }) => {
        return (
            <Link to='#' 
                className="text-secondary" 
                onClick={() => {
                    setReferralID(accountId)
                    setPage(2)
                }}>

                <div className="text-sm">
                    {accountId}
                </div>
            </Link>
        );
    }

    const UserName = ({ userName }) => {
        return (
            <div className="text-sm">{userName}</div>
        );
    }

    const Email = ({ email }) => {
        return (
            <div className="text-sm">{email}</div>
        );
    }

    const CreateAt = ({ createAt }) => {
        return (
            <div className="text-sm">{createAt}</div>
        );
    }

    return (
        <FieldsNotFoundError data={fields}>
            <PageController targetPage={0} currentPage={page} CASE="">
                <Loader loading={loading}>
                    <ReferralTemplate
                        Title={Title}
                        InvitedBy={InvitedBy}
                        SubTitle1={SubTitle1}
                        BTC={BTC}
                        ETH={ETH}
                        USDC={USDC}
                        USDT={USDT}
                        SubTitle2={SubTitle2}
                        AccountID={AccountID}
                        UserName={UserName}
                        Email={Email}
                        CreateAt={CreateAt}
                        ReferralData={friends}
                    />
                </Loader>
            </PageController>

            <PageController targetPage={1} currentPage={page} CASE="">
                <AuthCodeHelper title="Admin Authentication" btnName="Confirm" permission={USER_PERMISSION.INCOME_DECLAR} callback={handleAuthCode}/>
            </PageController>

            <PageController targetPage={2} currentPage={page} CASE="">
                <div>
                    <SuiButton
                        buttonColor="secondary"
                        onClick={() => setPage(0)}
                        >
                        <ArrowBackIosIcon/>
                        Back
                    </SuiButton>
                    <AccountDetails id={referralID} />
                </div>
            </PageController>
        </FieldsNotFoundError>
    );
}