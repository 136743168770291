import initalValue from "entities/base/initalValue";
import FirebaseGateway from "./firebaseGateway";
import GameApi from "api/game";

const BetaScheme = {
    id: initalValue.String,
    startAt: initalValue.Datetime,
    endAt: initalValue.Datetime,
    duration: initalValue.Int,
    usdtAmount: initalValue.Int,
    question1: initalValue.Int,
    question2: initalValue.Int,
    question3: initalValue.Int,
    question4: initalValue.Int,
    question5: initalValue.Int,
    status: {
        FINISHED: "FINISHED",
        INPROGRESS: "IN_PROGRESS",
        NOTYETSTARTED: "NOT_YET_STARTED"
    },
    created_at: initalValue.Datetime,
    updated_at: initalValue.Datetime,
    stats: {
        id: initalValue.Int,
        registrant_amount: initalValue.Int,
        participant_amount: initalValue.Int,
        winner_amount: initalValue.Int,
        total_pool_share: initalValue.Int,
        created_at: initalValue.Datetime,
        updated_at: initalValue.Datetime,
        evaluated: initalValue.Int,
        claimed_amount: initalValue.Int
    }
}

export default class CompetitionGateway {
    constructor() { }

    getScheme() {
        return BetaScheme;
    };

    async getAllData({ page, statusStatement, searchValue }) {
        let firebaseGateway = new FirebaseGateway();
        let latestToken = await firebaseGateway.getToken();

        return await GameApi.list({
            page: page,
            statusStatement: statusStatement,
            id_token: latestToken,
            searchValue: searchValue
        })
            .then(response => {
                if (response.status === 200) {
                    return {
                        data: this.formatCompetitionsData(response.data.data.data),
                        last_page: response.data.data.last_page,
                        per_page: response.data.data.per_page,
                        total: response.data.data.total
                    };
                }
            })
    }

    formatCompetitionData(apiData) {
        const {
            id, start_at, end_at, duration, usdt_amount, question1, question2, question3, question4, question5, status, created_at, updated_at, stats
        } = apiData;
        
        const {
            registrant_amount, participant_amount, winner_amount, total_pool_share, evaluated, claimed_amount
        } = apiData.stats;

        return {
            id: id,
            startAt: start_at,
            endAt: end_at,
            duration: duration,
            usdtAmount: usdt_amount,
            question1: question1,
            question2: question2,
            question3: question3,
            question4: question4,
            question5: question5,
            status: status,
            createdAt: created_at,
            updatedAt: updated_at,
            statsId: stats?.id,
            statsRegistrantAmount: registrant_amount,
            statsParticipantAmount: participant_amount,
            statsWinnerAmount: winner_amount,
            statsTotalPoolShare: total_pool_share,
            statsCreatedAt: stats?.created_at,
            statsUpdatedAt: stats?.updated_at,
            statsEvaluated: evaluated,
            statsClaimedAmount: claimed_amount
        };
    }

    formatCompetitionsData(apiData) {
        return apiData.map(data => {
            return this.formatCompetitionData(data);
        });
    }
}