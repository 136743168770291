import WalletApi from "api/wallet";
import initalValue from "entities/base/initalValue";
import FirebaseGateway from "./firebaseGateway";

const Scheme = {
    id: initalValue.Int,
    account_id: initalValue.String,
    fromAccountId: initalValue.String,
    toAccountId: initalValue.String,
    exchangeRate: initalValue.Double,
    toCryptoType: initalValue.String,
    fromCryptoType: initalValue.String,
    toAmount: initalValue.Double,
    fromAmount: initalValue.Double,
    commission: initalValue.Double,
    createAt: initalValue.Datetime
}

export default class WalletExchangeGateway {
    constructor() { }

    getScheme() {
        return Scheme;
    };

    async getExchangeRate(fromCryptoType, toCryptoType) {
        return await WalletApi.getExchangeRateFromCoinBaseByCurrency(fromCryptoType)
            .then((res) => {
                const rates = res.data.data.rates;
                if (rates) {
                    return rates[toCryptoType];
                }
            })
            .catch((err) => {
                console.log(err);
                return 0;
            });
    }

    async getAllData({ page, statusStatement, searchValue }) {
        let firebaseGateway = new FirebaseGateway();
        let latestToken = await firebaseGateway.getToken();

        return await WalletApi.getExchangeDetails({
            page: page,
            statusStatement: statusStatement,
            id_token: latestToken,
            searchValue: searchValue
        })
            .then(response => {
                if (response.status === 200) {
                    return {
                        data: this.formatWalletWithdrawsData(response.data.data.data),
                        last_page: response.data.data.meta.last_page,
                        per_page: response.data.data.meta.per_page,
                        total: response.data.data.meta.total
                    };
                }
            })
    }

    formatWalletWithdrawData(apiData) {
        const {
            id, account_id, from, to, commission, created_at
        } = apiData;

        return {
            id: id,
            account_id: account_id,
            fromAccountId: from?.account_id,
            toAccountId: to?.account_id,
            // exchangeRate: this.getExchangeRate(from?.crypto_type, to?.crypto_type),
            toCryptoType: to?.crypto_type,
            fromCryptoType: from?.crypto_type,
            toAmount: to?.amount,
            fromAmount: from?.amount,
            commission: commission,
            createAt: created_at
        };
    }

    formatWalletWithdrawsData(apiData) {
        return apiData.map(data => {
            return this.formatWalletWithdrawData(data);
        });
    }
}