import axios from "./index";
import { constant } from "config/constant";

class WalletApi {
  static list = data => {
    const id_token = localStorage.getItem("id_token");
    return axios.get(`${base}?page=${data.page}`, { headers: { "Authorization": `Bearer ${id_token}` } });
  };

  static getOneWallet = (id) => {
    const id_token = localStorage.getItem("id_token");
    if (id) {
      return axios.get(`${base}/${id}`, { headers: { "Authorization": `Bearer ${id_token}` } });
    }
    throw "wallet id is missing";
  };

  static getHistories = () => {
    const id_token = localStorage.getItem("id_token");
    return axios.get(`/histories`, { headers: { "Authorization": `Bearer ${id_token}` } });
  };

  static getHistoriesByType = (data) => {
    const id_token = localStorage.getItem("id_token");
    if (data) {
      return axios.get(`/histories?bithon_type=${data}`, { headers: { "Authorization": `Bearer ${id_token}` } });
    }
    throw "bithon type is missing";
  };

  static getHistoriesByTypeAndUid = (data) => {
    const id_token = localStorage.getItem("id_token");
    if (data) {
      return axios.get(`/histories?bithon_type=${data.type}&uid=${data.uid}`, { headers: { "Authorization": `Bearer ${id_token}` } });
    }
    throw "request data is missing";
  };

  static getHistoriesBySid = (id) => {
    const id_token = localStorage.getItem("id_token");
    if (id) {
      return axios.get(`/histories/${id}`, { headers: { "Authorization": `Bearer ${id_token}` } });
    }
    throw "wallet id is missing";
  };

  static changeTransStatus = ({ id, status }) => {
    const id_token = localStorage.getItem("id_token");
    if (id && status) {
      return axios.post(`/transactions/${id}/change_status?status=${status}`, { headers: { "Authorization": `Bearer ${id_token}` } });
    }
    throw "trans id is missing";
  };

  static getWithDrawDetails = ({ page, id, history_id, statusStatement }) => {
    const id_token = localStorage.getItem("id_token");
    if(id){
      return axios.get(`/transactions/withdraw?account_id=${id}`);
    }
    else if (history_id) {
      return axios.get(`/transactions/withdraw/${history_id}`, { headers: { "Authorization": `Bearer ${id_token}` } });
    }
    else if (statusStatement){
      return axios.get(`/transactions/withdraw?page=${page}&${statusStatement}`, { headers: { "Authorization": `Bearer ${id_token}` } }); 
    }
    else if(page){
      return axios.get(`/transactions/withdraw?page=${page}`, { headers: { "Authorization": `Bearer ${id_token}` } }); 
    }
    return axios.get(`/transactions/withdraw`, { headers: { "Authorization": `Bearer ${id_token}` } });
  };

  static getDepositDetails = ({ page, id, history_id, statusStatement }) => {
    if(id){
      return axios.get(`/transactions/deposit?account_id=${id}`);
    }
    else if (history_id) {
      return axios.get(`/transactions/deposit/${history_id}`, { headers: { "Authorization": `Bearer ${id_token}` } });
    }
    else if (statusStatement){
      return axios.get(`/transactions/deposit?page=${page}&${statusStatement}`, { headers: { "Authorization": `Bearer ${id_token}` } });
    }
    else if(page){
      return axios.get(`/transactions/deposit?page=${page}`, { headers: { "Authorization": `Bearer ${id_token}` } });
    }
    return axios.get(`/transactions/deposit`, { headers: { "Authorization": `Bearer ${id_token}` } });
  };

  static getPurchaseDetails = (id) => {
    const id_token = localStorage.getItem("id_token");
    if (id) {
      return axios.get(`/transactions/purchase?buyer_account_id=${id}`, { headers: { "Authorization": `Bearer ${id_token}` } });
    }
    return axios.get(`/transactions/purchase`, { headers: { "Authorization": `Bearer ${id_token}` } });
  }

  static getReceiveDetails = (id) => {
    const id_token = localStorage.getItem("id_token");
    if (id) {
      return axios.get(`/transactions/receive?seller_account_id=${id}`, { headers: { "Authorization": `Bearer ${id_token}` } });
    }
    return axios.get(`/transactions/receive`, { headers: { "Authorization": `Bearer ${id_token}` } });
  }

  static getTransferDetails = data => {
    const id_token = localStorage.getItem("id_token");
    if(data.statusStatement){
      return axios.get(`/transactions/transfer?page=${data.page}&status=${data.status}`, { headers: { "Authorization": `Bearer ${id_token}` } });
    }
    return axios.get(`/transactions/transfer?page=${data.page}`);
  }

  static getTransferDepositDetails = (id) => {
    const id_token = localStorage.getItem("id_token");
    if (id) {
      return axios.get(`/transactions/transfer?deposit_account_id=${id}`, { headers: { "Authorization": `Bearer ${id_token}` } });
    }
    return axios.get(`/transactions/transfer`, { headers: { "Authorization": `Bearer ${id_token}` } });
  }

  static getTransferWithdrawDetails = (id) => {
    const id_token = localStorage.getItem("id_token");
    if (id) {
      return axios.get(`/transactions/transfer?withdraw_account_id=${id}`, { headers: { "Authorization": `Bearer ${id_token}` } });
    }
    return axios.get(`/transactions/transfer`, { headers: { "Authorization": `Bearer ${id_token}` } });
  }

  static getExchangeDetails = ({ page, id }) => {
    const id_token = localStorage.getItem("id_token");
    if(id){
      return axios.get(`/transactions/exchange?account_id=${id}`, { headers: { "Authorization": `Bearer ${id_token}` } });
    }
    return axios.get(`/transactions/exchange?page=${page}`, { headers: { "Authorization": `Bearer ${id_token}` } });
  }

  static depositToUser = (data) => {
    const id_token = localStorage.getItem("id_token");
    if (data.amount && data.crypto_type && data.id) {

      const requestData = {
        amount: data.amount,
        crypto_type: data.crypto_type
      }

      return axios.put(`/customers/${data.id}/deposit`, requestData, { headers: { "Authorization": `Bearer ${id_token}` } })

    } else return {
      "error": "Something is missing."
    }
  }

  static withdrawFromUser = (data) => {
    const id_token = localStorage.getItem("id_token");
    if (data.amount && data.crypto_type && data.id) {

      const requestData = {
        amount: data.amount,
        crypto_type: data.crypto_type
      }

      return axios.put(`/customers/${data.id}/withdraw`, requestData, { headers: { "Authorization": `Bearer ${id_token}` } })

    } else return {
      "error": "Something is missing."
    }
  }

  static savingPlanList = () => {
    const id_token = localStorage.getItem("id_token");
    return axios.get(`/saving_plans`, { headers: { "Authorization": `Bearer ${id_token}` } });
  }

  static getSavingPlanByPage = ({ page, statusStatement }) => {
    const id_token = localStorage.getItem("id_token");
    if(page || statusStatement){
      return axios.get(`/saving_plans?page=${page}&${statusStatement}`, { headers: { "Authorization": `Bearer ${id_token}` } });
    }
    return axios.get(`/saving_plans`, { headers: { "Authorization": `Bearer ${id_token}` } });
  }

  static fixedSavingPlanList = () => {
    const id_token = localStorage.getItem("id_token");
    return axios.get(`/fixed_saving_plans`, { headers: { "Authorization": `Bearer ${id_token}` } });
  }

  static getFixedSavingPlanByPage = ({ page, statusStatement }) => {
    const id_token = localStorage.getItem("id_token");
    if (page || statusStatement) {
      return axios.get(`/fixed_saving_plans?page=${page}&${statusStatement}`, { headers: { "Authorization": `Bearer ${id_token}` } });
    }
    return axios.get(`/fixed_saving_plans`, { headers: { "Authorization": `Bearer ${id_token}` } });
  }

  static getWelcomegiftsByPage = ({ page }) => {
    const id_token = localStorage.getItem("id_token");
    if (page) {
      return axios.get(`/welcome_gifts?page=${page}`, { headers: { "Authorization": `Bearer ${id_token}` } });
    } else return {
      "error": "page is missing."
    }
  }

  static getExchangeRateFromCoinBaseByCurrency = (currency) => {
    return axios.get(`${constant.COINBASE_URL}${currency}`);
  }

}

let base = "/wallets";

export default WalletApi;
