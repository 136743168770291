import CardActions from '@mui/material/CardActions';

function CardFormActions({ children }){
	return (
		<CardActions disableSpacing style={{ backgroundColor: '#F1F1F1' }}>
            {children}
		</CardActions>
	);
}

export default CardFormActions;