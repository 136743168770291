/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React components
import { Pagination } from "@mui/material";
import Card from "@mui/material/Card";
import Popup from "assets/theme/components/popup";
import { DataTable, Loader } from "components/Custom";
import 'components/Custom/common.css';
import { SuiButton } from "components/Default";
import SuiBox from "components/Default/SuiBox";
import DashboardLayout from "components/examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/examples/Navbars/DashboardNavbar";
import WalletExchangeGateway from "entities/walletExchangeGateway";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { NumberFormatUtils } from "util/index";
import CustomPopup from "./components/custom_pop_up";

let walletExchangeGateway = new WalletExchangeGateway();

function WalletExchange() {
  //pop up pages
  const [page, setPage] = useState(0);
  const [openPopUp, setOpenPopup] = useState(false);
  const [popUpName, setPopUpName] = useState();
  const [passId, setPassId] = useState();
  const handlePopUp = (pName, id) => {
    setPopUpName(pName);
    setPassId(id);
    setOpenPopup(true);
    setPage(1);
  };

  const [tbPage, setTbPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const [rows, setRows] = useState([]);
  const [filterRows, setFilterRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const handlePageChange = (event, value) => {
    if (value <= totalPage) {
      setTbPage(value);
      fetchAllData(value);
    }
  };

  const fetchAllData = async () => {
    setLoading(true);

    await walletExchangeGateway.getAllData({
      page: page ?? tbPage
    })
      .then((res) => {
        if (res) {
          setRows(res.data);
          setFilterRows(res.data);
          setTotalPage(res.last_page);
        }
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (rows.length < 1) {
      fetchAllData();
    }
  }, []);


  const dt_columns = [
    {
      headerName: 'Exchange Record ID',
      field: 'id',
      width: 300,
      renderCell: ({ row }) => row.id ?? "-",
      sortable: true,
    },
    {
      headerName: 'User ID (From)',
      field: 'fromAccountID',
      width: 200,
      renderCell: ({ row }) => row.fromAccountId ? <Link to="#" className="text-secondary" onClick={() => { handlePopUp("AccountDetailsPopup", row.fromAccountId) }}>{row.fromAccountId}</Link> : "-",
      sortable: true,
    },
    {
      headerName: 'User ID (To)',
      field: 'toAccountID',
      width: 200,
      renderCell: ({ row }) => row.toAccountId ? <Link to="#" className="text-secondary" onClick={() => { handlePopUp("AccountDetailsPopup", row.toAccountId) }}>{row.toAccountId}</Link> : "-",
      sortable: true,
    },
    {
      headerName: 'Create Date',
      field: 'createAt',
      width: 300,
      renderCell: ({ row }) => moment(row.createAt).format("YYYY-MM-DD HH:mm:ss") ?? "-",
      sortable: true,
    },
    {
      headerName: 'Exchange Rate',
      field: 'exchangeRate',
      width: 200,
      renderCell: ({ row }) => NumberFormatUtils(row.exchangeRate, 8) ?? "-",
      sortable: true,
    },
    {
      headerName: 'Orginal Coin',
      field: 'fromCryptoType',
      width: 200,
      renderCell: ({ row }) => row.fromCryptoType ?? "-",
      sortable: true,
    },
    {
      headerName: 'Orginal Amount',
      field: 'fromAmount',
      width: 200,
      renderCell: ({ row }) => NumberFormatUtils(row.fromAmount, 8) ?? "-",
      sortable: true,
    },
    {
      headerName: 'Target Coin',
      field: 'toCryptoType',
      width: 200,
      renderCell: ({ row }) => row.toCryptoType ?? "-",
      sortable: true,
    },
    {
      headerName: 'Target Amount',
      field: 'toAmount',
      width: 200,
      renderCell: ({ row }) => NumberFormatUtils(row.toAmount, 8) ?? "-",
      sortable: true,
    },
    {
      headerName: 'Commission',
      field: 'commission',
      width: 200,
      renderCell: ({ row }) => NumberFormatUtils(row.commission, 8) ?? "-",
      sortable: true,
    }
  ];

  return (
    <div>
      {
        page > 0 ?
          <Popup
            openPopup={openPopUp}
            setOpenPopup={() => { setOpenPopup(false) }}
          >
            <CustomPopup name={popUpName} id={passId}></CustomPopup>
          </Popup> : <></>
      }
      <DashboardLayout>
        <DashboardNavbar />

        <Loader loading={loading}>
          <SuiButton
            buttonColor="secondary"
            onClick={() => { fetchAllData() }}>
            Refresh
          </SuiButton>

          <SuiBox py={3}>
            <SuiBox mb={3}>
              <Card>
                <DataTable dt_columns={dt_columns} dt_rows={rows} />
                <Pagination color={"secondary"} count={totalPage} page={tbPage} onChange={handlePageChange} />
              </Card>
            </SuiBox>
          </SuiBox>
        </Loader>

        
      </DashboardLayout>
    </div >
  );
}

export default WalletExchange;
