/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { IndeterminateCheckBox as IndeterminateCheckBoxIcon } from '@mui/icons-material';
import { Card, Chip, IconButton, Pagination } from '@mui/material';
import Popup from "assets/theme/components/popup";
import { DataTable, Loader } from "components/Custom";
import "components/Custom/common.css";
import table_columns_size from "components/Custom/dataTable/column_size";
import { SuiBox } from "components/Default";
import Footer from "components/examples/Footer";
import DashboardLayout from "components/examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/examples/Navbars/DashboardNavbar";
import { constant } from "config/constant";
import OfferGateway from "entities/offerGateway";
import moment from "moment";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Link } from 'react-router-dom';
import { DateFormatUtils, NullFormatUtils, NumberFormatUtils } from "util/index";
import LabelFilterPanel from "util/LabelFilterPanel";
import status from "util/LabelFilterPanel/base";
import { handleFilterByKeyword, handleHighlightKeywordInTable } from "util/SearchFunction";
import CustomPopup from "./components/custom_pop_up";
import "./style.css";

let offerGateway = new OfferGateway();

export default function Offer() {
  const alert = useAlert();
  let testMode = constant.DEBUG;
  // Pop Up Page
  const [page, setPage] = useState(0);
  const [openPopUp, setOpenPopup] = useState(false);
  const [popUpName, setPopUpName] = useState("");
  const [currentOffer, setCurrentOffer] = useState();

  const [tbPage, setTbPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  // Load data
  const [rows, setRows] = useState([]);
  const [filterRows, setFilterRows] = useState([]);
  const [allStatusWithCount, setAllStatusWithCount] = useState([]);
  const [currentFilter, setCurrentFilter] = useState(0); // # of chip label filter
  const [statusStatement, setStatusStatement] = useState(null);
  const [recentUpdateRows, setRecentUpdateRows] = useState([]);
  const [recentUpdateShow, setRecentUpdateShow] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleRecentUpdateRow = () => { setRecentUpdateShow(recentUpdateShow ? false : true) };

  const handlePopUp = (pName, id) => {
    setPopUpName(pName);
    setOpenPopup(true);
    setPage(1);
    if (id) setCurrentOffer(id);
  };

  const handlePageChange = (event, value) => {
    if (value <= totalPage) {
      setTbPage(value);
      fetchAllData(value);
    }
  };

  const handleSearch = (searchValue) => {
    fetchAllData(1, "", searchValue);
  }

  const fetchAllData = async (page, _statusStatement, searchValue) => {
    setLoading(true);

    const data_length = await offerGateway.getAllData({
      page: page ?? tbPage,
      statusStatement: _statusStatement ?? (statusStatement ?? null),
      searchValue: searchValue ?? null
    })
      .then((res) => {
        if (res) {
          if (page === 0) {
            return res.total;
          }
          setRows(res.data);
          setFilterRows(res.data);
          setTotalPage(res.last_page);
        }
        setLoading(false);
      })
      .catch((err) => {
        alert.error(err.message);
      })

    if (page === 0) return data_length
  };

  const fetchStatusCount = () => {
    let allStatus = [];
    status?.offer?.map((status, key) => {
      let _status = status;
      fetchAllData(0, `${status?.param?.name}=${status?.param?.value}`)
        .then((res) => {
          _status.count = res;
        })
      allStatus.push(_status);
    })
    setAllStatusWithCount(allStatus);
  }

  useEffect(() => {
    if (statusStatement === null) {
      fetchAllData();
    }

    if (rows.length < 1) {
      fetchAllData();
      fetchStatusCount();
    }

    if (rows.length >= 1) {
      const update_today_rows = rows.filter(row => moment(/* "2022-05-25" */).isSame(row.updateAt, 'day'))
      setRecentUpdateRows(update_today_rows);
    }
  }, [statusStatement]);

  const handleNewestDate = (date) => {
    var check_date = moment(date).format("YYYY-MM-DD");
    return moment(/* "2022-05-07" */).isSame(check_date, 'day') ? <Chip label="Newest" color="error" /> : <></>
  }

  const dt_columns = [
    {
      headerName: 'Offer ID',
      field: 'id',
      width: table_columns_size.xs,
      renderCell: ({ row }) =>
        row.id ?
          <Link to="#"
            className="text-secondary"
            onClick={() =>
              handlePopUp("OfferDetailsPopup", row.id)}>

            {handleHighlightKeywordInTable(row.SearchValue, row.id)}
          </Link>
          : "-",
      sortable: true,
    },
    {
      headerName: 'Title',
      field: 'title',
      width: table_columns_size.m,
      renderCell: ({ row }) =>
        <div style={{ whiteSpace: "initial" }}>
          {handleHighlightKeywordInTable(row.SearchValue, NullFormatUtils(row.title))}
        </div>,
      sortable: true,
    },
    {
      headerName: 'Category',
      field: 'category',
      width: table_columns_size.xs,
      renderCell: ({ row }) =>
        <>
          {handleHighlightKeywordInTable(row.SearchValue, NullFormatUtils(row.category))}
        </>,
      sortable: true,
    },
    {
      headerName: 'Seller ID',
      field: 'account_id',
      width: table_columns_size.m,
      renderCell: ({ row }) =>
        row.account_id ?
          <Link to="#"
            className="text-secondary"
            onClick={() => handlePopUp("AccountDetailsPopup", row.account_id)}>
            {handleHighlightKeywordInTable(row.SearchValue, row.account_id)}
          </Link>
          : "-",
      sortable: true,
    },
    {
      headerName: 'Create Date',
      field: 'createAt',
      width: table_columns_size.xm,
      renderCell: ({ row }) =>
        <div style={{ whiteSpace: "initial" }}>
          {handleHighlightKeywordInTable(row.SearchValue, DateFormatUtils(row.createAt, "YYYY/MM/DD hh:mm:ss a"))}
          {handleNewestDate(row.createAt)}
        </div>,
      sortable: true,
      type: 'date',
    },
    {
      headerName: 'Update At',
      field: 'updateAt',
      width: table_columns_size.xm,
      renderCell: ({ row }) =>
        <>
          {handleHighlightKeywordInTable(row.SearchValue, DateFormatUtils(row.updateAt, "YYYY/MM/DD hh:mm:ss a"))}
        </>,
      sortable: true,
      type: 'date',
    },
    {
      headerName: 'Inventory',
      field: 'stock',
      width: 100,
      renderCell: ({ row }) =>
        NullFormatUtils(row.stock),
      sortable: true,
    },
    {
      headerName: 'Price',
      field: 'price',
      width: 180,
      renderCell: ({ row }) =>
        '$' + NumberFormatUtils(row.price, 8),
      sortable: true,
    },
    {
      headerName: 'Crypto',
      field: 'crypto',
      width: 100,
      renderCell: ({ row }) =>
        handleHighlightKeywordInTable(row.SearchValue, NullFormatUtils(row.cryptoType)),
      sortable: true,
    },
    {
      headerName: 'Report',
      field: 'report',
      width: 100,
      renderCell: ({ row }) =>
        row.report ?
          <Link to="#" className="text-secondary" onClick={() => handlePopUp("Report", row.id)}>
            Report
          </Link>
          : "-",
      sortable: true,
    },
    {
      headerName: 'Status',
      field: 'status',
      width: 100,
      renderCell: ({ row }) =>
        <Chip label={handleHighlightKeywordInTable(row.SearchValue, NullFormatUtils(row.status))}
          className={handleFilterByKeyword(status.offer, "label", row.status)} />,
      sortable: true,
    }
  ];

  const handleChangeRows = async (statusParam, statusValue) => {
    setCurrentFilter(statusValue);
    setStatusStatement(`${statusParam}=${statusValue}`);
    fetchAllData(1, `${statusParam}=${statusValue}`);
    setLoading(false);
  };

  const handleClearAndRefresh = () => {
    setCurrentFilter(0);
    setStatusStatement(null);
  };

  const RecentUpdatePanel = () => {
    return (
      recentUpdateRows.length >= 1 ?
        <Card sx={{ p: 3, marginBottom: '1rem' }}>
          <div style={{ display: 'flex' }}>
            <IconButton color="primary" onClick={handleRecentUpdateRow}>
              <IndeterminateCheckBoxIcon />
            </IconButton>
            <h6 style={{ lineHeight: '3' }}>Recent Updated</h6>
          </div>

          {recentUpdateShow && <DataTable dt_columns={dt_columns} dt_rows={recentUpdateRows} height={300} />}
        </Card>
        : <></>
    );
  }

  return (
    <div>
      {page > 0 ?
        <Popup
          openPopup={openPopUp}
          setOpenPopup={() => { setOpenPopup(false) }}>

          <CustomPopup name={popUpName} id={currentOffer} />
        </Popup>
        : <></>
      }

      <DashboardLayout>
        <DashboardNavbar />
        <Loader loading={loading}>
          <SuiBox py={3}>
            <SuiBox mb={3}>
              <LabelFilterPanel
                currentFilter={currentFilter}
                handleClearAndRefresh={handleClearAndRefresh}
                fetchAllData={fetchAllData}
                allStatus={allStatusWithCount}
                handleChangeRows={handleChangeRows}
              />
              <RecentUpdatePanel />

              <Card>
                <DataTable dt_columns={dt_columns} dt_rows={filterRows} handleSearch={handleSearch}/>
                <Pagination color={"secondary"} count={totalPage} page={tbPage} onChange={handlePageChange} />
              </Card>
            </SuiBox>
          </SuiBox>
        </Loader>
        
      </DashboardLayout>
    </div>
  )
}