import 'components/Custom/common.css';
import InputWrapper from 'components/Custom/input_wrapper';
import AccountDetails from 'components/Page/account_details';
import WalletApi from 'api/wallet';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { SuiBox } from "components/Default";
import Grid from '@mui/material/Grid';

function TopupDetailsPopup({ id }) {

    const [topup, setTopup] = useState([]);

    const fetchData = () => {
        WalletApi.getDepositDetails({ history_id: id })
        .then((res) => {
          if (res.status === 200){
            let d = res?.data?.data;
            setTopup(d);
          }
          
        }).catch((e) => {
          console.log(e);
        });
      };
    
    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="popup-wrapper">
            <div className="popup-content" style={{ width: "80rem" }}>
                <h4>Top Up Details</h4>
                <InputWrapper attr={"History ID"} value={topup.id ?? "-"} size="lg" />
                <InputWrapper attr={"User ID"} value={topup.account_id ?? "-"} size="lg" />
                
                <Grid container>
                    <Grid item xs={12} sm={8} md={6} xl={6}>
                        <InputWrapper attr={"Crypto Type"} value={topup.wallet?.crypto_type ?? "-"} size="lg" />
                    </Grid>
                    <Grid item xs={12} sm={8} md={6} xl={6}>
                        <InputWrapper attr={"From Address"} size="lg" value={topup.from_address ?? "-"}/>
                    </Grid>
                </Grid>

                <InputWrapper attr={"Networks"} value={topup.network ?? "-"} size="lg" />
                {/* <InputWrapper attr={"Transaction ID"} value={topup.transaction_id ?? "-"} size="lg" /> */}
                
                <Grid container>
                    <Grid item xs={12} sm={8} md={6} xl={6}>
                        <InputWrapper attr={"Top Up Address"}  size="lg" value={topup.to_address ?? "-"}/>
                    </Grid>
                    <Grid item xs={12} sm={8} md={6} xl={6}>
                        <InputWrapper attr={"Create Date"} value={topup.created_at ? moment(topup.created_at).format("YYYY-MM-DD HH:mm") : "-"} size="lg" />
                    </Grid>
                </Grid>
                
                <InputWrapper attr={"Actual Amount"} value={topup.amount ?? "-"} size="lg" />
                <div className="popup-form-row">
                    <InputWrapper attr={"Status"} size="lg" value={topup.status ?? "-"}/>
                </div>
                <InputWrapper attr={"txid"} value={topup.txid ?? "-"} size="lg" />
               
                <SuiBox mt={2}>
                    <InputWrapper attr={"Remarks"} size="lg" />
                </SuiBox>
            </div>
        </div>
    );
}

function CustomPopup({ name, id }) {

    //console.log(id)

    if (name === "TopupDetailsPopup") {
        return <TopupDetailsPopup id={id} />
    } else if (name === "AccountDetailsPopup") {
        return <AccountDetails id={id} />
    } else return <></>
}

export default CustomPopup;