import AdvsApi from "api/advs";
import initalValue from "entities/base/initalValue";
import FirebaseGateway from "./firebaseGateway";

const Scheme = {
    id: initalValue.Int,
    title: initalValue.String,
    userId: initalValue.String,
    account_id: initalValue.String,
    username: initalValue.String,
    position: initalValue.String,
    cost: initalValue.Double,
    days: initalValue.Int,
    releaseDate: initalValue.Datetime,
    endDate: initalValue.Datetime,
    createAt: initalValue.Datetime,
    views: initalValue.Int,
    status: initalValue.String,
    url: initalValue.String,
    headerImage: initalValue.Image,
    contentImage: initalValue.Image,
    description: initalValue.String,
    admin_id: initalValue.String
}

export default class AdvertisementGateway {
    constructor() { }

    getScheme() {
        return Scheme;
    };

    async getAllData({ page, statusStatement, position = "A1" }) {
        let firebaseGateway = new FirebaseGateway();
        let latestToken = await firebaseGateway.getToken();

        return await AdvsApi.getListByPosition({
            position: position,
            page: page,
            statusStatement: statusStatement,
            id_token: latestToken
        })
            .then(response => {
                if (response.status === 200) {
                    return {
                        data: this.formatAdvsData(response.data.data.data),
                        last_page: response.data.data.last_page,
                        per_page: response.data.data.per_page,
                        total: response.data.data.total
                    };
                }
            })
    }

    async getOneAdvData(id) {
        return await AdvsApi.getOneAdvByFid(id)
            .then(response => {
                if (response.status === 200) {
                    return {
                        data: this.formatAdvData(response.data.data)
                    }
                }
            })
    }

    formatAdvData(apiData) {
        const {
            id, title, user_id, account_id, user_name, position, cost, days, release_date, end_at, view_count, status, url, image, content_images, description, admin_id, created_at
        } = apiData;

        return {
            id: id,
            title: title,
            userId: user_id,
            account_id: account_id,
            username: user_name,
            position: position,
            cost: cost,
            days: days,
            releaseDate: release_date,
            endDate: end_at,
            createAt: created_at,
            views: view_count,
            status: status,
            url: url,
            headerImage: image,
            contentImage: content_images,
            description: description,
            admin_id: admin_id
        };
    }

    formatAdvsData(apiData) {
        return apiData.map(data => {
            return this.formatAdvData(data);
        });
    }
}