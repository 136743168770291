import {
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Typography
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SuiButton from "components/Default/SuiButton";

import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(0)
  },
  dialogTitle: {
    paddingRight: "0px"
  }
}));

function Popup({ title, children, openPopup, setOpenPopup }) {
  const classes = useStyles();

  return (
    <Dialog
      open={openPopup}
      maxWidth="xl"
      classes={{ paper: classes.dialogWrapper }}
    >
      <DialogTitle className={classes.dialogTitle}>
        <div style={{ display: "flex" }}>
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {title}
          </Typography>
          <SuiButton
            color="secondary"
            onClick={() => {
              setOpenPopup(false);
            }}
          >
            <CloseIcon />
          </SuiButton>
        </div>
      </DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
    </Dialog>
  );
}

Popup.propTypes = {
  title: PropTypes.any,
  children: PropTypes.any,
  openPopup: PropTypes.any,
  setOpenPopup: PropTypes.any
};

export default Popup;
