import Form from "template/base/form";
import Row from "template/base/row";
import Column from "template/base/column";
import ControlPanel from "template/base/controlPanel";

function SecurityEditTemplate(props) {
  const {
    Title,
    SubTitle1,
    BiometricAuthSwitch,
    SubTitle2,
    SMSCodeSwitch,
    SubTitle3,
    GoogleAuthSwitch,
    ActionButton
  } = props;
  
  return (
    <Form>
      <Column>
        <Title />
        <Row>
          <SubTitle1 />
          <BiometricAuthSwitch />
        </Row>
        <Row>
          <SubTitle2 />
          <SMSCodeSwitch />
        </Row>
        <Row>
          <SubTitle3 />
          <GoogleAuthSwitch />
        </Row>
        <ControlPanel>
          <ActionButton />
        </ControlPanel>
      </Column>
    </Form>
  );
}

export default SecurityEditTemplate;
