import axios from "./index";

class OfferApi {
  static list = () => {
    return axios.get(`${base}`);
  };

  static getOfferByPage = ({ page, statusStatement, searchValue }) => {
    const id_token = localStorage.getItem("id_token");
    if(page && searchValue){
      return axios.get(`${base}?page=${page}&search="${searchValue}"`, { headers: {"Authorization" : `Bearer ${id_token}`} });
    }
    else if(page && !statusStatement){
      return axios.get(`${base}?page=${page}`, { headers: {"Authorization" : `Bearer ${id_token}`} });
    } 
    else if(!page && statusStatement){
      return axios.get(`${base}?${statusStatement}`, { headers: {"Authorization" : `Bearer ${id_token}`} });
    }
    else if(page && statusStatement){
      return axios.get(`${base}?page=${page}&${statusStatement}`, { headers: {"Authorization" : `Bearer ${id_token}`} });
    }
    throw "page and status not found";
  };

  static getOneOfferByFid = (id) => {
    const id_token = localStorage.getItem("id_token");
    if(id){
      return axios.get(`${base}/${id}`, { headers: {"Authorization" : `Bearer ${id_token}`} });
    }
    throw "Offer id is missing";
  };

  static editOfferStatus = (data) => {
    const id_token = localStorage.getItem("id_token");
    if(data){
      const requestData = {
        status: data.status
      };
      return axios.post(`${base}/edit/${data.id}`, requestData, { headers: {"Authorization" : `Bearer ${id_token}`} });
    }
    throw "request data is missing";
  };

  static blockOffer = (id) => {
    const id_token = localStorage.getItem("id_token");
    if(id){
      return axios.put(`${base}/${id}/block`, { headers: {"Authorization" : `Bearer ${id_token}`} });
    }
    throw "offer id is missing";
  };

  static unBlockOffer = (id) => {
    const id_token = localStorage.getItem("id_token");
    if(id){
      return axios.put(`${base}/${id}/on`, { headers: {"Authorization" : `Bearer ${id_token}`} });
    }
    throw "offer id is missing";
  };
}

let base = "/offers";

export default OfferApi;
