import Card from '@mui/material/Card';

function CardForm({ children }){
	return (
		<Card>
            {children}
		</Card>
	);
}

export default CardForm;