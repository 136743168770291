import { singleAxisLineOptions, multiAxisLineOptions } from "./constant";

const customTitle = {
  cryptotype: 0
  // BTC || ETH || USDC || USDT
  // 0      0      0       0
};

/* const SingleLineChartDataTemplate_1 = {
  labels: [],
  datasets: [
    {
      data: [],
      label: "BTC",
      borderColor: "#3e95cd",
      fill: false
    },
    {
      data: [],
      label: "ETH",
      borderColor: "#8e5ea2",
      fill: false
    },
    {
      data: [],
      label: "USDC",
      borderColor: "#3cba9f",
      fill: false
    },
    {
      data: [],
      label: "USDT",
      borderColor: "#e8c3b9",
      fill: false
    }
  ]
}; */

const colors = ["#3e95cd", "#8e5ea2", "#3cba9f", "#e8c3b9"];

/* const MultipleLineChartDataTemplate_1 = {
  labels: [],
  datasets: [
    {
      label: "A",
      yAxisID: "y1",
      data: []
    },
    {
      label: "B",
      yAxisID: "y2",
      data: []
    }
  ]
}; */

function mapToStyledLineChart({ options, labels, datasets }) {
  let temp = [];
  datasets.map((data, index) => {
    if (options === multiAxisLineOptions) {
      temp.push({
        label: data.label,
        borderColor: colors[index],
        yAxisID: "y" + (index + 1),
        data: data.data
      });
    }
    else {
      temp.push({
        label: data.label,
        borderColor: colors[index],
        fill: false,
        data: data.data
      });
    }
  });

  return {
    labels: labels,
    datasets: temp
  };
}

function mapToPieChart({ labels = [], data = []}){
  return {
    labels: labels,
    datasets: [
      {
        //label: '# of Votes',
        data: data,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };
}

function mapToBarChart({ labels, datasets }){
  let temp = [];

  datasets.map((data, index) => {
    temp.push({
      label: data.label,
      data: data.data,
      backgroundColor: colors[index]
    })
  })

  return {
    labels: labels,
    datasets: temp
  }
}

export { mapToStyledLineChart, mapToPieChart, mapToBarChart };
