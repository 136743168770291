import WalletApi from "api/wallet";
import initalValue from "entities/base/initalValue";
import FirebaseGateway from "./firebaseGateway";

const Scheme = {
    id: initalValue.Int,
    account_id: initalValue.String,
    userId: initalValue.String,
    endAt: initalValue.Datetime,
    days: initalValue.Int,
    APR: initalValue.Int,
    cryptoType: initalValue.String,
    savingAmount: initalValue.Double,
    expectedReturn: initalValue.Double,
    status: {
        completed: "已領取",
        processing: "進行中",
        cancelled: "已取消"
    },
    createAt: initalValue.Datetime
}

export default class WalletSavingPlanGateway {
    constructor() { }

    getScheme() {
        return Scheme;
    };

    async getAllData({ page, statusStatement, searchValue }) {
        console.log(page);
        let firebaseGateway = new FirebaseGateway();
        let latestToken = await firebaseGateway.getToken();

        return await WalletApi.getSavingPlanByPage({
            page: page,
            statusStatement: statusStatement,
            id_token: latestToken,
            searchValue: searchValue
        })
            .then(response => {
                if (response.status === 200) {
                    return {
                        data: this.formatWalletSavingPlansData(response.data.data.data),
                        last_page: response.data.data.last_page,
                        per_page: response.data.data.per_page,
                        total: response.data.data.total
                    };
                }
            })
    }

    formatWalletSavingPlanData(apiData) {
        const {
            id, user, uid, redeemed_at, days, annual_percentage_rate, crypto_type, saving_amount, expected_return, status, created_at
        } = apiData;

        return {
            id: id,
            account_id: user?.AccountID,
            userId: uid,
            endAt: redeemed_at,
            days: days,
            APR: annual_percentage_rate,
            cryptoType: crypto_type,
            savingAmount: saving_amount,
            expectedReturn: expected_return,
            status: status,
            createAt: created_at
        };
    }

    formatWalletSavingPlansData(apiData) {
        return apiData.map(data => {
            return this.formatWalletSavingPlanData(data);
        });
    }
}