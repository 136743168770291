import WalletApi from "api/wallet";
import initalValue from "entities/base/initalValue";
import FirebaseGateway from "./firebaseGateway";

const Scheme = {
    id: initalValue.Int,
    account_id: initalValue.String,
    userId: initalValue.String,
    cryptoType: initalValue.String,
    withdrawFee: initalValue.Double,
    networks: initalValue.String,
    amount: initalValue.Double,
    status: {
        completed: "completed",
        processing: "processing",
        failed: "failed"
    },
    createAt: initalValue.Datetime
}

export default class WalletWithdrawGateway {
    constructor() { }

    getScheme() {
        return Scheme;
    };

    async getAllData({ page, statusStatement, searchValue }) {
        let firebaseGateway = new FirebaseGateway();
        let latestToken = await firebaseGateway.getToken();

        return await WalletApi.getWithDrawDetails({
            page: page,
            statusStatement: statusStatement,
            id_token: latestToken,
            searchValue: searchValue
        })
            .then(response => {
                if (response.status === 200) {
                    return {
                        data: this.formatWalletWithdrawsData(response.data.data.data),
                        last_page: response.data.data.last_page,
                        per_page: response.data.data.per_page,
                        total: response.data.data.total
                    };
                }
            })
    }

    formatWalletWithdrawData(apiData) {
        const {
            id, account_id, UserID, wallet, commission, network, amount, status, created_at
        } = apiData;

        return {
            id: id,
            account_id: account_id,
            userId: UserID,
            cryptoType: wallet?.crypto_type,
            withdrawFee: commission,
            networks: network,
            amount: amount,
            status: status,
            createAt: created_at
        };
    }

    formatWalletWithdrawsData(apiData) {
        return apiData.map(data => {
            return this.formatWalletWithdrawData(data);
        });
    }
}