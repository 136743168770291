import DownloadIcon from '@mui/icons-material/Download';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Card, CardContent, CircularProgress, Grid } from "@mui/material";
import { Document, Font, Image, Page, PDFDownloadLink, StyleSheet, Text } from '@react-pdf/renderer';
import SavingPlanApi from 'api/analysis/savingPlan';
import defaultPicture from "assets/images/default_picture.png";
import Popup from "assets/theme/components/popup";
import DraggableCardChart from 'components/Custom/card_chart';
import { SuiButton } from "components/Default";
import { constant } from 'config/constant';
import { addDays } from 'date-fns';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import ChartMaker from "util/ChartMaker";
import { chartType, multiAxisLineOptions, singleAxisLineOptions } from "util/ChartMaker/constant";
import { DateFormatUtils } from 'util/index';
import './style.css';

export default function SavingPlanTab() {
    Font.register({
        family: 'Oswald',
        src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
    });
    // Create styles
    const styles = StyleSheet.create({
        body: {
          paddingTop: 35,
          paddingBottom: 65,
          paddingHorizontal: 35,
        },
        title: {
          fontSize: 24,
          textAlign: 'center',
          fontFamily: 'Oswald'
        },
        author: {
          fontSize: 12,
          textAlign: 'center',
          marginBottom: 40,
        },
        subtitle: {
          fontSize: 18,
          margin: 12,
          fontFamily: 'Oswald'
        },
        text: {
          margin: 12,
          fontSize: 14,
          textAlign: 'justify',
          fontFamily: 'Times-Roman'
        },
        image: {
          marginVertical: 15,
          marginHorizontal: 100,
        },
        header: {
          fontSize: 12,
          marginBottom: 20,
          textAlign: 'center',
          color: 'grey',
        },
        pageNumber: {
          position: 'absolute',
          fontSize: 12,
          bottom: 30,
          left: 0,
          right: 0,
          textAlign: 'center',
          color: 'grey',
        },
      });

    let testMode = constant.DEBUG;
    // Pop Up Page
    const [page, setPage] = useState(0);
    const [openPopUp, setOpenPopup] = useState(false);
    const [popUpName, setPopUpName] = useState("");

    const [state, setState] = useState([
        {
          startDate: new Date(),
          endDate: addDays(new Date(), 7),
          key: 'selection'
        }
    ]);

    const [interestData, setInterestData] = useState([]);
    const [cointypeData, setCoinTypeData] = useState([]);
    const [poolData, setPoolData] = useState([]);
    const [unFreezeData, setUnFreezeData] = useState([]);
    const [cancelRadioData, setCancelRadioData] = useState([]);
    const [loading, setLoading] = useState(true);

    const crypto_type_value = ["BTC", "ETH", "USDC", "USDT"];
    const cancel_radio_yKey = ["Count", "Ratio"];


    const handlePopUp = (pName) => {
        setPopUpName(pName);
        setOpenPopup(true);
        setPage(1);
    };

    const fetchCoinType = async (startDate, endDate) => {
        // coin type
        await SavingPlanApi.cointype({
            "date1": startDate, 
            "date2": endDate
        })
          .then((res) => {
            console.log(res.data.data);
            setCoinTypeData(res.data.data);
          })
          .catch((err) => {
            //console.log(err);
          });
    };

    const fetchInterest = async (startDate, endDate) => {
        await SavingPlanApi.interest({
            "date1": startDate, 
            "date2": endDate
        })
          .then((res) => {
            //console.log(res.data.data);
            setInterestData(res.data.data);
          })
          .catch((err) => {
            //console.log(err);
          });
    };

    const fetchPool = async (startDate, endDate) => {
        await SavingPlanApi.pool({
            "date1": startDate, 
            "date2": endDate
        })
          .then((res) => {
            //console.log(res.data.data);
            setPoolData(res.data.data);
          })
          .catch((err) => {
            //console.log(err);
          });
    };

    const fetchUnfreeze = async (startDate, endDate) => {
        await SavingPlanApi.unfreeze_amount({
            "date1": startDate, 
            "date2": endDate
        })
            .then((res) => {
                //console.log(res.data.data);
                setUnFreezeData(res.data.data);
            })
            .catch((err) => {
                //console.log(err);
            });
    }

    const fetchCancelRadio = async (startDate, endDate) => {
        await SavingPlanApi.cancel_radio({
            "date1": startDate, 
            "date2": endDate
        })
            .then((res) => {
                //console.log(res.data.data);
                setCancelRadioData(res.data.data);
            })
            .catch((err) => {
                //console.log(err);
            });
    }

    const fetchAllData = (startDate, endDate) => {
        if(startDate && endDate){
            fetchCoinType(startDate, endDate);
            fetchInterest(startDate, endDate);
            fetchPool(startDate, endDate);
            fetchUnfreeze(startDate, endDate);
            fetchCancelRadio(startDate, endDate);
            setLoading(false);
        } else {
            fetchCoinType();
            fetchInterest();
            fetchPool();
            fetchUnfreeze();
            fetchCancelRadio();
            setLoading(false);
        }
    }

    const handleDatePickerSelected = (date) => {
        //console.log(date);
        setState([date])

        let startDate = DateFormatUtils(date.startDate);
        let endDate = DateFormatUtils(date.endDate);

        //console.log(startDate);
        //console.log(endDate);

        setLoading(true);
        fetchAllData(startDate,endDate)
    }

    const [timeUnit, setTimeUnit] = useState([]);
    const handleTimeUnitChange = (title, value) => {
        setTimeUnit(prevState => ({ ...prevState, [title]: value }));
    }

    const FormatPdfName = (defaultName) => {
        const now = new Date();
        const formatNow = DateFormatUtils(now, "YYYY_MM_DD_HH_mm_ss");
        //console.log(formatNow);
        return defaultName.replace("%time%", formatNow);
    }

    const Chart = ({ id, chartName }) => {
        const url = document.getElementById(id).toDataURL('image/png', 1.0);
        //console.log(url);
        return (
            <>
                <Text style={styles.subtitle} break>
                    {chartName}
                </Text>
                <Image src={url} style={styles.image} source={{ data: Buffer, format: 'png' }}/>
                {/* <Text style={styles.text}>
                    En un lugar de la Mancha, de cuyo nombre no quiero acordarme, no ha
                    mucho tiempo que vivía un hidalgo de los de lanza en astillero, adarga
                    antigua, rocín flaco y galgo corredor. Una olla de algo más vaca que
                    carnero, salpicón las más noches, duelos y quebrantos los sábados,
                    lentejas los viernes, algún palomino de añadidura los domingos,
                    consumían las tres partes de su hacienda. El resto della concluían sayo
                    de velarte, calzas de velludo para las fiestas con sus pantuflos de lo
                    mismo, los días de entre semana se honraba con su vellori de lo más
                    fino. Tenía en su casa una ama que pasaba de los cuarenta, y una sobrina
                    que no llegaba a los veinte, y un mozo de campo y plaza, que así
                    ensillaba el rocín como tomaba la podadera. Frisaba la edad de nuestro
                    hidalgo con los cincuenta años, era de complexión recia, seco de carnes,
                    enjuto de rostro; gran madrugador y amigo de la caza. Quieren decir que
                    tenía el sobrenombre de Quijada o Quesada (que en esto hay alguna
                    diferencia en los autores que deste caso escriben), aunque por
                    conjeturas verosímiles se deja entender que se llama Quijana; pero esto
                    importa poco a nuestro cuento; basta que en la narración dél no se salga
                    un punto de la verdad
                </Text> */}
            </>
        )
    }
    // Create Document Component
    const MyDocument = () => (
        <Document>
            <Page size="A4" style={styles.body}>
                <Text style={styles.header} fixed>
                    BITHON GROUP LIMITED
                </Text>
                <Text style={styles.title}>Saving Plan</Text>
                <Text style={styles.author}>
                    {moment(state[0].startDate).format("MMM Do, YYYY") + " - " + moment(state[0].endDate).format("MMM Do, YYYY")}
                </Text>
                <Image src={defaultPicture} style={styles.image} />

                <Chart id="chart1" chartName="Popular Crypto"/>
                <Chart id="chart2" chartName="Saving Amount"/>
                <Chart id="chart3" chartName="Daily Interest"/>
                <Chart id="chart4" chartName="Unfreeze Amount"/>
                <Chart id="chart5" chartName="Cancel Ratio"/>

                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed />
            </Page>
        </Document>
    );

    const [loaded, setLoaded] = useState(false);
    const handleLoadPdfDocument = () => {
        setLoaded(true);
    }

    useEffect(() => {
        if(cointypeData.length < 1 
            && interestData.length < 1 
            && poolData.length < 1 
            && unFreezeData.length < 1
            && cancelRadioData.length < 1){
            fetchAllData();
        }
    }, []);

    return (
        <>
            {/* "Pop Up" */}
            { 
                page > 0 ?
                <Popup
                    openPopup={openPopUp}
                    setOpenPopup={() => { setOpenPopup(false) }}>

                    <DateRangePicker
                        onChange={item => handleDatePickerSelected(item.selection)}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        ranges={state}
                        direction="horizontal"
                        />
                </Popup>
                : <></>
            }

            <div className="tab-wrapper">
                {
                    loading ?
                        <CircularProgress />
                        : <>
                            <Grid container marginTop={1}>
                                <Grid item xs={8} sm={8} md={8} xl={8}></Grid>
                                <Grid item xs={3} sm={3} md={3} xl={3} justifyContent="end" display="flex">
                                    <div onClick={() => handlePopUp("DateRange")}>
                                        <Card>
                                            <CardContent className='text-sm date_style text-center bold'>
                                                { moment(state[0].startDate).format("MMM Do, YYYY") + " - " + moment(state[0].endDate).format("MMM Do, YYYY") }
                                            </CardContent>
                                        </Card>
                                    </div>
                                </Grid>
                                <Grid item xs={1} sm={1} md={1} xl={1} display="flex" justifyContent="center" alignItems="center">
                                    {
                                        !loaded?
                                            <SuiButton onClick={handleLoadPdfDocument}>
                                                <DownloadIcon />
                                            </SuiButton>
                                            : <></>
                                    }
                                    
                                    <PDFDownloadLink document={<MyDocument/>} fileName={FormatPdfName("bithon_savingplan_%time%.pdf")}>
                                    {({ blob, url, loading, error }) => 
                                        (
                                            loading || !loaded? 
                                                <></> 
                                                :   <SuiButton id="pdf_btn">
                                                            <PictureAsPdfIcon />
                                                    </SuiButton>
                                        )}
                                    </PDFDownloadLink>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <DraggableCardChart title="Popular Crypto" setTimeUnit={handleTimeUnitChange} fetchAllData={fetchAllData} tipsTitle="Daily crypto count for each saving plan" tipsContent="Count the number of users join the saving plan and grouped by crypto type">
                                    <ChartMaker id="chart1" yLabel="crypto_type" type={chartType.Line} xKeyName="created_at_date" yKeyName="crypto_type" valueKeyName="crypto_count" typeValues={crypto_type_value} data={cointypeData} options={singleAxisLineOptions({ unit: timeUnit["Popular Crypto"] })}/>
                                </DraggableCardChart>
                                
                                <DraggableCardChart title="Saving Amount" setTimeUnit={handleTimeUnitChange} fetchAllData={fetchAllData} tipsTitle="Daily saving pool in saving plans" tipsContent="Count the amount of processing saving plan">
                                    <ChartMaker id="chart2" yLabel="crypto_type" type={chartType.Line} xKeyName="redeemed_at_date" yKeyName="crypto_type" valueKeyName="crypto_count" typeValues={crypto_type_value} data={poolData} options={singleAxisLineOptions({ unit: timeUnit["Saving Amount"] })}/>
                                </DraggableCardChart>
                                
                                <DraggableCardChart title="Daily Interest" setTimeUnit={handleTimeUnitChange} fetchAllData={fetchAllData} tipsTitle="Daily interest sent to users" tipsContent="Count the amount of interest return to users">
                                    <ChartMaker id="chart3" yLabel="crypto_type" type={chartType.Line} xKeyName="redeemed_at_date" yKeyName="crypto_type" valueKeyName="crypto_count" typeValues={crypto_type_value} data={interestData} options={singleAxisLineOptions({ unit: timeUnit["Daily Interest"] })}/>
                                </DraggableCardChart>

                                <DraggableCardChart title="Unfreeze Amount" setTimeUnit={handleTimeUnitChange} fetchAllData={fetchAllData} tipsTitle="The freeze amount return back to users" tipsContent="The amount need to give back to users after the end date of saving plan">
                                    <ChartMaker id="chart4" yLabel="crypto_type" type={chartType.Line} xKeyName="date" yKeyName="crypto_type" valueKeyName="unfreeze_amount" typeValues={crypto_type_value} data={unFreezeData} options={singleAxisLineOptions({ unit: timeUnit["Unfreeze Amount"] })}/>
                                </DraggableCardChart>
                                
                                <DraggableCardChart title="Cancel Ratio" setTimeUnit={handleTimeUnitChange} fetchAllData={fetchAllData} tipsTitle="The ratio of cancelled saving plan" tipsContent="Number of saving plans cancelled / total saving plans">
                                    <ChartMaker id="chart5" yLabel="crypto_type" type={chartType.Line} xKeyName="Date" yKeyName={cancel_radio_yKey} data={cancelRadioData} options={multiAxisLineOptions}/>
                                </DraggableCardChart>
                            </Grid>
                        </>
                }
            </div>
        </>
    );
}