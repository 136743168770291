import bithonIcon from "assets/images/loadingIcon.gif";
import "./style.css";

function Loader({ width, loading = true, children }) {

    if(loading){
        return (
            <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center', height: '80vh', width: width ?? 'auto'}}>
                <img src={bithonIcon} width="80" height="80"/>
            </div>
        )
    }
    else{
        return children;
    }
    
}

export default Loader;