import { Grid } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import OTCApi from "api/otc";
import UserApi from "api/user";
import 'components/Custom/common.css';
import InputWrapper from 'components/Custom/input_wrapper';
import SuiButton from "components/Default/SuiButton";
import { OTC_PERMISSION } from 'config/permission';
import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import AuthCodeHelper from "util/AuthCodeHelper";

export default function PaymentMethod({ id, type }) {
    const alert = useAlert();

    const [page, setPage] = useState(1);
    const [auth, setAuth] = useState(false);
    const [otcData, setStaffData] = useState([]);
    const [inputs, setInputs] = useState({}); // inputs
    const [user, setUser] = useState();
    const [foundUser, setFoundUser] = useState(false);
    const [otcDetail, setOtcDetail] = useState();

    const [paymentMethod, setPaymentMethod] = useState({
        FPS: false,
        BankTransfer: false,
        Cash: true,
        Cheque: false
    });
    const [contact, setContact] = useState({
        Phone: false,
        Whatsapp: false,
        Email: false
    });

    const handleChange = e => setInputs(prevState => {
        if (["phone", "whatsapp", "email"].includes(e.target.name)) {
            setContact({ ...contact, [e.target.name.charAt(0).toUpperCase() + e.target.name.slice(1)]: true })
        }

        return ({ ...prevState, [e.target.name]: e.target.value })
    });
    //const handlePaymentMethodChange = (event) => {setPaymentMethod({...paymentMethod, [event.target.name]: event.target.checked})};
    const handleContactChange = (event) => { setContact({ ...contact, [event.target.name]: event.target.checked }) };
    //const { FPS, BankTransfer, Cash, Cheque } = paymentMethod;
    const { Phone, Whatsapp, Email } = contact;

    function getOTCDetails() {
        OTCApi.getOneOTCByFid(id)
            .then((res) => {
                if (res.status === 200) {
                    setOtcDetail(res.data.data);
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    function getUser() {
        if (inputs['seller_id'].length === 6) {
            UserApi.getOneUserByAid(inputs['seller_id'])
                .then((res) => {
                    if (res && res.status === 200) {
                        setUser(res.data.data)
                        setFoundUser(true);
                    }
                    else setFoundUser(false)
                })
                .catch((err) => {
                    console.log("User unfound.")
                    setFoundUser(false)
                })
        } else setFoundUser(false)
    }

    useEffect(() => {
        if (inputs['seller_id']) {
            getUser();
        }

        if (type === 0 && id) {
            getOTCDetails();
        }
    }, [inputs['seller_id'], id])

    const handleEditOTC = () => {
        if (!auth) {
            setPage(0);
        } else {
            // edit otc api
        }
    };

    const handleOFFOTC = async () => {
        if (!auth) {
            setPage(0);
        } else {
            // edit otc api
            await OTCApi.changeStatus({ "Status": "Off" })
                .then((res) => {
                    if (res && res.status === 200) {
                        alert.success("Edit OTC success");
                    } else {
                        alert.error("Somethings error");
                    }
                })
                .catch((err) => {
                    alert.error(err.message);
                })
        }
    };

    const handleONOTC = async () => {
        if (!auth) {
            setPage(0);
        } else {
            // edit otc api
            await OTCApi.changeStatus({ "Status": "On" })
                .then((res) => {
                    if (res && res.status === 200) {
                        alert.success("Edit OTC success");
                    } else {
                        alert.error("Somethings error");
                    }
                })
                .catch((err) => {
                    alert.error(err.message);
                })
        }
    };

    const handleReleaseOTC = async () => {
        if (!auth) {
            setPage(0);
        } else {
            const payment_method = Object.entries(paymentMethod).filter(([k, v]) => v === true).map(([k, v]) => k);
            const contact_arr = Object.entries(contact).filter(([k, v]) => v === true).map(([k, v]) => k);
            for (let index = 0; index < payment_method.length; index++) {
                const element = payment_method[index];
                const payment_method_chin = {
                    "FPS": "FPS轉數快",
                    "Cheque": "支票",
                    "Cash": "現金交收",
                    "BankTransfer": "銀行轉帳"
                }
                payment_method[index] = payment_method_chin[element];
            }

            if (inputs['branch_addr']
                && inputs['lower_limit']
                && inputs['sell_amount']
                && inputs['trade_desc']
                && inputs['unit_price']
                && inputs['upper_limit']
                && inputs['seller_id'] && foundUser === true
                && inputs['buy_amount']
                && inputs['buy_price']
                && payment_method.length >= 1
                && contact_arr.length >= 1) {
                // create otc api
                await OTCApi.add({
                    "Email": inputs['email'] ?? null,
                    "BranchAddress": inputs['branch_addr'],
                    "Phone": inputs['phone'] ?? null,
                    "LowerLimit": inputs['lower_limit'],
                    "SellingAmount": inputs['sell_amount'],
                    "TradeDescription": inputs['trade_desc'],
                    "SellingPrice": inputs['sell_price'],
                    "UpperLimit": inputs['upper_limit'],
                    "WhatsApp": inputs['whatsapp'] ?? null,
                    "PaymentMethod": payment_method,
                    "AccountID": inputs['seller_id'],
                    "BuyingPrice": inputs['buy_price'],
                    "BuyingAmount": inputs['buy_amount']
                }).then((res) => {
                    if (res && res.status === 200) {
                        alert.success("Create OTC success");
                    } else {
                        alert.error("Somethings error");
                    }
                })
                    .catch((err) => {
                        alert.error(err.message);
                    })
            } else {
                alert.error('You need to fill in all the fields.');
            }
        }
    };

    const handleAuthCode = (resp) => {
        // console.log(resp);

        if (resp) {
            if (resp.isAuth) {
                setAuth(true);
                setPage(1);
            } else {
                alert.error(resp.message);
            }
        }
        else {
            alert.error(ERROR.callback);
        }
    }

    const attributes = {
        "sell_amount": "SellingAmount",
        "sell_price": "SellingPrice",
        "email": "Email",
        "branch_addr": "BranchAddress",
        "lower_limit": "LowerLimit",
        "phone": "Phone",
        "trade_desc": "TradeDescription",
        "upper_limit": "UpperLimit",
        "whatsapp": "WhatsApp",
        "buy_amount": "BuyingAmount",
        "buy_price": "BuyingPrice"

    }

    //get corresponding data in regards of edit or not
    function getData(attr) {
        if (otcDetail && !showEditOrNot[type]) {
            let temp = attributes[attr];
            if (temp) return otcDetail[temp];
        }
        else if (inputs[attr]) return inputs[attr];
    }

    const showEditOrNot = {
        1: false,
        2: false,
        3: true
    }

    const actionButtons = {
        1: <SuiButton buttonColor="secondary" onClick={handleReleaseOTC}>Release</SuiButton>,
        "On":
            <>
                {/* <SuiButton buttonColor="secondary" className="mr-1-5rem" onClick={handleEditOTC}>Edit</SuiButton> */}
                <SuiButton buttonColor="secondary" onClick={handleOFFOTC}>OFF</SuiButton>
            </>,
        "Off": <SuiButton buttonColor="secondary" onClick={handleONOTC}>On</SuiButton>
    }

    if (page === 0) {
        return (<AuthCodeHelper title="Admin Authentication" btnName="Confirm" permission={OTC_PERMISSION.VIEW} callback={handleAuthCode} />);
    }
    else if (page === 1) {
        return (
            <div id="popup1" className="popup-wrapper">
                <div id="" className="popup1-flex-column">
                    <div style={{ fontWeight: 'bold', fontSize: '1rem' }}>Payment Method</div>
                    <FormGroup>
                        {
                            paymentMethod.FPS ?
                                <div>
                                    <FormControlLabel
                                        control={<Checkbox checked={true} name="FPS" />}
                                        label="FPS"
                                    />
                                    <Grid container style={{ width: '50rem' }}>
                                        <Grid item>
                                            <InputWrapper attr="Account Name" size="lg" disable={false} />
                                        </Grid>
                                        <Grid item>
                                            <InputWrapper attr="Account No." size="lg" disable={false} />
                                        </Grid>
                                    </Grid>
                                    <InputWrapper attr="Bank" size="m" disable={false} />
                                    <InputWrapper attr="Remark" size="m" disable={false} />
                                </div>
                                : <></>
                        }

                        {
                            paymentMethod.BankTransfer ?
                                <div>
                                    <FormControlLabel
                                        control={<Checkbox checked={true} name="BankTransfer" />}
                                        label="Bank Transfer"
                                    />
                                    <Grid container style={{ width: '50rem' }}>
                                        <Grid item>
                                            <InputWrapper attr="Account Name" size="lg" disable={false} />
                                        </Grid>
                                        <Grid item>
                                            <InputWrapper attr="Account No." size="lg" disable={false} />
                                        </Grid>
                                    </Grid>
                                    <InputWrapper attr="Bank" size="m" disable={false} />
                                    <InputWrapper attr="Remark" size="m" disable={false} />
                                </div>
                                : <></>
                        }

                        {
                            paymentMethod.Cash ?
                                <div className="popup-content">
                                    <FormControlLabel
                                        control={<Checkbox checked={true} name="Cash" />}
                                        label="Cash"
                                    />
                                    <InputWrapper attr="Account Name" size="lg" disable={false} />
                                    <InputWrapper attr="Paper value" size="m" disable={false} />
                                    <InputWrapper attr="Location" size="m" disable={false} />
                                    <InputWrapper attr="Remark" size="m" disable={false} />
                                </div>
                                : <></>
                        }

                        {
                            paymentMethod.Cheque ?
                                <div>
                                    <FormControlLabel
                                        control={<Checkbox checked={true} name="Cheque" />}
                                        label="Cheque"
                                    />
                                    <InputWrapper attr="Account Name" size="lg" disable={false} />
                                    <InputWrapper attr="Location" size="m" disable={false} />
                                    <InputWrapper attr="Remark" size="m" disable={false} />
                                </div>
                                : <></>
                        }
                    </FormGroup>
                </div>
            </div>
        );
    }
}