import PaymentMethod from "./payment_method";
import ComplaintPopup from "./complaint";
import AccountDetails from 'components/Page/account_details';

function CustomPopup({ name, id, type  }) {
    if(name === "PaymentMethod"){
        return <PaymentMethod id={id} type={type}/>
    }  else if (name === "Complaint"){
        return <ComplaintPopup/>
    }  else if (name === "AccountDetailsPopup") {
        return <AccountDetails id={id} />
    }  else return <></>
}

export default CustomPopup;