import { constant } from 'config/constant';

import { Card, CardContent, CircularProgress, Grid } from "@mui/material";
import OrderApi from 'api/analysis/order';
import GameApi from 'api/analysis/game';
import DraggableCardChart from 'components/Custom/card_chart';
import { useEffect, useState } from 'react';
import ChartMaker from 'util/ChartMaker';
import { chartType, multiAxisLineOptions, singleAxisLineOptions } from 'util/ChartMaker/constant';

import Popup from "assets/theme/components/popup";

import { addDays } from 'date-fns';
import moment from 'moment';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateFormatUtils } from 'util/index';
import './style.css';

export default function GameTab() {
    let testMode = constant.DEBUG;
    // Pop Up Page
    const [page, setPage] = useState(0);
    const [openPopUp, setOpenPopup] = useState(false);
    const [popUpName, setPopUpName] = useState("");

    const [state, setState] = useState([
        {
          startDate: new Date(),
          endDate: addDays(new Date(), 7),
          key: 'selection'
        }
    ]);

    const [countParticipants, setCountParticipants] = useState([]);
    const [loading, setLoading] = useState(true);

    const count_participants = ["count of participants"];

    const [timeUnit, setTimeUnit] = useState([]);
    const handleTimeUnitChange = (title, value) => {
        setTimeUnit(prevState => ({ ...prevState, [title]: value }));
    }

    const handlePopUp = (pName) => {
        setPopUpName(pName);
        setOpenPopup(true);
        setPage(1);
    };

    const fetchRefundSum = async (startDate, endDate) => {
        await GameApi.refundSum({
            "date1": startDate, 
            "date2": endDate
        })
          .then((res) => {
            console.log(res);
            console.log(res.data.data);
            setCountParticipants(res.data.data);
          })
          .catch((err) => {
            //console.log(err);
          });
    };

    const fetchAllData = (startDate, endDate) => {
        if(startDate && endDate){
            fetchRefundSum(startDate, endDate);
            setLoading(false);
        } else {
            fetchRefundSum();
            setLoading(false);
        }
    }

    const handleDatePickerSelected = (date) => {
        //console.log(date);
        setState([date])

        let startDate = DateFormatUtils(date.startDate);
        let endDate = DateFormatUtils(date.endDate);

        //console.log(startDate);
        //console.log(endDate);

        setLoading(true);
        fetchAllData(startDate,endDate)
    }

    useEffect(() => {
        if(countParticipants.length < 1)
        {
            fetchAllData();
        }
    }, []);

    return (
        <>
            {/* "Pop Up" */}
            { 
                page > 0 ?
                <Popup
                    openPopup={openPopUp}
                    setOpenPopup={() => { setOpenPopup(false) }}>

                    <DateRangePicker
                        onChange={item => handleDatePickerSelected(item.selection)}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        ranges={state}
                        direction="horizontal"
                        />
                </Popup>
                : <></>
            }

            <div className="tab-wrapper">
                {
                    loading ?
                        <CircularProgress />
                        : <>
                            <Grid container>
                                <Grid item xs={9} sm={9} md={9} xl={9}></Grid>
                                <Grid item xs={3} sm={3} md={3} xl={3}>
                                    <div onClick={() => handlePopUp("DateRange")}>
                                        <Card>
                                            <CardContent className='text-sm date_style text-center'>
                                                { moment(state[0].startDate).format("MMM Do, YYYY") + " - " + moment(state[0].endDate).format("MMM Do, YYYY") }
                                            </CardContent>
                                        </Card>
                                    </div>
                                </Grid>
                            </Grid>
                            
                            <Grid container spacing={2}>
                                <DraggableCardChart title="Daily competitions participants" setTimeUnit={handleTimeUnitChange} tipsTitle="The number of competitons participants" tipsContent="Count the participated competition players and grouped by created_at">
                                    <ChartMaker yLabel="ppl" type={chartType.Line} xKeyName="created_at_date" yKeyName={count_participants} valueKeyName="participants" typeValues={count_participants} data={countParticipants} options={singleAxisLineOptions({ unit: timeUnit["Participants"] })}/>
                                </DraggableCardChart>
                            </Grid>
                        </>
                }
            </div>
        </>
    );
}