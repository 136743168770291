import './styles.css';

import AccountDetailsPopup from './account_details';
import IdVerifyPopup from './id_verify';
import IncomeDeclarationPopup from './income_declaration';
import ShopVerifyPopup from './shop_verify';
import ReferralPopup from './referral';

function CustomPopup({ name, id, mySqlId }) {
    if (name === "AccountDetailsPopup") {
        return <AccountDetailsPopup id={id} mySqlId={mySqlId}/>;
    } else if (name === "IdVerifyPopup") {
        return <IdVerifyPopup id={id} />;
    } else if (name === "ShopVerifyPopup") {
        return <ShopVerifyPopup id={id} />;
    } else if (name === "IncomeDeclaration") {
        return <IncomeDeclarationPopup id={id} />;
    } else if (name === "Referral"){
        return <ReferralPopup id={id} />;
    } else return <></>
}

export default CustomPopup;