import 'components/Custom/common.css';
import AccountDetails from 'components/Page/account_details';

function CustomPopup({ name, id }) {

    if (name === "AccountDetailsPopup") {
        return <AccountDetails id={id} />
    } else return <></>
}

export default CustomPopup;