import OfferApi from "api/offer";
import initalValue from "entities/base/initalValue";
import FirebaseGateway from "./firebaseGateway";

const BetaScheme = {
    id: initalValue.String,
    title: initalValue.String,
    category: initalValue.String,
    userID: initalValue.String,
    account_id: initalValue.String,
    stock: initalValue.Int,
    price: initalValue.String,
    cryptoType: initalValue.String,
    report: initalValue.Boolean,
    status: {
        None: "None",
        On: "On",
        Deleted: "Deleted",
        Blocked: "Blocked",
        Off: "Off"
    },
    createAt: initalValue.Datetime,
    updateAt: initalValue.Datetime
}

const DetailsScheme = {
    id: initalValue.String,
	title: initalValue.String,
	category: initalValue.String,
	account_id: initalValue.String,
	shopVerifyStatus: {
        None: "None",
        On: "On",
        Deleted: "Deleted",
        Blocked: "Blocked",
        Off: "Off"
    },
	stock: initalValue.Int,
	price: initalValue.String,
	cryptoType: initalValue.String,
	status: {
        None: "None",
        On: "On",
        Deleted: "Deleted",
        Blocked: "Blocked",
        Off: "Off"
    },
	likes: initalValue.Int,
	sales: initalValue.Int,
	deliveryMethod: initalValue.String,
	productCondition: initalValue.String,
	headerImage: initalValue.Image,
	backgroundImage: initalValue.Image,
	contentImages: [initalValue.Image, initalValue.Image],
	description: initalValue.String,
	createAt: initalValue.Datetime,
	updateAt: initalValue.Datetime,
	handler: initalValue.String,
}

export default class OfferGateway {
    constructor() { }

    getScheme() {
        return [BetaScheme, DetailsScheme];
    };

    async getAllData({ page, statusStatement, searchValue }) {
        let firebaseGateway = new FirebaseGateway();
        let latestToken = await firebaseGateway.getToken();

        return await OfferApi.getOfferByPage({
            page: page,
            statusStatement: statusStatement,
            id_token: latestToken,
            searchValue: searchValue
        })
            .then(response => {
                if (response.status === 200) {
                    return {
                        data: this.formatOffersData(response.data.data.data),
                        last_page: response.data.data.last_page,
                        per_page: response.data.data.per_page,
                        total: response.data.data.total
                    };
                }
            })
    }

    async getOneOfferData({ type = "mysql_id", id = 0 }) {
        return await OfferApi.getOneOfferByFid(id)
            .then(response => {
                if(response.status === 200){
                    const likeData = Object.entries(res.data.data.Likes)
                        .map(a => ({ id: Object.keys(a[1])[0], ...Object.entries(a[1])[0][1] }));
                    let likeCount = 0;
                    likeData.map(data => {
                        if (data.IsLiked) { likeCount = likeCount + 1; }
                    })

                    return {
                        data: this.formatOfferData(response.data.data)
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                alert.error(err.message);
            });
    }

    formatOfferData(apiData) {
        const {
            id, title, category, user_id, account_id, stock, price, crypto_type_id, has_report, status, created_at, updated_at
        } = apiData;

        return {
            id: id,
            title: title,
            category: category,
            userID: user_id,
            account_id: account_id,
            stock: stock,
            price: price,
            cryptoType: crypto_type_id,
            report: has_report,
            status: status,
            createAt: created_at,
            updateAt: updated_at
        };
    }

    formatOfferDetails(apiData) {
        return { }
    }

    formatOffersData(apiData) {
        return apiData.map(data => {
            return this.formatOfferData(data);
        });
    }
}