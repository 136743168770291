import { Card } from "@mui/material";
import ClearAndRefreshButton from "./Component/ClearAndRefreshButton";
import RefreshButton from "./Component/RefreshButton";
import ExportButton from "./Component/ExportButton";
import StatusPanel from "./Component/StatusPanel";
//import AdvancedSearch from "./AdvancedSearch";

function LabelFilterPanel(props){
  return (
      <Card sx={{ p:3, flexDirection: 'row' , columnGap: '50', marginBottom: '1rem'}}>
        { props.currentFilter === 0 ? <></> : <ClearAndRefreshButton onClick={props.handleClearAndRefresh}/>}
        <RefreshButton onClick={props.fetchAllData}/>

        <StatusPanel  
          allStatus={props.allStatus} 
          currentFilter={props.currentFilter} 
          handleChangeRows={props.handleChangeRows}
          />
        {/* <AdvancedSearch/> */}
      </Card>
    );
}

export default LabelFilterPanel;