import { storage } from "../config/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getAuth, signInWithEmailAndPassword, signInWithCustomToken, getIdToken, onAuthStateChanged } from "firebase/auth";

class FireStorage {

  static signInFB = async ({ email, password }) => {
    const authentication = getAuth();
    //console.log(authentication);

    const res = await signInWithEmailAndPassword(authentication, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log(user);
        return user;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });

    console.log(res);
    
    return res;
  };

  

  static idToken = async () => {
    onAuthStateChanged(getAuth(), async (user) => {
      if (user) {
        let id_token = await getIdToken(user);
        //console.log(id_token);
        localStorage.setItem("id_token", id_token)
      }
    });
  }

  static signInFBWithToken = async ({ token }) => {
    const authentication = getAuth();
    let id_token = null;

    onAuthStateChanged(authentication, async (user) => {
      if (user) {
        id_token = await getIdToken(user);
        localStorage.setItem("id_token", id_token);
        //console.log(id_token);
      }
      return false;
    });
    
    const res = await signInWithCustomToken(authentication, token)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        //console.log(user);
        return user;
      })
      .catch((err) => {
        console.log(err);
        return false;
      })
    
    if(res){
      return id_token;
    }
    return false;
  }

  static saveImageToFB = async (data) => {
    const storageRef = ref(storage, `${data.Place}/${data.Name}`);
    
    // 'file' comes from the Blob or File API
    const snapshot =  await uploadBytes(storageRef, data.File)
      .then((snapshot) => {
        console.log('Uploaded a blob or file!');
        return snapshot;
      }).catch((e) => {
        console.log(e);
      });

    let url = "";
    if(snapshot){   
      url = await getDownloadURL(snapshot.metadata.ref).then((url) => {
        return url;
      });
    }
    console.log(url);

    return url;

    /* return await getDownloadURL(snapshot.metadata.ref).then((url) => {
      return url;
    }); */
  };
}

export default FireStorage;