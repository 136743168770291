import className from "./style";

const otcstatus = [
    {
      label: "active",
      value: 1,
      color: "success",
      class: className.success,
      param: {
        name: "status",
        value: "active"
      },
      count: 0
    },
    {
      label: "inactive",
      value: 2,
      color: "error",
      class: className.error,
      param: {
        name: "status",
        value: "inactive"
      },
      count: 0
    }
];

export default otcstatus;