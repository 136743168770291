import 'components/Custom/common.css';
import AccountDetails from 'components/Page/account_details';
import CancelPopup from './cancel_pop_up';
import SalePersonPopup from './saleperson_pop_up';

function CustomPopup({ name, id, offerId, salePersonId }) {
    if (name === "AccountDetailsPopup") {
        return <AccountDetails id={id} />
    } else if (name === "CancelPopup") {
        return <CancelPopup id={id} offerId={offerId} />
    } else if (name === "SalePersonPopup") {
        return <SalePersonPopup id={id} offerId={offerId} salePersonId={salePersonId}/>
    } else return <></>
}

export default CustomPopup;