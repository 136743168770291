import { Grid } from "@mui/material";
import { SuiBox } from "components/Default";
import Column from "template/base/column";
import Form from "template/base/form";
import FormContent from "template/base/formContent";
import Row from "template/base/row";

function OfferReportTemplate(props) {
  const {
    Title,
    ReportData = [],
    SubTitle,
    SubmitDate,
    UserId,
    ReportReason,
    ReportDetails,
    ReportProofs
  } = props;

  function RowsData({ data }) {
    return data.map((data, index) =>
      <Row key={index}>
        <FormContent xs={9} sm={9} md={9} xl={9}>
          <SubTitle index={index} />
          <Grid container alignItems="center">
            <Grid item xs={12} sm={8} md={6} xl={5}>
              <SubmitDate createAt={data?.CreateAt} />
            </Grid>
            <Grid item xs={12} sm={8} md={6} xl={7}>
              <UserId userId={data?.UserID}/>
            </Grid>
          </Grid>

          <SuiBox xs={2} lg={3} xl={6}>
            <ReportReason reportReason={data?.ReportReason}/>
          </SuiBox>
          <ReportDetails reportDetails={data?.ReportDetails}/>
        </FormContent>
        <ReportProofs reportProof1={data?.ReportProof1} reportProof2={data?.ReportProof2}/>
      </Row>
    );
  }

  return (
    <Form>
      <Column>
        <Title />
        <FormContent>
            <RowsData data={ReportData} />
        </FormContent>
      </Column>
    </Form>
  );
}

export default OfferReportTemplate;
