import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { Icon, IconButton, InputLabel, TextField } from '@mui/material';
import UserApi from 'api/user';
import WalletApi from 'api/wallet';
import { EditSuccessfulMsg, Loader } from 'components/Custom';
import InputWrapper from 'components/Custom/input_wrapper';
import SuiButton from 'components/Default/SuiButton';
import { WALLET_PERMISSION } from 'config/permission';
import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import AuthCodeHelper from "util/AuthCodeHelper";
import { NumberFormatUtils } from 'util/index';

const sampleWallet = {
    "message": "success",
    "data": [{
        "UserID": "H0X42lxetvgqImdgQTTIQFOIA3O2",
        "BTC": "1.09929747",
        "ETH": "0.000000000000000000",
        "USDT": "26210008436.87397753",
        "USDC": "199.04975000",
        "freeze_BTC": "0",
        "freeze_ETH": "0",
        "freeze_USDT": "0",
        "freeze_USDC": "0",
        "User": {
            "AccountID": "TTTTT",
            "Security": {
                "GoogleAuth": {
                    "Key": null,
                    "IsPermit": true
                }
            },
            "IdVerification": [],
            "ShopVerification": [],
            "UserName": ""
        },
        "total_BTC": "664561.389494877373150804",
        "total_HKD": "205139240759.727249148672031417",
        "total_freeze_BTC": "0.000000000000000000",
        "total_freeze_HKD": "0.000000000000000000"
    }]
};

const sampleUser = {
    "AccountID": "",
    "AccountStatus": "",
    "Description": "",
    "DeviceID": "",
    "DisplayAddress": {
        "Address1": "",
        "Address2": "",
        "District": null
    },
    "Email": "",
    "HeaderImage": "https://upload.wikimedia.org/wikipedia/commons/thumb/7/70/Checkerboard_pattern.svg/1200px-Checkerboard_pattern.svg.png",
    "IdVerifyStatus": "",
    "IsBioAuth": false,
    "Phone": "",
    "Rating": null,
    "Security": {
        "Biometrics": {
            "DeviceID": "",
            "IsBioAuth": false
        },
        "GoogleAuth": {
            "IsPermit": true,
            "Key": null
        },
        "Sms": {
            "Phone": null
        }
    },
    "ShopVerifyStatus": "",
    "UserIcon": "https://upload.wikimedia.org/wikipedia/commons/thumb/7/70/Checkerboard_pattern.svg/1200px-Checkerboard_pattern.svg.png",
    "UserName": "",
    "deviceID": ""
};



function CurrencyNetworkAddress (data) {

    if (data){

        const keys = Object.keys(data.data);

        return(
            //networkList.map
            <div className='popup-form-column flex-end'>
                <div className="popup-form-column">
                    {
                        keys.map((k, key) => {
                            return(
                                <span key={key} className="popup-form-row">
                                    <div className="popup-subtitle">{k}</div>
                                    <div className="popup-subtitle">{Object.values(data.data)[key]}</div> 
                                </span>
                            )
                        })
                    }
                             
                </div>
            </div>
        );
    }else{
        return (
            <div></div>
        )
    }

   

}

export default function WalletDetailsPopup({ id }){

    //page 0 : wallet details, page 1 : adjust BTC balance
    const [page, setPage] = useState(0);
    const [currency, setCurrency] = useState(""); //BTC, ETH, USDC, USDT
    const [walletData, setWalletData] = useState();
    const [oldValue, setOldValue] = useState();
    const [error, setError] = useState();
    const [mode, setMode] = useState(0);
    const [loading, setLoading] = useState(true);
    const [frozenValue, setFrozenValue] = useState(0);

    const fetchWalletData = async(id) =>{

        WalletApi.getOneWallet(id)
        .then((res) => {
            if (res.data){
                //console.log(res.data)
                setWalletData(res.data.data);
                setLoading(false);
            }
        })
        .catch((e)=>{
            //console.log(e);
            setError(e)
        })
    }

    useEffect(()=>{
        fetchWalletData(id);
    }, [id])


    const handleAdjustBTC = (m) =>{
        if (walletData.BTC){
            setCurrency("BTC");
            setOldValue(NumberFormatUtils(walletData.BTC, 8))
            setFrozenValue(NumberFormatUtils(walletData.freeze_BTC, 8))
            setPage(m);
        }
     
    }

    const handleAdjustETH = (m) =>{
        if (walletData.ETH){
            setCurrency("ETH");
            setOldValue(NumberFormatUtils(walletData.ETH, 8))
            setFrozenValue(NumberFormatUtils(walletData.freeze_ETH, 8))
            setPage(m);
        }
    }

    const handleAdjustUSDC = (m) =>{
        if (walletData.USDC){
            setCurrency("USDC");
            setOldValue(NumberFormatUtils(walletData.USDC, 8))
            setFrozenValue(NumberFormatUtils(walletData.freeze_USDC, 8))
            setPage(m);
        }
    }

    const handleAdjustUSDT = (m) =>{
        if (walletData.USDT){
            setCurrency("USDT");
            setOldValue(NumberFormatUtils(walletData.USDT, 8))
            setFrozenValue(NumberFormatUtils(walletData.freeze_USDT, 8))
            setPage(m);
        }
    }

    if (page === 0){
    return <WalletDetails 
        actionBTC={handleAdjustBTC}
        actionUSDC={handleAdjustUSDC}
        actionETH={handleAdjustETH}
        actionUSDT={handleAdjustUSDT} 
        id={id}/>
    }else if(page === 1 && oldValue){
        //mode 0 : available balance, mode 1 : frozen assets
        return <AdjustCurrencyBalance currency={currency} oldValue={oldValue} walletData={walletData}/>
    }else if (page === 2 && frozenValue && oldValue){
        return <AdjustFrozenBalanceForm currency={currency} oldValue={oldValue} walletData={walletData} frozenValue={frozenValue} />
    }

}

function WalletDetails({ actionBTC, actionETH, actionUSDC, actionUSDT, id }){

    //const [currentWallet]
    const [error, setError] = useState(undefined);
    const [walletData, setWalletData] = useState(sampleWallet);
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState(sampleUser);
    
    const [showBTCNetworks, setShowBTCNetworks] = useState(false);
    const [showETHNetworks, setShowETHNetworks] = useState(false);
    const [showUSDCNetworks, setShowUSDCNetworks] = useState(false);
    const [showUSDTNetworks, setShowUSDTNetworks] = useState(false);


    const handleShowBTCNetworks = () => { showBTCNetworks ? setShowBTCNetworks(false) : setShowBTCNetworks(true); }
    const handleShowETHNetworks = () => { showETHNetworks ? setShowETHNetworks(false) : setShowETHNetworks(true); }
    const handleShowUSDCNetworks = () => { showUSDCNetworks ? setShowUSDCNetworks(false) : setShowUSDCNetworks(true); }
    const handleShowUSDTNetworks = () => { showUSDTNetworks ? setShowUSDTNetworks(false) : setShowUSDTNetworks(true); }


    const fetchWalletData = async(id) =>{

        WalletApi.getOneWallet(id)
        .then((res) => {
            if (res.data){
                //console.log(res.data)
                setWalletData(res.data.data);
                setLoading(false);
            }
        })
        .catch((e)=>{
            setUserData(sampleWallet)
            console.log(e);
            setError(e)
        })
    }

    useEffect(()=>{
        fetchWalletData(id);
    }, [id])

    return(
        <div className="popup1-flex-column">
            {
                loading ?
                <Loader width='80vh'/>
                :
                <>
                <h4>Wallet Details</h4>
                <div className="popup-content">
                    {/*fetch user id by firebase id*/}
                    <InputWrapper attr={"User ID"} size="lg" value={walletData.User?.account_id ?? ""}/>
                    {/*fetch user name by firebase id*/}
                    <InputWrapper attr={"User Name"} size="lg" value={walletData.User?.username ?? ""}/>
                    {/* total value of the wallet in BTC */}
                    <InputWrapper attr={"Total BTC"} size="lg" value={NumberFormatUtils(parseInt(walletData.total_BTC) + parseInt(walletData.total_freeze_BTC), 8)}/>
                </div>
                {/* <h4>Balance</h4> */} 
                <div className="popup-content">


                {/*BTC*/}
                <h5>BTC</h5>
                <div className="popup-form-row">
                    <InputWrapper attr={"Total Balance"} size="col" value={NumberFormatUtils(parseInt(walletData.BTC) + parseInt(walletData.freeze_BTC), 8) ?? ""}/>
                    <InputWrapper attr={"Available Balance"} size="col" value={NumberFormatUtils(walletData.BTC, 8) ?? ""}/>
                    <SuiButton buttonColor={"secondary"} onClick={()=>actionBTC(1)}>EDIT</SuiButton>
                    <InputWrapper attr={"Frozen Amount"} size="col" value={NumberFormatUtils(walletData.freeze_BTC, 8)}/>
                    <SuiButton buttonColor={"secondary"} onClick={()=>actionBTC(2)}>EDIT</SuiButton>
                    <SuiButton onClick={handleShowBTCNetworks}>Address</SuiButton>
                </div>
                {
                    showBTCNetworks ? 
                        walletData?.addresses?.BTC ? 
                            <CurrencyNetworkAddress data={walletData?.addresses?.BTC}/>
                        :<div className="popup-subtitle">No address data</div>
                    :<></>
                }


                {/*ETH*/}
                <h5>ETH</h5>
                <div className="popup-form-row">
                    <InputWrapper attr={"Total Balance"} size="col" value={NumberFormatUtils(parseInt(walletData.ETH) + parseInt(walletData.freeze_ETH), 8) ?? ""}/>
                    <InputWrapper attr={"Available Balance"} size="col" value={NumberFormatUtils(walletData.ETH, 8) ?? ""}/>
                    <SuiButton buttonColor={"secondary"} onClick={()=>actionETH(1)}>EDIT</SuiButton>
                    <InputWrapper attr={"Frozen Amount"} size="col" value={NumberFormatUtils(walletData.freeze_ETH, 8)}/>
                    <SuiButton buttonColor={"secondary"} onClick={()=>actionETH(2)}>EDIT</SuiButton>
                    <SuiButton onClick={handleShowETHNetworks}>Address</SuiButton>
                </div>
                {
                    showETHNetworks ?
                        walletData?.addresses?.ETH ? 
                            <CurrencyNetworkAddress data={walletData?.addresses?.ETH}/>
                        :<div className="popup-subtitle">No address data</div>
                    :<></>
                }


                {/*USDC*/}
                <h5>USDC</h5>
                <div className="popup-form-row">
                    <InputWrapper attr={"Total Balance"} size="col" value={NumberFormatUtils(parseInt(walletData.USDC) + parseInt(walletData.freeze_USDC), 8) ?? ""}/>
                    <InputWrapper attr={"Available Balance"} size="col" value={NumberFormatUtils(walletData.USDC, 8) ?? ""}/>
                    <SuiButton buttonColor={"secondary"} onClick={()=>actionUSDC(1)}>EDIT</SuiButton>
                    <InputWrapper attr={"Frozen Amount"} size="col" value={NumberFormatUtils(walletData.freeze_USDC, 8)} />
                    <SuiButton buttonColor={"secondary"} onClick={()=>actionUSDC(2)}>EDIT</SuiButton>
                    <SuiButton onClick={handleShowUSDCNetworks}>Address</SuiButton>
                </div>
                {
                    showUSDCNetworks ?
                        walletData?.addresses?.USDC ? 
                            <CurrencyNetworkAddress data={walletData?.addresses?.USDC}/>
                        : <div className="popup-subtitle">No address data</div>
                    :<></>
                }




                {/*USDT*/}
                <h5>USDT</h5>
                <div className="popup-form-row">
                    <InputWrapper attr={"Total Balance"} size="col" value={NumberFormatUtils(parseFloat(walletData.USDT) + parseFloat(walletData.freeze_USDT), 8) ?? ""}/>
                    <InputWrapper attr={"Available Balance"} size="col" value={NumberFormatUtils(walletData.USDT, 8) ?? ""}/>
                    <SuiButton buttonColor={"secondary"} onClick={()=>actionUSDT(1)}>EDIT</SuiButton>
                    <InputWrapper attr={"Frozen Amount"} size="col" value={NumberFormatUtils(walletData.freeze_USDT, 8)}/>
                    <SuiButton buttonColor={"secondary"} onClick={()=>actionUSDT(2)}>EDIT</SuiButton>
                    <SuiButton onClick={handleShowUSDTNetworks}>Address</SuiButton>
                </div>
                {
                    showUSDTNetworks?
                        walletData?.addresses?.USDT ? 
                            <CurrencyNetworkAddress data={walletData?.addresses?.USDT}/>
                        :<div className="popup-subtitle">No address data</div>
                    :<></>
                }


                </div>
                </>
            }
            </div>
            
    );
}


function AdjustFrozenBalanceForm({ currency, oldValue, mode, walletData, frozenValue  }){
    
    const alert = useAlert();
    let testMode = false;

    const [addValue, setAddValue] = useState(true);
    const [newValue, setNewValue] = useState(oldValue);
    const [numError, setNumError] = useState(false);
    const [negNewValue, setNegNewValue] = useState(false);
    const [negNewFrozenValue, setNegNewFrozenValue] = useState(false);
    const unit = currency === "ETH" ? 18 : 8
    const [confirmPage, setConfirmPage] = useState(false);
    const [editReason, setEditReason] = useState("");
    const [showDeltaValue, setShowDeltaValue] = useState();
    const [newFrozenValue, setNewFrozenValue] = useState(0);
    const [deltaValue, setDeltaValue] = useState(0);

    const [auth, setAuth] = useState(false);
    const [authSuccess, setAuthSuccess] = useState(false);

    const handleDeltaValue = (e) => {
        let delta = e.target.value;
        setShowDeltaValue(e.target.value);

        let del = NumberFormatUtils(delta, unit);
        //if user input is not numerical, set as 0
        if (isNaN(del)){
            del = 0;
            setNumError(true);
            setDeltaValue(del);
        }else {
            setNumError(false);
            setDeltaValue(del);
        }

        if (addValue){
            setNewFrozenValue(NumberFormatUtils((parseFloat(frozenValue)+parseFloat(del)), unit))
            setNewValue(NumberFormatUtils((parseFloat(oldValue)-parseFloat(del)), unit))

            if (parseFloat(frozenValue)-parseFloat(deltaValue) < 0) setNegNewValue(true);
            else setNegNewValue(false);
    
        }else {
            setNewFrozenValue(NumberFormatUtils((parseFloat(frozenValue)-parseFloat(del)), unit))
            setNewValue(NumberFormatUtils((parseFloat(oldValue)+parseFloat(del)), unit))

            if (parseFloat(frozenValue)-parseFloat(deltaValue) < 0) setNegNewFrozenValue(true);
            else setNegNewFrozenValue(false);
        }

     
    }

    const handleAddValue = () => {
        setAddValue(true);
        setNewFrozenValue(NumberFormatUtils((parseFloat(frozenValue)+parseFloat(deltaValue)), unit))
        setNewValue(NumberFormatUtils((parseFloat(oldValue)-parseFloat(deltaValue)), unit))
      //  if (parseFloat(oldValue)-parseFloat(deltaValue)) setNegNewValue(true);
      //  else setNegNewValue(false);
    }

    const handleDeductValue = () => {
        setAddValue(false);
        setNewFrozenValue(NumberFormatUtils((parseFloat(frozenValue)-parseFloat(deltaValue)), unit))
        setNewValue(NumberFormatUtils((parseFloat(oldValue)+parseFloat(deltaValue)), unit))
       // if (parseFloat(frozenValue)-parseFloat(deltaValue) < 0) setNegNewFrozenValue(true);
       // else setNegNewFrozenValue(false);
    }


    const handleProceed = () => {

        if (!negNewValue && !negNewFrozenValue){
            setConfirmPage(true)
        }
    }

    useEffect(() => {
        if (newValue < 0){
            setNegNewValue(true)
        }else setNegNewValue(false)

        if (newFrozenValue < 0){
            setNegNewFrozenValue(true)
        }else setNegNewFrozenValue(false)
    }, [newValue, newFrozenValue])

        const handleAuthCode = (resp) => {
            // console.log(resp);
    
            if(resp){
                if(resp.isAuth){
                    setAuth(false);
                    setAuthSuccess(true);
                } else{
                    alert.error(resp.message);
                }
            }
            else{
                alert.error(ERROR.callback);
            }
        }

    function ConfirmInfo(){

        const [editSuccess, setEditSuccess] = useState(false);

        const handleProceed = () => {

            const freeze_data = {
                id: walletData.User.firebase_uid,
                amount: deltaValue,
                crypto_type: "freeze_"+currency,
                reason: editReason
            }

            const data = {
                id: walletData.User.firebase_uid,
                amount: deltaValue,
                crypto_type: currency,
                reason: editReason
            }

            // after freeze amount changed, the available balance need to be changed

            if (addValue === true){
                /* UserApi.freeze(freeze_data)
                .then((res)=> {
                    if (res.status === 200){
                       setEditSuccess(true);
                    }
                })
                .catch((e) => {
                    console.log(e)
                }) */

                UserApi.freeze(data)
                .then((res)=> {
                    //console.log(res)
                    if (res.status === 200){
                        setEditSuccess(true);
                    }
                })
                .catch((e) => {
                    console.log(e)
                })
            }else{
                /* UserApi.unfreeze(freeze_data)
                .then((res)=> {
                    //console.log(res)
                    if (res.status === 200){
                        setEditSuccess(true);
                    }
                })
                .catch((e) => {
                    console.log(e)
                }) */

                UserApi.unfreeze(data)
                .then((res)=> {
                    if (res.status === 200){
                       setEditSuccess(true);
                    }
                })
                .catch((e) => {
                    console.log(e)
                })
            }
        }

        if (editSuccess){
            return <EditSuccessfulMsg />
        }else return (
            <div className="popup-content">
                <h4>{"Edit confimation"}</h4>
                <InputWrapper attr={"User ID"} value={walletData.User.AccountID} size={"xl"}/>
                <InputWrapper attr={"User Name"} value={walletData.User.UserName} size={"xl"}/>
                <InputWrapper attr={"Crypto Type"} value={currency} size={"xl"}/>
                <InputWrapper attr={"Original Frozen Balance"} value={frozenValue} size={"xl"}/>
                <InputWrapper attr={"Adjusted Frozen Balance"} value={newFrozenValue} size={"xl"}/>
                <InputWrapper attr={"Original Available Balance"} value={oldValue} size={"xl"}/>
                <InputWrapper attr={"Adjusted Available Balance"} value={newValue} size={"xll"}/>
                <InputWrapper attr={addValue ? "Add Value" : "Deduct Value"} value={deltaValue} size={"xl"}/>
                <InputWrapper attr={"Edit Reason"} value={editReason} size={"xl"}/>
                <div className="popup-form-row">
                    <SuiButton onClick={()=>setConfirmPage(false)}>Back</SuiButton>
                    <SuiButton buttonColor={"secondary"} onClick={handleProceed}>Confirm</SuiButton>
                </div>
            </div>

        )
    }


    return(
        <div className="popup-content">
             {
                     !authSuccess ? 
                     <AuthCodeHelper title="Admin Authentication" btnName="Confirm" permission={WALLET_PERMISSION.BALANCE_EDIT} callback={handleAuthCode}/>
                     :
                     (
                         confirmPage?
                         <ConfirmInfo />
                         : 
                         <>
                            <h4>{"Adjust "+currency+" Frozen Value"}</h4>
                            <div className='popup-form-row'>
                                <IconButton color={ addValue ? "secondary" :"default"} 
                                            onClick={handleAddValue}>
                                    <Icon>add</Icon>
                                </IconButton>
                                <IconButton color={addValue ? "default" :"secondary"} 
                                            onClick={handleDeductValue}>
                                    <Icon>remove</Icon>
                                </IconButton>
                                <TextField 
                                value= {showDeltaValue} 
                                onChange={handleDeltaValue} 
                                error={numError}
                                />
                                <div className="popup-subtitle">{currency}</div>
                            </div>

                            <div>
                                <div className="popup-form-row">
                                    <div className="popup-form-column">
                                        <div className="popup-subtitle bold">Current Frozen Balance</div>
                                            <div className="popup-form-row">
                                                <InputWrapper attr="" value={frozenValue} />
                                                <div className="popup-subtitle">{currency}</div>
                                            </div>
                                            <div className="popup-subtitle bold">Current Available Balance</div>
                                            <div className="popup-form-row">
                                                <InputWrapper attr="" value={oldValue} />
                                                <div className="popup-subtitle">{currency}</div>
                                        </div>

                                    </div>
                                    <DoubleArrowIcon />
                                    <DoubleArrowIcon />
                                    <div className="popup-form-colum">
                                        <div className="popup-subtitle bold">Adjusted Frozen Balance</div>
                                            <div className="popup-form-row">
                                                <InputWrapper attr="" value={newFrozenValue} />
                                                <div className="popup-subtitle">{currency}</div>
                                            </div>
                                            <div className="popup-subtitle bold">Adjusted Available Balance</div>
                                            <div className="popup-form-row">
                                                <InputWrapper attr="" value={newValue} />
                                                <div className="popup-subtitle">{currency}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>

                                {
                                    negNewValue || negNewFrozenValue ?
                                        <InputLabel error sx={{fontSize: 12}}>Adjusted Balance must be positive!</InputLabel>
                                    :<></>
                                }

                            </div>
                            <InputWrapper attr="Edit Reason(Optional)" value={editReason} onChange={(e) => {setEditReason(e.target.value)}} disable={false} size="col"/>
                            <div className="flex-end">
                                <SuiButton buttonColor={"secondary"} onClick={handleProceed}>Proceed</SuiButton>
                            </div>
                        </>
                     )
            }
        </div>
    )

}


function AdjustCurrencyBalance({ currency, oldValue, walletData }){

    if (currency && oldValue && walletData ){
        
        const alert = useAlert();
        let testMode = false;   

        const [addValue, setAddValue] = useState(false);
        const [newValue, setNewValue] = useState(oldValue);
        const [numError, setNumError] = useState(false);
        const [NegNewValue, setNegNewValue] = useState(false);
        const unit = currency === "ETH" ? 18 : 8
        const [confirmPage, setConfirmPage] = useState(false);
        const [editReason, setEditReason] = useState("");
        const [showDeltaValue, setShowDeltaValue] = useState();
        const [deltaValue, setDeltaValue] = useState(0);

        const [auth, setAuth] = useState(false);
        const [authSuccess, setAuthSuccess] = useState(false);

        //page components
        function ConfirmInfo(){

            const [editSuccess, setEditSuccess] = useState(false);
    
            const handleProceed = () => {
    
                const data = {
                    id: walletData.User.firebase_uid,
                    amount: deltaValue,
                    crypto_type: currency,
                    reason: editReason
                }

                //console.log(data);
    
                if (addValue === true){
                    UserApi.deposit(data)
                    .then((res)=> {
                        if (res.status === 200){
                           setEditSuccess(true);
                        }
                    })
                    .catch((e) => {
                        console.log(e)
                    })

                }else{

                    UserApi.withdraw(data)
                    .then((res)=> {
                        //console.log(res)
                        if (res.status === 200){
                            setEditSuccess(true);
                        }
                    })
                    .catch((e) => {
                        console.log(e)
                    })
                }
            }
    
            if (editSuccess){
                return <EditSuccessfulMsg />
            } else return (
                  <div className="popup-content">
                    <h4>{"Edit confimation"}</h4>
                        <InputWrapper attr={"User ID"} value={walletData.User.account_id} size={"lg"}/>
                        <InputWrapper attr={"User Name"} value={walletData.User.username} size={"lg"}/>
                        <InputWrapper attr={"Crypto Type"} value={currency} size={"lg"}/>
                        <InputWrapper attr={"Original Balance"} value={oldValue} size={"lg"}/>
                        <InputWrapper attr={"Adjusted Balance"} value={newValue} size={"lg"}/>
                        <InputWrapper attr={addValue ? "Add Value" : "Deduct Value"} value={deltaValue} size={"lg"}/>
                        <InputWrapper attr={"Edit Reason"} value={editReason} size={"lg"}/>
                        <div className="popup-form-row">
                            <SuiButton onClick={()=>setConfirmPage(false)}>Back</SuiButton>
                            <SuiButton buttonColor={"secondary"} onClick={handleProceed}>Confirm</SuiButton>
                        </div>
                </div>
            )
        }

            const handleAuthCode = (resp) => {
                // console.log(resp);
        
                if(resp){
                    if(resp.isAuth){
                        setAuth(false);
                        setAuthSuccess(true);
                    } else{
                        alert.error(resp.message);
                    }
                }
                else{
                    alert.error(ERROR.callback);
                }
            }
            

        const handleDeltaValue = (e) => {
            let delta = e.target.value;
            setShowDeltaValue(e.target.value);
    
            try{
                let del = NumberFormatUtils(delta, unit);
    
                //if user input is not numerical, set as 0
                if (isNaN(del)){
                    del = 0;
                    setNumError(true);
                }else setNumError(false);
    
                setDeltaValue(del);
    
                if (addValue){
                    setNewValue(NumberFormatUtils((parseFloat(oldValue)+parseFloat(del)), unit))
                } 
                else setNewValue(NumberFormatUtils((parseFloat(oldValue)-parseFloat(del)), unit));
    
            }catch(e){
               // setDeltaValue(0);
                setNewValue(oldValue);
                setNumError(true);
                newValueCalculator();
            }
        }


        function handleAddValue(){
            setAddValue(true);
            setNewValue(NumberFormatUtils((parseFloat(oldValue)+parseFloat(deltaValue)), unit))
        }
    
        function handleDeductValue(){
            setAddValue(false);
            setNewValue(NumberFormatUtils((parseFloat(oldValue)-parseFloat(deltaValue)), unit));
        }
    
        //after changing +/-
        const newValueCalculator = () => {
            if (addValue){
                setNewValue(NumberFormatUtils((parseFloat(oldValue)+parseFloat(deltaValue)), unit))
            } else setNewValue(NumberFormatUtils((parseFloat(oldValue)-parseFloat(deltaValue)), unit));
        } 
    
        const handleProceed = () => {
            if (newValue < 0){
                setNegNewValue(true);
            }else{
                setNegNewValue(false);
                setConfirmPage(true)
            }
            
        }

        return(
             <div className='popup-wrapper'>
                 {
                     !authSuccess ? 
                     <AuthCodeHelper title="Admin Authentication" btnName="Confirm" permission={WALLET_PERMISSION.BALANCE_EDIT} callback={handleAuthCode}/>
                     :
                     (
                         confirmPage?
                         <ConfirmInfo />
                         : 
                         <div className="popup-content">
                            <h4>{"Adjust "+currency+" Available Value"}</h4>
                            <div className='popup-form-row'>
                                <IconButton color={ addValue ? "secondary" :"default"} 
                                            onClick={handleAddValue}>
                                    <Icon>add</Icon>
                                </IconButton>
                                <IconButton color={addValue ? "default" :"secondary"} 
                                            onClick={handleDeductValue}>
                                    <Icon>remove</Icon>
                                </IconButton>
                                <TextField 
                                value={showDeltaValue} 
                                onChange={handleDeltaValue} 
                                error={numError}
                                //defaultValue={0}
                                />
                                <div className="popup-subtitle">{currency}</div>
                            </div>

                            <div>
                                <div className="popup-subtitle bold">Current Available Balance</div>
                                <div className="popup-form-row">
                                    <InputWrapper attr="" value={oldValue} />
                                    <div className="popup-subtitle">{currency}</div>
                                </div>
                                
                            </div>
                            <div>
                                <div className="popup-subtitle bold">Adjusted Available Balance</div>
                                <div className="popup-form-row">
                                    <InputWrapper attr="" value={newValue} />
                                    <div className="popup-subtitle">{currency}</div>
                                </div>
                                {
                                    NegNewValue?
                                    <InputLabel error sx={{fontSize: 12}}>Adjusted Balance must be positive!</InputLabel>
                                    :<></>
                                }

                            </div>
                            <InputWrapper attr="Edit Reason(Optional)" value={editReason} onChange={(e) => {setEditReason(e.target.value)}} disable={false} size="col"/>
                            <div className="flex-end">
                                <SuiButton buttonColor={"secondary"} onClick={handleProceed}>Proceed</SuiButton>
                            </div>
                    </div>
                         
                        
                       
                     )
                     
                 }
               
            </div> 
        );

    }   else return (
        <div>
            Internal Error
            Something missing for the component
        </div>
    )

    
}
