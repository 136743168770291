export function ImageURLExistHelper(src) {
    const fakeImg = "https://upload.wikimedia.org/wikipedia/commons/thumb/7/70/Checkerboard_pattern.svg/1200px-Checkerboard_pattern.svg.png";

    if (!src || src.length < 1) {
        return fakeImg;
    } else {
        var http = new XMLHttpRequest();
        http.open('HEAD', src, false);
        http.send();
        //console.log(http.status);

        switch (http.status) {
            case 400:
                //console.log('Image exists.');
                return src;
            case 404:
                //console.log('Image does not exist.');
                return fakeImg;
            default:
                //console.log('Error:', http.status);
                return fakeImg;
        }
    }
}