import AddIcon from "@mui/icons-material/Add";
import { CircularProgress, Grid } from "@mui/material";
import GeneralApi from "api/general";
import { BasicDateTimePicker, InputWrapper, Loader } from "components/Custom";
import { PageController } from "components/Custom/custom_container";
import { SuiButton } from "components/Default";
import { GENERAL_PERMISSION } from 'config/permission';
import { useEffect, useState } from 'react';
import { useAlert } from "react-alert";
import ActionButtons from "util/ActionsButton";
import AuthCodeHelper from "util/AuthCodeHelper";

export default function FixedDepositTab() {
    const alert = useAlert();

    const [page, setPage] = useState(1);
    const [record, setRecord] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalPage, setTotalPage] = useState(1);
    const [rowPerPage, setRowPerPage] = useState(5);
    const [inputs, setInputs] = useState([]);
    const [count, setCount] = useState(1);
    const [fixedDeposit, setFixedDeposit] = useState([]);
    const [disable, setDisable] = useState(false);
    const [savingPlanData, setSavingPlanData] = useState([]);
    const [auth, setAuth] = useState(false);

    const currency = {
        "BTC": [],
        "ETH": [],
        "USDC": [],
        "USDT": []
    }

    const handleChange = e => setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));

    const handlePageChange = (event, value) => {
        if (value <= totalPage) {
            setPage(value);
        }
    };

    const handleRowPerPage = (v) => {
        setRowPerPage(v);
        let t = record.length / rowPerPage;
        if (record.length % rowPerPage > 0) t += 1;
        setTotalPage(t);
    };

    const actionEdit = e => {
        let newFixedDeposit = fixedDeposit;
        newFixedDeposit.push({
            crypto_type: "",
            min_amount: 0,
            max_amount: 0,
            days: 0,
            annual_percentage_rate: 0.00,
            start_at: "",
            end_at: ""
        });
        setCount(count + 1);
        setFixedDeposit(newFixedDeposit);
    }

    const handleDateChange = (name, newValue) => {
        setInputs(prevState => ({ ...prevState, [name]: newValue }));
    }

    const fetchSavingPlanData = async () => {
        await GeneralApi.list()
            .then((res) => {
                if (res.status === 200) {
                    setSavingPlanData(res.data.data.saving_plans);
                }
            })
            .catch((err) => {
                console.log(err);
                alert.error(err.message);
            });
    };

    const fetchFixedSavingPlanOffers = async () => {
        await GeneralApi.getfixedSavingPlanOffers()
            .then((res) => {
                if (res.status === 200) {
                    setFixedDeposit(res.data.data);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                alert.error(err.message);
            });
    };

    const editItem = (key) => {
        setDisable(prevState => ({ ...prevState, [key]: disable[key] === false ? true : false }));
    }

    const updateItem = async (key, index) => {
        let requestData;
        const isUpdateData = fixedDeposit.filter((data, index) => data.id === key);
        console.log(isUpdateData);
        if (isUpdateData.length === 1 && key) {
            requestData = isUpdateData[0];
        } else {
            console.log(index);
            requestData = {
                crypto_type: inputs[`crypto_type_${index}`],
                min_amount: inputs[`lowerLimit_${index}`],
                max_amount: inputs[`upperLimit_${index}`],
                days: inputs[`days_${index}`],
                annual_percentage_rate: inputs[`APR_${index}`],
                start_at: inputs[`startAt_${index}`],
                end_at: inputs[`endAt_${index}`]
            }
        }
        await GeneralApi.updatefixedSavingPlanOffers(requestData)
            .then((res) => {
                if (res && res.status === 200) {
                    alert.success(`Create or update fixed saving plan offer success`);
                }
            })
            .catch((err) => {
                alert.error(err.message);
            });
    }

    const removeItem = async (key, index) => {
        if (key) {
            await GeneralApi.removefixedSavingPlanOffers(key)
                .then((res) => {
                    if (res && res.status === 200) {
                        alert.success(`Remove fixed saving plan ${key} success`);
                    }
                })
                .catch((err) => {
                    alert.error(err.message);
                });
        } else {
            let newFixedDeposit = [];
            fixedDeposit.map((d, i) => {
                if (i !== index) {
                    newFixedDeposit.push(d);
                }
            })
            setFixedDeposit(newFixedDeposit);
        }
    }

    async function updateSavingPlan(_refBonus) {
        if (auth) {
            await GeneralApi.editSavingPlan({
                fixed_referral_bonus_percentage: _refBonus,
                referral_bonus_percentage: savingPlanData.saving_plan_referral_bonus_percentage,
                saving_plan_btc: savingPlanData.saving_plan_btc,
                saving_plan_eth: savingPlanData.saving_plan_eth,
                saving_plan_usdc: savingPlanData.saving_plan_usdc,
                saving_plan_usdt: savingPlanData.saving_plan_usdt
            })
                .then((res) => {
                    if (res.status) {
                        alert.success("Edit data Successful");
                    }
                })
                .catch((err) => {
                    alert.error(err.message);
                });
        } else {
            setPage(0);
        }
    }

    const handleAuthCode = (resp) => {
        if (resp) {
            if (resp.isAuth) {
                setAuth(true);
                setPage(1);
            } else {
                alert.error(resp.message);
            }
        }
        else {
            alert.error(ERROR.callback);
        }
    }

    useEffect(() => {
        if (fixedDeposit.length < 1) {
            fetchFixedSavingPlanOffers();
            fetchSavingPlanData();
        }
    }, [])

    return (
        <div className="tab-wrapper">
            {
                loading ?
                    <CircularProgress />
                    : <>
                        <PageController targetPage={0} currentPage={page} CASE="">
                            <AuthCodeHelper title="Admin Authentication" btnName="Confirm" permission={GENERAL_PERMISSION.EDIT} callback={handleAuthCode} />
                        </PageController>
                        <PageController targetPage={1} currentPage={page} CASE="">
                            <InputWrapper attr="Referral Bonus Percentage" name="refBonusPercent" value={inputs["refBonusPercent"] ?? savingPlanData.fixed_saving_plan_referral_bonus_percentage} size="col" onChange={handleChange} disable={false} />
                            <Grid container spacing={2}>
                                {
                                    fixedDeposit.length >= 1 ?
                                        fixedDeposit.map((data, index) => {
                                            return (
                                                <Grid container item xs={12} sm={12} md={12} xl={12} key={index}>
                                                    <Grid container item xs={11} sm={11} md={11} xl={11} spacing={1}>
                                                        <Grid item xs={12} sm={12} md={12} xl={12}>
                                                            {
                                                                disable[data.id] === false ?
                                                                    <InputWrapper attr="Crypto Type" name={`crypto_type_${index}`} value={inputs[`crypto_type_${index}`] ?? data.crypto_type} size="m" onChange={handleChange} disable={disable[data.id] ?? true} />
                                                                    : <h4>{data.crypto_type}</h4>
                                                            }
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={6} xl={6}>
                                                            {
                                                                disable[data.id] === false ?
                                                                    <>
                                                                        <div className="text-sm">Start At</div>
                                                                        <BasicDateTimePicker value={inputs[`startAt_${index}`] ?? data.start_at} onChange={(newValue) => { handleDateChange(`startAt_${index}`, newValue) }} />
                                                                    </>
                                                                    : <InputWrapper attr="Start At" name={`startAt_${index}`} value={inputs[`startAt_${index}`] ?? data.start_at} size="m" onChange={handleChange} disable={disable[data.id] ?? true} />
                                                            }
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={6} xl={6}>
                                                            {
                                                                disable[data.id] === false ?
                                                                    <>
                                                                        <div className="text-sm">End At</div>
                                                                        <BasicDateTimePicker value={inputs[`endAt_${index}`] ?? data.end_at} onChange={(newValue) => { handleDateChange(`endAt_${index}`, newValue) }} />
                                                                    </>
                                                                    : <InputWrapper attr="End At" name={`endAt_${index}`} value={inputs[`endAt_${index}`] ?? data.start_at} size="m" onChange={handleChange} disable={disable[data.id] ?? true} />
                                                            }
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={6} xl={6}>
                                                            <InputWrapper attr="Lower Limit" name={`lowerLimit_${index}`} value={inputs[`lowerLimit_${index}`] ?? data.min_amount} size="m" onChange={handleChange} disable={disable[data.id] ?? true} />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={6} xl={6}>
                                                            <InputWrapper attr="Upper Limit" name={`upperLimit_${index}`} value={inputs[`upperLimit_${index}`] ?? data.max_amount} size="m" onChange={handleChange} disable={disable[data.id] ?? true} />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={6} xl={6}>
                                                            <InputWrapper attr={/* "Option " +(index+1)+ " (days)" */"days"} name={`days_${index}`} value={inputs[`days_${index}`] ?? data.days} size="xl" onChange={handleChange} disable={disable[data.id] ?? true} />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={6} xl={6}>
                                                            <InputWrapper attr={/* "APR " +(index+1) */"APR"} name={`APR_${index}`} value={inputs[`APR_${index}`] ?? data.annual_percentage_rate} size="m" onChange={handleChange} disable={disable[data.id] ?? true} />
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container item xs={1} sm={1} md={1} xl={1}>
                                                        <ActionButtons
                                                            disable={disable[data.id] ?? true}
                                                            updateItem={() => updateItem(data.id, index)}
                                                            editItem={() => editItem(data.id)}
                                                            removeItem={() => removeItem(data.id, index)} />
                                                    </Grid>
                                                </Grid>
                                            );
                                        }) : <Loader />
                                }
                            </Grid>
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item xs={6} sm={6} md={6} xl={6}>
                                    <SuiButton variant="outlined" buttonColor="secondary" onClick={(e) => actionEdit(e.value)} style={{ width: '100%' }}>
                                        <AddIcon />
                                    </SuiButton>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} xl={6}>
                                    <SuiButton buttonColor="secondary" onClick={() => updateSavingPlan(inputs["refBonusPercent"])} style={{ width: '100%' }}>
                                        Update All
                                    </SuiButton>
                                </Grid>
                            </Grid>
                        </PageController>
                    </>
            }

        </div>
    );
}