import { constant } from 'config/constant';

import { Card, CardContent, CircularProgress, Grid } from "@mui/material";
import TransactionApi from 'api/analysis/transaction';
import TransferApi from 'api/analysis/transfer';
import Popup from "assets/theme/components/popup";
import DraggableCardChart from 'components/Custom/card_chart';
import { addDays } from 'date-fns';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import ChartMaker from 'util/ChartMaker';
import { chartType, singleAxisLineOptions } from 'util/ChartMaker/constant';
import { DateFormatUtils } from 'util/index';
import './style.css';

export default function WalletTab() {
    let testMode = constant.DEBUG;
    // Pop Up Page
    const [page, setPage] = useState(0);
    const [openPopUp, setOpenPopup] = useState(false);
    const [popUpName, setPopUpName] = useState("");

    const [state, setState] = useState([
        {
          startDate: new Date(),
          endDate: addDays(new Date(), 7),
          key: 'selection'
        }
    ]);

    const [exchangeCountData, setExchangeCountData] = useState([]);
    const [transferAmountData, setTransferAmountData] = useState([]);
    const [depositWithdrawCount, setDepositWithdrawCount] = useState([]);
    const [depositWithdrawAmount, setDepositWithdrawAmount] = useState([]);
    const [loading, setLoading] = useState(true);

    const crypto_type_value = ["BTC", "ETH", "USDC", "USDT"];
    const transfer_status_type_value = ["transfer", "exchange"];
    const transactions_type_value = ["deposit","withdraw"];

    const [timeUnit, setTimeUnit] = useState([]);
    const handleTimeUnitChange = (title, value) => {
        setTimeUnit(prevState => ({ ...prevState, [title]: value }));
    }

    const handlePopUp = (pName) => {
        setPopUpName(pName);
        setOpenPopup(true);
        setPage(1);
    };

    const fetchExchangeCount = async (startDate, endDate) => {
        await TransferApi.exchangeCount({
            "date1": startDate, 
            "date2": endDate
        })
          .then((res) => {
            setExchangeCountData(res.data.data);
          })
          .catch((err) => {
            //console.log(err);
          });
    };

    const fetchTransferAmount = async (startDate, endDate) => {
        await TransferApi.transferAmount({
            "date1": startDate, 
            "date2": endDate
        })
          .then((res) => {
            setTransferAmountData(res.data.data);
          })
          .catch((err) => {
            //console.log(err);
          });
    };

    const fetchDepositWithdrawCount = async (startDate, endDate) => {
        await TransactionApi.typeCount({
            "date1": startDate, 
            "date2": endDate
        })
            .then((res) => {
                setDepositWithdrawCount(res.data.data);
            })
            .catch((err) => {
                //console.log(err);
            });
    };

    const fetchDepositWithdrawAmount = async (startDate, endDate) => {
        await TransactionApi.amountByType({
            "date1": startDate, 
            "date2": endDate
        })
            .then((res) => {
                setDepositWithdrawAmount(res.data.data);
            })
            .catch((err) => {
                //console.log(err);
            });
    };

    const fetchAllData = (startDate, endDate) => {
        if(startDate && endDate){
            fetchExchangeCount(startDate, endDate);
            fetchTransferAmount(startDate, endDate);
            fetchDepositWithdrawCount(startDate, endDate);
            fetchDepositWithdrawAmount(startDate, endDate);
            setLoading(false);
        } else {
            fetchExchangeCount();
            fetchTransferAmount();
            fetchDepositWithdrawCount();
            fetchDepositWithdrawAmount();
            setLoading(false);
        }
    }

    const handleDatePickerSelected = (date) => {
        //console.log(date);
        setState([date])

        let startDate = DateFormatUtils(date.startDate);
        let endDate = DateFormatUtils(date.endDate);

        //console.log(startDate);
        //console.log(endDate);

        setLoading(true);
        fetchAllData(startDate,endDate)
    }

    useEffect(() => {
        if(exchangeCountData.length < 1 && transferAmountData.length < 1 && depositWithdrawCount.length < 1 && depositWithdrawAmount.length < 1){
            fetchAllData();
        }
    }, []);

    return (
        <>
            {/* "Pop Up" */}
            { 
                page > 0 ?
                <Popup
                    openPopup={openPopUp}
                    setOpenPopup={() => { setOpenPopup(false) }}>

                    <DateRangePicker
                        onChange={item => handleDatePickerSelected(item.selection)}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        ranges={state}
                        direction="horizontal"
                        />
                </Popup>
                : <></>
            }

            <div className="tab-wrapper">
                {
                    loading ?
                        <CircularProgress />
                        : <>
                            <Grid container>
                                <Grid item xs={9} sm={9} md={9} xl={9}></Grid>
                                <Grid item xs={3} sm={3} md={3} xl={3}>
                                    <div onClick={() => handlePopUp("DateRange")}>
                                        <Card>
                                            <CardContent className='text-sm date_style text-center'>
                                                { moment(state[0].startDate).format("MMM Do, YYYY") + " - " + moment(state[0].endDate).format("MMM Do, YYYY") }
                                            </CardContent>
                                        </Card>
                                    </div>
                                </Grid>
                            </Grid>
                            
                            <Grid container spacing={2}>
                                <DraggableCardChart title="Daily Exchange Count" setTimeUnit={handleTimeUnitChange} tipsTitle="The most popular crypto type" tipsContent="Count the number of exchange record by transfers.created_at and group by wallets.slug(crypto type e.g. BTC, ETH, USDC, USDT)">
                                    <ChartMaker yLabel="crypto_type" type={chartType.Line} xKeyName="Date" yKeyName="Coin_Type" valueKeyName="Count" typeValues={crypto_type_value} data={exchangeCountData} options={singleAxisLineOptions({ unit: timeUnit["Exchange Count"] })}/>
                                </DraggableCardChart>

                                <DraggableCardChart title="Daily Transfer Amount" setTimeUnit={handleTimeUnitChange} tipsTitle="The number of daily transfer and exchange record" tipsContent="Count the number of transfer record by transfer.status (transfer or exchange)">
                                    <ChartMaker yLabel="crypto_type" type={chartType.Line} xKeyName="created_at_date" yKeyName="status" valueKeyName="status_count" typeValues={transfer_status_type_value} data={transferAmountData} options={singleAxisLineOptions({ unit: timeUnit["Transfer Amount"] })}/>
                                </DraggableCardChart>

                                <DraggableCardChart title="Daily Transaction Count" setTimeUnit={handleTimeUnitChange} tipsTitle="The number of daily transaction" tipsContent="Count the number of transactions record by transactions.type (deposit or withdraw)">
                                    <ChartMaker yLabel="crypto_type" type={chartType.Line} xKeyName="created_at_date" yKeyName="type" valueKeyName="status_count" typeValues={transactions_type_value} data={depositWithdrawCount} options={singleAxisLineOptions({ unit: timeUnit["Deposit/Withdraw Count"] })}/>
                                </DraggableCardChart>

                                <DraggableCardChart title="Daily Transaction Amount" setTimeUnit={handleTimeUnitChange} tipsTitle="The amount of daily transaction" tipsContent="Count the sum of transaction.amount by date (transaction.created_at) and crypto type (wallets.slug)">
                                    <ChartMaker yLabel="crypto_type" type={chartType.Line} xKeyName="Date" yKeyName="Coin_Type" valueKeyName="Amount" typeValues={crypto_type_value} data={depositWithdrawAmount} options={singleAxisLineOptions({ unit: timeUnit["Deposit/Withdraw Amount"] })}/>
                                </DraggableCardChart>
                            </Grid>
                        </>
                }
            </div>
        </>
    );
}