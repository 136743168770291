import { Chip } from "@mui/material";

const StatusPanel = ({ allStatus, currentFilter, handleChangeRows }) => {

  return allStatus.map((status, key) => {
    return (
      <Chip
        label={
          status.label +
          " (" +
          status.count +
          ")"
        }
        key={key}
        className={
          currentFilter !== status.value
            ? "muiChip " + status.class
            : "muiChip " + status.class + "-selected"
        }
        style={{ width: "10rem", marginRight: "1rem" }}
        onClick={() =>
          handleChangeRows(status?.param?.name, status?.param?.value)
        }
      />
    );
  });
};

export default StatusPanel;
