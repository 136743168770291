import WalletApi from "api/wallet";
import initalValue from "entities/base/initalValue";
import FirebaseGateway from "./firebaseGateway";

const Scheme = {
    id: initalValue.Int,
    account_id: initalValue.String,
    userId: initalValue.String,
    redeemedAt: initalValue.Datetime,
    quota: initalValue.Int,
    count: initalValue.Int
}

export default class WalletWelcomeGiftGateway {
    constructor() { }

    getScheme() {
        return Scheme;
    };

    async getAllData({ page, searchValue }) {
        let firebaseGateway = new FirebaseGateway();
        let latestToken = await firebaseGateway.getToken();

        return await WalletApi.getWelcomegiftsByPage({
            page: page,
            id_token: latestToken
        })
            .then(response => {
                if (response.status === 200) {
                    return {
                        data: this.formatWalletGiftsData(response.data.data.users.data),
                        last_page: response.data.data.last_page,
                        per_page: response.data.data.per_page,
                        total: response.data.data.total,
                        quota: response.data.data.quota.quota || Scheme.quota,
                        count: response.data.data.quota.count || Scheme.count
                    };
                }
            })
    }

    formatWalletGiftData(apiData, index) {
        const {
            id, account_id, firebase_user_id, redeemed_welcome_gift_at
        } = apiData;

        return {
            id: firebase_user_id || Scheme.id,
            account_id: account_id || Scheme.account_id,
            userId: id || Scheme.userId,
            createAt: redeemed_welcome_gift_at || Scheme.redeemedAt
        };
    }

    formatWalletGiftsData(apiData) {
        return apiData.map((data, index) => {
            return this.formatWalletGiftData(data, index);
        });
    }
}