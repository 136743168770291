import '../styles.css';

import { useState, useEffect } from "react";

import SuiButton from "components/Default/SuiButton";
import SuiBox from "components/Default/SuiBox";

import InputWrapper from 'components/Custom/input_wrapper';
import AddCircleIcon from '@mui/icons-material/AddCircle';


import { GENERAL_PERMISSION } from 'config/permission';
import { useAlert } from 'react-alert';

// Call API
import GeneralApi from "api/general";
import AuthApi from 'api/auth';
import data from 'layouts/dashboard/components/Projects/data';
import AuthCodeHelper from "util/AuthCodeHelper";

export default function CrisisMgtPopup ({ type }) {
    //console.log(type);
    
    const [page, setPage] = useState(type ?? 0);
    const [editedValue, setEditValue] = useState([]);

    const handleBack = (...args) => {
        //console.log(args);
        if(editedValue){
            setEditValue(editedValue => ({ ...editedValue, [args[0]]: args[1] }));
        } else{
            setEditValue({ [args[0]]: args[1] });
        }
        //console.log(editedValue);
        //console.log(page);
        setPage(0);
    }; 

    const handleEdit = () => {
        //console.log(page);
        setPage(1);
    }; 

    const handleAuth = () => {
        setPage(2);
    };

    const handleRemoveItem = (...args) => {
        //console.log(args[0]);
        if(editedValue.length < 2){
            setEditValue([]);
        }else{
            //console.log(editedValue);
            let new_arr = [...editedValue];
            new_arr.splice(args[0], 1);
            //console.log(new_arr);
            setEditValue(new_arr);
        }
    };

    if (page === 0){
        return <CrisisMgt
            actionEdit={handleEdit} editParmas={editedValue} removeItem={handleRemoveItem} actionAuth={handleAuth}/>
    } else if(page === 1){
        return <AddCrisisMgt
            actionBack={handleBack}/>;
    }
}

async function EditCrisisMgt(inputs) {

    await GeneralApi.editServiceFee(inputs)
        .then((res) => {
            if(res && res.status === 200){
                console.log("update success");
            }
        })
        .catch((err) => {
            console.log(err);
        })
}

function AddCrisisMgt ({ actionBack }) {

    const [serviceFeeName, setServiceFeeName] = useState("");
    const [serviceFeeVal, setServiceFeeValue] = useState("");

    return (
        <div id="popup1" className="popup-wrapper">
            <div id="" className="popup1-flex-column">
                <h4>Access Code Management</h4>
                <div className="popup-content">
                    <SuiBox xs={2} lg={3} xl={6} width="100%">
                        <InputWrapper attr="Position Name" value={serviceFeeName} 
                            onChange={(event) => {setServiceFeeName(event.target.value)}} disable={false} size="lg"/> 
                    </SuiBox>
                    <SuiBox xs={2} lg={3} xl={6} width="100%"> 
                        <InputWrapper attr="Code" value={serviceFeeVal} 
                            onChange={(event) => {setServiceFeeValue(event.target.value)}} disable={false} size="lg"/> 
                    </SuiBox>
                </div>

                <div className="popup-form-row">
                    <SuiButton buttonColor="secondary" onClick={() => actionBack(serviceFeeName, serviceFeeVal)}>Add</SuiButton>
                </div>
                
            </div>
        </div>
    );
}

function CrisisMgt ({ actionEdit, editParmas, removeItem, actionAuth }) {
    const alert = useAlert();

    const [authCodeData, setAuthCodeData] = useState([]); 
    const [auth, setAuth] = useState(false);
    const [inputs, setInputs] = useState({});    // control all inputs
    const [disable, setDisable] = useState({});
    const [edit, setEdit] = useState(false);

    const handleChange = e => setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));

    const editItem = (key) => {
        setEdit(true);
        setDisable(prevState => ({ ...prevState, [key]: disable[key] ? false : true }));
    }
    /* const removeItem = (key) => {
        setInputs(prevState => ({ ...prevState, [key]: null }));
    } */

    const updateItem = async (key, newValue, oldValue) => {
        //console.log(newValue);
        if(newValue !== oldValue){
            await GeneralApi.editServiceFee({ "Key": key, "Value": newValue })
                .then((res) => {
                    if(res && res.status ===200){
                        alert.success("Edit Data Success");
                    }
                })
                .catch((err) => {
                    alert.error(err.message);
                })
        } else{
            alert.error("Data Not changed");
        }
    }

    const addItem = async (key, newValue) => {
        if(!isNaN(newValue)){
            await GeneralApi.addServiceFee({ "Key": key, "Value": newValue })
                .then((res) => {
                    if(res && res.status===200){
                        alert.success("Add Data Success");
                    }
                })
                .catch((err) => {
                    alert.error(err.message);
                })
        } else{
            alert.error("Data is not a number");
        }
    }

   const changeToStyleText = (role) => {
        const role_arr = role.split("_");
        return role_arr[0].charAt(0).toUpperCase() + role_arr[0].slice(1) + " " + role_arr[1].charAt(0).toUpperCase() + role_arr[1].slice(1);
   }

   const handleAuthCode = (resp) => {
    // console.log(resp);

    if(resp){
        if(resp.isAuth){
            setAuth(true);
        } else{
            alert.error(resp.message);
        }
    }
    else{
        alert.error(ERROR.callback);
    }
}
    
    const handleUpdateAdminCode = async () => {
        //console.log(inputs);
        let new_data = authCodeData.map(data => ({[data.role]: inputs[data.role] || data.code}))
        new_data = Object.assign({}, ...new_data)

        await AuthApi.updateAuthCode({
			"senior_administrator": new_data.senior_administrator,
            "account_manager": new_data.account_manager,
            "wallet_manager": new_data.wallet_manager,
            "ad_manager": new_data.ad_manager,
            "hr_manager": new_data.hr_manager
        })
            .then((res) => {
                if(res.status === 200){
                    //console.log(res.data.data);
                    alert.success("Update Admin Code Success");
                }
            })
            .catch((err) => {
                console.log(err);
                alert.error(err.message);
            })
    }

    const fetchAuthCodeData = async () => {
        await AuthApi.getAuthCode()
                .then((res) => {
                    if(res.status === 200){
                        //console.log(res.data.data);
                        setAuthCodeData(res.data.data);
                    }
                })
                .catch((err) => {
                    console.log(err);
                })}

    useEffect(()=>{
        if(editParmas.length !== 0 && authCodeData.length >= 1){
            setAuthCodeData(prevState => ({ ...prevState, editParmas}));
        } else {
            fetchAuthCodeData();
        }
    }, [])

    return (
        <>
            {
                (!auth) ?
                    <AuthCodeHelper title="Admin Authentication" btnName="Confirm" permission={GENERAL_PERMISSION.EDIT} callback={handleAuthCode}/>
                :   
                <div id="popup1" className="popup-wrapper">
                    <div id="" className="popup1-flex-column">
                        <h4>Access Code Management</h4>
                        <div className="popup-content">

                            <div className="popup-content">

                                <SuiBox xs={2} lg={3} xl={6}>
                                    {
                                        authCodeData ? authCodeData.map(data => {
                                            return <InputWrapper attr={changeToStyleText(data.role)} name={data.role} value={inputs[data.role] ?? data.code} size="xl" style={{ margin: '0.5rem'}} onChange={handleChange} disable={false}/> 
                                        }) : <></>
                                    }

                                    {
                                        editParmas ? Object.entries(editParmas).map(([key, value]) => {
                                            return <InputWrapper attr={key} name={key} value={inputs[key] ?? value} size="xl" style={{ margin: '0.5rem'}} disable={false} onChange={handleChange}/> 
                                        }) : <></>
                                    } 
                                </SuiBox>

                            </div>

                        </div>

                        {/* <SuiButton onClick={actionEdit}>
                            <AddCircleIcon></AddCircleIcon>
                        </SuiButton> */}

                        <div className="popup-form-row" style={{ flexDirection: 'row-reverse' }}>
                            <SuiButton buttonColor="secondary" onClick={handleUpdateAdminCode}>Update</SuiButton>
                        </div>
                        
                    </div>
                </div>
            }
        </>
    );
}