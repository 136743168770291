import { Loader } from "components/Custom";
import { FieldsNotFoundError, PageController } from "components/Custom/custom_container";
import { USER_PERMISSION } from 'config/permission';
import Options from "./options";
import { InputWrapper } from "components/Custom";
import GeneralApi from "api/general";
import { useState } from "react";
import { useAlert } from "react-alert";
import { SuiButton } from "components/Default";
import AuthCodeHelper from "util/AuthCodeHelper";
import EmailAuthHelper from "util/EmailAuthHelper";

export default function CancelPopup({ id, offerId }) {
    const alert = useAlert();
    const fields = [id, offerId];
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(true);
    const [inputs, setInputs] = useState({});
    const [auth, setAuth] = useState(false);

    const handleChange = e => setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));

    const handleEmailAuth = (e) => {
        if (e) {
            setPage(3);
        } else setPage(0)
    }

    const handleSendEmail = () => {
        setPage(4);
    }

    const handleCode = () => {
        setPage(2);
    }

    const handleCancel = async () => {
        await GeneralApi.cancelFixedSavingPlan({
            id: offerId,
            reason: inputs["reason"] ?? ""
        })
            .then((res) => {
                if (res.status === 200) {
                    alert.success("Cancel successfully");
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    const handleAuthCode = (resp) => {
        if (resp) {
            if (resp?.isAuth) {
                setAuth(true);
                setPage(3);
            } else {
                alert.error(resp?.message);
            }
        }
        else {
            alert.error(ERROR?.callback);
        }
    }

    return (
        <FieldsNotFoundError data={fields}>
            <PageController targetPage={0} currentPage={page} CASE="Admin Options List for (1): send email or (2): code authentication">
                <Options actionA={handleSendEmail} actionA_Name="Send Email" actionB={handleCode} actionB_Name="Code Authentication" />
            </PageController>

            <PageController targetPage={2} currentPage={page} CASE="">
                <AuthCodeHelper title="KYC Authentication" btnName="Confirm" permission={USER_PERMISSION.ID_VERIFY} callback={handleAuthCode} />
            </PageController>

            <PageController targetPage={3} currentPage={page} CASE="">
                <div>
                    Cancel FixedSavingPlan
                    <InputWrapper attr="Cancel Reason" name="reason" value={inputs["reason"] ?? ""} onChange={handleChange} size="lg" disable={false}/>
                    <SuiButton buttonColor="secondary" onClick={handleCancel}>
                        Cancel
                    </SuiButton>
                </div>
            </PageController>

            <PageController targetPage={4} currentPage={page} CASE="">
                <EmailAuthHelper action={handleEmailAuth} id={id} />
            </PageController>

        </FieldsNotFoundError>
    );
}