/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// Soft UI Dashboard React components
import { Switch } from "@mui/material";
import FireStorage from "api/firestorage";
import { useAuth } from "auth-context/auth.context";
import SuiBox from "components/Default/SuiBox";
import SuiButton from "components/Default/SuiButton";
import SuiInput from "components/Default/SuiInput";
import SuiTypography from "components/Default/SuiTypography";
import { constant } from "config/constant";
import $ from "jquery";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import AuthApi from "../../../api/auth";
import "./style.css";
import welcome_img from './welcome.png';

const googleAuthURL = "https://api.bithon.com.hk/auth/redirect/";

function SignIn() {
  const testMode = constant.DEBUG;
  const alert = useAlert();

  const history = useHistory();
  const { setUser } = useAuth();
  const { user } = useAuth();

  const [rememberMe, setRememberMe] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [error, setError] = useState(undefined);
  const [buttonText, setButtonText] = useState("Login");
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleCheckGoogleAuthCode = async ({ code }) => {
    const token = await AuthApi.Login({
        email: email,
        password: password,
        //firebase_token: token,
        google_authenticator_code: code
      })
      .then(res => {
        //console.log(res?.data?.data?.token);
        return res?.data?.data?.token;
      })
      .catch(err => {
        return false;
      });

      return token;
  };

  const handleLogin = async event => {
    if (event) {
      event.preventDefault();
    }
    if (user && user.token) {
      return history.push("/dashboard");
    }
    if (email === "") {
      return setError("You must enter your email.");
    }
    if (password === "") {
      return setError("You must enter your password");
    }
    setButtonText("Retry Login");

    const google_auth_inputs =
      $("#google-auth-input-1").val() +
      $("#google-auth-input-2").val() +
      $("#google-auth-input-3").val() +
      $("#google-auth-input-4").val() +
      $("#google-auth-input-5").val() +
      $("#google-auth-input-6").val();

    const csrf_token = await AuthApi.getToken()
      .then(res => {
        if (res) return true;
      })
      .catch(err => {
        return false;
      });

    if(csrf_token){
      const token = await AuthApi.Login({
          email: email,
          password: password,
          //firebase_token: token,
          google_authenticator_code: google_auth_inputs
        })
        .then(res => {
          //console.log(res?.data?.data?.token);
          return res?.data?.data?.token;
        })
        .catch(err => {
          return false;
        });

      if (token) {
        //console.log(token);
        localStorage.setItem("token", token);

        const loginFB = await FireStorage.signInFBWithToken({ token: token })
          .then(res => {
            //console.log(res);
            return res;
          })
          .catch(err => {
            return false;
          });
        if(loginFB){
          await AuthApi.getUserData({
            id_token: loginFB
          })
            .then(res => {
              //console.log(res);
              setProfile(res.data.data);
              setLoginSuccess(true);
              alert.success("Login Success");
            })
            .catch(err => {
              console.log(err);
              alert.error(err.message);
            });
        } else {
          alert.error("login firebase fail");
        }
      } else {
        alert.error("csrf_token not found")
      }
    }
  };

  const setProfile = async response => {
    let user = { ...response };
    user.name = response.name;
    user = JSON.stringify(user);
    setUser(user);
    localStorage.setItem("user", user);
  };

  /* const handleGoogleAdminLogin = () => {
    const user = localStorage.getItem("user");
    if(user){
      localStorage.setItem("user", null);
    }
    window.localStorage.clear();
    return window.open(constant.GOOGLE_AUTH_URL);
  };*/
  
  useEffect(() => {
    $(".inputs").keyup(function() {
      if (this.value.length == this.maxLength) {
        $(this).next(".inputs").focus();
      }
    });
  }, []);

  return (
    <CoverLayout
      title="Login"
      description={`${user ? "" : "Login with admin google account"}`}
      image={welcome_img}
    >
      {user && loginSuccess === true
        ? history.push("/users")
        : /* (
        <div>
          <h3 style={{ textAlign: "center" }}>You are already signed in.</h3>
          <SuiBox mt={4} mb={1}>
            <SuiButton variant="gradient" buttonColor="info" fullWidth onClick={login}>
              {`Let's go`}
            </SuiButton>
          </SuiBox>
        </div>
      ) */ <SuiBox
            component="form"
            role="form"
          >
            <SuiBox mb={2}>
              <SuiBox mb={1} ml={0.5}>
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Email
                </SuiTypography>
              </SuiBox>
              <SuiInput
                defaultValue={email}
                onChange={event => {
                  setEmail(event.target.value);
                  setError(undefined);
                }}
                type="email"
                placeholder="Email"
              />
            </SuiBox>
            <SuiBox mb={2}>
              <SuiBox mb={1} ml={0.5}>
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Password
                </SuiTypography>
              </SuiBox>
              <SuiInput
                defaultValue={password}
                onChange={event => {
                  setPassword(event.target.value);
                  setError(undefined);
                }}
                type="password"
                placeholder="Password"
              />
            </SuiBox>
            <SuiBox mb={2}>
              <SuiBox mb={1} ml={0.5}>
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Google安全驗證密鑰
                </SuiTypography>
              </SuiBox>
              <SuiBox>
                <input
                  id="google-auth-input-1"
                  className="inputs google-auth-inputs"
                  type="text"
                  maxLength="1"
                />
                <input
                  id="google-auth-input-2"
                  className="inputs google-auth-inputs"
                  type="text"
                  maxLength="1"
                />
                <input
                  id="google-auth-input-3"
                  className="inputs google-auth-inputs"
                  type="text"
                  maxLength="1"
                />
                <input
                  id="google-auth-input-4"
                  className="inputs google-auth-inputs"
                  type="text"
                  maxLength="1"
                />
                <input
                  id="google-auth-input-5"
                  className="inputs google-auth-inputs"
                  type="text"
                  maxLength="1"
                />
                <input
                  id="google-auth-input-6"
                  className="inputs google-auth-inputs"
                  type="text"
                  maxLength="1"
                />
              </SuiBox>
            </SuiBox>
            <SuiBox display="flex" alignItems="center">
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <SuiTypography
                variant="button"
                fontWeight="regular"
                onClick={handleSetRememberMe}
                customClass="cursor-pointer user-select-none"
              >
                &nbsp;&nbsp;Remember me
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={2} mb={2} textAlign="center">
              <h6
                style={{
                  fontSize: ".8em",
                  color: "red",
                  textAlign: "center",
                  fontWeight: 400,
                  transition: ".2s all"
                }}
              >
                {error}
              </h6>
            </SuiBox>

            <SuiBox mt={4} mb={1}>
              <SuiButton
                variant="gradient"
                buttonColor="info"
                fullWidth
                onClick={handleLogin}
              >
                {/* <GoogleIcon style={{marginRight: '1rem'}}/> */}
                {buttonText}
              </SuiButton>
            </SuiBox>
          </SuiBox>}
    </CoverLayout>
  );
}

export default SignIn;
