import '../styles.css';
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Editor from 'components/Custom/editor';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import UpdateIcon from '@mui/icons-material/Update';
import { IconButton } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ImageViewer from "components/Custom/image_viewer";
import InputWrapper from 'components/Custom/input_wrapper';
import { CommentWrapper } from 'components/Custom';
import UploadImage from 'components/Custom/upload_image';
import SuiBox from "components/Default/SuiBox";
import SuiButton from "components/Default/SuiButton";
import { useAlert } from 'react-alert';
import AuthCodeHelper from "util/AuthCodeHelper";
import { ImageURLExistHelper } from 'util/index';
import { GENERAL_PERMISSION } from 'config/permission';
import { HTMLContainer } from 'components/Custom';
import GeneralApi from "api/general";
import { Grid } from '@mui/material';

export default function ADManagement() {
    const [page, setPage] = useState(0);
    const [editedValue, setEditValue] = useState([]);

    const handleBack = (...args) => {
        let arr = {
            Position: args[0],
            Fee: args[1],
            ADImage: args[2][0].data_url
        };

        if(editedValue){
            //console.log(editedValue);
            //console.log(...editedValue, arr);
            setEditValue(editedValue => [...editedValue, arr]);
        } else {
            setEditValue(arr);
            //console.log(arr);
        }
        //console.log(page);
        setPage(0);
    }; 

    const handleEdit = () => {
        //console.log(page);
        setPage(1);
    }; 

    const handleRemoveItem = (...args) => {
        //console.log(args[0]);
        if(editedValue.length < 2){
            setEditValue([]);
        }else{
            //console.log(editedValue);
            let new_arr = [...editedValue];
            new_arr.splice(args[0], 1);
            //console.log(new_arr);
            setEditValue(new_arr);
        }
    };

    if (page === 0) {
        return <ADManagementPopup
            actionEdit={handleEdit} editParmas={editedValue} removeItem={handleRemoveItem} />
    } else if (page === 1) {
        return <EditADManagement
            actionBack={handleBack} />;
    }
}

async function updateADManagement(...args) {
    //console.log(args);

    await StaffApi.confirmAdmin(code)
        .then((res) => {
            if (res && res.status === 200) {

            }
        })
        .catch((err) => {
            console.log(err);
        })
}

function ADManagementPopup({ actionEdit, editParmas, removeItem }) {
    const alert = useAlert();

    const [advData, setAdvData] = useState([]);
    const [reminderDesc, setReminderDesc] = useState([]);
    const [err, setError] = useState();
    const [images, setImages] = useState(null);
    const onChange = (imageList, addUpdateIndex) => {
        //console.log(imageList, addUpdateIndex);
        setImages(imageList);
    };

    // control all inputs
    const [inputs, setInputs] = useState({});
    const handleChange = e => setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    const setContent = (name, value) => {
        setInputs(prevState => ({ ...prevState, [name]: value }));
    }

    const [reminderDisable, setReminderDisable] = useState(true);
    const [disable, setDisable] = useState({});
    const [edit, setEdit] = useState(false);
    const editItem = (key) => {
        setEdit(true);
        setDisable(prevState => ({ ...prevState, [key]: (disable[key] === true) ? false : true }));
    }

    const getImageName = (imageURL) => {
        if (imageURL) {
            const firebaseStorageURL = "https://firebasestorage.googleapis.com/v0/b/intepayhk-bd35c.appspot.com/o/images%2Fgeneral%2Fadv%2F";
            const image_split = imageURL.split(firebaseStorageURL);
            //console.log(image_split[1].split("?")[0]);
            return image_split[1].split("?")[0];
        }
        return "";
    };

    const updateImage = async (originalImage, images) => {
        await GeneralApi.saveImageToFB(
            {
                Place: "images/general/adv/",
                Name: originalImage && originalImage !== "" ? getImageName(originalImage) : images[0].file.name,
                File: images[0].file
            }
        ).then((res) => {
            if (res && res.status === 200) {
                alert.success("Upload Image Success");
                return res;
            }
            return null;
        }).catch((err) => {
            alert.error(err.message);
        })
    };

    const updateItem = async (id, position, fee, originalImage, images) => {

        try {
            //const imageFBURL = await updateImage(originalImage, images);
            //console.log(imageFBURL);
            if (true/* imageFBURL */) {
                let response = await GeneralApi.editAdsManagement(
                    {
                        "id": id,
                        "ads_image": images ?? originalImage,
                        // "crypto_type": newValue,
                        "fee": fee,
                        "position": position
                    }
                );
                if (response.status === 200) {
                    alert.success("Edit Data Success");
                }
            }
        } catch (err) {
            alert.error(err.message);
        }
    }

    const addItem = async (position, fee, images) => {
        try {
            const imageFBURL = await updateImage(images);
            //console.log(imageFBURL);
            if(imageFBURL){
                let response = await GeneralApi.addAdsManagement(
                    {
                        "ADImage": images,
                        //"CryptoType": newValue,
                        "Fee": fee,
                        "Position": position
                    }
                );
                let res = response.data;
                if (res && res.status === 200) {
                    alert.success("Edit Data Success");
                }
            } else {
                console.log("No imageFBURL");
            }

        } catch (err) {
            alert.error(err.message);
        }
    }

    useEffect(() => {
        const fetchADData = async () => {

            await GeneralApi.list()
                .then((res) => {
                    if (res.status === 200) {
                        setAdvData(res.data.data.ads_managements);
                        setReminderDesc(res.data.data.advertisement_managements);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        };

        fetchADData();
    }, [])

    const [input, setInput] = useState("");
    const [auth, setAuth] = useState(false);
    const handleInput = (e) => {
        setInput(e.target.value);
    }

    const handleAuthCode = (resp) => {
        if (resp) {
            if (resp.isAuth) {
                setAuth(true);
            } else {
                alert.error(resp.message);
            }
        }
        else {
            alert.error(ERROR.callback);
        }
    }

    const handleUpdateAdsReminder = async () => {
        if (reminderDisable && auth) {
            setReminderDisable(false);
        } else if (!reminderDisable && auth) {
            const zh = "<!DOCTYPE html> <html> <head> " + (inputs["zh_ad_desc"] || reminderDesc.zh_advertisement_application_description) + "</body> </html>";
            const eng = "<!DOCTYPE html> <html> <head> " + (inputs["eng_ad_desc"] || reminderDesc.eng_advertisement_application_description) + "</body> </html>";

            await GeneralApi.editAdsReminderDesc({
                "zh_advertisement_application_description": zh,
                "eng_advertisement_application_description": eng
            })
                .then((res) => {
                    if (res.status === 200) {
                        alert.success("Edit Reminder Success");
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        setEdit(true);
    }

    return (
        <>
            {
                (!auth && edit) ?
                    <AuthCodeHelper title="Admin Authentication" btnName="Confirm" permission={GENERAL_PERMISSION.EDIT} callback={handleAuthCode} />
                    :
                    <div id="popup1" className="popup-wrapper">
                        <div id="" className="popup1-flex-column">
                            <h4>ADs Reminder</h4>
                            {
                                reminderDisable ?
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} sm={6} md={6} xl={6}>
                                            <h5>ZH Description</h5>
                                            <HTMLContainer attr="" name="zh_ad_desc" html={reminderDesc.zh_advertisement_application_description}/>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6} xl={6}>
                                            <h5>English Description</h5>
                                            <HTMLContainer attr="" name="eng_ad_desc" html={reminderDesc.eng_advertisement_application_description}/>
                                        </Grid>
                                    </Grid>
                                    :
                                    <>
                                        <h5>ZH Description</h5>
                                        <Editor content={inputs["zh_ad_desc"] || reminderDesc.zh_advertisement_application_description} onChange={(newContent) => setContent("zh_ad_desc", newContent)} />
                                        <h5>English Description</h5>
                                        <Editor content={inputs["eng_ad_desc"] || reminderDesc.eng_advertisement_application_description} onChange={(newContent) => setContent("eng_ad_desc", newContent)} />
                                    </>
                            }
                            <SuiButton
                                buttonColor="secondary"
                                onClick={() => {
                                    handleUpdateAdsReminder();
                                }}
                            >
                                {reminderDisable ? <EditIcon></EditIcon> : "Save"}
                            </SuiButton>
                            <h4>ADs Management</h4>
                            <div>
                                {
                                    advData ? advData.map((data, index) =>
                                        <Row className="popup-form-row" style={{ marginBottom: 20 }}>
                                            <Col className='text-sm' style={{ verticalAlign: 'text-top', textAlign: 'left' }}>{index + 1}.</Col>
                                            <Col className="popup-content">
                                                <InputWrapper attr="Position" name={"position" + index} value={inputs["position" + index] || data.position} size="sm" disable={!disable[index]} onChange={handleChange} />
                                                <InputWrapper attr="Fee(USDT/Day)" name={"fee" + index} value={inputs["fee" + index] || data.fee} disable={!disable[index]} onChange={handleChange} />
                                            </Col>
                                            <Col>
                                                {
                                                    disable[index] ?
                                                        <UploadImage images={images ?? [{ data_url: ImageURLExistHelper(data.ads_image) }]} onChange={onChange} />
                                                        :
                                                        <ImageViewer headerText="Header Image" clickText="Click to View" type={0} src={ImageURLExistHelper(data.ads_image)} title={true} />
                                                }
                                            </Col>

                                            <ToggleButtonGroup
                                                color="primary"
                                                exclusive
                                                onChange={handleChange}>

                                                {
                                                    disable[index] ?
                                                        <ToggleButton value="update" onClick={() => updateItem(data.id, inputs["position" + index] || data.position, inputs["fee" + index] || data.fee, data.ads_image, images)}>
                                                            Update
                                                        </ToggleButton>
                                                        :
                                                        <ToggleButton value="edit" onClick={() => editItem(index)}>
                                                            Edit
                                                        </ToggleButton>
                                                }

                                                <ToggleButton value="delete" onClick={() => removeItem(index)}>
                                                    Delete
                                                </ToggleButton>

                                            </ToggleButtonGroup>
                                            {
                                                disable[index] ?
                                                    <IconButton color="error" onClick={() => editItem(index)}>
                                                        <ClearIcon />
                                                    </IconButton>
                                                    :
                                                    <></>
                                            }
                                        </Row>
                                    ) : null
                                }


                                {
                                    editParmas ? editParmas.map((data, index) =>
                                        <Row className="popup-form-row" style={{ marginBottom: 20 }}>
                                            <Col className='text-sm' style={{ verticalAlign: 'text-top', textAlign: 'left' }}>{advData.length + index + 1}.</Col>
                                            <Col className="popup-content">
                                                <InputWrapper attr="Position" name={"position" + advData.length + index} value={inputs["position" + advData.length + index] || data.Position} size="sm" disable={!disable[advData.length + index]} onChange={handleChange} />
                                                <InputWrapper attr="Fee(USDT/Day)" name={"fee" + advData.length + index} value={inputs["fee" + advData.length + index] || data.Fee} disable={!disable[advData.length + index]} onChange={handleChange} />
                                            </Col>
                                            <Col>
                                                {
                                                    disable[advData.length + index] ?
                                                        <UploadImage images={images ?? [{ data_url: ImageURLExistHelper(data.ADImage) }]} onChange={onChange} />
                                                        :
                                                        <ImageViewer headerText="Header Image" clickText="Click to View" type={0} src={ImageURLExistHelper(data.ADImage)} title={true} />
                                                }
                                            </Col>

                                            <ToggleButtonGroup
                                                color="primary"
                                                exclusive
                                                onChange={handleChange}>

                                                {
                                                    disable[advData.length + index] ?
                                                        <ToggleButton value="update" onClick={() => addItem(inputs["position" + advData.length + index], inputs["fee" + advData.length + index], data.ADImage, images)}>
                                                            <UpdateIcon></UpdateIcon>
                                                        </ToggleButton>
                                                        :
                                                        <ToggleButton value="edit" onClick={() => editItem(advData.length + index)}>
                                                            <EditIcon></EditIcon>
                                                        </ToggleButton>
                                                }

                                                <ToggleButton value="delete" onClick={() => removeItem(advData.length + index)}>
                                                    <DeleteIcon></DeleteIcon>
                                                </ToggleButton>

                                            </ToggleButtonGroup>
                                            {
                                                disable[index] ?
                                                    <IconButton color="error" onClick={() => editItem(advData.length + index)}>
                                                        <ClearIcon />
                                                    </IconButton>
                                                    :
                                                    <></>
                                            }
                                        </Row>
                                    ) : null
                                }
                            </div>

                            <SuiButton onClick={actionEdit}>
                                <AddCircleIcon></AddCircleIcon>
                            </SuiButton>

                            {/* <div className="popup-form-row" onClick={() => updateADManagement(editParmas)}>
                            <SuiButton buttonColor="secondary">Update</SuiButton>
                        </div> */}

                        </div>
                    </div>
            }
        </>
    );
}

function EditADManagement({ actionBack }) {

    const [adsName, setAdsName] = useState("");
    const [adsVal, setAdsValue] = useState("");
    const [images, setImages] = useState([]);

    const onChange = (imageList, addUpdateIndex) => {
        //console.log(imageList, addUpdateIndex);
        setImages(imageList);
        //localStorage["image-"+addUpdateIndex] = JSON.stringify(imageList);
    };

    return (
        <div id="popup1" className="popup-wrapper">
            <div id="" className="popup1-flex-column">
                <h4>ADs Management</h4>
                <div className="popup-content">
                    <SuiBox xs={2} lg={3} xl={6} width="100%">
                        <InputWrapper attr="Position" value={adsName}
                            onChange={(event) => { setAdsName(event.target.value) }} disable={false} size="lg" />
                    </SuiBox>
                    <SuiBox xs={2} lg={3} xl={6} width="100%">
                        <InputWrapper attr="Fee(USDT/Day)" value={adsVal}
                            onChange={(event) => { setAdsValue(event.target.value) }} disable={false} size="lg" />
                    </SuiBox>

                    <UploadImage images={images} onChange={onChange} />
                </div>

                <div className="popup-form-row">
                    <SuiButton buttonColor="secondary" onClick={() => actionBack(adsName, adsVal, images)}>Add</SuiButton>
                </div>

            </div>
        </div>
    );
}