import 'components/Custom/common.css';
import AccountDetails from 'components/Page/account_details';
import ExtraRewardEditPopUp from './extra_reward';
import RewardDetails from './reward_detail';
import EventDetails from './event_detail';

function CustomPopup({ name, id }) {

    if (name === "AccountDetailsPopup") {
        return <AccountDetails id={id} />
    } else if(name === "ExtraReward"){
        return <ExtraRewardEditPopUp id={id}/>
    } else if(name === "RewardDetails"){
        return <RewardDetails id={id}/>
    } else if(name === "EventDetails"){
        console.log(id);
        return <EventDetails id={id}/>
    } else return <></>
}

export default CustomPopup;