/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";

// Soft UI Dashboard React components
import SuiBox from "components/Default/SuiBox";
import SuiTypography from "components/Default/SuiTypography";
import SuiProgress from "components/Default/SuiProgress";

// image asset
import { FaBtc, FaEthereum } from "react-icons/fa";
import { IoLogoUsd } from "react-icons/io";
import UsdcIcon from "assets/images/usdc-coin.svg";
import UsdtIcon from "assets/images/icons8-tether.svg";

function ReportsBarChartItem({ color, icon, label, progress }) {
  const { size } = typography;

  return (
    <SuiBox>
      <SuiBox display="flex" alignItems="center" mb={2}>
        <SuiBox
          backgroundColor={icon.color}
          width="1.25rem"
          height="1.25rem"
          borderRadius="sm"
          color="white"
          fontSize={size.xs}
          display="flex"
          justifyContent="center"
          alignItems="center"
          boxShadow="md"
          mr={1}
          backgroundGradient
        >
          {icon.component == "bitcon"
            ? <FaBtc />
            : icon.component == "eth"
              ? <FaEthereum />
              : icon.component == "offer"
                ? <LocalOfferIcon />
                : icon.component == "usdc"
                  ? <IoLogoUsd />
                  : icon.component == "usdt"
                    ? <img src={UsdtIcon} alt="usdt" />
                    : <Icon fontSize="small" color="inherit">
                        {icon.component}
                      </Icon>}
        </SuiBox>
        <SuiTypography
          variant="caption"
          textTransform="capitalize"
          fontWeight="medium"
          textColor="text"
        >
          {label}
        </SuiTypography>
      </SuiBox>
      <SuiBox mt={1}>
        <SuiTypography variant="h5" fontWeight="bold" textColor={color}>
          {progress.content}
        </SuiTypography>
        {/* <SuiBox width="75%" mt={0.5}>
          <SuiProgress value={progress.percentage} color={color} noLabel/>
        </SuiBox> */}
      </SuiBox>
    </SuiBox>
  );
}

// Setting default values for the props of ReportsBarChartItem
ReportsBarChartItem.defaultProps = {
  color: "dark"
};

// Typechecking props for the ReportsBarChartItem
ReportsBarChartItem.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark"
  ]),
  icon: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark"
    ]).isRequired,
    component: PropTypes.node.isRequired
  }).isRequired,
  label: PropTypes.string.isRequired,
  progress: PropTypes.shape({
    content: PropTypes.string.isRequired,
    percentage: PropTypes.number.isRequired
  }).isRequired
};

export default ReportsBarChartItem;
