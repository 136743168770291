import { Grid } from "@material-ui/core";
import Box from '@mui/material/Box';
import AdvsApi from 'api/advs';
import { BasicDateTimePicker } from "components/Custom";
import CommentWrapper from 'components/Custom/comment_wrapper';
import BasicSelect from 'components/Custom/custom_select';
import ImageViewer from "components/Custom/image_viewer";
import InputWrapper from 'components/Custom/input_wrapper';
import RowRadioButtonsGroup from 'components/Custom/rowRadioButtons';
import UploadImage from 'components/Custom/upload_image';
import SuiBox from "components/Default/SuiBox";
import SuiButton from "components/Default/SuiButton";
import { constant } from 'config/constant';
import { ADVS_PERMISSION } from 'config/permission';
import AdvertisementGateway from "entities/advertisementGateway";
import FirebaseGateway from "entities/firebaseGateway";
import { useEffect, useState } from "react";
import { useAlert } from 'react-alert';
import AuthCodeHelper from "util/AuthCodeHelper";
import { ERROR } from "util/ErrorHelper";
import { DateFormatUtils, ImageURLExistHelper, NullFormatUtils } from 'util/index';
import './styles.css';

let advGateway = new AdvertisementGateway();
let firebaseGateway = new FirebaseGateway();

export default function AdvsDetails ({ id, action }){
    let testMode = constant.DEBUG;
    //console.log(action);
    const alert = useAlert();

    const [page, setPage] = useState(0);
    const [auth, setAuth] = useState(false);

    const EditAdvsDetails = async (action, id, blockReason) => {
        //console.log(id);
        // action 
        // 0 - reject, 1 - accept/release, 2 - block, 3 - unblock
        if(!auth){
            setPage(0);
        } else{
            try {
                let response;
                let message;
                if(action === 0 && id && blockReason){
                    //reject the advs
                    message = "Reject ADV";
                    response = await AdvsApi.rejectAdv({
                        id: id,
                        rejected_reason: blockReason
                    });
                } 
                else if(action === 1 && id){
                    //accept the advs
                    message = "Accept ADV";
                    response = await AdvsApi.acceptAdv(id);
                }
                else if(action === 2 && id /* && blockReason */){
                    //block the advs
                    message = "block ADV";
                    response = await AdvsApi.blockAdv({
                        id: id,
                        rejected_reason: blockReason
                    });
                }
                else if(action === 3 && id){
                    //unblock the advs
                    message = "unblock ADV";
                    response = await AdvsApi.unblockAdv(id);
                } else {
                    throw "somethings missing";
                }
                
                let res = response;
                if(res.status === 200){
                    console.log(res);
                    alert.success(`${message} Success`);
                }else{
                    //console.log(res);
                    alert.error(`Some error - Status ${res.status}`);
                }
            } catch (err) {
                alert.error(err.message);
            }
        }
    };

    const CreateAdvsDetails = async (inputs, releaseDate, endDate, headerImages) => {
        if(!auth){
            setPage(0);
        } else{
            let imageURL = "";
            await firebaseGateway.fireStorageHelper({
                    image: headerImages
                }).then((res) => {
                    console.log(res);
                    imageURL = res.data;
                }).catch((err) => {
                    alert.error(err.message);
                })

            await AdvsApi.addAdv({
                    "Days": inputs["days"],
                    "Image": imageURL,
                    "Position": "Official",
                    "Title": inputs["title"],
                    "Url": inputs["URL"],
                    "Option": inputs["URL"] ? 2 : 1,
                    "Description": inputs["description"],
                    "releaseDate": releaseDate,
                    "endDate": endDate
                }).then((res) => {
                    if(res.status === 200){
                        alert.success(`Create Adv Success`);
                    } else {
                        alert.error(`Some error - Status ${res.status}`);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    alert.error(err.message);
                });
        }
    }

    const handleAuthCode = (resp) => {
        // console.log(resp);

        if(resp){
            if(resp.isAuth){
                setAuth(true);
                handleChangePage(true);
            } else{
                alert.error(resp.message);
            }
        }
        else{
            alert.error(ERROR.callback);
        }
    }

    const handleChangePage = (auth) => {
        //console.log(action);
        
        switch (action) {
            case 0: // auth
                setPage(0);
                break;
            case 1: // view
            case 2: // edit
                setPage(1);
                break;
            case 3: // create
                //console.log(auth);
                if(!auth){
                    setPage(0);
                } else{
                    setPage(2);
                }
                break;
            default:
                break;
        }
    }

    useEffect(()=>{
        handleChangePage();
    }, []);

    if(page === 0){
        return (
            <AuthCodeHelper title="Admin Authentication" btnName="Confirm" permission={ADVS_PERMISSION.DEFAULT} callback={handleAuthCode}/>
        );
    }
    else if(page === 1){
        return (<AdvsDetailsPopup id={id} action={action} EditAdvsDetails={EditAdvsDetails}/>);
    }
    else if(page === 2){
        return (<CreateAdvsPopup action={action} CreateAdvsDetails={CreateAdvsDetails}/>);
    }
}

function CreateAdvsPopup ({ action, CreateAdvsDetails }) {
    const [inputs, setInputs] = useState({}); // inputs
    const [releaseDate, setReleaseDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [headerImages, setHeaderImages] = useState([]);

    const handleChange = e => setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    const header_onChange = (imageList, addUpdateIndex) => {  // upload image
        //console.log(imageList, addUpdateIndex);
        setHeaderImages(imageList);
    };

    useEffect(()=>{
    }, [])

    return (
        <>
            <div id="popup1" className="popup1-wrapper">
                <div id="" className="popup1-flex-column">
                    <h4>Advertisment Details</h4>
                    <div className="popup-content">
                        <SuiBox xs={2} lg={6} xl={12}>
                            <InputWrapper attr="Title" name="title" value={inputs["title"] ?? ""} onChange={handleChange} disable={false} size="sm"/>
                        </SuiBox>

                        <div id="popup-bd popup-" className="popup-form-row">
                            <InputWrapper attr="Position" name="position" value="Offical" disable="true" size="sm"/>
                            <InputWrapper attr="Days" name="days" value={inputs["days"] ?? ""} onChange={handleChange} disable={false} size="sm"/>
                        </div>

                        <SuiBox xs={2} lg={6} xl={12}>
                            <div className="text-sm">Create Date</div>
                            <BasicDateTimePicker value={releaseDate} onChange={(newValue) => { setReleaseDate(newValue) }}/>
                        </SuiBox>

                        <SuiBox xs={2} lg={6} xl={12}>
                            <div className="text-sm">End Date</div>
                            <BasicDateTimePicker value={endDate} onChange={(newValue) => { setEndDate(newValue) }}/>
                        </SuiBox>
                        
                        <SuiBox xs={2} lg={6} xl={12}>
                            <InputWrapper attr="URL" name="URL" value={inputs["URL"] ?? ""} onChange={handleChange} disable={false} size="sm"/>
                        </SuiBox>
                        
                        <div className="text-sm">One Image for Header</div>
                        <UploadImage images={headerImages} onChange={header_onChange} multiple={false}/>

                        <div className="text-sm">Description</div>
                        <CommentWrapper name="description" rows="5" value={inputs["description"] ?? ""}  disable={false} onChange={handleChange}/>

                        <Grid item className="popup-form-row">
                            <SuiButton buttonColor="secondary" onClick={() => CreateAdvsDetails(inputs, releaseDate, endDate, headerImages)}>Submit</SuiButton>
                        </Grid>
                    </div>
                </div>
            </div>
        </>
    );
}

function AdvsDetailsPopup ({ id, action, EditAdvsDetails }) {
    const alert = useAlert();

    const [advData, setAdvData] = useState([]); 
    const [blockReason, setBlockReason] = useState();
    const [disable, setDisable] = useState(action > 1 ? false : true);
    const [headerImages, setHeaderImages] = useState([]); //upload image
    const [contentImages, setContentImages] = useState([]);

    const header_onChange = (imageList, addUpdateIndex) => {
        //console.log(imageList, addUpdateIndex);
        setHeaderImages(imageList);
    };

    const content_onChange = (imageList, addUpdateIndex) => {
        //console.log(imageList, addUpdateIndex);
        setContentImages(imageList);
    };

    const fetchOneAdvsData = async (id) => {

        await advGateway.getOneAdvData(id)
            .then((res) => {
                if(res){
                    setAdvData(res.data);
                }
            })
            .catch((err) => {
                console.log(err);
                alert.error(err.message);
            });
    }

    useEffect(()=>{
        fetchOneAdvsData(id);
    }, [])

    const RejectReason = [
        {
            label : '涉及宣傳禁制商品或服務',
            value : 1
        },
        {
            label : '內容含有低俗/血腥/暴力/歧視/色情服務品牌',
            value : 2
        },
        {
            label : '侵犯他人版權或專利',
            value : 3
        },
        {
            label : '惡意軟件或釣魚網站',
            value : 4
        },
        {
            label : '不符合或損害幣坊品牌形象',
            value : 5
        },
        {
            label : '廣告資料出錯或計費錯誤',
            value : 6
        },
        {
            label : '具有煽動或鼓勵違法犯罪成份',
            value : 7
        }
    ]

    const handleStateBtn = (Status, id) => {
        if(Status && id){
            if (Status === "進行中") {
                return  (<SuiButton buttonColor="error" onClick={() => EditAdvsDetails(2, id, blockReason)}>Block</SuiButton>)}
            else if (Status === "審核中") {
                return  (<><SuiButton disabled={blockReason ? true : false} buttonColor="secondary" onClick={() => EditAdvsDetails(1, id)}>Release</SuiButton><SuiButton disabled={blockReason ? false : true} onClick={() => EditAdvsDetails(0, id, blockReason)}>Reject</SuiButton></>)}
            else if (Status === "不獲批"){
                return "";
            }
            else if (Status === "已完成"){
                return "";
            }
            else if (Status === "被封鎖"){
                return (<SuiButton buttonColor="secondary" onClick={() => EditAdvsDetails(3, id)}>Unblock</SuiButton>);
            }
        }
        return "";
    }

    return (
        <>
            <div id="popup1" className="popup1-wrapper">
                <div id="" className="popup1-flex-column">
                    <h4>Advertisment Details</h4>
                    <div className="popup-content">
                        <SuiBox xs={2} lg={6} xl={12}>
                            <InputWrapper attr="ADs ID" value={NullFormatUtils(id)} disable={disable} size="lg"/>
                        </SuiBox>
                        
                        <InputWrapper attr="Submit Date" value={DateFormatUtils(advData.create_at, "YYYY-MM-DD hh:mm:ss")} disable={disable} size="lg"/>

                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={8} md={6} xl={6}>
                                <InputWrapper attr="User ID" value={NullFormatUtils(advData.account_id)} disable={disable} size="sm"/>
                            </Grid>
                            <Grid item xs={12} sm={8} md={6} xl={6}>
                                <InputWrapper attr="User Name" value={NullFormatUtils(advData.username)} disable={disable} size="lg"/>
                            </Grid>
                        </Grid>

                        <SuiBox xs={2} lg={6} xl={12}>
                            <InputWrapper attr="Title" value={NullFormatUtils(advData.title)} disable={disable} size="sm"/>
                        </SuiBox>

                        <div id="popup-bd popup-" className="popup-form-row">
                            <InputWrapper attr="Position" value={NullFormatUtils(advData.position)} disable={disable} size="sm"/>
                            <InputWrapper attr="Days" value={NullFormatUtils(advData.days)} disable={disable} size="sm"/>
                            <InputWrapper attr="Cost(USDT)" value={NullFormatUtils(advData.cost)} disable={disable} size="lg"/>
                        </div>

                        <SuiBox xs={2} lg={6} xl={12}>
                            <InputWrapper attr="Release Date" value={DateFormatUtils(advData.release_date, "YYYY-MM-DD hh:mm:ss")} disable={disable} size="lg"/>
                        </SuiBox>

                        <SuiBox xs={2} lg={6} xl={12}>
                            <InputWrapper attr="End Date" value={DateFormatUtils(advData.end_at, "YYYY-MM-DD hh:mm:ss")} disable={disable} size="lg"/>
                        </SuiBox>

                        <div id="popup-bd popup-" className="popup-form-row">
                            <RowRadioButtonsGroup values={
                                [
                                    {
                                        value: 0,
                                        label: <div>URL<Box sx={{ bgcolor: 'text.disabled', color: 'background.paper', borderRadius: 1, p: 1 }}>{advData.url ?? "Example: http://xxx.xxx.xxx"}</Box></div>,
                                        type: "url"
                                    },
                                    {
                                    value: 1,
                                    label: "Jump to My Shop Page",
                                    type: "shop"
                                }
                                ]
                            } selectValue={advData.url === null ? "1" : "0"}/>
                        </div>
                        
                        {
                            disable ?
                                <>
                                    <ImageViewer headerText="Header Image" clickText="Click to View" type={0} src={ImageURLExistHelper(advData.image)} title={true}/>
                                    <ImageViewer headerText="Content Images" clickText="Click to View" type={1} src={ImageURLExistHelper(advData.content_images)} title={true}/>
                                </> :
                                <>
                                    <UploadImage images={headerImages} onChange={header_onChange}/>
                                    <UploadImage images={contentImages} onChange={content_onChange}/>
                                </>

                        }
                        
                        <CommentWrapper attr="Description" value={NullFormatUtils(advData.description)} disable={disable}/>

                        <InputWrapper attr="Views" value={NullFormatUtils(advData.view_count)} size="sm" disable={disable}/>
                        <InputWrapper attr="Status" value={NullFormatUtils(advData.status)} size="sm" disable={disable}/>

                        <br></br>Review Record
                        <InputWrapper attr="Review Date" value={/* advData.updateAt */""} size="lg" disable={disable}/>
                        {
                            advData.status === "審核中" ?
                            <BasicSelect title="Reject Reason" items={RejectReason} onChange={(e)=>{ e ? setBlockReason(e.label) : setBlockReason(null)}}/>
                            : <></>
                        }
                        <InputWrapper attr="Handler" value={NullFormatUtils(advData.admin_id)} size="sm"/>

                        <Grid item className="popup-form-row">
                            {   handleStateBtn(advData.status, id)  }
                        </Grid>
                    </div>
                </div>
            </div>
        </>
    );
}