const className = {
    success: 'css-18udauo-MuiChip-root',
    error: 'css-v945hf-MuiChip-root',
    warning: 'css-6bvs8j-MuiChip-root',
    default: 'css-12nrnta-MuiButtonBase-root-MuiChip-root'
};

const status = [
    {
      label: "FINISHED",
      value: 1,
      color: "success",
      class: className.success
    },
    {
      label: "IN_PROGRESS",
      value: 2,
      color: "warning",
      class: className.warning
    },
    {
      label: "NOT_YET_STARTED",
      value: 3,
      color: "default",
      class: className.default
    }
];

export { status };