/**
=========================================================
* Soft UI Dashboard React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { CachedOutlined } from "@material-ui/icons";
import { Card, CardContent } from "@mui/material";
import Grid from "@mui/material/Grid";
import DashboardApi from "api/dashboard";
import Popup from "assets/theme/components/popup";
import SuiBox from "components/Default/SuiBox";
import SuiButton from "components/Default/SuiButton";
import SuiTypography from "components/Default/SuiTypography";
import MiniStatisticsCard from "components/examples/Cards/StatisticsCards/MiniStatisticsCard";
import ReportsBarChart from "components/examples/Charts/BarCharts/ReportsBarChart";
import DashboardLayout from "components/examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/examples/Navbars/DashboardNavbar";
import { DASHBOARD_PERMISSION } from "config/permission";
import { addDays } from 'date-fns';
import AccLevelList from "layouts/dashboard/components/AccLevelList";
import moment from "moment";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { DateRangePicker } from 'react-date-range';
import AuthCodeHelper from "util/AuthCodeHelper";
import Count from "util/Counter";
import { ERROR } from "util/ErrorHelper";
import { DateFormatUtils, NumberFormatUtils } from "util/index";
import AdminActionOverview from "./components/OrderOverview";

const sample_data = [{
  "message": "success",
  "data": {
    "BTC": "40000.000000000000000000",
    "ETH": "40000.000000000000000000",
    "USDT": "40000.000000000000000000",
    "USDC": "40000.000000000000000000",
    "freeze_BTC": "0.000000000000000000",
    "freeze_ETH": "0.000000000000000000",
    "freeze_USDT": "0.000000000000000000",
    "freeze_USDC": "0.000000000000000000",
    "total_BTC": "42872.082623489101309640",
    "total_HKD": "15958634825.132658000000000000",
    "total_freeze_BTC": "0.000000000000000000",
    "total_freeze_HKD": "0.000000000000000000"
  }
}];

const reportsBarChartData = {
  chart: {
    labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: { label: "Sales", data: [450, 200, 100, 220, 500, 100, 400, 230, 500] },
  },
  items: [
    {
      icon: { color: "primary", component: "bitcon" },
      label: "BTC",
      progress: { content: "1000", percentage: 60 },
    },
    {
      icon: { color: "info", component: "eth" },
      label: "ETH",
      progress: { content: "1000", percentage: 90 },
    },
    {
      icon: { color: "warning", component: "usdt" },
      label: "USDT",
      progress: { content: "1000", percentage: 30 },
    },
    {
      icon: { color: "error", component: "usdc" },
      label: "USDC",
      progress: { content: "100", percentage: 50 },
    },
  ],
};



function Dashboard() {
  const alert = useAlert();
  const { chart, items } = reportsBarChartData;
  const [data, setData] = useState(sample_data);
  const [data2, setData2] = useState([]);
  const [topup, setTopup] = useState([]);
  const [withdraw, setWithdraw] = useState([]);
  const [salesRevenue, setSalesRevenue] = useState([]);
  const [refund, setRefund] = useState([]);
  const [onlineCommission, setOnlineCommission] = useState([]);
  const [offlineCommission, setOfflineCommission] = useState([]);
  //pop up pages
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection'
    }
  ]);
  const [page, setPage] = useState(1);
  const [openPopUp, setOpenPopup] = useState(true);
  const [popUpName, setPopUpName] = useState();
  const [authCode, setAuthCode] = useState("");
  const handlePopUp = (pName, pageNum) => {
    setPopUpName(pName);
    setPage(pageNum);
    setOpenPopup(true);
  };

  const handleBack = () => {
    setPage(0);
  };

  const handleAuthCode = resp => {
    //// console.log(resp);

    if (resp) {
      if (resp.isAuth) {
        handleBack();
      } else {
        alert.error(resp.message);
      }
    } else {
      alert.error(ERROR.callback);
    }
  };

  const handleDatePickerSelected = (date) => {
    //console.log(date);
    setState([date])

    let startDate = DateFormatUtils(date.startDate);
    let endDate = DateFormatUtils(date.endDate);

    // console.log(startDate);
    // console.log(endDate);

    //setLoading(true);
    //fetchData(startDate,endDate)
    fetchData2(startDate,endDate)
  }

  useEffect(() => {
    handlePopUp("AuthoizedToEditPopup", 1);
    fetchData();
    fetchData2();
  }, []);

  const fetchData = async (startDate = null, endDate = null) => {
    await DashboardApi.assets({
      start_at: startDate ? startDate + " 00:00:00" : "1999-12-31 12:59:59",
      end_at: endDate ? endDate + " 23:59:59" : "2030-12-31 12:59:59",
      code: "<string>"
    })
      .then(res => {
        if (res.status === 200) setData(res.data.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const fetchData2 = async (startDate = null, endDate = null) => {
    await DashboardApi.display({
      start_at: startDate ? startDate + " 00:00:00" : "1999-12-31 12:59:59",
      end_at: endDate ? endDate + " 23:59:59" : "2030-12-31 12:59:59"
    })
      .then(res => {
        console.log(res.data.data);
        if (res.status === 200){
          let d = res.data.data;
          setData2(d);
          const topup = ReportsBarChartItems({ 
            BTC: d?.payment_gateway?.deposit?.BTC, 
            ETH: d?.payment_gateway?.deposit?.ETH, 
            USDT: d?.payment_gateway?.deposit?.USDT, 
            USDC: d?.payment_gateway?.deposit?.USDC
          })
          setTopup(topup);

          const withdraw = ReportsBarChartItems({ 
            BTC: d?.payment_gateway?.withdraw?.BTC, 
            ETH: d?.payment_gateway?.withdraw?.ETH, 
            USDT: d?.payment_gateway?.withdraw?.USDT, 
            USDC: d?.payment_gateway?.withdraw?.USDC
          })
          setWithdraw(withdraw);

          const salesRevenue = ReportsBarChartItems({ 
            BTC: d?.orders?.revenue.find((d) => d.crypto_type === "BTC")?.sum_price ?? 0, 
            ETH: d?.orders?.revenue.find((d) => d.crypto_type === "ETH")?.sum_price ?? 0, 
            USDT: d?.orders?.revenue.find((d) => d.crypto_type === "USDT")?.sum_price ?? 0, 
            USDC: d?.orders?.revenue.find((d) => d.crypto_type === "USDC")?.sum_price ?? 0
          })
          setSalesRevenue(salesRevenue);

          const refund = ReportsBarChartItems({ 
            BTC: d?.orders?.refund.find((d) => d.crypto_type === "BTC")?.sum_price ?? 0, 
            ETH: d?.orders?.refund.find((d) => d.crypto_type === "ETH")?.sum_price ?? 0, 
            USDT: d?.orders?.refund.find((d) => d.crypto_type === "USDT")?.sum_price ?? 0, 
            USDC: d?.orders?.refund.find((d) => d.crypto_type === "USDC")?.sum_price ?? 0
          })
          setRefund(refund);

          const onlineCommission = ReportsBarChartItems({ 
            BTC: d?.orders?.online_commissions.find((d) => d.crypto_type === "BTC")?.sum_commission ?? 0, 
            ETH: d?.orders?.online_commissions.find((d) => d.crypto_type === "ETH")?.sum_commission ?? 0, 
            USDT: d?.orders?.online_commissions.find((d) => d.crypto_type === "USDT")?.sum_commission ?? 0, 
            USDC: d?.orders?.online_commissions.find((d) => d.crypto_type === "USDC")?.sum_commission ?? 0
          })
          setOnlineCommission(onlineCommission);

          const offlineCommission = ReportsBarChartItems({ 
            BTC: d?.orders?.offline_commissions.find((d) => d.crypto_type === "BTC")?.sum_commission ?? 0, 
            ETH: d?.orders?.offline_commissions.find((d) => d.crypto_type === "ETH")?.sum_commission ?? 0, 
            USDT: d?.orders?.offline_commissions.find((d) => d.crypto_type === "USDT")?.sum_commission ?? 0, 
            USDC: d?.orders?.offline_commissions.find((d) => d.crypto_type === "USDC")?.sum_commission ?? 0
          })
          setOfflineCommission(offlineCommission);
        } 
      })
      .catch(e => {
        console.log(e);
      });
  };

  const ReportsBarChartItems = ({ BTC, ETH, USDT, USDC }) => {
    return [
      {
        icon: { color: "primary", component: "bitcon" },
        label: "BTC",
        progress: { content: BTC, percentage: 60 },
      },
      {
        icon: { color: "info", component: "eth" },
        label: "ETH",
        progress: { content: ETH, percentage: 90 },
      },
      {
        icon: { color: "warning", component: "usdt" },
        label: "USDT",
        progress: { content: USDT, percentage: 30 },
      },
      {
        icon: { color: "error", component: "usdc" },
        label: "USDC",
        progress: { content: USDC, percentage: 50 },
      },
    ]
  }

  return (
    <div>
      {
        page === 2 ?
          <Popup
            openPopup={openPopUp}
            setOpenPopup={() => {
              setOpenPopup(false);
            }}
          >
            <DateRangePicker
              onChange={item => handleDatePickerSelected(item.selection)}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={state}
              direction="horizontal"
            />
          </Popup>
          : <></>
      }
      {page === 1
        ? <Popup
            openPopup={openPopUp}
            setOpenPopup={() => {
              setOpenPopup(false);
            }}
          >
            <AuthCodeHelper
              title="Admin Authentication"
              btnName="Confirm"
              // permission={DASHBOARD_PERMISSION.VIEWS}
              callback={handleAuthCode}
              />
          </Popup>
        : <DashboardLayout>
            <DashboardNavbar />
            <SuiBox py={3}>
              <Grid container>
                <Grid item xs={8} sm={8} md={8} xl={8}></Grid>
                <Grid item xs={3} sm={3} md={3} xl={3}>
                  <div onClick={() => handlePopUp("DateRange", 2)}>
                    <Card>
                      <CardContent className='text-sm date_style text-center'>
                        { moment(state[0].startDate).format("MMM Do, YYYY") + " - " + moment(state[0].endDate).format("MMM Do, YYYY") }
                      </CardContent>
                    </Card>
                  </div>
                </Grid>
                <Grid item xs={1} sm={1} md={1} xl={1}>
                  <div style={{ float: "right" }}>
                    <SuiButton
                      buttonColor="secondary"
                      onClick={() => {
                        fetchData();
                      }}
                    >
                      <CachedOutlined />
                    </SuiButton>
                  </div>
                </Grid>
              </Grid>

              <SuiBox mb={3}>
                <SuiTypography
                  variant="h5"
                  fontWeight="bold"
                  gutterBottom
                  ml={1}
                >
                  Company Asset
                </SuiTypography>
                <Grid container spacing={3} mb={3}>
                  <Grid item xs={18} sm={9} xl={6}>
                    <MiniStatisticsCard
                      title={{ text: "Total asset (BTC)" }}
                      count={NumberFormatUtils(Count(data.total_BTC, data.total_freeze_BTC), 8)}
                      percentage={{ color: "success", text: "" }}
                      icon={{ color: "primary", component: "bitcon" }}
                    />
                  </Grid>
                  <Grid item xs={18} sm={9} xl={6}>
                    <MiniStatisticsCard
                      title={{ text: "Total asset (HKD)" }}
                      count={NumberFormatUtils(Count(data.total_HKD, data.total_freeze_HKD), 2)}
                      percentage={{ color: "success", text: "" }}
                      icon={{ color: "primary", component: "paid" }}
                    />
                  </Grid>
                </Grid>
              </SuiBox>

              <SuiBox mb={3}>
                <SuiTypography
                  variant="h5"
                  fontWeight="bold"
                  gutterBottom
                  ml={1}
                >
                  Available Asset
                </SuiTypography>
                <Grid container spacing={3} mb={3}>
                  <Grid item xs={18} sm={9} xl={6}>
                    <MiniStatisticsCard
                      title={{ text: "Available asset (BTC)" }}
                      count={NumberFormatUtils(data.total_BTC, 8)}
                      percentage={{ color: "success", text: "" }}
                      icon={{ color: "info", component: "bitcon" }}
                    />
                  </Grid>
                  <Grid item xs={18} sm={9} xl={6}>
                    <MiniStatisticsCard
                      title={{ text: "Available asset (HKD)" }}
                      count={NumberFormatUtils(data.total_HKD, 2)}
                      percentage={{ color: "success", text: "" }}
                      icon={{ color: "info", component: "paid" }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} mb={3}>
                  <Grid item xs={12} sm={6} xl={3}>
                    <MiniStatisticsCard
                      title={{ text: "BTC" }}
                      count={NumberFormatUtils(data.BTC, 8)}
                      percentage={{ color: "success", text: "" }}
                      icon={{ color: "info", component: "bitcon" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} xl={3}>
                    <MiniStatisticsCard
                      title={{ text: "ETH" }}
                      count={NumberFormatUtils(data.ETH, 8)}
                      percentage={{ color: "success", text: "" }}
                      icon={{ color: "info", component: "eth" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} xl={3}>
                    <MiniStatisticsCard
                      title={{ text: "USDT" }}
                      count={NumberFormatUtils(data.USDT, 8)}
                      percentage={{ color: "success", text: "" }}
                      icon={{ color: "info", component: "usdt" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} xl={3}>
                    <MiniStatisticsCard
                      title={{ text: "USDC" }}
                      count={NumberFormatUtils(data.USDC, 8)}
                      percentage={{ color: "success", text: "" }}
                      icon={{ color: "info", component: "usdc" }}
                    />
                  </Grid>
                </Grid>
              </SuiBox>

              <SuiBox mb={3}>
                <SuiTypography
                  variant="h5"
                  fontWeight="bold"
                  gutterBottom
                  ml={1}
                >
                  Freezed Asset
                </SuiTypography>
                <Grid container spacing={3} mb={3}>
                  <Grid item xs={18} sm={9} xl={6}>
                    <MiniStatisticsCard
                      title={{ text: "Freezed asset (BTC)" }}
                      count={NumberFormatUtils(data.total_freeze_BTC, 8)}
                      percentage={{ color: "success", text: "" }}
                      icon={{ color: "warning", component: "bitcon" }}
                    />
                  </Grid>
                  <Grid item xs={18} sm={9} xl={6}>
                    <MiniStatisticsCard
                      title={{ text: "Freezed asset (HKD)" }}
                      count={NumberFormatUtils(data.total_freeze_HKD, 2)}
                      percentage={{ color: "success", text: "" }}
                      icon={{ color: "warning", component: "paid" }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} mb={3}>
                  <Grid item xs={12} sm={6} xl={3}>
                    <MiniStatisticsCard
                      title={{ text: "BTC" }}
                      count={NumberFormatUtils(data.freeze_BTC, 8)}
                      percentage={{ color: "success", text: "" }}
                      icon={{ color: "warning", component: "bitcon" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} xl={3}>
                    <MiniStatisticsCard
                      title={{ text: "ETH" }}
                      count={NumberFormatUtils(data.freeze_ETH, 8)}
                      percentage={{ color: "success", text: "" }}
                      icon={{ color: "warning", component: "eth" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} xl={3}>
                    <MiniStatisticsCard
                      title={{ text: "USDT" }}
                      count={NumberFormatUtils(data.freeze_USDT, 8)}
                      percentage={{ color: "success", text: "" }}
                      icon={{ color: "warning", component: "usdt" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} xl={3}>
                    <MiniStatisticsCard
                      title={{ text: "USDC" }}
                      count={NumberFormatUtils(data.freeze_USDC, 8)}
                      percentage={{ color: "success", text: "" }}
                      icon={{ color: "warning", component: "usdc" }}
                    />
                  </Grid>
                </Grid>
              </SuiBox>

              <SuiBox mb={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={6}>
                    <ReportsBarChart
                      title="Topup"
                      chart={chart}
                      items={topup}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <ReportsBarChart
                      title="Withdraw"
                      chart={chart}
                      items={withdraw}
                    />
                  </Grid>
                </Grid>
              </SuiBox>

              <SuiBox mb={3}>
                <SuiTypography
                  variant="h5"
                  fontWeight="bold"
                  gutterBottom
                  ml={1}
                >
                  Sales and Revenue
                </SuiTypography>
                <Grid container spacing={3} mb={3}>
                  <Grid item xs={12} lg={4} pb={3}>
                    <ReportsBarChart
                      title="Sales Revenue"
                      chart={chart}
                      items={salesRevenue}
                    />
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <ReportsBarChart
                      title="Refund"
                      chart={chart}
                      items={refund}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} xl={3} lg={4}>
                    <SuiBox mb={3}>
                      <MiniStatisticsCard
                        title={{ text: "Total Shop Offers" }}
                        count={data2?.offers?.total_size}
                        percentage={{ color: "success", text: "" }}
                        icon={{ color: "info", component: "offer" }}
                      />
                    </SuiBox>

                    <SuiBox mb={3}>
                      <MiniStatisticsCard
                        title={{ text: "Total Orders" }}
                        count={data2?.orders?.total_size}
                        percentage={{ color: "success", text: "" }}
                        icon={{ color: "info", component: "offer" }}
                      />
                    </SuiBox>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={3}>
                    <ReportsBarChart
                      title="Offline Commission"
                      chart={chart}
                      items={offlineCommission}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <ReportsBarChart
                      title="Online Commission"
                      chart={chart}
                      items={onlineCommission}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} xl={3} lg={3}>
                    <MiniStatisticsCard
                      title={{ text: "OTC Commission (USDT)" }}
                      count={data2?.over_the_counters?.commission_usdt}
                      percentage={{ color: "success", text: "" }}
                      icon={{ color: "info", component: "usdt" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} xl={3} lg={3}>
                    <MiniStatisticsCard
                      title={{ text: "Advertising Fee(USDT)" }}
                      count={data2?.advertisement?.total_fee}
                      percentage={{ color: "success", text: "" }}
                      icon={{ color: "info", component: "usdt" }}
                    />
                  </Grid>
                </Grid>
              </SuiBox>

              <SuiBox mb={3}>
                <SuiTypography variant="h5" fontWeight="bold" gutterBottom>
                  User Analysis
                </SuiTypography>
                <Grid container spacing={3} mb={3}>
                  <Grid item xs={12} sm={6} xl={3} lg={3}>
                    <MiniStatisticsCard
                      title={{ text: "Total Users" }}
                      count={data2?.users?.total_size}
                      percentage={{ color: "success", text: "" }}
                      icon={{ color: "info", component: "person" }}
                    />
                  </Grid>

                  <Grid item xs={12} lg={3}>
                    <AccLevelList
                      listName="Account Level"
                      BasicVal={data2?.users?.total_size - data2?.users?.deleted_size}
                      IDVal={data2?.users?.level_count?.id}
                      ShopVal={data2?.users?.level_count?.shop}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} xl={3} lg={3}>
                    <MiniStatisticsCard
                      title={{ text: "Account Deleted" }}
                      count={data2?.users?.deleted_size}
                      percentage={{ color: "success", text: "" }}
                      icon={{ color: "info", component: "person" }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} xl={3} lg={3}>
                    <MiniStatisticsCard
                      title={{ text: "New Users (Today)" }}
                      count={data2?.users?.register_size}
                      percentage={{ color: "success", text: "" }}
                      icon={{ color: "info", component: "person" }}
                    />
                  </Grid>
                </Grid>
              </SuiBox>
            </SuiBox>

            <AdminActionOverview />
          </DashboardLayout>}
    </div>
  );
}

export default Dashboard;
