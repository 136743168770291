import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { DataGrid, GridOverlay } from '@mui/x-data-grid';
import { SuiButton } from 'components/Default';
import CInput from 'react-composition-input';

import PropTypes from 'prop-types';

import "./style.css";

// React components
import { useState } from "react";

export default function DataTable({ dt_columns, dt_rows, height, handleSearch }) {
    const [searchText, setSearchText] = useState('');
    const [searchArray, setSearchArray] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filterRows, setfilterRows] = useState(dt_rows);
    const [error, setError] = useState(undefined);

    // Search Reg Exp
    // function escapeRegExp(value) {
    //     return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    // }

    // Search Toolbar
    function QuickSearchToolbar(props) {
        return (
            <>
                <div style={{ marginTop: '10px' }}></div>
                <div className="css-18mgw6g-MuiInputBase-root-MuiInput-root" style={{ marginLeft: '5px' }}>
                    <CInput
                        autoFocus={true}
                        style={{ border: 'none' }}
                        onInputChange={props.onChange}
                        value={props.value}
                        placeholder="Search"
                        className="css-19kae9b-MuiInputBase-input-MuiInput-input"
                        sx={{
                            width: {
                                xs: 5,
                                sm: 5,
                            },
                            m: (theme) => theme.spacing(1, 0.5, 1.5),
                            '& .MuiSvgIcon-root': {
                                mr: 0.5,
                            },
                            '& .MuiInput-underline:before': {
                                borderBottom: 1,
                                borderColor: 'divider',
                            },
                        }} />

                    <IconButton
                        title="Clear"
                        aria-label="Clear"
                        size="small"
                        style={{ visibility: searchText ? 'visible' : 'hidden' }}
                        onClick={props.clearSearch}>
                        <ClearIcon fontSize="small" />
                    </IconButton>

                    <SuiButton
                        color="secondary"
                        onClick={() => {
                            handleSearch(searchText)
                        }}
                    >
                        <SearchIcon fontSize="small" />
                    </SuiButton>
                </div>

                <TextField
                    style={{ display: "none" }}
                    variant="standard"
                    value={props.value}
                    onChange={props.onChange}
                    placeholder="Search"
                    InputProps={{
                        startAdornment: <SearchIcon fontSize="small" />,
                        endAdornment: (
                            <IconButton
                                title="Clear"
                                aria-label="Clear"
                                size="small"
                                style={{ visibility: props.value ? 'visible' : 'hidden' }}
                                onClick={props.clearSearch}
                            >
                                <ClearIcon fontSize="small" />
                            </IconButton>
                        ),
                    }}
                    sx={{
                        width: {
                            xs: 1,
                            sm: 1,
                        },
                        m: (theme) => theme.spacing(1, 0.5, 1.5),
                        '& .MuiSvgIcon-root': {
                            mr: 0.5,
                        },
                        '& .MuiInput-underline:before': {
                            borderBottom: 1,
                            borderColor: 'divider',
                        },
                    }}
                />
            </>
        );
    }

    QuickSearchToolbar.propTypes = {
        clearSearch: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        value: PropTypes.string.isRequired,
    };



    // Load data overlay
    function CustomLoadingOverlay() {
        return (
            <GridOverlay>
                <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                    <LinearProgress />
                </div>
            </GridOverlay>
        );
    }

    const StyledGridOverlay = styled(GridOverlay)(({ theme }) => ({
        flexDirection: 'column',
        '& .ant-empty-img-1': {
            fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
        },
        '& .ant-empty-img-2': {
            fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
        },
        '& .ant-empty-img-3': {
            fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
        },
        '& .ant-empty-img-4': {
            fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
        },
        '& .ant-empty-img-5': {
            fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
            fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
        },
    }));

    // No Data
    function CustomNoRowsOverlay() {
        return (
            <StyledGridOverlay>
                <svg
                    width="120"
                    height="100"
                    viewBox="0 0 184 152"
                    aria-hidden
                    focusable="false"
                >
                    <g fill="none" fillRule="evenodd">
                        <g transform="translate(24 31.67)">
                            <ellipse
                                className="ant-empty-img-5"
                                cx="67.797"
                                cy="106.89"
                                rx="67.797"
                                ry="12.668"
                            />
                            <path
                                className="ant-empty-img-1"
                                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                            />
                            <path
                                className="ant-empty-img-2"
                                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                            />
                            <path
                                className="ant-empty-img-3"
                                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                            />
                        </g>
                        <path
                            className="ant-empty-img-3"
                            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                        />
                        <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                        </g>
                    </g>
                </svg>
                <Box sx={{ mt: 1 }}>No Rows</Box>
            </StyledGridOverlay>
        );
    }

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);

        // const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        // const _dt_rows = JSON.parse(JSON.stringify(dt_rows));

        // const filteredRows = _dt_rows.filter((row) => {
        //     return Object.keys(row).some((field) => {
        //         if(searchRegex.test(row[field])){
        //             row["SearchValue"] = searchValue;
        //             return true;
        //         }
        //         if(typeof row[field] === 'object' || row[field] !== null){
        //             let _row = row[field];
        //             if(_row){
        //                 return Object.keys(_row).some((field) => {
        //                     if(_row[field]){
        //                         if(searchRegex.test(_row[field])){
        //                             row["SearchValue"] = searchValue;
        //                         }
        //                         return searchRegex.test(_row[field]);
        //                     }
        //                 })
        //             }
        //         }
        //         return false;
        //     });
        // });

        // setfilterRows(filteredRows);
    };

    return (
        <div>
            <Box className="dtable" sx={{ height: height ? height : '75vh', width: '100%' }}>
                <DataGrid
                    sx={{ border: 'none' }}
                    components={{
                        Toolbar: QuickSearchToolbar,
                        LoadingOverlay: CustomLoadingOverlay,
                        NoRowsOverlay: CustomNoRowsOverlay
                    }}
                    rows={searchText !== '' ? filterRows : dt_rows}
                    loading={loading}
                    columns={dt_columns}
                    hideFooter
                    componentsProps={{
                        toolbar: {
                            value: searchText,
                            onChange: (event) => requestSearch(event.target.value),
                            clearSearch: () => handleSearch(''),
                        },
                    }}
                />
            </Box>
        </div>
    );
}