import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import "./style.css";

export default function ListView({ title, data, handleChangePage }) {
  return (
    <List
      sx={{
        width: '100%',
        //maxWidth: 360,
        bgcolor: 'background.paper',
        position: 'relative',
        overflow: 'auto',
        maxHeight: 300,
        '& ul': { padding: 0 },
      }}
      subheader={
        <ListSubheader component="div" id="nested-list-subheader" 
          sx={{
            position: 'unset',
            paddingLeft: '0'
          }}>
          <Grid container spacing={4}>
            {title.map((item) => (
              <Grid item xl={item.size}>
                {item.name}
              </Grid>
            ))}
          </Grid>
        </ListSubheader>
      }
    >
        <li>
          <ul>
            {data.map((item) => (
              <>
                <ListItem 
                  sx={{ 
                    display: "flex",
                    flexDirection: "row",
                    columnGap: "3rem"
                  }}>
                  <ListItemText primary={item.account_id} className="custom--list--item--text"/>
                  <ListItemText primary={item.registration} className="custom--list--item--text"/>
                  <ListItemText primary={item.participation} className="custom--list--item--text"/>
                  <ListItemText primary={item.submit_time} className="custom--list--item--text"/>
                  <Link to="#" className="text-secondary answer--link" onClick={(event) => { event.preventDefault(); handleChangePage(item.account_id) } }>
                    <ListItemText primary={item.correct_answer + "/5"} className="custom--list--item--text"/>
                    {item.correct_answer === 5 ? <EmojiEventsIcon/> : <></>}
                  </Link>
                  <ListItemText primary={item.prize_received} className="custom--list--item--text"/>
                </ListItem>
              </>
            ))}
          </ul>
        </li>
    </List>
  );
}