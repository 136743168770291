import axios from "./index";

class StaffApi {
  static list = (page) => {
    const id_token = localStorage.getItem("id_token");
    if(page){
      return axios.get(`${base}?page=${page}`, { headers: {"Authorization" : `Bearer ${id_token}`} });
    }
    return axios.get(`${base}`);
  };

  static getOneStaffByFid = (id) => {
    const id_token = localStorage.getItem("id_token");
    if(id){
      return axios.get(`${base}/${id}`, { headers: {"Authorization" : `Bearer ${id_token}`} });
    }
    throw "staff id is missing";
  };

  static add = (data) => {
    const id_token = localStorage.getItem("id_token");
    if(data){
      return axios.post(`${base}`, data, { headers: {"Authorization" : `Bearer ${id_token}`} });
    }
    throw "request data is missing";
  };

  static update = (data) => {
    const id_token = localStorage.getItem("id_token");
    if(data){
      const requestData = {
        phone: data.phone,
        job_title: data.job_title,
        name: data.name,
        nick_name: data.nick_name,
        gender: data.gender
      };
  
      return axios.put(`${base}/${data.id}`, requestData, { headers: {"Authorization" : `Bearer ${id_token}`} });
    }
    throw "request data is missing";
  };

  static changePassword = (data) => {
    const id_token = localStorage.getItem("id_token");
    console.log(data);
    const requestData = {
      password: data.password
    };
    
    return axios.put(`${base}/change_password`, requestData, { headers: {"Authorization" : `Bearer ${data?.id_token}`} });
  }

  static delete = (data) => {
    const id_token = localStorage.getItem("id_token");
    return axios.post(`${base}/delete`, { headers: {"Authorization" : `Bearer ${id_token}`} });
  };
}

let base = "/staffs";

export default StaffApi;