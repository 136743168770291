import UserApi from 'api/user';
import { InputWrapper } from "components/Custom";
import { PageController } from 'components/Custom/custom_container';
import { useEffect, useState } from "react";
import { useAlert } from 'react-alert';
import QRCode from "react-qr-code";
import GoogelAuthTemplate from "template/googleAuthTemplate";
import AuthCodeHelper from 'util/AuthCodeHelper';
import { ERROR } from "util/ErrorHelper";
import { STAFF_PERMISSION } from 'config/permission';

export default function GoogleAuth({ id, googleAuthKey, googleAuthUrl }) {
    const alert = useAlert();

    const [page, setPage] = useState(1);
    const [auth, setAuth] = useState(false);
    const [googleAuthQRCode, setGoogleAuthQRCode] = useState("not_yet_loaded");
    const [googleAuthQRCodeURL, setGoogleAuthQRCodeURL] = useState("");

  /* const fetchData = async () => {
    await UserApi.generateGoogleAuthKey()
      .then(res => {
        if (res.status === 200) {
          console.log(res.data.data);
          setGoogleAuthQRCodeURL(res.data.data.QRCodeUrl);
          setGoogleAuthQRCode(res.data.data.secret_key);
        }
      })
      .catch(err => {
        console.log(err);
        alert.error("QR code not generated");
      });
  }; */

  const handleAuthCode = (resp) => {
    // console.log(resp);

    if(resp){
        if(resp.isAuth){
            setAuth(true);
            setPage(1);
        } else{
            alert.error(resp.message);
        }
    }
    else{
        alert.error(ERROR.callback);
    }
}

  useEffect(() => {
    if(!auth){
        setPage(0);
    }
    /* if(googleAuthQRCode === "not_yet_loaded"){
        fetchData();
    } */
  }, [auth]);

  /* 
    Components
  */

  const Title = () => <h4>Google安全驗證器</h4>
  const QrCode = () => <QRCode value={googleAuthUrl} />
  const GoogleAuthCode = () => <InputWrapper value={googleAuthKey}/>
  const googleAuthDetails = {
    Title,
    QrCode,
    GoogleAuthCode
  };

  return (
    <>
        <PageController targetPage={0} currentPage={page} CASE="authen with admin code">
            <AuthCodeHelper title="Admin Authentication" btnName="Confirm" permission={STAFF_PERMISSION.VIEW_GOOGLE_AUTH} callback={handleAuthCode}/>
        </PageController>

        <PageController targetPage={1} currentPage={page} CASE="">
            <GoogelAuthTemplate googleAuthDetails={googleAuthDetails} />
        </PageController>
    </>
  );
}
