import AccountDetails from 'components/Page/account_details';
import OfferDetails from './offer_details';
import ReportPopup from './report';

function CustomPopup({ name, id, api }) {
    if (name === "OfferDetailsPopup") {
        return <OfferDetails id={id} />;
    } else if (name === "Report") {
        return <ReportPopup id={id} />;
    } else if (name === "AccountDetailsPopup") {
        return <AccountDetails id={id} />;
    } else return <></>
}

export default CustomPopup;