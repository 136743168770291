import { SuiButton } from "components/Default";
import { CachedOutlined } from "@mui/icons-material";

const RefreshButton = props => {
  return (
    <SuiButton
      style={{ marginRight: "1rem" }}
      buttonColor="secondary"
      onClick={() => props.onClick()}
    >
      <CachedOutlined />
      Refresh
    </SuiButton>
  );
};

export default RefreshButton;
