/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React components
import { Card, Chip, Pagination } from '@mui/material';
import Popup from "assets/theme/components/popup";
import { DataTable, Loader } from "components/Custom";
import "components/Custom/common.css";
import table_columns_size from 'components/Custom/dataTable/column_size';
import { SuiBox } from "components/Default";
import DashboardLayout from "components/examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/examples/Navbars/DashboardNavbar";
import AdvertisementGateway from "entities/advertisementGateway";
import { useEffect, useState } from "react";
import { useAlert } from 'react-alert';
import { Link } from 'react-router-dom';
import { NullFormatUtils, NumberFormatUtils } from "util/index";
import LabelFilterPanel from "util/LabelFilterPanel";
import status from "util/LabelFilterPanel/base";
import { handleFilterByKeyword } from "util/SearchFunction";
import CustomPopup from "./components";

let advGateway = new AdvertisementGateway();

function Adv1() {
  const alert = useAlert();

  const [page, setPage] = useState(0);  //pop up pages
  const [loading, setLoading] = useState(true);

  const [openPopUp, setOpenPopup] = useState(false);
  const [popUpName, setPopUpName] = useState("");
  const [currentAdv, setCurrentAdv] = useState();

  const [rows, setRows] = useState([]); // data-table
  const [filterRows, setFilterRows] = useState([]);
  const [currentFilter, setCurrentFilter] = useState(0); //status value
  const [allStatusWithCount, setAllStatusWithCount] = useState([]);
  const [statusStatement, setStatusStatement] = useState(null);

  const [tbPage, setTbPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const handlePopUp = (pName, id) => {
    setPopUpName(pName);
    setOpenPopup(true);
    setPage(1);
    if (id) setCurrentAdv(id);
  };

  const handlePageChange = (event, value) => {
    console.log(value);
    if (value <= totalPage) {
      setTbPage(value);
      fetchAllData(value);
    }
  };

  const fetchAllData = async (page, _statusStatement) => {
    if (_statusStatement) {
      setStatusStatement(_statusStatement);
    }
    setLoading(true);

    const data_length = await advGateway.getAllData({
      position: "A1",
      page: page ?? tbPage,
      statusStatement: _statusStatement ?? (statusStatement ?? null)
    })
      .then((res) => {
        if (res) {
          if (page === 0) {
            return res.total;
          }
          setRows(res.data);
          setFilterRows(res.data);
          setLoading(false);
          setTotalPage(res.last_page);
        }

      }).catch((e) => {
        console.log(e);
      });

    if (page === 0) return data_length
  };

  const fetchStatusCount = () => {
    let allStatus = [];
    status?.adv?.map((status, key) => {
      let _status = status;
      fetchAllData(0, `${status?.param?.name}=${status?.param?.value}`)
        .then((res) => {
          _status.count = res;
        })
      allStatus.push(_status);
    })
    setStatusStatement(null);
    setAllStatusWithCount(allStatus);
  }

  useEffect(() => {
    if (rows.length < 1) {
      fetchAllData();
      fetchStatusCount();
    }
  }, [rows]);

  const dt_columns = [
    {
      headerName: 'ADsID',
      field: 'id',
      width: table_columns_size.m,
      renderCell: ({ row }) => row.id ? <Link to="#" className="text-secondary" onClick={() => handlePopUp("AdvsDetailsPopup", row.id)}>{row.id}</Link> : "-",
      sortable: true,
    },
    {
      headerName: 'Title',
      field: 'Title',
      width: table_columns_size.l,
      renderCell: ({ row }) => NullFormatUtils(row.title),
      sortable: true,
    },
    {
      headerName: 'UserID',
      field: 'account_id',
      width: table_columns_size.m,
      renderCell: ({ row }) =>
        row.account_id
          ? <Link to="#" className="text-secondary" onClick={() => handlePopUp("AccountDetailsPopup", row.account_id)}>{row.account_id}</Link> : "-",
      sortable: true,
    },
    {
      headerName: 'UserName',
      field: 'username',
      width: table_columns_size.m,
      renderCell: ({ row }) => NullFormatUtils(row.username),
      sortable: true,
    },
    {
      headerName: 'Position',
      field: 'position',
      width: table_columns_size.m,
      renderCell: ({ row }) => NullFormatUtils(row.position),
      sortable: true,
    },
    {
      headerName: 'Cost (USDT)',
      field: 'cost',
      width: table_columns_size.m,
      renderCell: ({ row }) => '$' + NumberFormatUtils(row.cost, 8),
      sortable: true,
    },
    {
      headerName: 'Days',
      field: 'days',
      width: table_columns_size.m,
      renderCell: ({ row }) => NullFormatUtils(row.days),
      sortable: true,
    },
    {
      headerName: 'ReleaseDate',
      field: 'releaseDate',
      width: table_columns_size.m,
      renderCell: ({ row }) => NullFormatUtils(row.releaseDate),
      sortable: true,
    },
    {
      headerName: 'EndDate',
      field: 'endDate',
      width: table_columns_size.m,
      renderCell: ({ row }) => NullFormatUtils(row.endDate),
      sortable: true,
    },
    {
      headerName: 'Views',
      field: 'views',
      width: table_columns_size.m,
      renderCell: ({ row }) => !isNaN(row.views) ? row.views : NullFormatUtils(row.views),
      sortable: true,
    },
    {
      headerName: 'Status',
      field: 'status',
      width: table_columns_size.m,
      renderCell: ({ row }) =>
        <Chip label={NullFormatUtils(row.status)} className={handleFilterByKeyword(status.adv, "label", row.status)} />,
      sortable: true,
    }
  ];

  const handleChangeRows = async (statusParam, statusValue) => {
    setCurrentFilter(statusValue);
    fetchAllData(1, `${statusParam}=${statusValue}`)
  };

  const handleClearAndRefresh = () => {
    setCurrentFilter(0);
    setStatusStatement(null);
    setRows([]);
  };

  return (
    <div>
      {
        page > 0 ?
          <Popup
            openPopup={openPopUp}
            setOpenPopup={() => { setOpenPopup(false) }}
          >
            <CustomPopup name={popUpName} id={currentAdv} action={1} />
          </Popup> : <></>
      }
      <DashboardLayout>
        <DashboardNavbar />

        <Loader loading={loading}>
            <SuiBox py={3} >
              <SuiBox mb={3} >
                <LabelFilterPanel
                  currentFilter={currentFilter}
                  handleClearAndRefresh={handleClearAndRefresh}
                  fetchAllData={fetchAllData}
                  allStatus={allStatusWithCount}
                  handleChangeRows={handleChangeRows}
                />
                <Card>
                  <DataTable dt_columns={dt_columns} dt_rows={filterRows} />
                  <Pagination color={"secondary"} count={totalPage} page={tbPage} onChange={handlePageChange} />
                </Card>
              </SuiBox>
            </SuiBox>
        </Loader>

        
      </DashboardLayout>
    </div>
  );
}

export default Adv1;
