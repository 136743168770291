import '../styles.css';

import { useState, useEffect } from "react";

import SuiButton from "components/Default/SuiButton";
import InputWrapper from 'components/Custom/input_wrapper';
import CommentWrapper from 'components/Custom/comment_wrapper';

import ImageViewer from "components/Custom/image_viewer";
import { ImageURLExistHelper } from 'util/index';
import { NullFormatUtils } from "util/index";
import { useAlert } from 'react-alert';
import OrderApi from 'api/order';

const fakeImg = "https://upload.wikimedia.org/wikipedia/commons/thumb/7/70/Checkerboard_pattern.svg/1200px-Checkerboard_pattern.svg.png";

export default function RefundPopup ({ id }) {
    const alert = useAlert();

    //console.log(id);

    const [orderData, setOrderData] = useState([]); 
    const [err, setError] = useState();

    const StatusButtonComponents = ({ status, id }) => {
      /* 退款處理中
      退款失敗
      退款成功
      客服處理中
      人工退款失敗
      人工退款成功 */
  
      if(status === "客服處理中"){
        return (
          <div className="popup-form-row">
              <SuiButton buttonColor="secondary" onClick={() => ArtificalRefund(id, "人工退款成功")}>Artifical Refund</SuiButton>
              <SuiButton buttonColor="primary"  onClick={() => ArtificalRefund(id, "人工退款失敗")}>Reject</SuiButton>
          </div>
        );
      } 
  
      return <></>
    }

    async function ArtificalRefund(id, newStatus) {
      if(newStatus === "人工退款失敗"){
        await OrderApi.rejectRefund(id)
          .then((res) => {
            if(res && res.status === 200){
              alert.success("Edit data Successful");
            }
          })
          .catch((err) => {
            alert.error(err.message);
          });
      } 
      else if(newStatus === "人工退款成功"){
        await OrderApi.approveRefund(id)
          .then((res) => {
            if(res && res.status === 200){
              alert.success("Edit data Successful");
            }
          })
          .catch((err) => {
            alert.error(err.message);
          });
      }
      else {
        throw "new status not found."
      }
    }

    const fetchOneOrderData = async (id) => {
      await OrderApi.getOneOrderByFid(id)
        .then((res) => {
          if (res.status === 200){
            //console.log(res.data.data);
            setOrderData(res.data.data);
          }
        })
        .catch((err) => {
          alert.error(err.message);
        });
    };

    //fetch order
    useEffect(()=>{
        fetchOneOrderData(id);
    }, []) 

    return (
      <>
        <div id="popup1" className="popup1-wrapper">
            <div id="" className="popup1-flex-column">
                <h4>Refund</h4>
                <div className="popup-content">
                      <InputWrapper attr="Submit Date" value={NullFormatUtils(orderData.refund_submitted_at)} size="lg"/>
                      <InputWrapper attr="Price" value={NullFormatUtils(orderData.price)} size="sm"/>
                      <InputWrapper attr="Reason" value={NullFormatUtils(orderData.refund_reason)} size="sm"/>
                      <CommentWrapper attr="Remarks" value={NullFormatUtils(orderData.refund_message)} disable="true"/>
                      <ImageViewer headerText="Proof Images" clickText="Click to View" type={1} src={ImageURLExistHelper(orderData.refund_images)} title={true}/>
                      <InputWrapper attr="Status" value={NullFormatUtils(orderData.refund_status)} size="sm"/>
                      <InputWrapper attr="Handler" value={orderData.handler ? NullFormatUtils(orderData.handler.name) : "-"} size="sm"/>
                </div>
                
                <StatusButtonComponents 
                  status={orderData.refund_status ?? null}
                  id={orderData.short_id}/>
            </div>
        </div>
      </>
    );
}