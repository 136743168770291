import * as React from 'react';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import "./style.css";

export default function BasicDateTimePicker({ label, value, onChange }) {

  return (
    <div className="date-time-picker-content">
        <div className="popup-subtitle">
          {label}
        </div>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            renderInput={(props) => <TextField {...props} />}
            //label={label}
            inputFormat="yyyy-MM-dd hh:mm:ss a"
            value={value}
            onChange={onChange}
          />
        </LocalizationProvider>
    </div>
  );
}