import React, {useState, useRef, useMemo} from 'react';
import JoditEditor from "jodit-react";

export default function Editor ({ name, content, onChange }){
    //console.log(name);

	return (
        <>
            <JoditEditor
                name={name}
                value={content}
                tabIndex={1}
                onChange={onChange}
            />
        </>
        );
}