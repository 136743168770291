import Form from "template/base/form";
import FormContent from "template/base/formContent";
import Row from "template/base/row";
import Column from "template/base/column";
import ControlPanel from "template/base/controlPanel";

function IDVerificationTemplate(props) {
  const {
    Title,
    Name,
    IdNo,
    IdFrontImage,
    IdBackImage,
    Phone,
    Birthday,
    Gender,
    SubmitDate,
    SubTitle,
    Address,
    District,
    Status,
    RejectReason,
    RejectReasonSelector,
    Handler,
    ActionButton,
    AddrImage
  } = props;
  
  return (
    <Form>
      <Column>
        <Title />

        <FormContent>
          <Name />
          <IdNo />
          <IdFrontImage />
          <IdBackImage />
          <AddrImage/>
          <Row>
            <Phone />
            <Birthday />
          </Row>
          <Gender />
          <SubmitDate />

          <SubTitle />
          <Address />
          <District />
          <Status />
          <RejectReason />

          <ControlPanel>
            <RejectReasonSelector />
            <Handler />
            <ActionButton />
          </ControlPanel>
        </FormContent>
      </Column>
    </Form>
  );
}

export default IDVerificationTemplate;
