import { HTMLContentUtils } from "./HTMLContentUtils";

function handleFilterByKeyword(dataset, keyword, target){
  const filterSet = dataset.filter(d => d[keyword] === target);
  if(filterSet.length >= 1){
    return filterSet[0].class;
  }
  return "";
}

function handleHighlightKeywordInTable(
    SearchValue, 
    rowData
  ){
    if(SearchValue && rowData){
      //console.log(SearchValue);
      const new_rowData = rowData.replace(
          new RegExp(SearchValue, 'gi'),
          match => {
            if(match){
              return `<mark style="background: #8D8DAA; color: white;">${match}</mark>`;
            }
          }
      );
      return HTMLContentUtils(new_rowData);
    }
    return rowData;
  };

export { handleHighlightKeywordInTable, handleFilterByKeyword };