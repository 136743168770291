import { IconButton } from "@mui/material";
import { Clear as ClearIcon } from "@mui/icons-material";

const ClearAndRefreshButton = props => {
  return (
    <IconButton color="error" onClick={props.onClick}>
      <ClearIcon />
    </IconButton>
  );
};

export default ClearAndRefreshButton;
