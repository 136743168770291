import { Rating } from '@mui/material';
import '../styles.css';

import { useState, useEffect } from "react";

import SuiButton from "components/Default/SuiButton";
import InputWrapper from 'components/Custom/input_wrapper';
import CommentWrapper from 'components/Custom/comment_wrapper';
import { NullFormatUtils } from "util/index";
import { useAlert } from 'react-alert';

import OrderApi from 'api/order';

export default function ReviewPopup ({ id }) {
    const alert = useAlert();

    const [orderData, setOrderData] = useState([]); 
    const [err, setError] = useState();

    //console.log(id);

    const handleSubmit = async (id, status) => {
      try {
        //console.log(status);

        let response;
        if(status === "hidden"){
          response = await OrderApi.showOrder(id);
        } 
        else if(status === "active"){
          response = await OrderApi.hideOrder(id);
        }
        else{
          alert.error("status unexpected");
        }

        if(response.status === 200){
            alert.success("Edit Data Success");
        }

      } catch (err) {
        alert.error(err.message);
      }
    };

    //fetch order
    useEffect(()=>{
        const fetchOneOrderData = async (id) => {

          await OrderApi.getOneOrderByFid(id)
            .then((res) => {
              if(res.status === 200){
                //console.log(res.data.data.review);
                setOrderData(res.data.data.review);
              }   
            })
            .catch((err) => {
              console.log(err);
            });
          };

        fetchOneOrderData(id);
    }, []); 

    return (
        <div id="popup1" className="popup-wrapper">
            <div id="" className="popup1-flex-column">
                <h4>Review</h4>
                <>
                        <div className="popup-content">
                            <div className="popup-input-wrapper">
                                <div className="popup-subtitle">
                                    Score
                                </div>
                              <Rating value={orderData.rating ?? 0}/>
                            </div>
                            <CommentWrapper attr="Comments" value={NullFormatUtils(orderData.comment)} disable={true}/>
                            <InputWrapper attr="Handler" value={NullFormatUtils(orderData.Handler)} size="sm"/>
                        </div>
                        <div className="popup-form-row">
                          <SuiButton buttonColor="secondary" onClick={() => handleSubmit(orderData.id, orderData.status)}>{orderData.status === "active" ? "Hidden" : "On"}</SuiButton>
                        </div>
                      </>
            </div>
        </div>
    );
}