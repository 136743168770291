import axios from "./index";

class OfferApi {
  static mostLikes = data => {
    return axios.get(`likes/offer_with_most_likes`, {
      params: data
    });
  };
}

let base = "offer";

export default OfferApi;
