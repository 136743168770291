import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Box } from '@mui/system';
import UserApi from 'api/user';
import { Loader } from 'components/Custom';
import 'components/Custom/common.css';
import { FieldsNotFoundError, PageController, StatusContainer } from "components/Custom/custom_container";
import EditSuccessfulMsg from 'components/Custom/edit_successful';
import ExpandMore from 'components/Custom/expand_more';
import ImageViewer from "components/Custom/image_viewer";
import InputWrapper from 'components/Custom/input_wrapper';
import SuiButton from 'components/Default/SuiButton';
import { constant } from 'config/constant';
import { USER_PERMISSION } from 'config/permission';
import CustomerGateway from 'entities/customerGateway';
import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import AccountDetailsTemplate from 'template/accountDetailsTemplate';
import AuthCodeHelper from "util/AuthCodeHelper";
import EmailAuthHelper from 'util/EmailAuthHelper';
import { ERROR } from "util/ErrorHelper";
import HKIDAuthenticationHelper from 'util/HKIDAuthenticationHelper';
import { DateFormatUtils, ImageURLExistHelper, NullFormatUtils } from "util/index";
import SecurityEditPopup from './security_edit_popup';
import './styles.css';

let customerGateway = new CustomerGateway();

function ConfirmAction({ action, actionReturn, actionName }) {
    return (
        <div id="popup1" className="popup-wrapper">
            Confirm to {actionName} this account?
            <div onClick={action}>
                <SuiButton buttonColor="error">Yes</SuiButton>
            </div>
            <div onClick={actionReturn}>
                <SuiButton buttonColor="secondary">No</SuiButton>
            </div>
        </div>
    );
}

export default function AccountDetailsPopup({ id, mySqlId }) {
    const fields = [id, mySqlId];

    const alert = useAlert();
    const [page, setPage] = useState(0);
    const [error, setError] = useState(undefined);
    const [userData, setUserData] = useState([]);
    const [userHistoryData, setUserHistoryData] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const [code, setCode] = useState();
    const [loading, setLoading] = useState(true);
    const [action, setAction] = useState("Delete"); //"Delete" / "Unblock" / "Block" handler: handleSwitchPage 
    const [auth, setAuth] = useState(false);

    const testMode = constant.DEBUG;

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const fetchOneUserHistoryData = async (id) => {
        await customerGateway.getOneUserHistory(id)
            .then((res) => {
                if (res) {
                    setUserHistoryData(res.data);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const fetchOneUserData = async (id) => {
        await customerGateway.getOneUserData({
            type: "firebase_id",
            id: id
        })
            .then((res) => {
                if (res) {
                    console.log(res);
                    setUserData(res.data);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        if (userData.length < 1) {
            fetchOneUserData(id);
        }
        if (userHistoryData.length < 1) {
            fetchOneUserHistoryData(mySqlId);
        }
    }, [])

    /* 
        Components
     */

    const Title = () => <h4>Account Details</h4>
    const Icon = () => <ImageViewer headerText="Icon" clickText="Click to View" type={0} src={ImageURLExistHelper(userData?.userIcon)} title={true} />
    const HeaderImage = () => <ImageViewer headerText="Header Image" clickText="Click to View" type={0} src={ImageURLExistHelper(userData?.headerImage)} title={true} />
    const AccountID = () => <InputWrapper attr="Account ID" value={NullFormatUtils(userData?.account_id)} size="m" />
    const UserID = () => <InputWrapper attr="User ID" value={NullFormatUtils(userData?.id)} fullWidth={false} size="sm" />
    const Email = () => <InputWrapper attr="Email" value={NullFormatUtils(userData?.email)} size="sm" />
    const PrevEmail = () => {
        return (
            <StatusContainer status={true} param={expanded}>
                <Collapse in={true} timeout="auto" unmountOnExit className="previous__container">
                    <h5 className="previous">Email History</h5>
                    <List component="div" disablePadding>
                        {
                            userHistoryData?.email && userHistoryData?.email.length >= 1 ?
                                userHistoryData?.email.map((prev) =>
                                    <ListItemButton>
                                        <Grid container>
                                            <Grid item xs={5} sm={5} md={5} xl={5} alignItems="center">
                                                <ListItemText className="previous--lightGrey" primary={prev?.from} />
                                            </Grid>
                                            <Grid item xs={1} sm={1} md={1} xl={1} alignItems="center">
                                                <ListItemText className="previous--lightGrey" primary=">>>" />
                                            </Grid>
                                            <Grid item xs={5} sm={5} md={5} xl={5} alignItems="center">
                                                <ListItemText className="previous--lightGrey" primary={prev?.to} />
                                            </Grid>
                                            <Grid item xs={3} sm={3} md={3} xl={3}>
                                                <ListItemText className="previous--lightGrey" primary={DateFormatUtils(prev?.updated_at, "YYYY-MM-DD HH:mm:ss")} />
                                            </Grid>
                                        </Grid>
                                    </ListItemButton>
                                )
                                : <></>
                        }
                    </List>
                </Collapse>
            </StatusContainer>
        )
    }
    const Phone = () => <InputWrapper attr="Phone" value={NullFormatUtils(userData?.phone)} size="sm" />
    const PrevPhone = () => {
        return (
            <StatusContainer status={true} param={expanded}>
                <Collapse in={true} timeout="auto" unmountOnExit className="previous__container">
                    <h5 className="previous">Phone History</h5>
                    <List component="div" disablePadding>
                        {
                            userHistoryData?.phone && userHistoryData?.phone.length >= 1 ?
                                userHistoryData?.phone.map((prev, index) =>
                                    <ListItemButton key={index}>
                                        <Grid container>
                                            <Grid item xs={3} sm={3} md={3} xl={3} alignItems="center">
                                                <ListItemText className="previous--lightGrey" primary={prev?.from} />
                                            </Grid>
                                            <Grid item xs={1} sm={1} md={1} xl={1} alignItems="center">
                                                <ListItemText className="previous--lightGrey" primary=">>>" />
                                            </Grid>
                                            <Grid item xs={3} sm={3} md={3} xl={3} alignItems="center">
                                                <ListItemText className="previous--lightGrey" primary={prev?.to} />
                                            </Grid>
                                            <Grid item xs={5} sm={5} md={5} xl={5}>
                                                <ListItemText className="previous--lightGrey" primary={DateFormatUtils(prev?.updated_at, "YYYY-MM-DD HH:mm:ss")} />
                                            </Grid>
                                        </Grid>
                                    </ListItemButton>
                                )
                                : <></>
                        }
                    </List>
                </Collapse>
            </StatusContainer>
        )
    }
    const Gender = () => <InputWrapper attr="Gender" value={NullFormatUtils(userData?.idVerification?.gender)} size="sm" />
    const Birthday = () => <InputWrapper attr="Birthday" value={DateFormatUtils(userData?.idVerification?.birthday) ?? "-"} size="sm" />
    const Likes = () => <InputWrapper attr="Likes" value={userData?.likes} size="sm" />
    const Description = () => <InputWrapper attr="Description" value={NullFormatUtils(userData?.description)} size="lg" />

    const SubTitle = () => {
        return (
            <StatusContainer status={true} param={expanded}>
                <div className="popup-subtitle">Display Address</div>
            </StatusContainer>
        );
    }

    const Address = () => {
        return (
            <StatusContainer status={true} param={expanded}>
                <InputWrapper attr="Address" value={userData?.address} size="lg" />
            </StatusContainer>
        );
    }

    const District = () => {
        return (
            <StatusContainer status={true} param={expanded}>
                <InputWrapper attr="District" value={userData?.district} size="lg" />
            </StatusContainer>
        );
    }

    const Status = () => <InputWrapper attr="Account Status" value={userData?.accountStatus} size="lg" />
    const Title2 = () => <h4>Security</h4>
    const SubTitle1 = () => <InputWrapper attr="Biometric Authentication" value={userData?.isPermitBiometrics} size="xl" />

    const BiometricAuthEditButton = () => {
        return (
            <StatusContainer status={true} param={userData?.isPermitBiometrics}>
                <SuiButton buttonColor={"secondary"} onClick={() => { setPage(4) }}>Edit</SuiButton>
            </StatusContainer>
        );
    }

    const SubTitle2 = () => <InputWrapper attr="SMS Code" value={userData?.isPermitSms ? "True" : "False"} size="xl" />

    const SMSCodeEditButton = () => {
        const fields = [userData?.smsphone]
        return (
            <StatusContainer status={true} param={userData?.isPermitSms}>
                <FieldsNotFoundError data={fields}>
                    <SuiButton buttonColor={"secondary"} onClick={() => { setPage(4) }}>Edit</SuiButton>
                </FieldsNotFoundError>
            </StatusContainer>
        );
    }

    const SMSPhone = () => {
        const fields = [userData?.smsphone]
        return (
            <FieldsNotFoundError data={fields}>
                <InputWrapper value={userData?.smsphone} size="xl" attr="Verified Phone No" />
            </FieldsNotFoundError>
        );
    }

    const SubTitle3 = () => <InputWrapper attr="Google Authenticator" value={userData?.isPermitGoogleAuth ? "True" : "False"} size="xl" />

    const GoogleAuthEditButton = () => {
        return (
            <StatusContainer status={true} param={userData?.isPermitGoogleAuth}>
                <SuiButton buttonColor={"secondary"} onClick={() => { setPage(4) }}>Edit</SuiButton>
            </StatusContainer>
        );
    }

    const EmailVerify = () => <InputWrapper attr="Email Verification" value={"True" /*default true*/} size="xl" />

    const hiddenChipLabel = ["DisplayAddress", "PreviousEmail", "PreviousPhone"];
    const ExpandMoreController = () => {
        return (
            <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                hiddenChipLabel={hiddenChipLabel}
                aria-expanded={expanded}
                aria-label="show more"
            >
                <ExpandMoreIcon />
            </ExpandMore>
        );
    }

    const ActionButton = () => {
        return (
            <>
                <StatusContainer status="Active" param={userData?.accountStatus}>
                    <Box display="flex" flexDirection="row" columnGap={2} py={2} className="button-end">
                        <div id="delete-button" className="button-end" onClick={() => { setAction("Delete"); setPage(6) }}>
                            <SuiButton variant="outlined" buttonColor={"error"} >Delete Account</SuiButton>
                        </div>
                        <div id="delete-button" className="button-end" onClick={() => { setAction("Block"), setPage(6) }}>
                            <SuiButton buttonColor={"error"} >Block</SuiButton>
                        </div>
                    </Box>
                </StatusContainer>

                <StatusContainer status="Blocked" param={userData?.accountStatus}>
                    <Box display="flex" flexDirection="row" columnGap={2} py={2}>
                        <div id="delete-button" className="button-end" onClick={() => { setAction("Unblock"), setPage(6) }}>
                            <SuiButton buttonColor={"secondary"} >Unblock</SuiButton>
                        </div>
                        <div id="delete-button" className="button-end" onClick={() => { setAction("Delete"), setPage(6) }}>
                            <SuiButton buttonColor={"error"} >Delete Account</SuiButton>
                        </div>
                    </Box>
                </StatusContainer>
            </>
        );
    }

    const userDetails = {
        Title,
        Icon,
        HeaderImage,
        AccountID,
        UserID,
        Email,
        Phone,
        Gender,
        Birthday,
        Likes,
        Description,
        SubTitle,
        Address,
        District,
        Status,
        Title2,
        SubTitle1,
        BiometricAuthEditButton,
        SubTitle2,
        SMSCodeEditButton,
        SMSPhone,
        SubTitle3,
        GoogleAuthEditButton,
        EmailVerify,
        ExpandMoreController,
        ActionButton,
        PrevEmail,
        PrevPhone
    }

    const handleSwitchPage = () => {

        if (action === "Delete") {
            /* process */
            // (1.1) AccountDetailsTemplate 
            // (1.2) AuthCodeHelper
            // (1.3) ConfirmDelete
            // (1.4) Back to AccountDetailsTemplate
            if (page === 0) setPage(6);
            else if (page === 6) setPage(2);
            else if (page === 2) setPage(0);
        }
        else if (action === "Unblock") {
            /* process */
            // (1.1) AccountDetailsTemplate
            // (1.2) HKIDAuthenticationHelper --> UnBlock
            if (page === 0) setPage(1);
            // (2.1) AuthCodeHelper
            // (2.2) ConfirmUnBlock
            // (2.3) AccountDetailsTemplate
            else if (page === 6) setPage(7);
            else if (page === 7) setPage(0);
        } else if (action === "Block") {
            /* process */
            // (1.1) AccountDetailsTemplate
            // (1.2) HKIDAuthenticationHelper --> Block
            if (page === 0) setPage(1);
            // (2.1) AuthCodeHelper
            // (2.2) ConfirmBlock
            // (2.3) AccountDetailsTemplate
            else if (page === 6) setPage(8);
            else if (page === 8) setPage(0);
        }
    }

    const handleDelete = async () => {
        //delete record api
        if (userData.IdVerifyStatus !== "None") {
            if (page === 1) {
                setPage(2);
            }
        }

        if (page === 2) {
            //delete action
            await UserApi.deleteAccount({ "id": id })
                .then((res) => {
                    if (res.status === 200) {
                        setPage(3);
                    }
                })
                .catch((err) => {
                    console.log(err.message)
                });
        }
    }

    const handleBlock = async () => {
        if (id) {
            await UserApi.blockAccount({ id: id })
                .then((res) => {
                    if (res.status === 200) {
                        handleSwitchPage();
                    }
                })
                .catch((err) => {
                    setError(err)
                })
        }
    }

    const handleUnblock = () => {
        if (id) {
            UserApi.unBlockAccount({ id: id })
                .then((res) => {
                    if (res.status === 200) {
                        handleSwitchPage();
                    }
                })
                .catch((err) => {
                    setError(err)
                })
        }
    }

    const handleEmailAuth = (e) => {
        console.log(e)
        if (e) {
            setPage(5);
            setCode(e);
        } else setPage(0)
    }

    const handleAuthCode = (resp) => {
        // console.log(resp);

        if (resp) {
            if (resp.isAuth) {
                setAuth(true);
                handleSwitchPage();
            } else {
                alert.error(resp.message);
            }
        }
        else {
            alert.error(ERROR.callback);
        }
    }
    const handleIdAuth = (r) => { if (r === true) handleDelete(); }
    const handleSecurityEdit = (e) => { if (e) setPage(3) }

    return (
        <FieldsNotFoundError data={fields}>
            <Loader loading={loading}>

                <PageController targetPage={0} currentPage={page} CASE="display account details">
                    <AccountDetailsTemplate userDetails={userDetails} />
                </PageController>

                <PageController targetPage={5} currentPage={page} CASE="edit security">
                    <SecurityEditPopup userData={userData} action={handleSecurityEdit} code={code} id={id} />
                </PageController>

                <PageController targetPage={1} currentPage={page} CASE="authen with hkid (deprecated)">
                    <HKIDAuthenticationHelper id={id} getAuth={handleIdAuth} />
                </PageController>

                <PageController targetPage={4} currentPage={page} CASE="authen with email (send email to user)">
                    <EmailAuthHelper action={handleEmailAuth} id={id} />
                </PageController>

                <PageController targetPage={6} currentPage={page} CASE="authen with admin code">
                    <AuthCodeHelper title="Admin Authentication" btnName="Confirm" permission={USER_PERMISSION.DELETE_ACC} callback={handleAuthCode} />
                </PageController>

                <PageController targetPage={3} currentPage={page} CASE="display edit success message">
                    <EditSuccessfulMsg action={handleSwitchPage} />
                </PageController>

                <PageController targetPage={2} currentPage={page} CASE="confirm to delete">
                    <ConfirmAction action={handleDelete} actionReturn={handleSwitchPage} actionName={action.toLocaleLowerCase()} err={error} />
                </PageController>

                <PageController targetPage={7} currentPage={page} CASE="confirm to unblock account">
                    <ConfirmAction action={handleUnblock} actionReturn={handleSwitchPage} actionName={action.toLocaleLowerCase()} err={error} />
                </PageController>

                <PageController targetPage={8} currentPage={page} CASE="confirm to block account">
                    <ConfirmAction action={handleBlock} actionReturn={handleSwitchPage} actionName={action.toLocaleLowerCase()} err={error} />
                </PageController>

            </Loader>
        </FieldsNotFoundError>
    )
}