import Form from "./base/form";
import Column from "./base/column";
import FormContent from "./base/formContent";

export default function GoogelAuthTemplate({ googleAuthDetails }) {
  const { Title, QrCode, GoogleAuthCode } = googleAuthDetails;

  return (
    <Form>
      <Column>
        <Title />
        <FormContent>
          <QrCode />
          <Column>
            <h2>安全驗證密鑰</h2>
            <h5>請將16位密鑰記錄在紙上，</h5>
            <h5>並保存在安全的地方。</h5>
            <h5>如遇到手機丟失，</h5>
            <h5>你可以通過該密鑰恢復你的谷歌驗證。</h5>
          </Column>
          <GoogleAuthCode />
        </FormContent>
      </Column>
    </Form>
  );
}
