const ERROR = {
    CALLBACK: "Callback resp not found",
    NETWORK: "Cannot connect to API",
    POPUP_01: "Popup fields missing"
};

function NetworkErrorHelper({ status }){
    switch (status) {
        case "":
            return ERROR.NETWORK;
        
        default:
            break;
    }
}

export {
    ERROR,
    NetworkErrorHelper
};