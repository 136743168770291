// Soft UI Dashboard React components
import { Pagination } from "@mui/material";
import Card from "@mui/material/Card";
import Popup from "assets/theme/components/popup";
import { DataTable, Loader } from "components/Custom";
import { SuiBox, SuiButton } from "components/Default";
import Footer from "components/examples/Footer";
import DashboardLayout from "components/examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/examples/Navbars/DashboardNavbar";
import WalletTransferGateway from "entities/walletTransferGateway";
import moment from "moment";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Link } from 'react-router-dom';
import CustomPopup from "./components/custom_pop_up";

let walletTransferGateway = new WalletTransferGateway();

export default function WalletTransfer() {
  const alert = useAlert();
  const [tbPage, setTbPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const [rows, setRows] = useState([]);
  const [filterRows, setFilterRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(0);
  const [openPopUp, setOpenPopup] = useState(false);
  const [popUpName, setPopUpName] = useState();
  const [id, setId] = useState();
  const handlePopUp = (pName, id) => {
    setPopUpName(pName);
    setId(id);
    setOpenPopup(true);
    setPage(1);
  };

  const dt_columns = [
    {
      headerName: 'Transfer Record ID',
      field: 'id',
      renderCell: ({ row }) => `${row.id || "-"}`,
      sortable: true,
      width: 300
    },
    {
      headerName: 'Sender ID',
      field: 'senderId',
      renderCell: ({ row }) => row.senderId ? <Link to="#" className="text-secondary" onClick={() => { handlePopUp("AccountDetailsPopup", row.senderId) }}>{row.senderId}</Link> : "-",
      sortable: true,
      width: 150
    },
    {
      headerName: 'Receiver ID',
      field: 'receiverId',
      renderCell: ({ row }) => row.receiverId ? <Link to="#" className="text-secondary" onClick={() => { handlePopUp("AccountDetailsPopup", row.receiverId) }}>{row.receiverId}</Link> : "-",
      sortable: true,
      width: 150
    },
    {
      headerName: 'Create At',
      field: 'createAt',
      renderCell: ({ row }) => `${moment(row.createAt).format("YYYY-MM-DD HH:mm") || "-"}`,
      sortable: true,
      width: 200
    },
    {
      headerName: 'Method',
      field: 'method',
      renderCell: ({ row }) => `${row.method || "-"}`,
      sortable: true,
      width: 100
    },
    {
      headerName: 'Crypto Type',
      field: 'cryptoType',
      renderCell: ({ row }) => `${row.cryptoType || "-"}`,
      sortable: true,
      width: 150
    },
    {
      headerName: 'Amount',
      field: 'amount',
      renderCell: ({ row }) => `${row.amount || "-"}`,
      sortable: true,
      width: 200
    },
    {
      headerName: 'Status',
      field: 'status',
      renderCell: ({ row }) => `${row.status || "-"}`,
      sortable: true,
      width: 150
    }
  ]

  const handlePageChange = (event, value) => {
    if (value <= totalPage) {
      setTbPage(value);
      fetchAllData(value);
    }
  };

  const fetchAllData = async (page) => {
    setLoading(true);

    await walletTransferGateway.getAllData({
      page: page ?? tbPage,
      statusStatement: null
    })
      .then((res) => {
        if (res) {
          setRows(res.data);
          setFilterRows(res.data);
          setTotalPage(res.last_page);
          setLoading(false);
        }
      })
      .catch((err) => {
        alert.error(err.message);
      })
  }

  useEffect(() => {
    if (rows.length < 1) {
      fetchAllData();
    }
  }, []);

  return (
    <div>
      {
        page > 0 ?
          <Popup
            openPopup={openPopUp}
            setOpenPopup={() => { setOpenPopup(false) }}
          >
            <CustomPopup name={popUpName} id={id}></CustomPopup>
          </Popup> : <></>
      }
      <DashboardLayout>
        <DashboardNavbar />

        <Loader loading={loading}>
          <SuiButton
            buttonColor="secondary"
            onClick={() => { fetchAllData() }}>
            Refresh
          </SuiButton>

          <SuiBox py={3}>
            <SuiBox mb={3}>
              <Card>
                <DataTable dt_columns={dt_columns} dt_rows={rows} />
                <Pagination color={"secondary"} count={totalPage} page={tbPage} onChange={handlePageChange} />
              </Card>
            </SuiBox>
          </SuiBox>
        </Loader >

        
      </DashboardLayout>
    </div >
  );
}