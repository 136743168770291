import { Grid } from "@mui/material";

function Column({ xs, sm, md, xl, children }){
    if(xs && sm && md && xl){
        return (
        <Grid item xs={xs} sm={sm} md={md} xl={xl} className="popup-content">
            { children }
        </Grid>
        );
    }
    return (
        <Grid item xs className="popup-content">
            { children }
        </Grid>
    );
}

export default Column;