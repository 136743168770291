import Column from "template/base/column";
import ControlPanel from "template/base/controlPanel";
import Form from "template/base/form";
import FormContent from "template/base/formContent";
import Row from "template/base/row";

function ShopVerificationTemplate(props) {
  const {
    Title,
    CompanyName,
    SubmitDate,
    BRNo,
    DateOfExpiry,
    BRImages,
    ContactNo,
    RejectReason,
    SubTitle,
    Address,
    District,
    Status,
    ShopQRImage,
    RejectReasonSelector,
    Handler,
    ActionButton,
    AddrImage
  } = props;
  
  return (
    <Form>
      <Column>
        <Title />
        <FormContent>
          <CompanyName />
          <SubmitDate />
          <Row>
            <BRNo />
            <DateOfExpiry />
          </Row>
          <BRImages />
          <AddrImage/>
          <ContactNo />
          <RejectReason />

          <SubTitle />
          <Address />
          <District />
          <Status />

          <ShopQRImage/>

          <ControlPanel>
            <RejectReasonSelector />
            <Handler />
            <ActionButton />
          </ControlPanel>
        </FormContent>
      </Column>
    </Form>
  );
}

export default ShopVerificationTemplate;
