import AccountDetails from 'components/Page/account_details';
import AdvsDetails from 'components/Page/advs_details';

function CustomPopup({ name, id, action }) {
    if(name === "AccountDetailsPopup"){
        return <AccountDetails id={id}/>
    } else if(name === "AdvsDetailsPopup"){
        return <AdvsDetails id={id} action={action}/>;
    } else return <></>
}

export default CustomPopup;