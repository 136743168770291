import axios from "./index";

class OrderApi {
  static refundSum = data => {
    return axios.get(`${base}/refund`, {
      params: data
    });
  };

  static count = data => {
    return axios.get(`${base}/amount`, {
      params: data
    });
  };

  static cryptoCount = data => {
    return axios.get(`${base}/cur_type`, {
      params: data
    });
  };

  static commission = data => {
    return axios.get(`${base}/profit`, {
      params: data
    });
  };

  static priceAvg = data => {
    return axios.get(`${base}/type_average`, {
      params: data
    });
  };
}

let base = "orders";

export default OrderApi;
