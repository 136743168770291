import '../styles.css';

import { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";

import SuiButton from "components/Default/SuiButton";
import SuiBox from "components/Default/SuiBox";
import CommentWrapper from 'components/Custom/comment_wrapper';
import SwitchWrapper from 'components/Custom/switch_wrapper';
import BasicDateTimePicker from 'components/Custom/date_time_picker';
import AuthCodeHelper from "util/AuthCodeHelper";

import { GENERAL_PERMISSION } from 'config/permission';
import { useAlert } from 'react-alert';
import moment from "moment";

// Call API
import GeneralApi from "api/general";
import AuthApi from 'api/auth';

export default function CrisisMgtPopup () {
    const alert = useAlert();

    const [crisisData, setCrisisData] = useState([]); 
    const [auth, setAuth] = useState(false);
    const [adminCodeInput, setAdminCodeInput] = useState("");
    const [update, setUpdate] = useState(false);

    const [topUp, setTopUp] = useState(null);
    const [withdraw, setWithDraw] = useState(null);
    const [game45s, setGame45s] = useState(null);
    const [adv, setAdv] = useState(null);
    const [exchange, setExchange] = useState(null);
    const [savingplan, setSavingPlan] = useState(null);
    const [scaningpay, setScaningPay] = useState(null);
    const [scaningreceive, setScaningReceive] = useState(null);
    const [transfer, setTransfer] = useState(null);
    const [sysMainten, setSysMainten] = useState(null);
    const [content, setContent] = useState(null);
    const [startAt, setStartAt] = useState(null);
    const [endAt, setEndAt] = useState(null);

    let testMode = false;

    async function updateCrisisMgt({
      topup, 
      withdraw,
      game45s,
      adv,
      exchange,
      savingPlan,
      scaningpay,
      scaningreceive,
      transfer, 
      sysIsMaintenance, 
      sysMaintenContent, 
      sysMaintenStartAt, 
      sysMaintenEndAt
    }) {
      if(auth){
        const sysMainten = {
          "Content": sysMaintenContent,
          "IsMaintenance": sysIsMaintenance,
          "StartAt": moment(sysMaintenStartAt).format("YYYY-MM-DD HH:mm:ss"),
          "EndAt": moment(sysMaintenEndAt).format("YYYY-MM-DD HH:mm:ss")
        };

        await GeneralApi.editCrisisMgt({
          "SuspendTop-up": topup,
          "SuspendWithdrawal": withdraw,
          "Suspend45sChallenge": game45s,
          "SuspendAdvertisement": adv,
          "SuspendExchange": exchange,
          "SuspendSavingPlan": savingPlan,
          "SuspendScaningPay": scaningpay,
          "SuspendScaningReceive": scaningreceive,
          "SuspendTransfer": transfer,
          "SystemMaintenance": sysMainten
        })
          .then((res) => {
            if(res && res.status === 200){
              alert.success("Edit data Successful");
            }})
          .catch((err) => {
            alert.error(err.message);
          });
      } else{
        setUpdate(true);
      }
    }

    const fetchCrisisMgtData = async () => {
      await GeneralApi.list()
        .then((res) => {
          if(res && res.status === 200){
            setCrisisData(res.data.data.crisis_managements);
            //console.log(res.data.data.crisis_managements);
          }
        })
        .catch((err) => {
          console.log(err);
          alert.error(err.message);
        });
    };

      const handleAuthCode = (resp) => {
        // console.log(resp);

        if(resp){
            if(resp.isAuth){
                setAuth(true);
            } else{
                alert.error(resp.message);
            }
        }
        else{
            alert.error(ERROR.callback);
        }
    }
    
    useEffect(()=>{
        fetchCrisisMgtData();
    }, []);

    return (
        <>
          {
            !auth ?
            <AuthCodeHelper title="Admin Authentication" btnName="Confirm" permission={GENERAL_PERMISSION.EDIT} callback={handleAuthCode}/>
            :   
            <div id="popup1" className="popup-wrapper">
              <div id="" className="popup1-flex-column">
                  <h4>Crisis Management</h4>
                  <div className="popup-content">

                        <Grid container alignItems="center">
                          <Grid item xs={12} sm={4} md={4} xl={4}>
                            <SwitchWrapper attr="Suspend 45s Challenge" size="xl" checked={game45s ?? crisisData["Suspend45sChallenge"]} onChange={() => {setGame45s(game45s ? false : true)}}/>
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} xl={4}>
                            <SwitchWrapper attr="Suspend Advertisement" size="xl" checked={adv ?? crisisData["SuspendAdvertisement"]} onChange={() => {setAdv(adv ? false : true)}}/>
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} xl={4}>
                            <SwitchWrapper attr="Suspend Exchange" size="xl" checked={exchange ?? crisisData["SuspendExchange"]} onChange={() => {setExchange(exchange ? false : true)}}/>
                          </Grid>
                        </Grid>

                        <Grid container alignItems="center">
                          <Grid item xs={12} sm={4} md={4} xl={4}>
                            <SwitchWrapper attr="Suspend SavingPlan" size="xl" checked={savingplan ?? crisisData["SuspendSavingPlan"]} onChange={() => {setSavingPlan(savingplan ? false : true)}}/>
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} xl={4}>
                            <SwitchWrapper attr="Suspend ScaningPay" size="xl" checked={scaningpay ?? crisisData["SuspendScaningPay"]} onChange={() => {setScaningPay(scaningpay ? false : true)}}/>
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} xl={4}>
                            <SwitchWrapper attr="Suspend ScaningReceive" size="m" checked={scaningreceive ?? crisisData["SuspendScaningReceive"]} onChange={() => {setScaningReceive(scaningreceive ? false : true)}}/>
                          </Grid>
                        </Grid>

                        <Grid container alignItems="center">
                          <Grid item xs={12} sm={4} md={4} xl={4}>
                            <SwitchWrapper attr="Suspend Top-up" size="xl" checked={topUp ?? crisisData["SuspendTop-up"]} onChange={() => {setTopUp(topUp ? false : true)}}/>
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} xl={4}>
                            <SwitchWrapper attr="Suspend Withdrawal" size="xl" checked={withdraw ?? crisisData.SuspendWithdrawal} onChange={() => {setWithDraw(withdraw ? false : true)}}/> 
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} xl={4}>
                            <SwitchWrapper attr="Suspend Transfer" size="xl" checked={transfer ?? crisisData.SuspendTransfer} onChange={() => {setTransfer(transfer ? false : true)}}/> 
                          </Grid>
                        </Grid>
                        <SwitchWrapper attr="System Maintenance" size="xl" checked={sysMainten ?? crisisData.SystemMaintenance?.IsMaintenance} onChange={() => {setSysMainten(sysMainten ? false : true)}}/> 

                        <SuiBox xs={2} lg={3} xl={6} style={{ marginLeft: '2rem'}}>
                            <CommentWrapper attr="Content" rows="5" value={content ?? (crisisData.SystemMaintenance?.Content)} size="xl" style={{ margin: '0.5rem'}} onChange={(event) => {setContent(event.target.value)}} disable={false}/> 
                            <Grid container alignItems="center" style={{ margin: '0.5rem'}}>
                                <Grid item xs={12} sm={8} md={6} xl={3} className="text-sm">Start At</Grid>
                                <Grid item xs={12} sm={8} md={6} xl={9}>
                                    <BasicDateTimePicker value={startAt ?? (crisisData.SystemMaintenance?.StartAt)} onChange={(newValue) => { setStartAt(newValue) }}/>
                                </Grid>
                            </Grid>

                            <Grid container alignItems="center" style={{ margin: '0.5rem'}}>
                                <Grid item xs={12} sm={8} md={6} xl={3} className="text-sm">End At</Grid>
                                <Grid item xs={12} sm={8} md={6} xl={9}>
                                    <BasicDateTimePicker value={endAt ?? (crisisData.SystemMaintenance?.EndAt)} onChange={(newValue) => { setEndAt(newValue) }}/>
                                </Grid>
                            </Grid>
                        </SuiBox>
                  </div>

                  <div className="popup-form-row">
                      <SuiButton buttonColor="secondary" onClick={
                        () => 
                          updateCrisisMgt({
                            topup: topUp ?? crisisData["SuspendTop-up"], 
                            withdraw: withdraw ?? crisisData.SuspendWithdrawal, 
                            game45s: game45s ?? crisisData["Suspend45sChallenge"],
                            adv: adv ?? crisisData["SuspendAdvertisement"],
                            exchange: exchange ?? crisisData["SuspendExchange"],
                            savingPlan: savingplan ?? crisisData["SuspendSavingPlan"],
                            scaningpay: scaningpay ?? crisisData["SuspendScaningPay"],
                            scaningreceive: scaningreceive ?? crisisData["SuspendScaningReceive"],
                            transfer: transfer ?? crisisData.SuspendTransfer,
                            sysIsMaintenance: sysMainten ?? crisisData.SystemMaintenance?.IsMaintenance, 
                            sysMaintenContent: content ?? crisisData.SystemMaintenance?.Content,
                            sysMaintenStartAt: startAt ?? crisisData.SystemMaintenance?.StartAt,
                            sysMaintenEndAt: endAt ?? crisisData.SystemMaintenance?.EndAt })
                          }>
                        
                        Update</SuiButton>
                  </div>
                  
              </div>

          </div>
          }
        </>
    );
}