import { Grid } from "@material-ui/core";
import { InputLabel } from "@mui/material";
import UserApi from 'api/user';
import { Loader } from "components/Custom";
import {
    EditableContainer, FieldsNotFoundError, IsExist, PageController, StatusContainer
} from "components/Custom/custom_container";
import BasicSelect from 'components/Custom/custom_select';
import ImageViewer from "components/Custom/image_viewer";
import InputWrapper from 'components/Custom/input_wrapper';
import UploadImage from 'components/Custom/upload_image';
import { SuiBox } from "components/Default";
import SuiButton from "components/Default/SuiButton";
import { constant } from "config/constant";
import { USER_PERMISSION } from 'config/permission';
import CustomerGateway from 'entities/customerGateway';
import { useEffect, useState } from "react";
import { useAlert } from 'react-alert';
import IDVerificationTemplate from "template/idVerificationTemplate";
import AuthCodeHelper from "util/AuthCodeHelper";
import EmailAuthHelper from "util/EmailAuthHelper";
import { ERROR } from "util/ErrorHelper";
import HKIDAuthenticationHelper from "util/HKIDAuthenticationHelper";
import { DateFormatUtils, ImageURLExistHelper, NullFormatUtils } from 'util/index';
import Options from "./options";
import './styles.css';

let customerGateway = new CustomerGateway();

export default function IdVerifyPopups({ id }) {
    const fields = [id];
    const alert = useAlert();
    const [userData, setUserData] = useState([]);
    const [idVerifyData, setIdVerifyData] = useState([]);

    let testMode = constant.DEBUG;

    const [inputs, setInputs] = useState({});
    const handleChange = e => setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));

    const [auth, setAuth] = useState(false);
    const [edit, setEdit] = useState(false);
    const [page, setPage] = useState(0);
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [idDulplicateMsg, setIdDulplicateMsg] = useState("");

    const [rejectedReason, setRejectedReason] = useState();
    const RejectReasons = [
        {
            label: "身份證件無效",
            value: 1
        },
        {
            label: "身份證件模糊",
            value: 2
        },
        {
            label: "身份證資料與提交的資料不一致",
            value: 3
        },
        {
            label: "身份證件上載失敗",
            value: 4
        },
        {
            label: "身份證件有效期不足1個月",
            value: 5
        },
        {
            label: "該身份已經被註冊",
            value: 6
        },
        {
            label: "個人聯絡資料有誤",
            value: 7
        },
        {
            label: "賬戶持有人真實身份存疑",
            value: 8
        }
    ];

    function getRejectedReason() {
        console.log(RejectReasons.find((a) => a.value === rejectedReason)["label"]);

        let i;
        for (i = 0; i < RejectReasons.length; i++) {
            if (RejectReasons[i].value === rejectedReason) {
                return RejectReasons[i].label;
            }
        }
    }

    const handleEditSubmit = async () => {
        if (!edit) {
            //setPage(2);
            setEdit(true);
        } else {
            //else handle submit change
            const new_userData = Object.entries(userData).map(a => ({ [a[0]]: inputs[a[0]] || a[1] }));

            const request = {
                id: id,
                status: rejectedReason ? "Rejected" : "Approved",
                rejected_reason: rejectedReason ? getRejectedReason() : null,
                user: new_userData
            };

            await UserApi.editIdVerify(request)
                .then((res) => {
                    if (res.status === 200) {
                        console.log(res);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        return;
    };

    const handleStatusChange = async () => {

        await UserApi.editIdVerifyWithStatusAndReason({
            id: id,
            status: rejectedReason ? "Rejected" : "Approved",
            rejected_reason: rejectedReason ? getRejectedReason() : null
        }).then((res) => {
            console.log(res);
            alert.success("Verify Success");
        }).catch((e) => {
            console.log(e);
        });
    }

    const handleCheckDuplicate = () => {

        if (idVerifyData?.idNumber) {
            UserApi.hkidCheck({ hkid: idVerifyData?.idNumber })
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data.data.length > 1) {
                            let list = res.data.data.filter(function (value) {
                                if (value !== id) {
                                    return value
                                }
                            });

                            console.log(list)
                            setIdDulplicateMsg("The HKID has been registered by user with ID " + list.join(", "))
                        } else {
                            setIdDulplicateMsg("OK")
                        }
                    }
                })
        }
    }

    const handleImageChange = (imageList, addUpdateIndex) => {
        //console.log(imageList, addUpdateIndex);
        setImages(imageList);
    };

    /*
    const handleHKID = () => {
            setPage(1);
    }
    */

    const handleCode = () => {
        setPage(2);
    }

    const handleAuth = (e) => {
        if (e) {
            setPage(3);
        }
    }

    const handleSendEmail = () => {
        setPage(4);
    }

    const handleEmailAuth = (e) => {
        //if pass email auth
        if (e) {
            setPage(3);
            setCode(e);
        } else setPage(0)
    }

    const handleAuthCode = (resp) => {
        if (resp) {
            if (resp?.isAuth) {
                setAuth(true);
                setPage(3);
            } else {
                alert.error(resp?.message);
            }
        }
        else {
            alert.error(ERROR?.callback);
        }
    }

    const fetchOneUserData = async (id) => {
        await customerGateway.getOneUserData({
            type: "firebase_id",
            id: id,
            format: "IdVerify"
        })
            .then((res) => {
                if (res) {
                    setUserData(res.data);
                    setIdVerifyData(res.data.idVerification);

                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    /* 
        Components
    */
    const Title = () => {
        return (
            <h4>ID Verification</h4>
        );
    }

    const SubTitle = () => {
        return (
            <>Personal Address:</>
        );
    }

    const Name = () => {
        return (
            <>
                <EditableContainer editMode={true} currentEditMode={edit} desc="">
                    <InputWrapper attr="Name" name="name" value={inputs["name"] || userData?.username} onChange={handleChange} size="sm" disable={false} />
                </EditableContainer>

                <EditableContainer editMode={false} currentEditMode={edit} desc="">
                    <InputWrapper attr="Name" name="name" value={userData?.username} onChange={handleChange} size="sm" disable={true} />
                </EditableContainer>
            </>
        );
    }

    const DuplicationCheckComponent = () => {
        return (
            <StatusContainer status="Processing" param={userData?.idVerifyStatus}>
                <SuiButton size={"small"} sx={{ width: 200 }} onClick={handleCheckDuplicate} buttonColor={"secondary"}> Duplication Check </SuiButton>
                {idDulplicateMsg ?
                    <InputLabel error sx={{ fontSize: 11 }}>{idDulplicateMsg}</InputLabel>
                    : <></>
                }
            </StatusContainer>
        );
    }

    const IdNo = () => {
        return (
            <div className="popup1-flex-column">
                <InputWrapper attr="ID No." value={NullFormatUtils(idVerifyData?.idNumber)} onChange={handleChange} size="sm" />
                <DuplicationCheckComponent />
            </div>
        );
    }

    const IdFrontImage = () => {
        return (
            <>
                <EditableContainer editMode={true} currentEditMode={edit} desc="">
                    <div>
                        <div className="text-sm">Id Front Image</div>
                        <UploadImage images={images ?? ImageURLExistHelper(idVerifyData?.idFrontImage)} onChange={handleImageChange} />
                    </div>
                </EditableContainer>

                <EditableContainer editMode={false} currentEditMode={edit} desc="">
                    <ImageViewer headerText="ID Images" clickText="Click to View" type={2}
                        src={
                            [ImageURLExistHelper(idVerifyData?.idFrontImage), ImageURLExistHelper(idVerifyData?.idBackImage)]
                        } title={true} />
                </EditableContainer>
            </>
        );
    }

    const IdBackImage = () => {
        return (
            <>
                <EditableContainer editMode={true} currentEditMode={edit} desc="">
                    <div>
                        <div className="text-sm">Id Back Image</div>
                        <UploadImage images={images ?? ImageURLExistHelper(idVerifyData?.idBackImage)} onChange={handleImageChange} />
                    </div>
                </EditableContainer>

                {/* <EditableContainer editMode={false} currentEditMode={edit} desc="">
                </EditableContainer> */}
            </>
        );
    }

    const AddrImage = () => {
        return (
            <>
                <EditableContainer editMode={true} currentEditMode={edit} desc="">
                    <div>
                        <div className="text-sm">Address Image</div>
                        <UploadImage images={images ?? ImageURLExistHelper(idVerifyData?.addrImage)} onChange={handleImageChange} />
                    </div>
                </EditableContainer>

                <EditableContainer editMode={false} currentEditMode={edit} desc="">
                    <ImageViewer headerText="Address Image" clickText="Click to View" type={0}
                        src={ImageURLExistHelper(idVerifyData?.addrImage)} title={true} />
                </EditableContainer>
            </>
        )
    }

    const Phone = () => {
        return (
            <>
                <EditableContainer editMode={true} currentEditMode={edit} desc="">
                    <InputWrapper attr="Phone" name="phone" value={inputs["phone"] || NullFormatUtils(userData?.phone)} onChange={handleChange} size="sm" disable={false} />
                </EditableContainer>

                <EditableContainer editMode={false} currentEditMode={edit} desc="">
                    <InputWrapper attr="Phone" name="phone" value={NullFormatUtils(userData?.phone)} onChange={handleChange} size="sm" disable={true} />
                </EditableContainer>
            </>
        );
    }

    const Birthday = () => {
        return (
            <>
                <EditableContainer editMode={true} currentEditMode={edit} desc="">
                    <InputWrapper attr="Birthday" name="birthday" value={inputs["birthday"] || DateFormatUtils(idVerifyData?.birthday)} onChange={handleChange} size="sm" disable={false} />
                </EditableContainer>

                <EditableContainer editMode={false} currentEditMode={edit} desc="">
                    <InputWrapper attr="Birthday" name="birthday" value={DateFormatUtils(idVerifyData?.birthday)} onChange={handleChange} size="sm" disable={true} />
                </EditableContainer>
            </>
        );
    }

    const Gender = () => {
        return (
            <>
                <EditableContainer editMode={true} currentEditMode={edit} desc="">
                    <InputWrapper attr="Gender" name="gender" value={inputs["gender"] || NullFormatUtils(idVerifyData?.gender)} onChange={handleChange} size="sm" disable={false} />
                </EditableContainer>

                <EditableContainer editMode={false} currentEditMode={edit} desc="">
                    <InputWrapper attr="Gender" name="gender" value={NullFormatUtils(idVerifyData?.gender)} onChange={handleChange} size="sm" disable={true} />
                </EditableContainer>
            </>
        );
    }

    const SubmitDate = () => {
        return (
            <InputWrapper attr="Submit Date" value={DateFormatUtils(idVerifyData?.createAt)} onChange={handleChange} size="lg" />
        );
    }

    const Address = () => {
        return (
            <>
                <EditableContainer editMode={true} currentEditMode={edit} desc="">
                    <InputWrapper attr="Address" name="address" value={inputs["address"] || NullFormatUtils(idVerifyData?.address)} onChange={handleChange} size="lg" disable={false} />
                </EditableContainer>

                <EditableContainer editMode={false} currentEditMode={edit} desc="">
                    <InputWrapper attr="Address" name="address" value={NullFormatUtils(idVerifyData?.address)} onChange={handleChange} size="lg" disable={true} />
                </EditableContainer>
            </>
        );
    }

    const District = () => {
        return (
            <>
                <EditableContainer editMode={true} currentEditMode={edit} desc="">
                    <InputWrapper attr="District" name="district" value={inputs["district"] || NullFormatUtils(idVerifyData?.district)} onChange={handleChange} size="lg" disable={false} />
                </EditableContainer>

                <EditableContainer editMode={false} currentEditMode={edit} desc="">
                    <InputWrapper attr="District" name="district" value={NullFormatUtils(idVerifyData?.district)} onChange={handleChange} size="lg" disable={true} />
                </EditableContainer>
            </>
        );
    }

    const Status = () => {
        return (
            <InputWrapper attr="Status" value={NullFormatUtils(userData?.idVerifyStatus)} size="lg" />
        );
    }

    const RejectReason = () => {
        return (
            <StatusContainer status="Rejected" param={userData?.accountStatus}>
                <InputWrapper size="lg" attr="Rejected Reason" value={NullFormatUtils(idVerifyData?.rejectReason)} />
            </StatusContainer>
        );
    }

    const RejectReasonSelector = () => {
        return (
            <BasicSelect title="Reject Reason" items={RejectReasons} onChange={(e) => { setRejectedReason(e?.value); console.log(e) }}></BasicSelect>
        );
    }

    const Handler = () => {
        return (
            <InputWrapper attr="Handler" value={NullFormatUtils(idVerifyData?.handler_id)} onChange={handleChange} size="lg" />
        );
    }

    const ActionButton = () => {
        return (
            <>
                <EditableContainer editMode={true} currentEditMode={edit} desc="">
                    <Grid item className="popup-form-row">
                        <SuiBox mt={3}>
                            <SuiButton buttonColor="secondary" onClick={handleEditSubmit}>Save</SuiButton>
                            <SuiButton buttonColor="secondary" disabled={IsExist(rejectedReason)} onClick={handleStatusChange}>Approve</SuiButton>
                            <SuiButton disabled={IsExist(rejectedReason)} onClick={handleStatusChange}>Reject</SuiButton>
                        </SuiBox>
                    </Grid>
                </EditableContainer>

                <EditableContainer editMode={false} currentEditMode={edit} desc="">
                    <Grid item className="popup-form-row">
                        <SuiBox mt={3}>
                            <SuiButton buttonColor="secondary" onClick={handleEditSubmit}>Edit</SuiButton>
                            <SuiButton buttonColor="secondary" disabled={false} onClick={handleStatusChange}>Approve</SuiButton>
                            <SuiButton disabled={false} onClick={handleStatusChange}>Reject</SuiButton>
                        </SuiBox>
                    </Grid>
                </EditableContainer>
            </>
        );
    }

    useEffect(() => {
        if (userData.length <= 1) {
            fetchOneUserData(id);
        }
    }, [])

    return (
        <FieldsNotFoundError data={fields}>
            <Loader loading={loading}>

                <PageController targetPage={0} currentPage={page} CASE="Admin Options List for (1): send email or (2): code authentication">
                    <Options actionA={handleSendEmail} actionA_Name="Send Email" actionB={handleCode} actionB_Name="Code Authentication" />
                </PageController>

                <PageController targetPage={1} currentPage={page} CASE="">
                    <HKIDAuthenticationHelper id={id} getAuth={handleAuth} />
                </PageController>

                <PageController targetPage={2} currentPage={page} CASE="">
                    <AuthCodeHelper title="KYC Authentication" btnName="Confirm" permission={USER_PERMISSION.ID_VERIFY} callback={handleAuthCode} />
                </PageController>

                <PageController targetPage={3} currentPage={page} CASE="">
                    <IDVerificationTemplate
                        Title={Title}
                        Name={Name}
                        IdNo={IdNo}
                        IdFrontImage={IdFrontImage}
                        IdBackImage={IdBackImage}
                        AddrImage={AddrImage}
                        Phone={Phone}
                        Birthday={Birthday}
                        Gender={Gender}
                        SubmitDate={SubmitDate}
                        SubTitle={SubTitle}
                        Address={Address}
                        District={District}
                        Status={Status}
                        RejectReason={RejectReason}
                        RejectReasonSelector={RejectReasonSelector}
                        Handler={Handler}
                        ActionButton={ActionButton}
                    />
                </PageController>

                <PageController targetPage={4} currentPage={page} CASE="">
                    <EmailAuthHelper action={handleEmailAuth} id={id} />
                </PageController>

            </Loader>
        </FieldsNotFoundError>
    )
}