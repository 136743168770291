import { LargeAmountFormatUtils } from "util/TextFormatConverter";
import { chartType } from "./constant";
import { Line, Bar, Pie } from "react-chartjs-2";
import { mapToStyledLineChart, mapToPieChart, mapToBarChart } from "./style";
import { DateFormatUtils } from "util/TextFormatConverter";
import data from "layouts/dashboard/components/Projects/data";
import React from "react";

function GenerateLabel({ data, xKeyName }) {
  let xLabel = [];

  if(data && data.length >= 1){
    data.map((data) => {
      if(data[xKeyName]){
        xLabel.push(data[xKeyName]);
      }
    });

    xLabel = [...new Set(xLabel)].sort((a, b) => new Date(a) - new Date(b));
    //console.log(xLabel);
  }

  return xLabel;
}

function FormatLabel({ label }){
  let temp = [];
  label.map((data) => {
    temp.push(DateFormatUtils(data, "MMM DD"));
  })
  return temp;
}

function GenerateDataSet({
  data = [],
  xLabel = [], // from GenerateLabel()
  yKeyName, // e.g crypto type
  xKeyName, // e.g date
  valueKeyName,
  typeValues
}) {
  let dataset = [];

  const _typeValues = typeValues ?? yKeyName;
  _typeValues.map((typeValue, index) => {

      const typeValuesByLabel = data
        .filter(a => a[yKeyName] == typeValue || yKeyName.includes(typeValue))
        .filter(b => xLabel.includes(b[xKeyName]))
      //console.log(typeValuesByLabel);
  
      let temp = [];
      for (const key in xLabel) {
        const each = typeValuesByLabel.filter(c => c[xKeyName] === xLabel[key])[0]
          each  
          ? temp.push(LargeAmountFormatUtils(each[valueKeyName ?? typeValue]))
          : temp.push(0)
      }
  
      dataset.push({
        data: temp,
        label: typeValue
      });
    })

  //console.log(dataset);
  return dataset;
}

/* function GenerateDataSetV2({
  data = [],
  yKeyName,
  xKeyName,
  valueKeyName,
  typeValues
}) {
  let dataset = [];

  data.map(data => {
    dataset.push({
      x: data[xKeyName],
      y: data[valueKeyName]
    })
  })
  
  return dataset;
} */

function ChartMaker(props) {
  //console.log(props);
  const chartRef = React.createRef();
  
  if (props.type === chartType.Line) {
    const xLabel = GenerateLabel({ data: props.data, xKeyName: props.xKeyName });
    const dataset = GenerateDataSet({
      data: props.data,
      xLabel: xLabel, // from GenerateLabel()
      yKeyName: props.yKeyName,
      xKeyName: props.xKeyName,
      valueKeyName: props.valueKeyName,
      typeValues: props.typeValues
    })

    /* const dataset = GenerateDataSetV2({
      data: props.data,
      yKeyName: props.yKeyName,
      xKeyName: props.xKeyName,
      valueKeyName: props.valueKeyName,
      typeValues: props.typeValues
    }) */

    const chartData = mapToStyledLineChart({ 
      options: props.options,
      labels: xLabel,
      datasets: dataset
    })
    //console.log(chartData);
    //console.log(props.options);

    return <Line id={props.id} data={chartData} options={props.options} ref={chartRef}/>;
  } 
  else if (props.type === chartType.Bar) {
    let xLabel = [];
    if(!props.xLabel){
      xLabel = GenerateLabel({ data: props.data, xKeyName: props.xKeyName });
    }
    const dataset = GenerateDataSet({
      data: props.data,
      xLabel: props.xLabel ?? xLabel,
      yKeyName: props.yKeyName,
      xKeyName: props.xKeyName,
      valueKeyName: props.valueKeyName,
      typeValues: props.typeValues
    })

    const chartData = mapToBarChart({ 
      labels: props.xLabel ?? xLabel,
      datasets: dataset
    })

    return <Bar id={props.id} data={chartData} options={props.options} ref={chartRef}/>;
  }
  else if(props.type === chartType.Pie) {
    const labels = [];
    const datasets_data = [];
    
    if(props.typeKeyName && props.valueKeyName){
      props.data.map((data) => {
        labels.push(data[props.typeKeyName]);
        datasets_data.push(data[props.valueKeyName]);
      });
    } 
    else if(props.data){
      Object.entries(props.data[0]).map(([k,v]) => {
        labels.push(k);
        datasets_data.push(v);
      })
    }

    const allDataIsNull = datasets_data.filter((data) => data === 0 || null || undefined);
    if(allDataIsNull.length === datasets_data.length){
      return <>{"No data"}</>;
    }
    //console.log(labels);
    //console.log(datasets_data);
    const chartData = mapToPieChart({ 
      labels: labels, 
      data: datasets_data 
    })

    return <Pie id={props.id} data={chartData} options={props.options} ref={chartRef}/>;
  }

  return <></>
}

export default ChartMaker;
