// React components
import { useEffect, useState } from 'react';

//import custom components
import 'components/Custom/common.css';

import * as AcDetails from "./AcDetails";
import * as Security from "./Security";

import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useAlert } from 'react-alert';
import { constant } from 'config/constant';

// Call API
import UserApi from 'api/user';
import sample from './sample_data';

export default function AccountDetails({ id }) {
    //console.log(id);

    let testMode = constant.DEBUG;
    const [userData, setUserData] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const alert = useAlert();

    const ExpandMore = styled((props) => {
        const { expand, ...other } = props;
        return <IconButton {...other} />;
      })(({ theme, expand }) => ({
        transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        })
      }));

      const handleExpandClick = () => {
        setExpanded(!expanded);
      };

    //fetch users
    const fetchOneUserData = async (id) => {

        await UserApi.getOneUserByFid(id)
            .then((res) => {
                if(res && res.status === 200){
                    setUserData(res.data.data);
                } else if(testMode){
                    alert.error("Unexpected Error");
                    setUserData(sample);
                }
            })
            .catch((err) => {
                console.log(err);
                //  For test
                if(testMode){
                    setUserData(sample);
                }
            });
        
        if(userData.length < 1){
            await UserApi.getOneUserByAid(id)
                .then((res) => {
                    if(res.status === 200){
                        setUserData(res.data.data);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    alert.error(err.message);
                    //  For test
                    if(testMode){
                        setUserData(sample);
                    }
                });
        }
    };

    useEffect(() => {
        fetchOneUserData(id);
    }, [id]);

    return (
        <>
            <Card>
                <CardContent>
                    <div id="" className="popup-wrapper">
                        <div id="" className="popup1-flex-column">
                            <h4>Account Details</h4>
                            <div className="popup-content">

                                <AcDetails.Icon userData={userData}/>
                                <AcDetails.PersonalDetails id={id} userData={userData}/>
                                {
                                    expanded ?
                                        <AcDetails.DisplayAddress userData={userData}/>
                                        : <></>
                                }
                                <AcDetails.Status userData={userData}/>
                                
                            </div>
                        </div>

                        <div id="" className="security">
                            <h4>Security</h4>
                            <div className="popup-content">
                                
                                <Security.BiometricAuth userData={userData}/>
                                <Security.SMSCode userData={userData}/> 
                                <Security.SMSPhone userData={userData}/> 
                                <Security.GoogleAuth userData={userData}/> 
                                <Security.EmailVerify userData={userData}/>
                                
                            </div>
                        </div>
                    </div>
                </CardContent>
                <CardActions disableSpacing style={{ backgroundColor: '#F1F1F1' }}>
                    <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                        >
                        <ExpandMoreIcon />
                    </ExpandMore>
                </CardActions>
            </Card>
        </>
    )
}
