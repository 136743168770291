import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import UpdateIcon from '@mui/icons-material/Update';

/* const Action_Permission = {
    VIEW: false,
    ADD: false, // Create new record
    EDIT: false, // Modify the record by {id}
    DELETE: false, // Delete the record by {id}
    HIDE: false // Hide the record from app ui, keep in database
};

const showActions = ({ page }) => {
    //TODO - map to page actions

    //TODO - map to managers name

    return {
        page: page,
        action: Action_Permission,
        managers: "[page]_PERMISSION"
    };
};

const _add_buttons = ({ action, data, customStyle = null }) => {
    const [list, setList] = useState([]);
    const [action, setAction] = useState(false);

    const handleAction = (e) => {
        setAction(true);
        setList(data);
    }
    
    if(action.has_add_permission()){
        if(!customStyle){
            if(action){
                return (
                    <SuiButton buttonColor="secondary" onClick={(e) => handleAction(e)}>
                        <AddCircleIcon/>
                    </SuiButton>
                );
            } else {
                return (
                    <SuiButton buttonColor="secondary" onClick={(e) => handleAction(e)}>
                        <UpdateIcon/>
                    </SuiButton>
                );
            }
        }
        return {
            hasPermission: true,
            message: "Custom Add Button",
            components: customStyle,
            data: list
        };
    }
    return {
        hasPermission: false,
        message: "No permission",
        components: <></>,
        data: list
    };
}; */


function ActionButtons({ disable = false, updateItem, editItem, removeItem }){
    return (
        <>
            <ToggleButtonGroup
                color="primary"
                exclusive>
                            
                {
                    disable === false ?
                        <ToggleButton value="update" onClick={updateItem}>
                            <UpdateIcon></UpdateIcon>
                        </ToggleButton>
                    :   <ToggleButton value="edit" onClick={editItem}>
                            <EditIcon></EditIcon>
                        </ToggleButton>
                }   
                        <ToggleButton value="delete" onClick={removeItem}>
                            <DeleteIcon></DeleteIcon>
                        </ToggleButton>

            </ToggleButtonGroup>
        </>
    );
}

export default ActionButtons;