import axios from "./index";

class SavingPlanApi {
  static interest = data => {
    return axios.get(`${base}/interest`, {
      params: data
    });
  };

  static cointype = data => {
    return axios.get(`${base}/coin_type`, {
      params: data
    });
  };

  static pool = data => {
    return axios.get(`${base}/pool`, {
      params: data
    });
  };

  static unfreeze_amount = data => {
    return axios.get(`${base}/unfreeze_amount`, {
      params: data
    });
  };

  static cancel_radio = data => {
    return axios.get(`${base}/cancel_ratio`, {
      params: data
    });
  };
}

let base = "saving_plan";

export default SavingPlanApi;
