//commons
import 'components/Custom/common.css';
import InputWrapper from 'components/Custom/input_wrapper';

function BiometricAuth({ userData }) {
    return (
        <div className="popup-input-wrapper">
            <InputWrapper attr="Biometric Authentication"
                value={userData.Security && userData.Security.Biometrics ?
                    (userData.Security.Biometrics.IsBioAuth ? "True" : "False") : "False"} size="xl" />
        </div>
    );
}

function SMSCode({ userData }) {
    return (
        <div className="popup-input-wrapper">
            <InputWrapper attr="SMS Code" 
                value={ userData.Security &&  userData.Security.Sms? 
                    ( userData.Security.Sms.Phone ? "True": "False") : "False"} size="xl" />
        </div>
    );
}

function SMSPhone({ userData }){
    return (
        <>
            {
                userData.Security && userData.Security.Sms ?
                (
                    userData.Security.Sms.Phone !== null?
                        <InputWrapper value={userData.Security.Sms.Phone} size="xl" attr="Verified Phone No"/>
                    :<></>
                )
                :<></>
            }
        </>
    );
}

function GoogleAuth({ userData }){
    return (
        <div className="popup-input-wrapper">
            <InputWrapper attr="Google Authenticator"
                value={userData.Security && userData.Security.GoogleAuth ? (userData.Security.GoogleAuth.IsPermit ? "True" : "False") : "False"} size="xl" />
        </div>
    );
}

function EmailVerify({ userData }){
    return (
        <div className="popup-input-wrapper">
            <InputWrapper attr="Email Verification" value={"True" /*default true*/} size="xl" />
        </div>
    );
}
export {BiometricAuth, SMSCode, SMSPhone, GoogleAuth, EmailVerify};