import { Grid } from "@material-ui/core";
import '../styles.css';

import { useState, useEffect } from "react";

import { Row } from "react-bootstrap";

import SuiButton from "components/Default/SuiButton";
import SuiBox from "components/Default/SuiBox";

import * as Custom from 'components/Custom';
import Editor from 'components/Custom/editor';
/* import InputWrapper from 'components/Custom/input_wrapper';
import CommentWrapper from 'components/Custom/comment_wrapper'; */

import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import UpdateIcon from '@mui/icons-material/Update';
import DeleteIcon from '@mui/icons-material/Delete';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import AuthCodeHelper from "util/AuthCodeHelper";

import { GENERAL_PERMISSION } from 'config/permission';
import { useAlert } from 'react-alert';

// Call API
import GeneralApi from "api/general";
import AuthApi from "api/auth";

export default function FAQ () {
    const [page, setPage] = useState(0);
    const [editedValue, setEditValue] = useState([]);

    const handleBack = (...args) => {
        let arr = {
            Question: args[0],
            Answer: args[1],
            Question_ENG: args[2],
            Answer_ENG: args[3]
        };
        if(editedValue){
            //console.log(editedValue);
            //console.log(...editedValue, arr);
            setEditValue(editedValue => [...editedValue, arr]);
        } else{
            setEditValue(arr);
            //console.log(arr);
        }
        //console.log(page);
        setPage(0);
    }; 

    const handleEdit = () => {
        //console.log(page);
        setPage(1);
    };

    if (page === 0){
        return <FAQPopup
            actionEdit={handleEdit} editParmas={editedValue}/>
    } else if(page === 1){
        return <EditFAQ
            actionBack={handleBack}/>;
    }
}

async function editFAQ(inputs) {

    try {
        //console.log(inputs);
        let response = await GeneralApi.AddFAQ();
          if (response.data && response.data.success === false) {
          }
          let res = response.data;
            if(res.message == "success"){
            }

    } catch (err) {
      console.log(err);
    }
}

function FAQPopup ({ actionEdit, editParmas }) {
    const alert = useAlert();

    const [faqData, setFaqData] = useState([]); 
    const [inputs, setInputs] = useState({});
    const [auth, setAuth] = useState(false);   //auth
    const [disable, setDisable] = useState({});
    const [edit, setEdit] = useState(false);

    const handleChange = e => setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    const setContent = (name, value) => {
        setInputs(prevState => ({ ...prevState, [name]: value }));
    }

    const editItem = (key) => {
        setEdit(true);
        setDisable(prevState => ({ ...prevState, [key]: disable[key] ? false : true }));
    }

    const updateItem = async (fid, q, a, q_e, a_e) => {

        await GeneralApi.editFAQs(
            {
                Question: q,
                Answer: "<!DOCTYPE html> <html> <head> " + a + "</body> </html>",
                Question_ENG: q_e,
                Answer_ENG: "<!DOCTYPE html> <html> <head> " + a_e + "</body> </html>",
                fid: fid
            }
        )
        .then((res) => {
            if(res && res.status === 200){
                alert.success("Edit Data Success");
            }
        })
        .catch((err) => {
            alert.error(err.message);
        });
    }

    const addItem = async (fid, q, a, q_e, a_e) => {

        await GeneralApi.addFAQs(
            {
                Question: q,
                Answer: "<!DOCTYPE html> <html> <head> " + a + "</body> </html>",
                Question_ENG: q_e,
                Answer_ENG: "<!DOCTYPE html> <html> <head> " + a_e + "</body> </html>",
                fid: fid
            }
        )
        .then((res) => {
            if(res && res.status === 200){
                alert.success("Edit Data Success");
            }
        })
        .catch((err) => {
            alert.error(err.message);
        });
    }

    const isHTML = (str) => !(str || '')
        // replace html tag with content
        .replace(/<([^>]+?)([^>]*?)>(.*?)<\/\1>/ig, '')
        // remove remaining self closing tags
        .replace(/(<([^>]+)>)/ig, '')
        // remove extra space at start and end
        .trim();

      const fetchFAQData = async () => {

        await GeneralApi.list()
            .then((res) => {
                if(res && res.status === 200){
                    const faqData = res.data.data.faqs;

                    setFaqData(faqData);
                    //console.log(faqData);
                }
            })
            .catch((err) => {
                console.log(err);
            })
      };

      const handleAuthCode = (resp) => {
        // console.log(resp);

        if(resp){
            if(resp.isAuth){
                setAuth(true);
            } else{
                alert.error(resp.message);
            }
        }
        else{
            alert.error(ERROR.callback);
        }
    }

    useEffect(()=>{
        fetchFAQData();
    }, [])

    return (
        <>
            {
                (!auth && edit) ?
                <AuthCodeHelper title="Admin Authentication" btnName="Confirm" permission={GENERAL_PERMISSION.EDIT} callback={handleAuthCode}/>
                :   
                <div id="popup1" className="popup-wrapper">
                    <div id="" className="popup1-flex-column">
                        <h4>FAQ</h4>
                        <div className="popup-content">
                            {
                                faqData ? (faqData.map((data, index) => 
                                    <Row>
                                        <SuiBox mb={1}>
                                            <Grid container alignItems="center">
                                                <Grid item lg={2}>
                                                    {index + 1}
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12} xl={12}>
                                                    <SuiBox mb={1} className="popup-content">
                                                        <Custom.InputWrapper attr="Question" name={"q" + index} value={inputs["q" + index] || data.question} onChange={handleChange} size="sm" disable={!disable["a" + index]}></Custom.InputWrapper>
                                                        <Custom.InputWrapper attr="Question (English)" name={"q[e]" + index} value={inputs["q[e]" + index] || data.question_eng} onChange={handleChange} size="lg" disable={!disable["a" + index]}></Custom.InputWrapper>
                                                    </SuiBox>
                                                    
                                                    {
                                                        disable["a" + index] ?
                                                            <Editor content={inputs["a" + index] || data.answer} onChange={(newContent) => setContent("a" + index, newContent)}/>
                                                            : <Custom.HTMLContainer attr="Answer" html={inputs["a" + index] || data.answer}/>
                                                    }

                                                    {
                                                        disable["a" + index] ?
                                                            <Editor content={inputs["a[e]" + index] || data.answer_eng} onChange={(newContent) => setContent("a[e]" + index, newContent)}/>
                                                            : <Custom.HTMLContainer attr="Answer(Eng)" html={data.answer_eng}/>
                                                    }

                                                    <ToggleButtonGroup
                                                        color="primary"
                                                        exclusive
                                                        onChange={handleChange}>
                                                        
                                                        {
                                                            disable["a" + index] ?
                                                                <ToggleButton value="update" onClick={() => updateItem(data.id, inputs["q" + index] ?? data.question, inputs["a" + index] ?? data.answer, inputs["q[e]" + index] ?? data.question_eng, inputs["a[e]" + index] ?? data.answer_eng)}>
                                                                    <UpdateIcon></UpdateIcon>
                                                                </ToggleButton>
                                                                :
                                                                <ToggleButton value="edit" onClick={() => editItem("a" + index)}>
                                                                    <EditIcon></EditIcon>
                                                                </ToggleButton>
                                                        }

                                                        <ToggleButton value="delete" onClick={() => removeItem(key)}>
                                                            <DeleteIcon></DeleteIcon>
                                                        </ToggleButton>

                                                    </ToggleButtonGroup>

                                                </Grid>
                                            </Grid>
                                        </SuiBox>
                                    </Row>
                                    )
                                ) : null
                            }

                            {
                                editParmas ? editParmas.map((data, index) =>
                                    <>
                                        <Row>
                                            <SuiBox mb={1}>
                                                <Grid container alignItems="center">
                                                    <Grid item lg={2}>
                                                        {faqData.length + index + 1}
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} xl={12}>
                                                        <SuiBox mb={1}>
                                                            <Custom.InputWrapper attr="Question" name={"q" + index} value={inputs["q" + index] || data.Question} onChange={handleChange} size="sm" disable={!disable["a" + index]}></Custom.InputWrapper>
                                                        </SuiBox>
                                                        {
                                                            disable["a" + index] ?
                                                                (
                                                                    isHTML(data.Answer) ? 
                                                                        <Editor content={inputs["a" + index] || data.Answer} onChange={(newContent) => setContent("a" + index, newContent)}/>
                                                                        : <Custom.InputWrapper attr="Answer" name={"a" + index} value={inputs["a" + index] || data.Answer} onChange={handleChange} size="sm" disable={!disable["a" + index]}></Custom.InputWrapper>
                                                                ) :
                                                                (
                                                                    isHTML(data.Answer) ? 
                                                                        <Custom.HTMLContainer attr="Answer" html={data.Answer}/>
                                                                        : <Custom.InputWrapper attr="Answer" name={"a" + index} value={inputs["a" + index] || data.Answer} onChange={handleChange} size="sm" disable={!disable["a" + index]}></Custom.InputWrapper>
                                                                )
                                                        }

                                                        <SuiBox mb={1}>
                                                            <Custom.InputWrapper attr="Question (English)" name={"q[e]" + index} value={inputs["q[e]" + index] || data.Question_ENG} onChange={handleChange} size="lg" disable={!disable["a" + index]}></Custom.InputWrapper>
                                                        </SuiBox>
                                                        {
                                                            disable["a" + index] ?
                                                                (
                                                                    isHTML(data.Answer) ? 
                                                                        <Editor content={inputs["a[e]" + index] || data.Answer_ENG} onChange={(newContent) => setContent("a[e]" + index, newContent)}/>
                                                                        : <Custom.InputWrapper attr="Answer(English)" name={"a[e]" + index} value={inputs["a[e]" + index] || data.Answer_ENG} onChange={handleChange} size="sm" disable={!disable["a" + index]}></Custom.InputWrapper>
                                                                ) :
                                                                (
                                                                    isHTML(data.Answer) ? 
                                                                        <Custom.HTMLContainer attr="Answer(English)" html={data.Answer_ENG}/>
                                                                        : <Custom.InputWrapper attr="Answer(English)" name={"a[e]" + index} value={inputs["a[e]" + index] || data.Answer_ENG} onChange={handleChange} size="sm" disable={!disable["a" + index]}></Custom.InputWrapper>
                                                                )
                                                        }

                                                        {
                                                            disable["a" + index] ?
                                                                <SuiButton onClick={() => addItem(data.id, inputs["q" + index] ?? data.Question, inputs["a" + index] ?? data.Answer, inputs["q[e]" + index] ?? data.Question_ENG, inputs["a[e]" + index] ?? data.Answer_ENG)}>
                                                                    <UpdateIcon></UpdateIcon>
                                                                </SuiButton>
                                                                :
                                                                <SuiButton onClick={() => editItem("a" + index)}>
                                                                    <EditIcon></EditIcon>
                                                                </SuiButton>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </SuiBox>
                                        </Row>
                                    </>
                                    )
                                    : null
                            }

                        </div>
                        <SuiButton onClick={actionEdit}>
                            <AddCircleIcon></AddCircleIcon>
                        </SuiButton>

                        {/* <div className="popup-form-row">
                            <SuiButton buttonColor="secondary" onClick={changeMode}>
                                {
                                    editMode ? "Update" : "Edit"
                                }
                            </SuiButton>
                        </div> */}
                        
                    </div>
                </div>
            }
        </>
    );
}

function EditFAQ ({ actionBack }) {

    const [faqName, setServiceFeeName] = useState("");
    const [faqVal, setServiceFeeValue] = useState("");
    const [engFaqName, setEngServiceFeeName] = useState("");
    const [engFaqVal, setEngServiceFeeValue] = useState("");

    return (
        <div id="popup1" className="popup-wrapper">
            <div id="" className="popup1-flex-column">
                <h4>FAQ</h4>
                <div className="popup-content">
                    <SuiBox xs={2} lg={3} xl={6} width="100%">
                        <Custom.InputWrapper attr="Question" value={faqName} 
                            onChange={(event) => {setServiceFeeName(event.target.value)}} disable={false} size="lg"/> 
                    </SuiBox>
                    <SuiBox xs={2} lg={3} xl={6} width="100%"> 
                        <Editor content={faqVal} onChange={(event) => {setServiceFeeValue(event)}}/>
                        {/* <Custom.InputWrapper attr="Answer" value={faqVal} 
                            onChange={(event) => {setServiceFeeValue(event.target.value)}} disable={false} size="lg"/>  */}
                    </SuiBox>
                    <SuiBox xs={2} lg={3} xl={6} width="100%">
                        <Custom.InputWrapper attr="Question (ENG)" value={engFaqName} 
                            onChange={(event) => {setEngServiceFeeName(event.target.value)}} disable={false} size="lg"/> 
                    </SuiBox>
                    <SuiBox xs={2} lg={3} xl={6} width="100%"> 
                        <Editor content={engFaqVal} onChange={(event) => {setEngServiceFeeValue(event)}}/>
                        {/* <Custom.InputWrapper attr="Answer" value={faqVal} 
                            onChange={(event) => {setServiceFeeValue(event.target.value)}} disable={false} size="lg"/>  */}
                    </SuiBox>
                </div>

                <div className="popup-form-row">
                    <SuiButton buttonColor="secondary" onClick={() => actionBack(faqName, faqVal, engFaqName, engFaqVal)}>Add</SuiButton>
                </div>
                
            </div>
        </div>
    );
}
