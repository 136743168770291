/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React components
import { CachedOutlined } from "@material-ui/icons";
import Card from "@mui/material/Card";
import Popup from "assets/theme/components/popup";
import { DataTable, Loader } from "components/Custom";
import { SuiBox, SuiButton } from "components/Default";
import DashboardLayout from "components/examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/examples/Navbars/DashboardNavbar";
import { constant } from 'config/constant';
import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import CustomPopup from "./components/custom_pop_up";
import { table_columns_size } from "./variable";
import OTCGateway from "entities/otcGateway";
import { useAlert } from "react-alert";

const otc_adv_example = [{
  "id": 40206584273,
  "sell_ad": true,
  "buy_ad": true,
  "sales": 4,
  "volume_USDT": 21839,
  "createAt": "2021/06/30 13:43"
}];

let otcGateway = new OTCGateway();

function OTCUser() {
  const alert = useAlert();
  let testMode = constant.DEBUG;

  const [page, setPage] = useState(0);  //pop up pages
  const [openPopUp, setOpenPopup] = useState(false);
  const [popUpName, setPopUpName] = useState("");
  const [currentStaff, setCurrentStaff] = useState();
  const [type, setType] = useState(0);

  const [rows, setRows] = useState(otc_adv_example); // data-table
  const [filterRows, setFilterRows] = useState(otc_adv_example);
  const [currentFilter, setCurrentFilter] = useState(0); //status value
  const [statusStatement, setStatusStatement] = useState(null);
  const [loading, setLoading] = useState(true);

  const [tbPage, setTbPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const handlePageChange = (event, value) => {
    console.log(value);
    if (value <= totalPage) {
      setTbPage(value);
      fetchAllData(value);
    }
  };

  const handlePopUp = (pName, id, type, data) => {
    setPopUpName(pName);
    setOpenPopup(true);
    setPage(1);
    setType(type);
    if (id) setCurrentStaff(id);
  };

  const fetchAllData = async (page, _statusStatement) => {
    setLoading(true);

    const data_length = await otcGateway.getAllData({
      page: page ?? tbPage,
      statusStatement: _statusStatement ?? (statusStatement ?? null)
    })
      .then((res) => {
        if (res) {
          if (page === 0) {
            return res.total;
          }
          setRows(res.data);
          setFilterRows(res.data);
          setTotalPage(res.last_page);
        }
        setLoading(false);
      })
      .catch((err) => {
        alert.error(err.message);
      })

    if (page === 0) return data_length
  };

  useEffect(() => {
    if (statusStatement === null) {
      fetchAllData();
    }
    if (rows.length < 1) {
      fetchAllData();
      fetchStatusCount();
    }
  }, [statusStatement]);

  const dt_columns = [
    {
      headerName: 'User ID',
      field: 'id',
      width: table_columns_size.m,
      renderCell: row =>
        row.id ?
          <Link to="#" className="text-secondary" onClick={() => handlePopUp("OTCUserSetting", row.id, 0)}>{row.id}</Link> : "-",
      sortable: true,
    },
    {
      headerName: 'Allow Private Order',
      field: 'allow_private_order',
      width: table_columns_size.m,
      renderCell: ({ row }) => row.allow_incognito_over_the_counter_order,
      sortable: true,
    },
    {
      headerName: 'Contact',
      field: 'contact',
      width: table_columns_size.m,
      renderCell: ({ row }) => row.contact_count,
      sortable: true,
    },
    {
      headerName: 'Payment Method',
      field: 'sales_buy',
      width: table_columns_size.m,
      renderCell: ({ row }) => row.payment_method_count,
      sortable: true,
    }
  ];

  return (
    <div>
      {
        page > 0 ?
          <Popup
            openPopup={openPopUp}
            setOpenPopup={() => { setOpenPopup(false) }}
          >
            <CustomPopup name={popUpName} id={currentStaff} type={type} />
          </Popup> : <></>
      }
      <DashboardLayout>
        <DashboardNavbar />

        <Loader loading={loading}>
          <SuiBox py={3}>
            <SuiBox mb={3}>
              <Card sx={{ p: 3, flexDirection: 'row', columnGap: '50', marginBottom: '0.5rem' }}>
                <SuiButton
                  style={{ marginRight: '1rem' }}
                  buttonColor="secondary"
                  onClick={() => { fetchAllData() }}>
                  <CachedOutlined />
                  Refresh
                </SuiButton>
              </Card>
              <Card>
                <DataTable dt_columns={dt_columns} dt_rows={filterRows} />
              </Card>
            </SuiBox>
          </SuiBox>
        </Loader>
        
      </DashboardLayout>
    </div>
  );
}

export default OTCUser;
