import Axios from "axios";
import { constant } from "../config/constant";

const id_token = localStorage.getItem("id_token");

const axios = Axios.create({
  baseURL: `${constant.API_SERVER}`,
  headers: { "Content-Type": "application/json", "Authorization" : `Bearer ${id_token}` },
  withCredentials: true
});

axios.interceptors.request.use(
  config => {
    return Promise.resolve(config);
  },
  error => Promise.reject(error)
);

axios.interceptors.response.use(
  response => Promise.resolve(response),
  error => {
    return Promise.reject(error);
  }
);

export default axios;
