import axios from "./index";

class TransferApi {
  static exchangeCount = data => {
    return axios.get(`${base}/crypto_count`, {
      params: data
    });
  };

  static transferAmount = data => {
    return axios.get(`${base}/status_count`, {
      params: data
    });
  };
}

let base = "transfer";

export default TransferApi;
