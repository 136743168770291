import className from "./style";

const savingplanstatus = [
    {
      label: "進行中",
      value: 1,
      color: "warning",
      class: className.warning,
      param: {
        name: "status",
        value: "進行中"
      },
      count: 0
    },
    {
      label: "已領取",
      value: 2,
      color: "success",
      class: className.success,
      param: {
        name: "status",
        value: "已領取"
      },
      count: 0
    },
    {
        label: "已取消",
        value: 2,
        color: "error",
        class: className.error,
        param: {
          name: "status",
          value: "已取消"
        },
        count: 0
      }
];

export default savingplanstatus;