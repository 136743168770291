import React from 'react';
import ImageUploading from 'react-images-uploading';
import "./style.css";

const UploadImage = ({ images, onChange, multiple }) => {
  const maxNumber = 69;

  return (
    <div>
      <ImageUploading
        multiple={multiple}
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <div>Drag Images here or</div>
            <button
              style={isDragging ? { backgroundColor: 'darkturquoise' } : { backgroundColor: '#fff'}}
              onClick={onImageUpload}
              className="upload-image-btn"
              {...dragProps}
            >
              Upload Image
            </button>
            &nbsp;
            {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
            <div style={{ display: 'flex' }}>
              {imageList.map((image, index) => (
                <div key={index} className="image-item">
                  <img src={image['data_url']} alt="" width="100" />
                  <div className="image-item__btn-wrapper">
                    <button className="upload-image-btn" onClick={() => onImageUpdate(index)}>Update</button>
                    <button className="upload-image-btn" onClick={() => onImageRemove(index)}>Remove</button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </ImageUploading>
    </div>
  );
}

export default UploadImage;