import Profile from "../components/profile";
import GoogleAuth from "./google_auth";

function CustomPopup({ name, id, action, googleAuthKey, googleAuthUrl }) {
    console.log(id);
    if(name === "ProfilePopup"){
        return <Profile id={id} action={action}/>;
    } else if(name === "GoogleAuth"){
        return <GoogleAuth id={id} googleAuthKey={googleAuthKey} googleAuthUrl={googleAuthUrl} />;
    } else return <></>
}

export default CustomPopup;