import React from 'react';
import Select from 'react-select';

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' }
]

export default function BasicSelect({  title, items, onChange, size, defaultValue, position  }){

  const defaultSize = 400;

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      width: state.selectProps.width,
      color: state.selectProps.menuColor,
      fontSize: 12,
      padding: 20,
      zIndex: 1005
    }),
  
    control: (_, { selectProps: { width }}) => ({
      width: width ?? (size ?? defaultSize),
      fontSize: 15,
      display: "flex",
      flexDirection: "row",
      borderRadius: 8,
      borderColor: "Black",
      zIndex: 999
    }),
  
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
  
      return { ...provided, opacity, transition };
    }
  }

  return (
    <div className={position === "row" ? "popup-input-wrapper" : ""}>
      {
        title ? <div className="popup-subtitle">{title}</div> : <></>
      }
        
        <Select 
          options={items}
          styles={customStyles}
          onChange={onChange}
          isClearable={true}
          //defaultValue={defaultValue}
          />
    </div>
  );
}