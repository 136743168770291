import PropTypes from "prop-types";
import React, { useState, useEffect } from 'react';
import { CircularProgress, Pagination } from "@mui/material";
import WalletApi from "api/wallet";
import { NumberFormatUtils } from 'util/index';
import moment from "moment";
function WithdrawTabRow(props) {

    return (
        <div className="tab-row-wrapper">
            <div className="row-start">
                <p>
                    {props.currency}
                </p>
                <p>
                    {props.dateTime}
                </p>
            </div>
            <div className="row-end">
                <p>
                    {props.withdrawValue}
                </p>
                <p>
                    {props.status}
                </p>
            </div>
        </div>
    );
}

WithdrawTabRow.propTypes = {
    currency: PropTypes.string.isRequired,
    dateTime: PropTypes.string.isRequired,
    withdrawValue: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired
};

export default function WithdrawTab(id) {

    const [page, setPage] = useState(1);
    const [record, setRecord] = useState();
    const [loading, setLoading] = useState(true);
    const [totalPage, setTotalPage] = useState(1);
    const [rowPerPage, setRowPerPage] = useState(5);

    const handlePageChange = (event, value) => {
        if (value <= totalPage) {
            setPage(value);
        }
    };

    const handleRowPerPage = (v) => {
        setRowPerPage(v);
        let t = record.length / rowPerPage;
        if (record.length % rowPerPage > 0) t += 1;
        setTotalPage(t);
    };

    useEffect(() => {
        WalletApi.getWithDrawDetails({ id: id })
            .then((res) => {
                if (res.status === 200) {
                    console.log(res.data.data);
                    let d = res.data.data.data;
                    setRecord(d)
                    setLoading(false);

                    let t = parseInt(d.length / rowPerPage);
                    if (d.length % rowPerPage > 0) t += 1;
                    setTotalPage(t)
                }
            })
            .catch((e) => {
                console.log(e)
            }) 
    }, [])

    return (
        <div className="tab-wrapper">
            {
                loading ?
                    <CircularProgress />
                    : <>
                        {
                            record.map((r, key) => {
                                if (key >= rowPerPage * (page - 1) && key < rowPerPage * page)
                                    return (
                                        <WithdrawTabRow key={key} currency={r?.wallet?.crypto_type} dateTime={moment(r?.created_at).format("YYYY/MM/DD HH:mm")} withdrawValue={NumberFormatUtils(r?.amount, 8)} status={r?.status} />
                                    )
                            })
                        }
                        <div className="center">
                            <Pagination color={"secondary"} count={totalPage} page={page} onChange={handlePageChange}  />
                        </div>
                    </>
            }

        </div>
    );
}

