import Switch from "@mui/material/Switch";
import './styles.css';
import "components/Custom/common.css";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    root: {
      width: "50px",
      height: "24px",
      padding: "0px"
    },
    switchBase: {
      color: "#818181",
      padding: "1px",
      "&$checked": {
        "& + $track": {
          backgroundColor: "#23bf58 !important"
        }
      }
    },
    thumb: {
      color: "white",
      width: "20px",
      height: "20px",
      margin: "1px"
    },
    track: {
      //borderRadius: "20px",
      border: "none !important",
      backgroundColor: "#818181",
      opacity: "1 !important",
      "&:after, &:before": {
        color: "white",
        fontSize: "11px",
        position: "absolute",
        top: "6px"
      },
      "&:after": {
        content: "'On'",
        left: "8px"
      },
      "&:before": {
        content: "'Off'",
        right: "7px"
      }
    },
    checked: {
      color: "#23bf58 !important",
      transform: "translate('26px', '-10px') !important"
    }
  });

function SwitchWrapper({ attr, size, onChange, checked }) {
    const classes = useStyles();

    return (
        <div className={size === "sm" || size === "lg" || size === "xl" || size === "col" ? "switch-wrapper-" + size : "switch-wrapper"}>
            <label htmlFor="title" className="switch-wrapper-subtitle left">{attr}</label>
            <Switch 
                classes={{
                    root: classes.root,
                    switchBase: classes.switchBase,
                    thumb: classes.thumb,
                    track: classes.track,
                    checked: classes.checked
                }}
                checked={checked} onChange={onChange} />
        </div>
    );
}

export default SwitchWrapper;