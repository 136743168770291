import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { Grid } from "@mui/material";
import GeneralApi from "api/general";
import { BasicDateTimePicker, InputWrapper, UploadImage } from "components/Custom";
import { SuiButton } from "components/Default";
import FirebaseGateway from "entities/firebaseGateway";
import moment from 'moment';
import { useState } from "react";
import { useAlert } from 'react-alert';

let firebaseGateway = new FirebaseGateway();

export default function ExtraRewardEditPopUp() {
    const alert = useAlert();
    const [inputs, setInputs] = useState({});
    const [newOptionsList, setNewOptionsList] = useState([]);
    const [count, setCount] = useState(1);
    const [startAt, setStartAt] = useState(null);
    const [endAt, setEndAt] = useState(null);

    const [optionIndex, setOptionIndex] = useState(0);
    const [optionImagesList, setOptionImagesList] = useState([]);
    const [bannerImages, setBannerImages] = useState([]);
    const option_onChange = (imageList, addUpdateIndex) => {  // upload image
        setOptionImagesList(prevState => ({ ...prevState, [optionIndex]: imageList }))
    };
    const handleSelectOption = (index) => {
        setOptionIndex(index);
    }
    const banner_onChange = (imageList, addUpdateIndex) => {
        setBannerImages(imageList);
    };
    const handleChange = e => setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));

    const actionEdit = e => {
        let new_options = newOptionsList;
        new_options.push({
            name: "",
            image: ""
        });
        setCount(count + 1);
        setNewOptionsList(new_options);
        console.log(newOptionsList);
    }

    const removeItem = async (key, action) => {
        if (action === "remove_from_list") {

        }
    }

    const saveExtraReward = async () => {
        console.log(inputs);
        console.log(bannerImages);
        console.log(optionImagesList);


        let bannerURL = null;
        let reward_options = [];

        await firebaseGateway.fireStorageHelper({
            image: bannerImages,
            place: `images/general/deposit/`
        }).then((res) => {
            console.log(res);
            bannerURL = res.data;
        }).catch((err) => {
            alert.error(err.message);
        })

        Object.entries(optionImagesList).map(async ([key, value]) => {
            await firebaseGateway.fireStorageHelper({
                image: value,
                place: `images/general/deposit/`
            }).then((res) => {
                console.log(res);
                reward_options.push({
                    title: inputs["option_" + key],
                    image_url: res.data
                })

                if (reward_options.length === Object.keys(optionImagesList).length) {
                    GeneralApi.createOrEditExtraReward({
                        crypto_type: "USDT",
                        min_amount: inputs.lower,
                        max_amount: inputs.Upper,
                        days: inputs.days,
                        annual_percentage_rate: inputs.APR,
                        start_at: moment(startAt).format("YYYY-MM-DD HH:mm:ss"),
                        end_at: moment(endAt).format("YYYY-MM-DD HH:mm:ss"),
                        type: "ExtraReward",
                        quota: inputs.quota,
                        enabled: true, // ???
                        title: inputs.title,
                        description: inputs.reward,
                        banner_url: bannerURL,
                        reward_options: reward_options
                    }).then((res) => {
                        console.log(res);
                        alert.success("Create Extra Reward Success");
                    }).catch((e) => {
                        console.log(e);
                    });
                }
            }).catch((err) => {
                alert.error(err.message);
            })
        });
    }

    return (
        <>
            <h4>Create New Campaign</h4>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={6} xl={6}>
                    <InputWrapper attr="Campaign" name="title" value={inputs["title"] ?? ""} onChange={handleChange} disable={false} size="m" />

                    <Grid container spacing={1}>
                        <Grid item xs={12} lg={6} xl={6}>
                            <h5>StartAt</h5>
                            <BasicDateTimePicker value={startAt} onChange={(newValue) => setStartAt(newValue)} />
                        </Grid>
                        <Grid item xs={12} lg={6} xl={6}>
                            <h5>EndAt</h5>
                            <BasicDateTimePicker value={endAt} onChange={(newValue) => setEndAt(newValue)} />
                        </Grid>
                        <Grid item xs={12} lg={6} xl={6}>
                            <InputWrapper attr="Quota" name="quota" value={inputs["quota"] ?? ""} onChange={handleChange} disable={false} size="m" />
                        </Grid>
                        <Grid item xs={12} lg={6} xl={6}>
                            <InputWrapper attr="APR" name="APR" value={inputs["APR"] ?? ""} onChange={handleChange} disable={false} size="m" />
                        </Grid>
                        <Grid item xs={12} lg={8} xl={8}>
                            <InputWrapper attr="Reward" name="reward" value={inputs["reward"] ?? ""} onChange={handleChange} disable={false} size="m" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={6} xl={6}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} lg={12} xl={12}>
                            <InputWrapper attr="Status" name="status" value="待領獎" disable={true} size="m" />
                        </Grid>
                        <Grid item xs={12} lg={6} xl={6}>
                            <InputWrapper attr="Lower" name="lower" value={inputs["lower"] ?? ""} onChange={handleChange} disable={false} size="m" />
                        </Grid>
                        <Grid item xs={12} lg={6} xl={6}>
                            <InputWrapper attr="Upper" name="Upper" value={inputs["Upper"] ?? ""} onChange={handleChange} disable={false} size="m" />
                        </Grid>
                        <Grid item xs={12} lg={6} xl={6}>
                            <InputWrapper attr="Days" name="days" value={inputs["days"] ?? ""} onChange={handleChange} disable={false} size="m" />
                        </Grid>
                        <Grid item xs={12} lg={6} xl={6}>
                            <InputWrapper attr="Crypto" name="crypto" value="USDT" disable={true} size="m" />
                        </Grid>
                        <Grid item xs={12} lg={12} xl={12}>
                            <h5>Banner</h5>
                            <UploadImage images={bannerImages} onChange={banner_onChange} multiple={false} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <h5>Options</h5>
            <div style={{ display: "none" }}>{newOptionsList.length}</div>
            <Grid container>
                {
                    newOptionsList.map((d, index) => {
                        return (
                            <Grid item xs={12} lg={6} xl={6} key={index}>
                                <Grid container>
                                    <Grid item xs={5} lg={10} xl={10}>
                                        <InputWrapper name={"option_" + index} value={inputs["option_" + index] ?? ""} onChange={handleChange} disable={false} size="lg" />
                                    </Grid>
                                    <Grid item xs={2} lg={2} xl={2}>
                                        <SuiButton buttonColor="error" onClick={() => removeItem(index, "remove_from_list")}>
                                            <DeleteIcon />
                                        </SuiButton>
                                    </Grid>
                                    <Grid item xs={5} lg={10} xl={10} onClick={() => handleSelectOption(index)}>
                                        <UploadImage images={optionImagesList[index]} onChange={option_onChange} multiple={false} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        );
                    })
                }
            </Grid>

            <SuiButton buttonColor="secondary" onClick={(e) => actionEdit(e.value)}>
                <AddCircleIcon />
            </SuiButton>

            <Grid container>
                <Grid item xs={12} lg={11} xl={11}></Grid>
                <Grid item xs={12} lg={1} xl={1}>
                    <SuiButton buttonColor="secondary" onClick={(e) => saveExtraReward()}>
                        Save
                    </SuiButton>
                </Grid>
            </Grid>
        </>
    );
}