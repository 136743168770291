import React, { useState, useEffect } from 'react';
import UserApi from 'api/user';
import { Input, FormControl, FormHelperText } from '@mui/material';
import SuiButton from 'components/Default/SuiButton';

export default function HKIDAuthenticationHelper ({ id, getAuth }) {

    const [idVerifyData, setIdVerifyData] = useState();
    const [input, setInput] = useState("");
    const [match, setMatch] = useState(false);
    const [tried, setTried] = useState(false);
    const [error, setError] = useState("");

    const fetchOneUserData = async (id) => {
        await UserApi.getOneUserByFid(id)
            .then((res) => {
                if (res) {
                    const idVerifyData = res.data.IdVerification;
                    //console.log(idVerifyData);
                    setIdVerifyData(idVerifyData);
                }
            })
            .catch((err) => {
                console.log(err);
                return setError("There has been an error.");
            });
    };

    //fetch user
    useEffect(() => {
        fetchOneUserData(id);
    }, [id]);

    const handleCompareID = () => {

        if (idVerifyData.IdNumber && input === idVerifyData.IdNumber.substring(0, 4)) {
            setMatch(true);
            setTried(false);
            getAuth(true);
        } else {
            setMatch(false);
            setTried(true);
            getAuth(false);
        }
    };

    return (
        <div id="popup1">
            <div>
                Authentication
            </div>
            <div className="popup-content">
                <div className="popup-subtitle">
                    Please input the HKID of account owner (first 4 character and digits):
                </div>
                <FormControl error variant="standard">
                    <Input onChange={(e) => { setInput(e.target.value) }} error={!match && tried} />
                    {   
                        !match && tried ?
                            <FormHelperText id="component-error-text">ID does not match</FormHelperText>
                            : <></>
                    }
                </FormControl>
                <SuiButton onClick={handleCompareID} buttonColor="secondary" fullWidth>Authenticate</SuiButton>

            </div>
        </div>
    )
}