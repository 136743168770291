import { HTMLContainer } from "components/Custom";

/* const isHTML = (str) => !(str || '')
        // replace html tag with content
        .replace(/<([^>]+?)([^>]*?)>(.*?)<\/\1>/ig, '')
        // remove remaining self closing tags
        .replace(/(<([^>]+)>)/ig, '')
        // remove extra space at start and end
        .trim(); */

function HTMLContentUtils( str ){
	var htmlRegex = new RegExp("<(.|\n)*?>");
	if(htmlRegex.test(str)){
		return (<HTMLContainer html={str}/>);
	}
   return str;
}

export { HTMLContentUtils };