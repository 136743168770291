import { ERROR } from "util/ErrorHelper";

// for status components e.g approved, rejected, etc.
function StatusContainer({ status = "Rejected", param, children }){
    if(status === param){
		return children
	}
	return <></>
}
function NotStatusContainer({ status = "Rejected", param, children }){
    if(status !== param){
		return children
	}
	return <></>
}
//
function EditableContainer({ editMode = true, currentEditMode, desc = "", children }){
    if(editMode === currentEditMode){
		return children
	}
	return <></>
}
function IsExist(param){
	if(param){
		return true
	}
	return false;
}
/* function inputAttribute(props){
	let attrName = props.attrName;
	let dataset = props.dataset;
	
	if(attrName && dataset){
		return inputs[attrName] ?? NullFormatUtils(dataset[attrName]);
	}
	return "";
} */
//
function PageController({ currentPage, targetPage, CASE = "desc_text", children }){
	if(currentPage === targetPage){
		return children
	}
	return <></>
}

function FieldsNotFoundError({ data = ["field1", "field2"], children }){
    if(data.some(element => element === null || undefined)){
		console.log(
			{
				isError: true,
				message: ERROR.POPUP_01
			}
		);
        return <></>
    }
    return children;
}

export {
	StatusContainer,
	NotStatusContainer,
	EditableContainer,
	IsExist,
	PageController,
	FieldsNotFoundError
}