// Copy From components/account_details
import 'components/Custom/common.css';

import InputWrapper from 'components/Custom/input_wrapper';
import AccountDetails from 'components/Page/account_details';
import { SuiButton, SuiBox } from "components/Default";
import { useState, useEffect } from "react";
import { DateFormatUtils } from 'util/index';
import { Grid } from '@mui/material';

import { useAlert } from 'react-alert';
import WalletApi from "api/wallet";

function WithdrawDetailsPopup({ id }) {
    const alert = useAlert();

    const [withDraw, setWithDraw] = useState([]);
    const [transId, setTransId] = useState(null);

    /* const completedTrans = () => {
        if(id){
            WalletApi.completeTrans(id)
                .then((res) => {
                    if (res.status === 200){
                        console.log("transaction completed");
                        alert.success("transaction completed");
                    } else{
                        alert.error("Somethings error");
                    }
                })
                .catch((e) => {
                    console.log(e);
                    alert.error(e.message);
                });
        }
    } */

    const changeStatus = (status) => {
        if(transId){
            WalletApi.changeTransStatus({ id: transId, status: status })
            .then((res) => {
                if (res.status === 200){
                    alert.success(`transaction ${status}`);
                } else{
                    alert.error("Somethings error");
                }
            })
            .catch((e) => {
                console.log(e);
                alert.error(e.message);
            });
        }
    }

    const fetchData = () => {
        // wait for changes
        /* WalletApi.getHistoriesBySid(id)
        .then((res) => {
          if (res.status === 200){
            let d = res.data.data;
            console.log(d);
            setWithDraw(d)
          }
          
        }).catch((e) => {
          console.log(e)
        }) */

        // filter by frontend
        WalletApi.getWithDrawDetails({ history_id: id })
            .then((res) => {
                if(res.status === 200){
                    let d = res.data;
                    console.log(d);
                    setWithDraw(d);
                    setTransId(d.transfer_id);
                }
            })
            .catch((err) => {
                console.log(err);
            });
      }
    
    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="popup-wrapper" >
            <div className="popup-content" style={{ width: '100%'}}>
                <h4>Withdraw Details</h4>
                <InputWrapper attr={"History ID"} size="lg" value={withDraw.id ?? "-"}/>
                <InputWrapper attr={"User ID"} size="lg" value={withDraw.UserID}/>

                <Grid container alignItems="center">
                    <Grid item xs={12} sm={8} md={6} xl={6}>
                        <InputWrapper attr={"Crypto Type"} size="lg" value={withDraw.wallet ? withDraw.wallet.crypto_type : "-"}/>
                    </Grid>
                    <Grid item xs={12} sm={8} md={6} xl={6}>
                        <InputWrapper attr={"Withdraw Address"} size="lg" value={withDraw.address}/>
                    </Grid>
                </Grid>
                <div className="popup-form-row">
                    <InputWrapper attr={"Networks"} size="lg" value={withDraw.network}/>
                    <InputWrapper attr={"Withdraw Fee"} size="lg" value={withDraw.commission}/>
                </div>
                <div className="popup-form-row">
                    <InputWrapper attr={"Account ID"} size="lg" value={withDraw.account_id ?? "-"}/>
                    <InputWrapper attr={"Create Date"} size="lg" value={DateFormatUtils(withDraw.created_at, "YYYY-MM-DD HH:mm:ss")}/>
                </div>
                <div className="popup-form-row">
                    <InputWrapper attr={"Withdraw Amount"} size="lg" value={withDraw.amount ?? "-"}/>
                </div>
                <div className="popup-form-row">
                    <InputWrapper attr={"Actual Amount"} size="lg" value={withDraw.requested_amount ?? 0}/>
                    <InputWrapper attr={"Status"} size="lg" value={withDraw.status}/>
                </div>
                <InputWrapper attr={"txid"} size="lg" value={withDraw.txid}/>
                
                <SuiBox mt={2}>
                    <InputWrapper attr={"Remarks"} size="lg" value={withDraw.remarks} disable={false}/>
                </SuiBox>
                {
                    withDraw.status === "processing" ?
                        <div className="popup-form-row" style={{ flexDirection: 'row-reverse' }}>
                            <SuiButton
                                buttonColor="error"
                                onClick={() => changeStatus("failed")}>
                                Rejected
                            </SuiButton>
                            <SuiButton
                                buttonColor="secondary"
                                onClick={() => changeStatus("completed")}>
                                Completed
                            </SuiButton>
                        </div>
                        : <></>
                }
            </div>
        </div>
    );
}

function CustomPopup({ name, id }) {
    if (name === "WithdrawDetailsPopup") {
        return <WithdrawDetailsPopup id={id}/>;
    } else if (name === "AccountDetailsPopup") {
        return <AccountDetails id={id} />
    } return <></>
}

export default CustomPopup;