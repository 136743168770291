/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React components
import { CachedOutlined } from "@material-ui/icons";
import { Chip } from '@mui/material';
import Card from "@mui/material/Card";
import Popup from "assets/theme/components/popup";
import { DataTable, Loader } from "components/Custom";
import { SuiBox, SuiButton } from "components/Default";
import DashboardLayout from "components/examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/examples/Navbars/DashboardNavbar";
import { constant } from 'config/constant';
import OTCGateway from "entities/otcGateway";
import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { NullFormatUtils, NumberFormatUtils, DateFormatUtils } from "util/index";
import CustomPopup from "./components/custom_pop_up";
import { table_columns_size } from "./variable";

const otc_trans_example = [{
  "id": 40206584273,
  "seller_id": "abdhsaj",
  "buyer_id": "A0437",
  "unit_price": 7.78,
  "hkd": 21839,
  "usdt": 212137,
  "createAt": "2021/06/30 13:43",
  "updateAt": "2021/06/30 13:43",
  "order_type": "BUY",
  "method": "cash",
  "complaint": true,
  "status": "待付款"
}];

let otcGateway = new OTCGateway();

function OTCREGTRANS() {
  let testMode = constant.DEBUG;

  const [page, setPage] = useState(0);  //pop up pages
  const [openPopUp, setOpenPopup] = useState(false);
  const [popUpName, setPopUpName] = useState("");
  const [currentStaff, setCurrentStaff] = useState();
  const [type, setType] = useState(0);

  const [rows, setRows] = useState(otc_trans_example); // data-table
  const [filterRows, setFilterRows] = useState(otc_trans_example);
  const [currentFilter, setCurrentFilter] = useState(0); //status value
  const [statusStatement, setStatusStatement] = useState(null);
  const [loading, setLoading] = useState(true);

  const [tbPage, setTbPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const handlePopUp = (pName, id, type, data) => {
    setPopUpName(pName);
    setOpenPopup(true);
    setPage(1);
    setType(type);
    if (id) setCurrentStaff(id);
  };

  const fetchAllData = async (page, _statusStatement) => {
    setLoading(true);

    const data_length = await otcGateway.getRegularOrderData()
      .then((res) => {
        if (res) {
          if (page === 0) {
            return res.total;
          }
          setRows(res.data);
          setFilterRows(res.data);
          setTotalPage(res.last_page);
        }
        setLoading(false);
      })
      .catch((err) => {
        alert.error(err.message);
      })

    if (page === 0) return data_length
  };

  useEffect(() => {
    if (statusStatement === null) {
      fetchAllData();
    }
    if (rows.length < 1) {
      fetchAllData();
      fetchStatusCount();
    }
  }, [statusStatement]);

  const dt_columns = [
    {
      headerName: 'MSQL ID',
      field: 'id',
      width: table_columns_size.m,
      renderCell: ({ row }) => NullFormatUtils(row.id),
      sortable: true,
    },
    {
      headerName: 'Transaction ID',
      field: 'shortId',
      width: table_columns_size.m,
      renderCell: ({ row }) => NullFormatUtils(row.id),
      sortable: true,
    },
    {
      headerName: 'Seller ID',
      field: 'seller_id',
      width: table_columns_size.s,
      renderCell: ({ row }) =>
        row.sellerId ?
          <Link to="#" className="text-secondary" onClick={() => handlePopUp("AccountDetailsPopup", row.sellerId)}>{row.sellerId}</Link>
          : "-",
      sortable: true,
    },
    {
      headerName: 'Buyer ID',
      field: 'buyer_id',
      width: table_columns_size.s,
      renderCell: ({ row }) =>
        row.buyerId ?
          <Link to="#" className="text-secondary" onClick={() => handlePopUp("AccountDetailsPopup", row.buyerId)}>{row.buyerId}</Link>
          : "-",
      sortable: true,
    },
    {
      headerName: 'Unit Price',
      field: 'unit_price',
      width: table_columns_size.m,
      renderCell: ({ row }) => '$' + NumberFormatUtils(row.unit_price, 8),
      sortable: true,
    },
    {
      headerName: 'HKD',
      field: 'hkd',
      width: table_columns_size.m,
      renderCell: ({ row }) => '$' + NullFormatUtils(row.hkd),
      sortable: true,
    },
    {
      headerName: 'USDT',
      field: 'USDT',
      width: table_columns_size.m,
      renderCell: ({ row }) => '$' + NullFormatUtils(row.usdt),
      sortable: true,
    },
    {
      headerName: 'CreateDate',
      field: 'createAt',
      width: table_columns_size.m,
      renderCell: ({ row }) => DateFormatUtils(row.createAt, "YYYY/MM/DD HH:mm:ss"),
      sortable: true,
    },
    {
      headerName: 'UpdateAt',
      field: 'updateAt',
      width: table_columns_size.m,
      renderCell: ({ row }) => DateFormatUtils(row.updateAt, "YYYY/MM/DD HH:mm:ss"),
      sortable: true,
    },
    {
      headerName: 'Order Type',
      field: 'order_type',
      width: table_columns_size.m,
      renderCell: ({ row }) => NullFormatUtils(row.orderType),
      sortable: true,
    },
    {
      headerName: 'Method',
      field: 'method',
      width: table_columns_size.m,
      renderCell: ({ row }) =>
        row.method ?
          <Link to="#" className="text-secondary" onClick={() => handlePopUp("PaymentMethod", row.id)}>{row.method}</Link> : "-",
      sortable: true,
    },
    {
      headerName: 'Complaint',
      field: 'complaint',
      width: table_columns_size.m,
      renderCell: ({ row }) =>
        row.complaint ?
          <Link to="#" className="text-secondary" onClick={() => handlePopUp("Complaint", row.id)}>{"Review"}</Link> : "-",
      sortable: true,
    },
    {
      headerName: 'Commission',
      field: 'commission',
      width: table_columns_size.m,
      renderCell: ({ row }) => '$' + NullFormatUtils(row.commission),
      sortable: true,
    },
    {
      headerName: 'Commission Rate',
      field: 'commissionRate',
      width: table_columns_size.m,
      renderCell: ({ row }) => NullFormatUtils(row.commissionRate) + '%',
      sortable: true,
    },
    {
      headerName: 'Status',
      field: 'Status',
      width: table_columns_size.m,
      renderCell: ({ row }) => <Chip label={row.status} />,
      sortable: true,
    }
  ];

  return (
    <div>
      {
        page > 0 ?
          <Popup
            openPopup={openPopUp}
            setOpenPopup={() => { setOpenPopup(false) }}
          >
            <CustomPopup name={popUpName} id={currentStaff} type={type} />
          </Popup> : <></>
      }
      <DashboardLayout>
        <DashboardNavbar />

        <Loader loading={loading}>
          <SuiBox py={3}>
            <SuiBox mb={3}>
              <Card sx={{ p: 3, flexDirection: 'row', columnGap: '50', marginBottom: '0.5rem' }}>
                <SuiButton
                  style={{ marginRight: '1rem' }}
                  buttonColor="secondary"
                  onClick={() => { fetchData() }}>
                  <CachedOutlined />
                  Refresh
                </SuiButton>
              </Card>
              <Card>
                <DataTable dt_columns={dt_columns} dt_rows={filterRows} />
              </Card>
            </SuiBox>
          </SuiBox>
          </Loader>
          
      </DashboardLayout>
    </div>
  );
}

export default OTCREGTRANS;
