import { Card, CardContent, Grid } from "@mui/material";
import UserApi from 'api/analysis/user';
import Popup from "assets/theme/components/popup";
import { Loader } from "components/Custom";
import DraggableCardChart from 'components/Custom/card_chart';
import { constant } from 'config/constant';
import { addDays } from 'date-fns';
import Table from "components/examples/Table";
import moment from 'moment';
import { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import ChartMaker from 'util/ChartMaker';
import { chartType, singleAxisLineOptions } from 'util/ChartMaker/constant';
import { DateFormatUtils } from "util/index";
import './style.css';

export default function UserTab() {
    let testMode = constant.DEBUG;
    // Pop Up Page
    const [page, setPage] = useState(0);
    const [openPopUp, setOpenPopup] = useState(false);
    const [popUpName, setPopUpName] = useState("");

    const [state, setState] = useState([
        {
          startDate: new Date(),
          endDate: addDays(new Date(), 7),
          key: 'selection'
        }
    ]);

    const [increaseCount, setIncreaseCount] = useState([]);
    const [noOfReferral, setNoOfReferral] = useState([]);
    const [userReferMostPeople, setUserReferMostPeople] = useState([]);
    const [referralRatio, setReferralRatio] = useState([]);
    const [loading, setLoading] = useState(true);

    const increase_count = ["number of people"];

    const [timeUnit, setTimeUnit] = useState([]);
    const handleTimeUnitChange = (title, value) => {
        setTimeUnit(prevState => ({ ...prevState, [title]: value }));
    }

    const handlePopUp = (pName) => {
        setPopUpName(pName);
        setOpenPopup(true);
        setPage(1);
    };

    const fetchIncreaseCount = async (startDate, endDate) => {
        await UserApi.increaseCount({
            "date1": startDate, 
            "date2": endDate
        })
          .then((res) => {
            //console.log(res.data.data);
            setIncreaseCount(res.data.data);
          })
          .catch((err) => {
            //console.log(err);
          });
    };

    const fetchNoOfReferral = async (startDate, endDate) => {
        await UserApi.noOfReferral({
            "date1": startDate, 
            "date2": endDate
        })
          .then((res) => {
            //console.log(res.data.data);
            setNoOfReferral(res.data.data);
          })
          .catch((err) => {
            //console.log(err);
          });
    };

    const fetchUserReferMostPeople = async (startDate, endDate) => {
        await UserApi.userReferMostPeople({
            "date1": startDate, 
            "date2": endDate
        })
            .then((res) => {
                //console.log(res.data.data);
                const give_id_to_dataset = res.data.data.map((data, index) => ({ id: index, ...data}))
                setUserReferMostPeople(give_id_to_dataset);
            })
            .catch((err) => {
                //console.log(err);
            });
    };

    const fetchReferralRatio = async (startDate, endDate) => {
        await UserApi.referralRatio({
            "date1": startDate ?? "2020-01-01", 
            "date2": endDate ?? "2050-12-31"
        })
            .then((res) => {
                //console.log(res.data.data);
                setReferralRatio(res.data.data);
            })
            .catch((err) => {
                //console.log(err);
            });
    };

    const fetchAllData = (startDate, endDate) => {
        if(startDate && endDate){
            fetchIncreaseCount(startDate, endDate);
            fetchNoOfReferral(startDate, endDate);
            fetchUserReferMostPeople(startDate, endDate);
            fetchReferralRatio(startDate, endDate);
            setLoading(false);
        } else {
            fetchIncreaseCount();
            fetchNoOfReferral();
            fetchUserReferMostPeople();
            fetchReferralRatio();
            setLoading(false);
        }
    }

    const handleDatePickerSelected = (date) => {
        //console.log(date);
        setState([date])

        let startDate = DateFormatUtils(date.startDate);
        let endDate = DateFormatUtils(date.endDate);

        //console.log(startDate);
        //console.log(endDate);

        setLoading(true);
        fetchAllData(startDate,endDate)
    }

    useEffect(() => {
        if(increaseCount.length < 1 && 
            noOfReferral.length < 1 && 
            userReferMostPeople.length < 1 && 
            referralRatio.length < 1)
        {
            fetchAllData();
        }
    }, []);

    const dt_columns = [
        { name: 'referral', align: 'left' },
        { name: 'referral_count', align: 'center'}
    ];

    return (
        <>
            {/* "Pop Up" */}
            { 
                page > 0 ?
                <Popup
                    openPopup={openPopUp}
                    setOpenPopup={() => { setOpenPopup(false) }}>

                    <DateRangePicker
                        onChange={item => handleDatePickerSelected(item.selection)}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        ranges={state}
                        direction="horizontal"
                        />
                </Popup>
                : <></>
            }

            <div className="tab-wrapper">
                <Loader loading={loading}>
                        <>
                            <Grid container>
                                <Grid item xs={9} sm={9} md={9} xl={9}></Grid>
                                <Grid item xs={3} sm={3} md={3} xl={3}>
                                    <div onClick={() => handlePopUp("DateRange")}>
                                        <Card>
                                            <CardContent className='text-sm date_style text-center'>
                                                { moment(state[0].startDate).format("MMM Do, YYYY") + " - " + moment(state[0].endDate).format("MMM Do, YYYY") }
                                            </CardContent>
                                        </Card>
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} >
                                <DraggableCardChart title="Daily New User" setTimeUnit={handleTimeUnitChange} fetchData={fetchIncreaseCount} tipsTitle="Daily New User" tipsContent="Increasing no. of users based on [created_at] which means to registration date">
                                    <ChartMaker yLabel="ppl" type={chartType.Line} xKeyName="created_at" yKeyName={increase_count} valueKeyName="Increase" typeValues={increase_count} data={increaseCount} options={singleAxisLineOptions({ unit: timeUnit["Increase Count"] })}/>
                                </DraggableCardChart>

                                <DraggableCardChart title="Daily Referral Count" setTimeUnit={handleTimeUnitChange} fetchData={fetchNoOfReferral} tipsTitle="Daily Count of Signed In with Referral" tipsContent="Increasing no. of referral account_id found in user record based on [created_at] which means to registration date">
                                    <ChartMaker yLabel="ppl" type={chartType.Line} xKeyName="created_at_date" yKeyName={increase_count} valueKeyName="referral_count" typeValues={increase_count} data={noOfReferral} options={singleAxisLineOptions({ unit: timeUnit["No of referral"] })}/>
                                </DraggableCardChart>

                                <DraggableCardChart title="Daily Top Refer User" showTimeUnit={false} tipsTitle="Daily Top User with most referral" tipsContent="Find the users who refer the most people to sign in Bithon">
                                    {/* <div style={{ height: 400, width: '100%' }}>
                                        <DataGrid
                                            rows={userReferMostPeople}
                                            columns={dt_columns}
                                            pageSize={5}
                                            rowsPerPageOptions={[5]}
                                        />
                                    </div> */}
                                    <Table
                                        columns={dt_columns}
                                        rows={userReferMostPeople}
                                    />
                                </DraggableCardChart>

                                <DraggableCardChart title="Daily Referral Ratio" size={{xs:12, sm:4, md:4, xl:4}} showTimeUnit={false} tipsTitle="Referral vs Without Referral" tipsContent="Radio of users with referral and users without referral and their numbers">
                                    <ChartMaker type={chartType.Pie} data={referralRatio}/>
                                </DraggableCardChart>
                            </Grid>
                        </>
                </Loader>
            </div>
        </>
    );
}

