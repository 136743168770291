import OTCPopup from "./details";
import AccountDetails from 'components/Page/account_details';

function CustomPopup({ name, id, type  }) {
    if(name === "OTCPopup"){
        return <OTCPopup id={id} type={type}/>
    }  else if (name === "AccountDetailsPopup") {
        return <AccountDetails id={id} />
    }  else return <></>
}

export default CustomPopup;