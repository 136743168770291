import { Grid } from "@mui/material";
import CardForm from "template/base/cardForm";
import CardFormActions from "template/base/cardFormActions";
import CardFormContent from "template/base/cardFormContent";
import Column from "template/base/column";
import ControlPanel from "template/base/controlPanel";
import Form from "template/base/form";
import FormContent from "template/base/formContent";
import Row from "template/base/row";

function AccountDetailsTemplate({ userDetails }) {
  const {
    Title,
    Icon,
    HeaderImage,
    AccountID,
    UserID,
    Email,
    Phone,
    Gender,
    Birthday,
    Likes,
    Description,
    SubTitle,
    Address,
    District,
    Status,
    Title2,
    SubTitle1,
    BiometricAuthEditButton,
    SubTitle2,
    SMSCodeEditButton,
    SMSPhone,
    SubTitle3,
    GoogleAuthEditButton,
    EmailVerify,
    ExpandMoreController,
    ActionButton,
    PrevEmail,
    PrevPhone
  } = userDetails;

  return (
    <CardForm>
      <CardFormContent>
        <Form>
          <Column xs={12} sm={7} md={7} xl={7}>
            <Title />
            <FormContent>
              <Row>
                <Icon />
                <HeaderImage />
              </Row>

              <Grid container alignItems="center">
                <Grid item xs={12} sm={8} md={6} xl={5}>
                  <AccountID />
                </Grid>
                <Grid item xs={12} sm={8} md={6} xl={7}>
                  <UserID />
                </Grid>
              </Grid>

              <PrevEmail/>
              <Email />

              <PrevPhone/>

              <Grid container alignItems="center">
                <Grid item xs={12} sm={8} md={6} xl={6}>
                  <Phone />
                </Grid>
                <Grid item xs={12} sm={8} md={6} xl={6}>
                  <Gender />
                </Grid>
              </Grid>

              <Grid container alignItems="center">
                <Grid item xs={12} sm={8} md={6} xl={6}>
                  <Birthday />
                </Grid>
                <Grid item xs={12} sm={8} md={6} xl={6}>
                  <Likes />
                </Grid>
              </Grid>

              <Description />

              <SubTitle />
              <Address />
              <District />
              <Status />
            </FormContent>
          </Column>

          <Column xs={12} sm={3} md={3} xl={3}>
            <Title2 />
            <FormContent>
              <Row>
                <SubTitle1 />
                <BiometricAuthEditButton />
              </Row>
              <Row>
                <SubTitle2 />
                <SMSCodeEditButton />
              </Row>
              <Row>
                <SMSPhone />
              </Row>
              <Row>
                <SubTitle3 />
                <GoogleAuthEditButton />
              </Row>
              <Row>
                <EmailVerify />
              </Row>
            </FormContent>
          </Column>
        </Form>
      </CardFormContent>

      <CardFormActions>
        <ExpandMoreController />
      </CardFormActions>

      <ControlPanel>
        <ActionButton />
      </ControlPanel>
    </CardForm>
  );
}

export default AccountDetailsTemplate;
