/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Pagination } from "@mui/material";
import Card from "@mui/material/Card";
import Popup from "assets/theme/components/popup";
import { DataTable, Loader } from "components/Custom";
import { SuiBox, SuiButton } from "components/Default";
import DashboardLayout from "components/examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/examples/Navbars/DashboardNavbar";
import WalletGateway from "entities/walletGateway";
import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { HTMLContentUtils } from "util/HTMLContentUtils";
import { NullFormatUtils } from "util/index";
import CustomPopup from "./components/custom_pop_up";

let walletGateway = new WalletGateway();

function Wallet() {
  //pop up pages
  const [page, setPage] = useState(0);
  const [openPopUp, setOpenPopup] = useState(false);
  const [popUpName, setPopUpName] = useState("");
  const [loading, setLoading] = useState(true);
  const [passId, setPassId] = useState();
  const handlePopUp = (pName, id) => {
    setPopUpName(pName);
    setOpenPopup(true);
    setPassId(id);
    setPage(1);
  };

  const [rows, setRows] = useState([]);
  const [filterRows, setFilterRows] = useState([]);

  const [tbPage, setTbPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const handlePageChange = (event, value) => {
    if (value <= totalPage) {
      setTbPage(value);
      fetchAllData(value);
    }
  };

  const fetchAllData = async (page, searchValue) => {
    await walletGateway.getAllData({
      page: page ?? tbPage,
      searchValue: searchValue
    })
      .then((res) => {
        if (res) {
          setRows(res.data);
          setFilterRows(res.data);
          setLoading(false);
          setTotalPage(res.last_page);
        }
      }).catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (rows.length < 1) {
      fetchAllData();
    }
  }, []);

  const checkSearchKeyword = (SearchValue, rowData) => {
    if(SearchValue && rowData){
      //console.log(SearchValue);
      const new_rowData = rowData.replace(
        new RegExp(SearchValue, 'gi'),
        match => {
          if (match) {
            return `<mark style="background: #8D8DAA; color: white;">${match}</mark>`;
          }
        }
      );
      return HTMLContentUtils(new_rowData);
    }
    return rowData;
  };

  const dt_columns = [
    {
      headerName: 'Wallet Details',
      field: 'id',
      renderCell: ({ row }) => row.id ? <Link to="#" className="text-secondary" onClick={() => { handlePopUp("WalletDetailsPopup", row.userId) }}>{<SuiButton buttonColor="secondary" >View</SuiButton>}</Link> : "-",
      sortable: true,
      width: 150,
    },
    {
      headerName: 'User ID',
      field: 'account_id',
      renderCell: ({ row }) => row.account_id ? <Link to="#" className="text-secondary" onClick={() => { handlePopUp("AccountDetailsPopup", row.account_id) }}>{checkSearchKeyword(row.SearchValue, NullFormatUtils(row.account_id))}</Link> : "-",
      sortable: true,
      width: 150
    },
    {
      headerName: 'User Name',
      field: 'username',
      renderCell: ({ row }) => checkSearchKeyword(row.SearchValue, NullFormatUtils(row.username)),
      sortable: true,
      width: 150
    },
    {
      headerName: 'TotalBTC',
      field: 'totalBTC',
      renderCell: ({ row }) => !isNaN(parseFloat(row.totalBTC)) ? '$' + parseFloat(row.totalBTC).toFixed(8) : "-",
      sortable: true,
      width: 250
    },
    {
      headerName: 'TotalHKD',
      field: 'totalHKD',
      renderCell: ({ row }) => !isNaN(parseFloat(row.totalHKD)) ? '$' + parseFloat(row.totalHKD).toFixed(2) : "-",
      sortable: true,
      width: 150
    },
    {
      headerName: 'History',
      field: 'History',
      renderCell: ({ row }) => <Link to="#" className="text-secondary" onClick={() => { handlePopUp("HistoryPopup", row.account_id) }}>History</Link>,
      sortable: true,
      width: 100
    }
  ];

  return (
    <div>
      {
        page > 0 ?
          <Popup
            openPopup={openPopUp}
            setOpenPopup={() => { setOpenPopup(false) }}
          >
            <CustomPopup name={popUpName} id={passId}></CustomPopup>
          </Popup> : <></>
      }
      <DashboardLayout>
        <DashboardNavbar />
        <Loader loading={loading}>
          <>
            <SuiButton
              buttonColor="secondary"
              onClick={() => { setLoading(true); fetchAllData(); }}>
              Refresh
            </SuiButton>

            <SuiBox py={3}>
              <SuiBox mb={3}>
                <Card>
                  <DataTable dt_columns={dt_columns} dt_rows={rows} />
                  <Pagination color={"secondary"} count={totalPage} page={tbPage} onChange={handlePageChange} />
                </Card>
              </SuiBox>
            </SuiBox>
          </>
        </Loader>
        
      </DashboardLayout>
    </div>
  );
}

export default Wallet;
