// Copy From components/account_details
import React from 'react';
import './styles.css';

import WalletDetailsPopup from './components/wallet_details';
import HistoryPopup from './components/history';
import AccountDetails from 'components/Page/account_details';
import './components/history/styles.css';

function CustomPopup({ name, id }) {

    if (name === "WalletDetailsPopup") {
        return <WalletDetailsPopup id={id} />
    } else if (name === "HistoryPopup") {
        return <HistoryPopup id={id} />
    } else if (name === "AccountDetailsPopup") {
        return <AccountDetails id={id} />
    } else return <></>

}

//hierachy: WalletDetailsPopup->WalletDetail-(call)->AdjustCurrency

export default CustomPopup;