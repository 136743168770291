import '../styles.css';

import { useState, useEffect } from "react";
import ImageViewer from "components/Custom/image_viewer";

import { Row, Col } from "react-bootstrap";

import SuiButton from "components/Default/SuiButton";

import InputWrapper from 'components/Custom/input_wrapper';
import CommentWrapper from 'components/Custom/comment_wrapper';
import UploadImage from 'components/Custom/upload_image';
import { useAlert } from 'react-alert';
import { ImageURLExistHelper } from 'util/index';
const fakeImg = "https://upload.wikimedia.org/wikipedia/commons/thumb/7/70/Checkerboard_pattern.svg/1200px-Checkerboard_pattern.svg.png";

// Call API
import GeneralApi from "api/general";

export default function AppNotificationPopup () {
    const alert = useAlert();

    const [appNotificationData, setAppNotificationData] = useState([]); 
    const [err, setError] = useState();
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [noticeIcon, setNoticeIcon] = useState("");
    const [images, setImages] = useState([]);

    const onChange = (imageList, addUpdateIndex) => {
        //console.log(imageList, addUpdateIndex);
        setImages(imageList);
    };

    const handleAnnounce = async () => {

        await GeneralApi.createAppNotices(
            {   
                title: title,
                message: content
            }
        ).then((res) => {
            if(res.status === 200){
                alert.success("Create App Notices Success");
            }
        }).catch((err) => {
            console.log(err);
            alert.error(err.message);
        });
    };

    /* const fetchAppNotificationData = async () => {

        await GeneralApi.list()
            .then((res) => {
                if(res && res.status === 200){
                    const appData = Object.entries(res.data.data.app_notices)
                        .map(a => ({ id: Object.keys(a[1])[0], ...Object.entries(a[1])[0][1] }));

                    setAppNotificationData(appData);
                    //console.log(appData);
                }
            })
            .catch((err) => {
                ////err);
            });
      }; */
      
    useEffect(()=>{
        //fetchAppNotificationData();
    }, []);

    return (
        <div id="popup1" className="popup-wrapper">
            <Row id="" className="popup1-flex-column">
                <Row>
                    <h4>App Notification</h4>
                    <Row className="popup-content">
                        <Row className="popup-form-row">
                                <Col className="popup-content">
                                    <InputWrapper attr="Title" value={title} onChange={(event) => setTitle(event.target.value)} disable={false} size="m"/>
                                    <CommentWrapper attr="Content" value={content} onChange={(event) => setContent(event.target.value)} disable={false} rows="10" size="col"/>
                                </Col>
                                {/* <Col>
                                    <UploadImage images={images} onChange={onChange}/>
                                    <ImageViewer headerText="Notice Icon" clickText="Click to View" type={0} src={JSON.parse(localStorage.getItem("image-0"))[0].data_url} title={false}/>
                                </Col> */}
                            </Row>
        
                        <Row className="popup-form-row">
                            <SuiButton buttonColor="secondary" onClick={handleAnnounce}>Announce</SuiButton>
                        </Row>
                    </Row>
                </Row>
                {/* <Row>
                    <h4>Record</h4>
                    <Row className="popup-content">
                    {
                        appNotificationData ? appNotificationData.map((data, index) => 
                            <Row className="popup-form-row">
                                <Col className='text-sm'>{index + 1}.</Col>
                                <Col>
                                    <ImageViewer headerText="Notice Icon" clickText="Click to View" type={0} src={ImageURLExistHelper(data.NoticeIcon)} title={true}/>
                                </Col>
                                <Col className="popup-content">
                                    <InputWrapper attr="Title" value={data.Title} disable={true} size="sm"/>
                                    <CommentWrapper attr="Content" value={data.Content} disable={true} rows="5" />
                                </Col>
                            </Row>
                        ) : null
                    }
                    </Row>
                </Row> */}
            </Row>

        </div>
    );
}