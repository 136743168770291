import { constant } from "config/constant";

const DASHBOARD_PERMISSION = constant.PAGE.DASHBOARD;
const USER_PERMISSION = constant.PAGE.USER;
const OFFER_PERMISSION = constant.PAGE.OFFERS;
const ORDER_PERMISSION = constant.PAGE.ORDERS;
const WALLET_PERMISSION = constant.PAGE.WALLETS;
const OTC_PERMISSION = constant.PAGE.OTC;
const CRYPTO_PERMISSION = constant.PAGE.CRYPTO;
const ADVS_PERMISSION = constant.PAGE.ADVS;
const STAFF_PERMISSION = constant.PAGE.STAFFS;
const GENERAL_PERMISSION = constant.PAGE.GENERAL;
const OTHER_PERMISSION = constant.PAGE.OTHERS;

export { 
    DASHBOARD_PERMISSION,
    USER_PERMISSION, 
    OFFER_PERMISSION, 
    ORDER_PERMISSION, 
    WALLET_PERMISSION,
    OTC_PERMISSION, 
    CRYPTO_PERMISSION, 
    ADVS_PERMISSION, 
    STAFF_PERMISSION,
    GENERAL_PERMISSION, 
    OTHER_PERMISSION
};