import { Grid } from "@mui/material";
import GeneralApi from "api/general";
import { InputWrapper } from "components/Custom";
import ImageViewer from "components/Custom/image_viewer";
import { SuiButton } from "components/Default";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { ImageURLExistHelper } from 'util/index';

export default function EventDetails({ id }) {
    const alert = useAlert();
    const [rewardData, setRewardData] = useState([]);
    const [optionImages, setOptionImages] = useState([]);

    const showEdit = () => {
        
    }

    const fetchRewardDetails = async () => {
        await GeneralApi.getfixedSavingPlanOffersById(id)
            .then((res) => {
                if (res.status === 200) {
                    setRewardData(res.data.data);
                    let option_images = [];
                    res.data.data.reward_options.map((d, index) => {
                        option_images.push(d?.image_url);
                    })
                    setOptionImages(option_images);
                }
            })
            .catch((err) => {
                alert.error(err.message);
            });
    };

    useEffect(() => {
        fetchRewardDetails();
    }, []);

    return (
        <>
            <h4>Campaign Details</h4>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={6} xl={6}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} lg={12} xl={12}>
                            <ImageViewer headerText="Banner" clickText="Click to View" type={0} src={ImageURLExistHelper(rewardData?.banner_url)} title={true} />
                        </Grid>
                        <Grid item xs={12} lg={12} xl={12}>
                            <InputWrapper attr="Campaign" name="title" value={rewardData?.title} disable={true} size="m" />
                        </Grid>
                        <Grid item xs={12} lg={6} xl={6}>
                            <InputWrapper attr="Start Date" name="startAt" value={rewardData?.start_at} disable={true} size="m" />
                        </Grid>
                        <Grid item xs={12} lg={6} xl={6}>
                            <InputWrapper attr="End Date" name="endAt" value={rewardData?.end_at} disable={true} size="m" />
                        </Grid>
                        <Grid item xs={12} lg={6} xl={6}>
                            <InputWrapper attr="Quota" name="quota" value={rewardData?.quota} disable={true} size="m" />
                        </Grid>
                        <Grid item xs={12} lg={6} xl={6}>
                            <InputWrapper attr="APR" name="APR" value={rewardData?.annual_percentage_rate} disable={true} size="m" />
                        </Grid>
                        <Grid item xs={12} lg={8} xl={8}>
                            <InputWrapper attr="Reward" name="reward" value={rewardData?.description} disable={true} size="m" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={6} xl={6}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} lg={12} xl={12}>
                            <InputWrapper attr="Status" name="status" value="待領獎" disable={true} size="m" />
                        </Grid>
                        <Grid item xs={12} lg={6} xl={6}>
                            <InputWrapper attr="Lower" name="lower" value={rewardData?.min_amount} disable={true} size="m" />
                        </Grid>
                        <Grid item xs={12} lg={6} xl={6}>
                            <InputWrapper attr="Upper" name="Upper" value={rewardData?.max_amount} disable={true} size="m" />
                        </Grid>
                        <Grid item xs={12} lg={6} xl={6}>
                            <InputWrapper attr="Days" name="days" value={rewardData?.days} disable={true} size="m" />
                        </Grid>
                        <Grid item xs={12} lg={6} xl={6}>
                            <InputWrapper attr="Crypto" name="crypto" value="USDT" disable={true} size="m" />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={1} mt={1}>
                {
                    rewardData?.reward_options?.length >= 1 ?
                        rewardData?.reward_options?.map((d, index) => {
                            return (
                                <Grid item xs={12} lg={6} xl={6}>
                                    <InputWrapper attr={"Option "+(index+1)} name="option_index" value={d?.title} disable={true} size="m" />
                                </Grid>
                            );
                        }) : <></>
                }
            </Grid>
            <ImageViewer clickText="Click to View" type={1} src={ImageURLExistHelper(optionImages)} title={true} />

            <Grid container mt={1}>
                <Grid item xs={12} lg={11} xl={11}></Grid>
                <Grid item xs={12} lg={1} xl={1}>
                    <SuiButton buttonColor="secondary" onClick={(e) => showEdit()}>
                        Edit
                    </SuiButton>
                </Grid>
            </Grid>
        </>
    );
}