import { Grid } from "@mui/material";
import { InputWrapper } from "components/Custom";
import { SuiButton, SuiBox } from "components/Default";
import { useEffect, useState } from "react";
import GeneralApi from "api/general";
import { useAlert } from "react-alert";

export default function RewardDetails({ id }) {
    const alert = useAlert();
    const [rewardDetails, setRewardDetails] = useState([]);
    const [inputs, setInputs] = useState({});
    const handleChange = e => setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));

    const handleMarkReceived = async () => {
        await GeneralApi.markReceivedReward(id)
            .then((res) => {
                if (res.status === 200) {
                    alert.success("Mark received");
                }
            })
            .catch((err) => {
                alert.error(err.message);
            });
    }

    const fetchEventDetails = async () => {
        await GeneralApi.getOffersHasTag()
            .then((res) => {
                if (res.status === 200) {
                    let data = res.data.data.data.filter((d, index) => d.id === id)[0];
                    console.log(data);

                    const tag = JSON.parse(data?.tags);
                    setRewardDetails({
                        receiver: data?.user?.AccountID, // not found
                        phone: "", // not found
                        reward: tag?.offer_id, // not found
                        selected: tag?.option_name,
                        status: data?.status
                    });
                }
            })
            .catch((err) => {
                alert.error(err.message);
            });
    }

    useEffect(() => {
        if (rewardDetails.length < 1) {
            fetchEventDetails();
        }
    }, []);

    return (
        <>
            <h4>Reward</h4>
            <Grid container spacing={1}>
                <Grid item xs={12} lg={6} xl={6}>
                    <InputWrapper attr="Receiver" name="receiver" value={inputs["receiver"] ?? rewardDetails?.receiver} disable={true} size="m" />
                </Grid>
                <Grid item xs={12} lg={6} xl={6}>
                    <InputWrapper attr="Phone" name="phone" value={inputs["phone"] ?? rewardDetails?.phone} disable={true} size="m" />
                </Grid>
                <Grid item xs={12} lg={8} xl={8}>
                    <InputWrapper attr="Reward" name="reward" value={inputs["reward"] ?? rewardDetails?.reward} disable={true} size="m" />
                </Grid>
                <Grid item xs={12} lg={12} xl={12}>
                    <InputWrapper attr="Selected" name="selected" value={inputs["selected"] ?? rewardDetails?.selected} disable={true} size="m" />
                </Grid>
                <Grid item xs={12} lg={12} xl={12}>
                    <InputWrapper attr="Status" name="status" value={inputs["status"] ?? rewardDetails?.status} disable={true} size="m" />
                </Grid>
            </Grid>

            <Grid container mt={1}>
                <Grid item xs={12} lg={11} xl={11}></Grid>
                <Grid item xs={12} lg={1} xl={1}>
                    <SuiButton buttonColor="secondary" onClick={(e) => handleMarkReceived()}>
                        Received
                    </SuiButton>
                </Grid>
            </Grid>
        </>
    );
}