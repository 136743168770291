import CryptoDetailsPopup from "./crypto_details";

const offer_details = {
    "040206584273": {
        "Category": "數碼電器",
        "ContentImages": [],
        "CreateAt": "February 8, 2022 at 10: 37: 35 AM UTC + 8",
        "Status": "待確認",
        "CryptoType": "USDT",
        "DeliveryMethod": "",
        "Description": "Ajsj\nDjjs\nSj",
        "Handler": {
            "CreateAt": "",
            "InternalRemark": "",
            "Name": ""
        },
        "HeaderImage": "https://firebasestorage.googleapis.com/v0/b/intepayhk-bd35c.appspot.com/o/images%2Fproducts%2FZYJcBig4qYhHg5Q0s4VDyTHqLBw2%2F5lS0z8lAa1YGT3UBdXmR%2Fheader_1.png?alt=media&token=a65aee09-a7d4-431d-9423-2826ca4d4b4d",
        "IsHidden": false,
        "Price": 250,
        "ProductCondition": "",
        "Sales": 0,
        "Stock": 20,
        "Title": "剃須刀",
        "UpdateAt": "February 8, 2022 at 10: 37: 35 AM UTC + 8",
        "UserID": "ZYJcBig4qYhHg5Q0s4VDyTHqLBw2",
        "Report": {
            "SZPOaVmXfynpqAjjHExa": {
                "CreateAt": "February 4, 2022 at 5: 33: 30 PM UTC + 8",
                "ReportDetails": null,
                "ReportProof1": null,
                "ReportProof2": null,
                "ReportReason": null,
                "Status": "Pending",
                "UserID": "ZYJcBig4qYhHg5Q0s4VDyTHqLBw2"
            }
        }
    },
}



function CustomPopup({ name, id }) {
    if(name === "CryptoDetailsPopup"){
        return <CryptoDetailsPopup id={id}/>;
    } else return <></>
}

export default CustomPopup;