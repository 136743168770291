/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { CachedOutlined } from "@material-ui/icons";
import AddIcon from "@mui/icons-material/Add";
import Visibility from "@mui/icons-material/Visibility";
import { IconButton, Pagination } from "@mui/material";
import Card from "@mui/material/Card";
import StaffApi from "api/staff";
import Popup from "assets/theme/components/popup";
import { DataTable, Loader } from "components/Custom";
import { SuiBox, SuiButton } from "components/Default";
import DashboardLayout from "components/examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/examples/Navbars/DashboardNavbar";
import { constant } from 'config/constant';
import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { NullFormatUtils } from "util/index";
import { CustomPopup } from "./components";
import "./style.css";
import { table_columns_size } from "./variable";

function Staffs() {
  //pop up pages
  const [page, setPage] = useState(0);
  const [openPopUp, setOpenPopup] = useState(false);
  const [popUpName, setPopUpName] = useState("");
  const [key, setKey] = useState("");
  const [url, setUrl] = useState("");
  const [userId, setUserId] = useState(null);
  const [action, setAction] = useState(0);
  const handlePopUp = ({ pName, id, action, key, url }) => {
    console.log(id);
    setPopUpName(pName);
    setAction(action);
    setUserId(id);
    setKey(key);
    setUrl(url);
    setOpenPopup(true);
    setPage(1);
  };

  const [rows, setRows] = useState([]);
  const [filterRows, setFilterRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const [tbPage, setTbPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(0);

  let testMode = constant.DEBUG;

  const handlePageChange = (event, value) => {
    //console.log(value);
    if (value <= totalPage) {
        setTbPage(value);
        fetchData(value);
    }
  };

  const handleRowPerPage = (v) => {
      setRowPerPage(v);
      let t = filterRows.length / rowPerPage;
      if (filterRows.length % rowPerPage > 0) t += 1;
      setTotalPage(t);
  }

  const fetchData = async (page) => {
    setLoading(true);

    await StaffApi.list(page ?? tbPage)
      .then((res) => {
        if(res.status === 200){
          setRows(res.data.data.data);
          setLoading(false);

          setTotalPage(res.data.data.last_page);
          setRowPerPage(res.data.data.per_page);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

    const dt_columns = [
      {
        headerName: 'Staff ID',
        field: 'id',
        width: table_columns_size.xs,
        renderCell: row => row.id ? <Link to="#" className="text-secondary" 
          onClick={() => 
            handlePopUp({
              pName: "ProfilePopup",
              id: row.firebase_user_id,
              action: 2
            })}>{row.id}</Link> : "-",
        sortable: true,
      },
      {
        headerName: 'Staff Name',
        field: 'name',
        width: table_columns_size.m,
        renderCell: ({ row }) => NullFormatUtils(row.name),
        sortable: true,
      },
      {
        headerName: 'Staff Email',
        field: 'email',
        width: table_columns_size.m,
        renderCell: ({ row }) => NullFormatUtils(row.email),
        sortable: true,
      },
      {
        headerName: 'Staff Phone',
        field: 'phone',
        width: table_columns_size.m,
        renderCell: ({ row }) => NullFormatUtils(row.phone),
        sortable: true,
      },
      {
        headerName: 'Job Title',
        field: 'job_title',
        width: table_columns_size.m,
        renderCell: ({ row }) => NullFormatUtils(row.job_title),
        sortable: true,
      },
      {
        headerName: 'Google Auth',
        field: 'google_auth',
        width: table_columns_size.m,
        renderCell: ({ row }) => 
          <IconButton
              aria-label="google auth code"
              onClick={() => 
                handlePopUp({
                  pName: "GoogleAuth",
                  key: row.google_authenticator_secret, 
                  url: row.qr_code_url
                })}
            >
            <Visibility/>
          </IconButton>,
        sortable: true,
      }
    ];

  return (
    <div>
      {
        page > 0 ?
          <Popup
            openPopup={openPopUp}
            setOpenPopup={() => { setOpenPopup(false) }}
          >
            <CustomPopup name={popUpName} action={action} googleAuthKey={key} googleAuthUrl={url} id={userId}/>
          </Popup> : <></>
      }
      <DashboardLayout>
        <DashboardNavbar />

        <Loader loading={loading}>
          <>
              <Card sx={{ p:3, flexDirection: 'row' , columnGap: '50'}}>
                <SuiButton
                  className="p3"
                  buttonColor="secondary"
                  onClick={() => 
                      handlePopUp({
                        pName: "ProfilePopup",
                        id: 0,
                        action: 1
                      })}>
                  <AddIcon />
                  {/* New Staff */}
                </SuiButton>

                <SuiButton
                  style={{ marginRight: '1rem' }}
                  buttonColor="secondary"
                  onClick={()=>{ fetchData()}}>
                  <CachedOutlined/>
                    Refresh
                </SuiButton>
              </Card>

              <SuiBox py={3}>
                <SuiBox mb={3}>
                  <Card>
                      <DataTable dt_columns={dt_columns} dt_rows={rows}/>
                      <Pagination color={"secondary"} count={totalPage} page={tbPage} onChange={handlePageChange} rowsPerPage={rowPerPage} onRowsPerPageChange={handleRowPerPage}/>
                  </Card>
                </SuiBox>
              </SuiBox>
            </>
        </Loader>
        
        
      </DashboardLayout>
    </div>
  );
}

export default Staffs;
