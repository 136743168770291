/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useAuth } from "auth-context/auth.context";

// react-router components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import FeaturedVideoIcon from '@mui/icons-material/FeaturedVideo';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import PaidIcon from '@mui/icons-material/Paid';
import AppBar from "@mui/material/AppBar";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Toolbar from "@mui/material/Toolbar";

// Soft UI Dashboard React components
import SuiAvatar from "components/Default/SuiAvatar";
import SuiBox from "components/Default/SuiBox";
import SuiInput from "components/Default/SuiInput";

// Soft UI Dashboard React example components
import Breadcrumbs from "components/examples/Breadcrumbs";
import NotificationItem from "components/examples/NotificationItem";

// Custom styles for DashboardNavbar
import styles from "components/examples/Navbars/DashboardNavbar/styles";

// Soft UI Dashboard React context
import { useSoftUIController } from "context";

//import pop up components
import Popup from "assets/theme/components/popup";
import moment from "moment";
import { DateFormatUtils } from "util/index";
import CustomPopup from "layouts/staffs/components/custom_pop_up";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const classes = styles({ transparentNavbar, absolute, light, isMini });
  const route = useLocation().pathname.split("/").slice(1); 

  let { user } = useAuth();

  //pop up pages
  const [page, setPage] = useState(0);
  const [openPopUp, setOpenPopup] = useState(false);
  const [popUpName, setPopUpName] = useState("");
  const [currentStaff, setCurrentStaff] = useState();
  const [type, setType] = useState(0);
  const handlePopUp = (pName, id, type) => {
    setPopUpName(pName);
    setOpenPopup(true);
    setPage(1);
    setType(type);
    if (id) setCurrentStaff(id);
  };

  const [notification, setNotification] = useState([]);

  useEffect(() => {
    const cookienotification = localStorage.getItem("notification")
    if(cookienotification){
      // console.log(JSON.parse(cookienotification));
      if(notification) setNotification(JSON.parse(cookienotification));
    }

    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      /* dispatch({
        type: "TRANSPARENT_NAVBAR",
        value: (fixedNavbar && window.scrollY === 0) || !fixedNavbar,
      }); */
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => dispatch({ type: "MINI_SIDENAV", value: !miniSidenav });
  const handleConfiguratorOpen = () =>
    dispatch({ type: "OPEN_CONFIGURATOR", value: !openConfigurator });
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const handleOpenProfile = (event) => {setOpenProfile(event.currentTarget); handlePopUp("ProfilePopup", 0, 3);};

  const toIcon = (icon_name) => {
    switch (icon_name) {
      case "PersonOutlineIcon":
        return <PersonOutlineIcon size="12px" color="primary"/>;
      case "VerifiedUserIcon":
        return <VerifiedUserIcon size="12px" color="primary"/>;
      case "FeaturedVideoIcon":
        return <FeaturedVideoIcon size="12px" color="primary"/>;
      case "FileUploadIcon":
        return <FileUploadIcon size="12px" color="primary"/>;
      case "PaidIcon":
        return <PaidIcon size="12px" color="primary"/>;
      default:
        return <></>;
    }
  }
  const nowDateTimePass = (date) => {
    const now = DateFormatUtils(new Date(), "YYYY/MM/DD HH:mm:ss");
    var ms = moment(now, "YYYY/MM/DD HH:mm:ss").diff(moment(date, "YYYY/MM/DD HH:mm:ss"));
    var d = moment.duration(ms);
    var s = d._data.hours > 0 ?
              d._data.hours + "hours"
              : d._data.minutes > 0 ?
                d._data.minutes + "minutes"
                : d._data.seconds + "seconds";
    return s;
  }
  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      style={{ marginTop: "1rem" }}
    >
      {
        notification.length >= 1 ?
          notification.map((d, index) => {
            return (
              <NotificationItem
                image={toIcon(d.icon)}
                title={d.title}
                date={nowDateTimePass(d.createAt)}
                onClick={handleCloseMenu}
                key={index}
              />
            );
          }) : <></>
      }
    </Menu>
  );

  return (
    <div>
      {
        page > 0 ?
          <Popup
            openPopup={openPopUp}
            setOpenPopup={() => { setOpenPopup(false) }}
          >
              <CustomPopup name={popUpName} id={currentStaff} type={type}></CustomPopup>
          </Popup> : <></>
      }
      <AppBar
        position={absolute ? "absolute" : navbarType}
        color="inherit"
        className={classes.navbar}
      >
        <Toolbar className={classes.navbar_container}>
          <SuiBox customClass={classes.navbar_row} color="inherit" mb={{ xs: 1, md: 0 }}>
            <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
          </SuiBox>
          {isMini ? null : (
            <SuiBox customClass={classes.navbar_row}>
              <SuiBox pr={1}>
                <SuiInput
                  placeholder="Type here..."
                  withIcon={{ icon: "search", direction: "left" }}
                  customClass={classes.navbar_input}
                />
              </SuiBox>
              <SuiBox
                color={light ? "white" : "inherit"}
                customClass={classes.navbar_section_desktop}
              >
                <IconButton
                  size="small"
                  color="inherit"
                  className={classes.navbar_mobile_menu}
                  onClick={handleMiniSidenav}
                >
                  <Icon>{miniSidenav ? "menu_open" : "menu"}</Icon>
                </IconButton>
                <IconButton
                  color="inherit"
                  className={classes.navbar_icon_button}
                  onClick={handleConfiguratorOpen}
                >
                  <Icon>settings</Icon>
                </IconButton>
                <IconButton
                  color="inherit"
                  className={classes.navbar_icon_button}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleOpenMenu}
                >
                  <Icon>notifications</Icon>
                </IconButton>
                <span className="text-sm">{user ? user.name : 'username' } </span>
                <IconButton
                  color="inherit"
                  className={classes.navbar_icon_button}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleOpenProfile}
                >
                  <SuiAvatar src="" alt="Avatar"/>
                </IconButton>
                {renderMenu()}
              </SuiBox>
            </SuiBox>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
