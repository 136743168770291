import userstatus from "./user";
import offerstatus from "./offer";
import walletstatus from "./wallet";
import otcstatus from "./otc";
import orderStatus from "./order";
import advstatus from "./adv";
import savingplanstatus from "./savingplan";

const status = {
    user: userstatus,
    offer: offerstatus,
    wallet: walletstatus,
    otc: otcstatus,
    order: orderStatus,
    adv: advstatus,
    savingplan: savingplanstatus
}

export default status;