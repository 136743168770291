import { TextareaAutosize } from '@material-ui/core';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import UpdateIcon from '@mui/icons-material/Update';
import VerifiedIcon from '@mui/icons-material/Verified';
import { Grid } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import IconButton from "@mui/material/Icon";
import Tooltip from '@mui/material/Tooltip';
import OTCApi from "api/otc";
import UserApi from 'api/user';
import { InputWrapper } from "components/Custom";
import 'components/Custom/common.css';
import SuiButton from "components/Default/SuiButton";
import { OTC_PERMISSION } from 'config/permission';
import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import AuthCodeHelper from "util/AuthCodeHelper";
import { NullFormatUtils } from "util/index";

import "./style.css";

export default function OTC ({ id, type }){
    const alert = useAlert();

    const [page, setPage] = useState(type ?? 2);
    const [auth, setAuth] = useState(false);
    const [otcData, setStaffData] = useState([]);
    const [inputs, setInputs] = useState({}); // inputs
    const [user, setUser] = useState();
    const [foundUser, setFoundUser] = useState(false);
    const [otcDetail, setOtcDetail] = useState([]);
    const [otcUserList, setOtcUserList] = useState([]);
    const [newOTCUserList, setNewOTCUserList] = useState([]);
    const [userAccountList, setUserAccountList] = useState([]);
    const [count, setCount] = useState(1);

    const [paymentMethod, setPaymentMethod] = useState({
        FPS: false,
        BankTransfer: false,
        Cash: false,
        Cheque: false
      });
    const [contact, setContact] = useState({
        Phone: false,
        Whatsapp: false,
        Email: false
      });

    const handleChange = e => setInputs(prevState => {
        if(["phone", "whatsapp", "email"].includes(e.target.name)){
            setContact({...contact, [e.target.name.charAt(0).toUpperCase() + e.target.name.slice(1)]: true})
        }
        
        return ({ ...prevState, [e.target.name]: e.target.value })
    });

    const handlePaymentMethodChange = (event) => {setPaymentMethod({...paymentMethod, [event.target.name]: event.target.checked})};
    const handleContactChange = (event) => {setContact({...contact, [event.target.name]: event.target.checked})};
    const { FPS, BankTransfer, Cash, Cheque } = paymentMethod;
    const { Phone, Whatsapp, Email } = contact;
    
    function getOTCDetails(){
        OTCApi.getOneOTCByFid(id)
        .then((res) => {
            if (res.status === 200){
                setOtcDetail(res.data.data);
            }
        })
        .catch((err) => {
            console.log(err);
        })
    }

    function getOTCUserList(){
        OTCApi.getOTCUserList()
        .then((res) => {
            if (res.status === 200){
                setOtcUserList(res.data.data);
            }
        })
        .catch((err) => {
            console.log(err);
        })
    }

    function getUser(){
        if (inputs['account_id'].length === 6){
            UserApi.getOneUserByAid(inputs['account_id'])
            .then((res) => {
                if (res && res.status === 200) {
                    setUser(res.data.data)
                    setFoundUser(true);
                }
                else setFoundUser(false)
            })
            .catch((err) => {
                console.log("User unfound.")
                setFoundUser(false)
            })
        }else setFoundUser(false)
    }

    const getAllVerifiedShop = async() => {
        await UserApi.getUserWithoutIDShopVerify()
            .then((res) => {
                if(res.status === 200){
                    const shop = Object.entries(res.data.data).map(([k,v]) => ({ id: k, ...v }))
                        .filter(u => u.ShopVerifyStatus === "Approved")
                        .filter(u => u.account_id !== undefined);
                    console.log(shop);
                    const selectValue = shop.map((d, i) => ({ label: d.account_id, value: (i+1) }));
                    console.log(selectValue);
                    setUserAccountList(selectValue);
                }
            })
            .catch((err) => {
                alert.error(err.message);
            })
    }

    useEffect(() => {
        if(otcUserList.length < 1){
            getOTCUserList();
        }   
        if(userAccountList.length < 1){
            if(userAccountList < 3){
                setUserAccountList(userAccountList.length ? userAccountList.length + 1 : userAccountList + 1);
                getAllVerifiedShop();
            }
        }

        /* if (inputs['account_id']){
            getUser();
        } */

        if (page === 1 && id){
            getOTCDetails();
        } else if (page === 2 && !auth){
            setPage(0);
        }
    }, [])

    useEffect(() => {
        setNewOTCUserList(newOTCUserList);
    }, [newOTCUserList])

    const handleEditOTC = () => {
        if(!auth){
            setPage(0);
        } else{
            // edit otc api
        }
    };

    const handleOFFOTC = async () => {
        if(!auth){
            setPage(0);
        } else{
            // edit otc api
            await OTCApi.changeStatus({
                id: id,
                status: "inactive"
            })
                .then((res) => {
                    if(res && res.status === 200){
                        alert.success("Edit OTC success");
                    } else{
                        alert.error("Somethings error");
                    }
                })
                .catch((err) => {
                    alert.error(err.message);
                })
        }
    };

    const handleONOTC = async () => {
        if(!auth){
            setPage(0);
        } else{
            // edit otc api
            await OTCApi.changeStatus({
                id: id,
                status: "active"
            })
                .then((res) => {
                    if(res && res.status === 200){
                        alert.success("Edit OTC success");
                    } else{
                        alert.error("Somethings error");
                    }
                })
                .catch((err) => {
                    alert.error(err.message);
                })
        }
    };

    const handleReleaseOTC = async () => {
        if(!auth){
            setPage(0);
        } else{
            const payment_method = Object.entries(paymentMethod).filter(([k,v]) => v===true).map(([k,v]) => k);
            const contact_arr = Object.entries(contact).filter(([k,v]) => v===true).map(([k,v]) => k);
            for (let index = 0; index < payment_method.length; index++) {
                const element = payment_method[index];
                const payment_method_chin = {
                    "FPS":"FPS轉數快",
                    "Cheque":"支票",
                    "Cash":"現金交收",
                    "BankTransfer":"銀行轉帳"
                }
                payment_method[index] = payment_method_chin[element];
            }

            if(inputs['address']
            && inputs['username']
            && inputs['usdt_lower_limit']
            && inputs['hkd_to_usdt_remaining_amount']
            && inputs['description']
            && inputs['hkd_to_usdt_unit_price']
            && inputs['usdt_upper_limit']
            && inputs['account_id']
            && inputs['usdt_to_hkd_remaining_amount']
            && inputs['usdt_to_hkd_unit_price']
            && payment_method.length >= 1
            && contact_arr.length >= 1){
               // create otc api
                await OTCApi.add({
                    "name": inputs['username'] ?? null,
                    "email": inputs['email'] ?? null,
                    "address": inputs['address'],
                    "phone": inputs['phone'] ?? null,
                    "usdt_lower_limit": inputs['usdt_lower_limit'],
                    "hkd_to_usdt_remaining_amount": inputs['hkd_to_usdt_remaining_amount'],
                    "description": inputs['description'],
                    "hkd_to_usdt_unit_price": inputs['hkd_to_usdt_unit_price'],
                    "usdt_upper_limit": inputs['usdt_upper_limit'],
                    "whatsapp": inputs['whatsapp'] ?? null,
                    "payment_methods": payment_method,
                    "account_id": inputs['account_id'],
                    "usdt_to_hkd_unit_price": inputs['usdt_to_hkd_unit_price'],
                    "usdt_to_hkd_remaining_amount": inputs['usdt_to_hkd_remaining_amount']
                }).then((res) => {
                    if(res && res.status === 200){
                        alert.success("Create OTC success");
                    } else{
                        alert.error("Somethings error");
                    }
                })
                .catch((err) => {
                    alert.error(err.message);
                })
            } else{
                alert.error('You need to fill in all the fields.');
            }
        }
    };

        const attributes = {
            "sell_amount" : "SellingAmount",
            "hkd_to_usdt_unit_price": "SellingPrice",
            "email": "Email",
            "address": "BranchAddress",
            "usdt_lower_limit": "LowerLimit",
            "phone": "Phone",
            "description": "TradeDescription",
            "usdt_upper_limit": "UpperLimit",
            "whatsapp": "WhatsApp",
            "usdt_to_hkd_remaining_amount": "BuyingAmount",
            "usdt_to_hkd_unit_price": "BuyingPrice"

        }

    //get corresponding data in regards of edit or not
    function getData(attr){ 
        if (otcDetail && showEditOrNot[type]){
            return otcDetail[attr];
        }
        return inputs[attr];
    }

    const showEditOrNot = {
        1: true, // otc details
        2: false,
        3: true
    }

    const actionButtons = {
        1: <SuiButton buttonColor="secondary" onClick={handleReleaseOTC}>Release</SuiButton>,
        "active": 
            <>
                {/* <SuiButton buttonColor="secondary" className="mr-1-5rem" onClick={handleEditOTC}>Edit</SuiButton> */}
                <SuiButton buttonColor="secondary" onClick={handleOFFOTC}>OFF</SuiButton>
            </>,
        "inactive": <SuiButton buttonColor="secondary" onClick={handleONOTC}>On</SuiButton>
    }

    const actionEdit = e => {
        let new_user = newOTCUserList;
        new_user.push({
            account_id: ""
        });
        setCount(count + 1);
        setNewOTCUserList(new_user);
        console.log(newOTCUserList);
    }

    const removeItem = async (key, action) => {
        if(action === "call_api"){
            await OTCApi.removeOTCUser(key)
                .then((res) => {
                    if(res.status === 200){
                        alert.success(`Remove ${key} success`);
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
        }
        else if(action === "remove_from_list"){

        }
    }

    const addItem = async (index) => {
        if(index !== false && index !== null){
            console.log(index);
            const data = newOTCUserList.filter((d, i) => i === index)[0];
            
            await OTCApi.addOTCUser(data)
                .then((res) => {
                    if(res.status === 200){
                        alert.success(`Add ${data.account_id} into list`);
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    }

    const handleAuthCode = (resp) => {
        // console.log(resp);

        if(resp){
            if(resp.isAuth){
                setAuth(true);
                setPage(type);
            } else{
                alert.error(resp.message);
            }
        }
        else{
            alert.error(ERROR.callback);
        }
    }

    const handleStoreNewUser = (inputName, account_id, index) => {
        setInputs(prevState => ({ ...prevState, [inputName]: account_id }));

        if(account_id){
            let new_user = newOTCUserList;
            new_user.map((d, i) => {
                i === index ? d["account_id"] = account_id : ""
            })
            setNewOTCUserList(new_user);
            console.log(newOTCUserList);
        }
    }

    if(page === 0){
        return (<AuthCodeHelper title="Admin Authentication" btnName="Confirm" permission={OTC_PERMISSION.VIEW} callback={handleAuthCode}/>);
    }
    else if(page === 1){
        return (
            <div id="popup1" className="popup-wrapper">
                <div id="" className="popup1-flex-column">
                    <h4>OTC Offer Details</h4>
                    <div className="popup-content">
    
                        <Grid container alignItems="center" className="input-wrapper-rows">
                            <Grid item xs={12} sm={8} md={6} xl={6} >
                                <InputWrapper attr={
                                    <div className="icons-row" >
                                        Seller ID 

                                        <div>
                                            <Tooltip title="6 characters Account ID">
                                                <IconButton color="secondary">
                                                <InfoIcon/>
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <div className="right"><VerifiedIcon color={foundUser ? "success" : "action"}/></div>
                                </div>
                                } 
                                name="account_id" value={inputs["account_id"] ?? NullFormatUtils(otcDetail.user?.account_id)} onChange={handleChange} size="lg" disable={showEditOrNot[type]}/>
                               
                            </Grid>
                            
                            <Grid item xs={12} sm={8} md={6} xl={6} className="input-1">
                                <InputWrapper attr="Seller Name" name="username" value={inputs["username"] ?? NullFormatUtils(otcDetail.user?.user_name)} onChange={handleChange}  size="lg" disable={showEditOrNot[type]}/>
                            </Grid>
                        </Grid>
    
                        {/* <Grid container alignItems="center" className="input-wrapper-rows">
                            <Grid item xs={12} sm={8} md={6} xl={6} className="input-1">
                                <InputWrapper attr="Seller Name" value="" size="lg" disable={showEditOrNot[type]}/>
                            </Grid>
                            <Grid item xs={12} sm={8} md={6} xl={4} mr={6}>
                                <InputWrapper attr="Account Level" value="" size="lg" disable={showEditOrNot[type]}/>
                            </Grid>
                        </Grid> */}
                        
    
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={8} md={6} xl={6}>
                                <InputWrapper attr="Selling Amount" name="hkd_to_usdt_remaining_amount" value={getData("hkd_to_usdt_remaining_amount")} onChange={handleChange} size="lg" disable={showEditOrNot[type]}/>
                            </Grid>
                            <Grid item xs={12} sm={8} md={6} xl={6}>
                                <InputWrapper attr="Selling Price" name="hkd_to_usdt_unit_price" value={getData("hkd_to_usdt_unit_price")} onChange={handleChange} size="lg" disable={showEditOrNot[type]}/>
                            </Grid>  
                        </Grid>

                        <Grid container alignItems="center">
                           
                            <Grid item xs={12} sm={8} md={6} xl={6}>
                                <InputWrapper attr="Buying Amount" name="usdt_to_hkd_remaining_amount" value={getData("usdt_to_hkd_remaining_amount")} onChange={handleChange} size="lg" disable={showEditOrNot[type]}/>
                            </Grid>
                            <Grid item xs={12} sm={8} md={6} xl={6}>
                                <InputWrapper attr="Buying Price" name="usdt_to_hkd_unit_price" value={getData("usdt_to_hkd_unit_price")} size="lg" onChange={handleChange} disable={showEditOrNot[type]}/>
                            </Grid>
                        </Grid>

                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={8} md={6} xl={6}>
                                <InputWrapper attr="Account Level" name="account_level" 
                                    value={ foundUser && user ? (user.ShopVerifyStatus === "Approved" ? "Shop" : (user.IdVerifyStatus === "Approved" ? "ID" : "")): ""  }
                                    size="lg" onChange={handleChange} disable={true}/>
                            </Grid>
                            <Grid item xs={12} sm={8} md={6} xl={6}>
                                <InputWrapper attr="Crypto" name="crypto" value="USDC" size="lg" onChange={handleChange} disable={true}/>
                            </Grid>
                        </Grid>
    
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={8} md={6} xl={6}>
                                <InputWrapper attr="Lower Limit" name="usdt_lower_limit" value={getData("usdt_lower_limit")} onChange={handleChange} size="lg" disable={showEditOrNot[type]}/>
                            </Grid>
                            <Grid item xs={12} sm={8} md={6} xl={6}>
                                <InputWrapper attr="Upper Limit" name="usdt_upper_limit" value={getData("usdt_upper_limit")} onChange={handleChange} size="lg" disable={showEditOrNot[type]}/>
                            </Grid>
                        </Grid>
    
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={8} md={6} xl={6}>
                                <InputWrapper attr="Create At" value="" size="lg" disable={true}/>
                            </Grid>
                            <Grid item xs={12} sm={8} md={6} xl={6}>
                                <InputWrapper attr="Update At" value="" size="lg" disable={true}/>
                            </Grid>
                        </Grid>
    
                        <div style={{ fontWeight: 'bold', fontSize: '1rem'}}>Payment Method</div>
                        <FormGroup className="popup-input-wrapper">
                            <FormControlLabel
                                control={<Checkbox checked={FPS} onChange={handlePaymentMethodChange} name="FPS" />}
                                label="FPS"
                                />
                            <FormControlLabel
                                control={<Checkbox checked={BankTransfer} onChange={handlePaymentMethodChange} name="BankTransfer" />}
                                label="Bank Transfer"
                                />
                            <FormControlLabel
                                control={<Checkbox checked={Cash} onChange={handlePaymentMethodChange} name="Cash" />}
                                label="Cash"
                                />
                            <FormControlLabel
                                control={<Checkbox checked={Cheque} onChange={handlePaymentMethodChange} name="Cheque" />}
                                label="Cheque"
                                />
                        </FormGroup>
    
                        <div style={{ fontWeight: 'bold', fontSize: '1rem'}}>Contact</div>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={Phone} onChange={handleContactChange} name="Phone" />}
                                label="Phone"
                                />
                            <div><InputWrapper attr="+852" name="phone" value={getData("phone")} onChange={handleChange} size="sm" disable={showEditOrNot[type]}/></div>
    
                            <FormControlLabel
                                control={<Checkbox checked={Whatsapp} onChange={handleContactChange} name="Whatsapp" />}
                                label="Whatsapp"
                                />
                            <div><InputWrapper name="whatsapp" value={getData("whatsapp")} onChange={handleChange} disable={showEditOrNot[type]}/></div>
    
                            <FormControlLabel
                                control={<Checkbox checked={Email} onChange={handleContactChange} name="Email" />}
                                label="Email"
                                />
                            <div><InputWrapper name="email" value={inputs["email"] ?? getData("email")} onChange={handleChange} disable={showEditOrNot[type]}/></div>
                        </FormGroup>
    
                    </div>
                </div>
    
                <div id="" className="security">
                    <div className="popup-content">
                        <h4>Trade Description</h4>
                        <div id="offer-description">
                            <TextareaAutosize name="description" value={getData("description")} style={{ height: 200, width: 600 }} disabled={false} onChange={handleChange}/>
                        </div>
    
                        <h4>Branch address</h4>
                        <div id="offer-description">
                            <TextareaAutosize name="address" value={getData("address")} style={{ height: 200, width: 600 }} disabled={false} onChange={handleChange}/>
                        </div>
    
                        <div id="popup-phone popup-sex" className="popup-form-row">
                            <InputWrapper attr="Status" value={getData("status")} size="sm"/>
                            <InputWrapper attr="Handler" value={otcData.Handler ? NullFormatUtils(otcData.Handler.Name) : '-'} size="sm"/>
                        </div>
                    </div>
                    
                    <div style={{ float: 'right', marginTop: '1rem' }}>
                        {   actionButtons[  getData("status") ]  }
                    </div>
                </div>
                
                
            </div>
        );
    }
    else if(page === 2){
        // View User List
        return (
            <div id="popup1" className="popup-wrapper">
                <div id="" className="popup1-flex-column">
                    <h4>OTC User List</h4>
                    <div className="popup-content">
                        {
                            otcUserList.length >= 1?
                                otcUserList.map((d, index) => {
                                    return (
                                        <Grid container key={index}>
                                            <Grid item>
                                                <InputWrapper value={d.account_id} size="sm"/>
                                            </Grid>
                                            <Grid item>
                                                <SuiButton buttonColor="error" onClick={() => removeItem(d.account_id, "call_api")}>
                                                    <DeleteIcon/>
                                                </SuiButton>
                                            </Grid>
                                        </Grid>
                                    )
                                })
                                : <></>
                        }
                        <div style={{ display: "none" }}>{newOTCUserList.length}</div>
                        {   newOTCUserList.length >= 1 ? 
                                <h5 style={{ backgroundColor: "#17c1e8", fontWeight: 'normal', paddingLeft: '0.5rem', color: 'white' }}>
                                    New Users
                                </h5> 
                                : <></>}
                        {
                            newOTCUserList.map((d, index) => {
                                    return (
                                        <Grid container key={index}>
                                            <Grid item>
                                                <InputWrapper name={"accountID_"+index} value={inputs["accountID_"+index]} onChange={(e)=>{ handleStoreNewUser(e.target.name, e.target.value, index)}}  size="m" disable={false}/>
                                            </Grid>
                                            <Grid item>
                                                <SuiButton onClick={() => addItem(index)}>
                                                    <UpdateIcon/>
                                                </SuiButton>
                                                <SuiButton buttonColor="error" onClick={() => removeItem(index, "remove_from_list")}>
                                                    <DeleteIcon/>
                                                </SuiButton>
                                            </Grid>
                                        </Grid>
                                    );
                                })
                        }

                        <SuiButton buttonColor="secondary" onClick={(e) => actionEdit(e.value)}>
                            <AddCircleIcon/>
                        </SuiButton>
                    </div>
                </div>
            </div>
        );
    }
}