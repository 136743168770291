//commons
import 'components/Custom/common.css';
import Grid from "@mui/material/Grid";
import InputWrapper from 'components/Custom/input_wrapper';
import { NullFormatUtils, DateFormatUtils } from "util/index";
import { ImageURLExistHelper } from 'util/index';
import ImageViewer from "components/Custom/image_viewer";

function Icon({ userData }) {
    return (
        <div id="popup-icon" className="popup-form-row">
            <ImageViewer headerText="Icon" clickText="Click to View" type={0} src={ImageURLExistHelper(userData.UserIcon)} title={true} />
            <ImageViewer headerText="Header Image" clickText="Click to View" type={0} src={ImageURLExistHelper(userData.HeaderImage)} title={true} />
        </div>
    )
}

function PersonalDetails({ id, userData }) {
    return (
        <>
            <Grid container alignItems="center">
                <Grid item xs={12} sm={8} md={6} xl={5}>
                    <InputWrapper attr="Account ID" value={NullFormatUtils(userData.account_id)} size="lg" />
                </Grid>
                <Grid item xs={12} sm={8} md={6} xl={7}>
                    <InputWrapper attr="User ID" value={NullFormatUtils(userData.firebase_user_id)} fullWidth={false} size="sm" />
                </Grid>
            </Grid> 

            <Grid id="popup-fullName">
                <InputWrapper attr="Full Name" value={NullFormatUtils(userData.id_verification?.full_name)} size="m" />
            </Grid>

            <Grid id="popup-email">
                <InputWrapper attr="Email" value={NullFormatUtils(userData.Email)} size="sm" />
            </Grid>
            
            <Grid container alignItems="center">
                <Grid item xs={12} sm={8} md={6} xl={6}>
                    <InputWrapper attr="Phone" value={NullFormatUtils(userData.Phone)} size="sm" />
                </Grid>
                <Grid item xs={12} sm={8} md={6} xl={6}>
                    <InputWrapper attr="Gender" value={NullFormatUtils(userData.id_verification?.gender)} size="sm" />
                </Grid>
            </Grid>

            <Grid container alignItems="center">
                <Grid item xs={12} sm={8} md={6} xl={6}>
                    <InputWrapper attr="Birthday" value={
                        DateFormatUtils(userData.id_verification?.birthday) ?? "-"} size="sm" />
                </Grid>
                <Grid item xs={12} sm={8} md={6} xl={6}>
                    <InputWrapper attr="Likes" value={userData.likes ? userData.likes.length : 0} size="sm" />
                </Grid>
            </Grid>

            <div id="popup-description">
                <InputWrapper attr="Description" value={NullFormatUtils(userData.description)} size="lg" />
            </div>
        </>
    )
}

function DisplayAddress({ userData }) {
    return (
        <>
            <div className="popup-subtitle">Display Address:</div>
            <InputWrapper attr="Address" value={userData.DisplayAddress?.Address1 ?? "-"} size="lg" />
            {/* <InputWrapper attr="Address 2" value={userData.DisplayAddress?.Address2 ?? "-"} size="lg" /> */}
            <InputWrapper attr="District" value={userData.DisplayAddress?.District ?? "-"} size="lg" />
        </>
    );
}

function Status({ userData }) {
    return (
        <InputWrapper attr="Account Status" value={userData.AccountStatus} size="lg" />
    );
}

export { Icon, PersonalDetails, DisplayAddress, Status };