import { Loader } from 'components/Custom';
import { FieldsNotFoundError, PageController } from 'components/Custom/custom_container';
import InputWrapper from 'components/Custom/input_wrapper';
import { constant } from "config/constant";
import { USER_PERMISSION } from 'config/permission';
import CustomerGateway from 'entities/customerGateway';
import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import IncomeDeclarationTemplate from 'template/incomeDeclarationTemplate';
import AuthCodeHelper from "util/AuthCodeHelper";
import { ERROR } from 'util/ErrorHelper';
import { NullFormatUtils } from "util/index";
import './styles.css';

let customerGateway = new CustomerGateway();

export default function IncomeDeclarationPopup({ id }) {
    const fields = [id];
    const alert = useAlert();
    const [auth, setAuth] = useState(false);
    const [page, setPage] = useState(0);
    const [userData, setUserData] = useState();
    const [incomeDeclareData, setIncomeDeclareData] = useState();
    const [loading, setLoading] = useState(true);

    let testMode = constant.DEBUG;

    const handleAuthCode = (resp) => {
        // console.log(resp);
    
        if(resp){
            if(resp.isAuth){
                setAuth(true);
                setPage(0);
            } else{
                alert.error(resp.message);
            }
        }
        else{
            alert.error(ERROR.callback);
        }
    }

    const fetchData = async () => {
        await customerGateway.getOneUserData({
            type: "firebase_id",
            id: id,
            format: "Income"
        })
            .then((res) => {
                if (res) {
                    setUserData(res.data);
                    setIncomeDeclareData(res.data.incomeDeclaration);

                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        if(!auth){
            setPage(1);
        } 
        else {
            fetchData();
        }
    }, [auth]);

    /* 
        Component
    */
    const Title = () => {
        return (
            <h4>Income Declaration</h4>
        );
    }

    const UserID = () => {
        return (
            <InputWrapper attr="User ID" value={NullFormatUtils(userData?.account_id)} size="xl" />
        );
    }

    const WorkingStatus = () => {
        return (
            <InputWrapper attr="Working Status" value={NullFormatUtils(incomeDeclareData?.workingStatus)} size="xl" />
        );
    }

    const MaritalStatus = () => {
        return (
            <InputWrapper attr="Marital Status" value={NullFormatUtils(incomeDeclareData?.maritalStatus)} size="xl" />
        );
    }

    const SourceOfIncome = () => {
        return (
            <InputWrapper attr="Source Of Income" value={NullFormatUtils(incomeDeclareData?.sourceOfIncome)} size="xl" />
        );
    }

    const CryptoExperience = () => {
        return (
            <InputWrapper attr="Crypto Experience" value={NullFormatUtils(incomeDeclareData?.cryptoExperience)} size="xl" />
        );
    }

    const PersonalAssetHKD = () => {
        return (
            <InputWrapper attr="Personal Asset HKD" value={NullFormatUtils(incomeDeclareData?.personalAssetHKD)} size="xl" />  
        );
    }

    const JobTitle = () => {
        return (
            <InputWrapper attr="Job Title" value={NullFormatUtils(incomeDeclareData?.jobTitle)} size="xl" />   
        );
    }

    const Industry = () => {
        return (
            <InputWrapper attr="Industry" value={NullFormatUtils(incomeDeclareData?.industry)} size="xl" />
        );
    }

    return (
        <FieldsNotFoundError data={fields}>
            <PageController targetPage={0} currentPage={page} CASE="">
                <Loader loading={loading}>
                    <IncomeDeclarationTemplate
                        Title={Title}
                        UserID={UserID}
                        WorkingStatus={WorkingStatus}
                        MaritalStatus={MaritalStatus}
                        SourceOfIncome={SourceOfIncome}
                        CryptoExperience={CryptoExperience}
                        PersonalAssetHKD={PersonalAssetHKD}
                        JobTitle={JobTitle}
                        Industry={Industry}
                    />
                </Loader>
            </PageController>

            <PageController targetPage={1} currentPage={page} CASE="">
                <AuthCodeHelper title="Admin Authentication" btnName="Confirm" permission={USER_PERMISSION.INCOME_DECLAR} callback={handleAuthCode}/>
            </PageController>
        </FieldsNotFoundError>
    );
}