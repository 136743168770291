const initalValue = {
    Int: 0,
    Double: '0.0',
    String: "--",
    Email: "--",
    Image: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/70/Checkerboard_pattern.svg/1200px-Checkerboard_pattern.svg.png",
    Datetime: "YYYY-MM-DD HH:mm:ss",
    Boolean: false
};

export default initalValue;