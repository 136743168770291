import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TextareaAutosize } from "@mui/material";
import { Box } from '@mui/system';
import OfferApi from 'api/offer';
import { InputWrapper, Loader } from 'components/Custom';
import 'components/Custom/common.css';
import { FieldsNotFoundError, PageController, StatusContainer } from "components/Custom/custom_container";
import ExpandMore from 'components/Custom/expand_more';
import ImageViewer from "components/Custom/image_viewer";
import SuiButton from "components/Default/SuiButton";
import { constant } from "config/constant";
import { OFFER_PERMISSION } from 'config/permission';
import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import OfferDetailsTemplate from 'template/offerDetailsTemplate';
import AuthCodeHelper from "util/AuthCodeHelper";
import { ImageURLExistHelper, NullFormatUtils } from "util/index";


export default function OfferDetails({ id }) {
    const fields = [id];

    const alert = useAlert();
    const [page, setPage] = useState(1);
    const [offerData, setOfferData] = useState([]); 
    const [loading, setLoading] = useState(true);
    const [liked, setLiked] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const [auth, setAuth] = useState(false);
    const [authAction, setAuthAction] = useState();

    let testMode = constant.DEBUG;

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const AuthActionList = {
        "Blocked": OFFER_PERMISSION.BLOCK,
        "On": OFFER_PERMISSION.UNBLOCK
    }

    const handleAuthCode = (resp) => {
        // console.log(resp);

        if(resp){
            if(resp.isAuth){
                setAuth(true);
                setPage(1);
            } else{
                alert.error(resp.message);
            }
        }
        else{
            alert.error(ERROR.callback);
        }
    }
    
    async function EditOfferStatus(id, status) {
        if(!auth){
            setAuthAction(status);
            setPage(0);
        } else {
            try {
                let res;
                if(status === "Blocked"){
                    res = await OfferApi.blockOffer(id);
                } else if (status === "On"){
                    res = await OfferApi.unBlockOffer(id);
                }
    
                if(res.status === 200){
                    alert.success("Change Offer Status Success");
                }
        
            } catch (err) {
                alert.error(err);
            }
        }
    }

    const fetchOneOfferData = async (id) =>{
        await OfferApi.getOneOfferByFid(id)
            .then((res) => {
                if (res.status === 200) {
                    setOfferData(res.data.data);
                    setLoading(false);
                }
            }).catch((e) => {
                console.log(e);
                if(testMode){
                    alert.success("Run Sample Mode");
                    setLoading(false);
                }
            })
    }
    
    useEffect(()=>{
        if(offerData.length < 1){
            fetchOneOfferData(id);
        }
    }, [])

    /* 
        Components
     */

    const Title = () => <h4>Offer Details</h4>;
    const OfferId = () => <InputWrapper attr="Offer ID" value={id} size="sm"/>
    const SellerId = () =>  <InputWrapper attr="Seller ID" value={offerData?.User?.account_id ?? "Account ID"} size="sm"/>
    const AccountLevel = () => <InputWrapper attr="Account Level" value={offerData?.User?.ShopVerifyStatus ?? "-"} size="lg"/>
    const OfferTitle = () => <InputWrapper attr="Title" value={NullFormatUtils(offerData?.title)} size="sm"/> 
    const Price = () => <InputWrapper attr="Price" value={NullFormatUtils(offerData?.price)} size="sm"/>
    const Crypto = () => <InputWrapper attr="Crypto" value={NullFormatUtils(offerData?.cryptoType)} size="sm"/>
    const Inventory = () => <InputWrapper attr="Inventory" value={NullFormatUtils(offerData?.stock)} size="sm"/>
    const Likes = () => <InputWrapper attr="Likes" size="sm" value={offerData?.likes} />
    const Category = () => <InputWrapper attr="Category" value={NullFormatUtils(offerData?.category)} size="sm"/>
    const Sales = () => <InputWrapper attr="Sales" value={NullFormatUtils(offerData?.sales)} size="sm"/>
    const Delivery = () => <InputWrapper attr="Delivery" value={NullFormatUtils(offerData?.delivery_method)} size="lg"/>
    const ProductCondition = () => <InputWrapper attr="Product Condition" value={NullFormatUtils(offerData?.product_condition)} size="lg"/>

    const HeaderImage = () => {
        return (
            <StatusContainer status={true} param={expanded}>
                <ImageViewer headerText="Header Image" clickText="Click to View" type={0} src={ImageURLExistHelper(offerData?.header_image)} title={true}/>
            </StatusContainer>
        );
    }

    const BackgroundImage = () => {
        return (
            <StatusContainer status={true} param={expanded}>
                <ImageViewer headerText="Background Image" clickText="Click to View" type={0} src={ImageURLExistHelper(offerData?.background_image)} title={true}/>
            </StatusContainer>
        );
    }

    const ContentImage = () => {
        return (
            <StatusContainer status={true} param={expanded}>
                 <ImageViewer headerText="Content Image" clickText="Click to View" type={1} src={ImageURLExistHelper(offerData?.content_images)} title={true}/>
            </StatusContainer>
        );
    }

    const Title2 = () => <h4>Offer Description</h4>
    const Description = () => <TextareaAutosize style={{ height: 500, width: 600 }} disabled={true} value={NullFormatUtils(offerData?.description)}/>
    const Status = () => <InputWrapper attr="Status" value={NullFormatUtils(offerData?.status)} size="sm"/>
    const Handler = () => <InputWrapper attr="Handler" value={NullFormatUtils(offerData?.handler_id)} size="sm"/>

    const ExpandMoreController = () => {
        return (
            <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
                >
                <ExpandMoreIcon />
            </ExpandMore>
        );
    }

    const ActionButton = () => {
        return (
            <>
                <StatusContainer status="On" param={offerData?.Status}>
                    <Box display="flex" flexDirection="row" columnGap={2} py={2} className="button-end">
                        <SuiButton buttonColor="secondary" onClick={() => EditOfferStatus(id, "Blocked")}>Blocked</SuiButton>
                    </Box>
                </StatusContainer>

                <StatusContainer status="Blocked" param={offerData?.Status}>
                    <Box display="flex" flexDirection="row" columnGap={2} py={2} className="button-end">
                        <SuiButton buttonColor="secondary" onClick={() => EditOfferStatus(id, "On")}>UnBlocked</SuiButton>
                    </Box>
                </StatusContainer>
            </>
        )
    }

    return (
        <FieldsNotFoundError data={fields}>
            <Loader loading={loading}>

                <PageController targetPage={0} currentPage={page} CASE="">
                    <AuthCodeHelper title="Admin Authentication" btnName="Confirm" permission={AuthActionList[authAction]} callback={handleAuthCode}/>
                </PageController>

                <PageController targetPage={1} currentPage={page} CASE="">
                    <OfferDetailsTemplate
                          Title={Title}
                          OfferId={OfferId}
                          SellerId={SellerId}
                          AccountLevel={AccountLevel}
                          OfferTitle={OfferTitle}
                          Price={Price}
                          Crypto={Crypto}
                          Inventory={Inventory}
                          Likes={Likes}
                          Category={Category}
                          Sales={Sales}
                          Delivery={Delivery}
                          ProductCondition={ProductCondition}
                          HeaderImage={HeaderImage}
                          BackgroundImage={BackgroundImage}
                          ContentImage={ContentImage}
                          ActionButton={ActionButton}
                          Title2={Title2}
                          Description={Description}
                          Status={Status}
                          Handler={Handler}
                          ExpandMoreController={ExpandMoreController}
                    />
                </PageController>

            </Loader>
        </FieldsNotFoundError>
    );
}