import WalletApi from "api/wallet";
import initalValue from "entities/base/initalValue";
import FirebaseGateway from "./firebaseGateway";

const Scheme = {
    id: initalValue.Int,
    account_id: initalValue.String,
    userId: initalValue.String,
    username: initalValue.String,
    totalBTC: initalValue.Double,
    totalHKD: initalValue.Double
}

export default class WalletGateway {
    constructor() { }

    getScheme() {
        return Scheme;
    };

    async getAllData({ page, searchValue }) {
        let firebaseGateway = new FirebaseGateway();
        let latestToken = await firebaseGateway.getToken();

        return await WalletApi.list({
            page: page,
            id_token: latestToken,
            searchValue: searchValue
        })
            .then(response => {
                if (response.status === 200) {
                    return {
                        data: this.formatWalletsData(response.data.data.data),
                        last_page: response.data.data.last_page,
                        per_page: response.data.data.per_page,
                        total: response.data.data.total
                    };
                }
            })
    }

    formatWalletData(apiData, index) {
        const {
            account_id, mysql_user_id, username, total_BTC, total_HKD
        } = apiData;

        return {
            id: index + 1,
            account_id: account_id,
            userId: mysql_user_id,
            username: username,
            totalBTC: total_BTC,
            totalHKD: total_HKD
        };
    }

    formatWalletsData(apiData) {
        return apiData.map((data, index) => {
            return this.formatWalletData(data, index);
        });
    }
}