import UserApi from "api/user";
import initalValue from "entities/base/initalValue";
import FirebaseGateway from "./firebaseGateway";
import AuthApi from "api/auth";

const BetaScheme = {
    id: initalValue.String,
    account_id: initalValue.String,
    mysql_id: initalValue.Int,
    username: initalValue.String,
    email: initalValue.Email,
    phone: initalValue.String,
    createAt: initalValue.Datetime,
    userIcon: initalValue.Image,
    headerImage: initalValue.Image,
    gender: initalValue.String,
    birthday: initalValue.String,
    likes: initalValue.Int,
    description: initalValue.String,
    address: initalValue.String,
    district: initalValue.String,
    isPermitBiometrics: initalValue.Boolean,
    isPermitSms: initalValue.Boolean,
    smsphone: initalValue.String,
    isPermitGoogleAuth: initalValue.Boolean,
    IdVerifyStatus: {
        None: "None",
        Processing: "Processing",
        Approved: "Approved",
        Rejected: "Rejected"
    },
    ShopVerifyStatus: {
        None: "None",
        Processing: "Processing",
        Approved: "Approved",
        Rejected: "Rejected"
    },
    AccountStatus: {
        None: "None",
        Active: "Active",
        Deleted: "Deleted",
        EmailNotVerified: "EmailNotVerified",
        Blocked: "Blocked"
    }
}

const IdVerificationScheme = {
    fullName: initalValue.String,
    idNumber: initalValue.String,
    gender: initalValue.String,
    birthday: initalValue.String,
    address: initalValue.String,
    district: initalValue.String,
    idBackImage: initalValue.Image,
    idFrontImage: initalValue.Image,
    addrImage: initalValue.Image,
    internalRemarks: initalValue.String,
    rejectReason: initalValue.String,
    createAt: initalValue.Datetime,
    updateAt: initalValue.Datetime,
    handler: initalValue.String
}

const ShopVerificationScheme = {
    address: initalValue.String,
    district: initalValue.String,
    brImage: initalValue.Image,
    brNumber: initalValue.String,
    companyName: initalValue.String,
    companyPhone: initalValue.String,
    addrImage: initalValue.Image,
    qrImage: initalValue.Image,
    createAt: initalValue.Datetime,
    updateAt: initalValue.Datetime,
    rejectReason: initalValue.String,
    internalRemarks: initalValue.String,
    handler: initalValue.String
}

const IncomeDeclarationScheme = {
    cryptoExperience: initalValue.String,
    industry: initalValue.String,
    internationality: initalValue.String,
    jobTitle: initalValue.String,
    maritalStatus: initalValue.String,
    personalAssetHKD: initalValue.String,
    sourceOfIncome: initalValue.String,
    workingStatus: initalValue.String,
    createAt: initalValue.Datetime,
    updateAt: initalValue.Datetime
}

const ReferralScheme = {
    invitedBy: BetaScheme,
    BTC: initalValue.Double,
    ETH: initalValue.Double,
    USDC: initalValue.Double,
    USDT: initalValue.Double,
    friends: []
}

export default class CustomerGateway {
    constructor() { }

    getScheme() {
        return [BetaScheme, IdVerificationScheme, ShopVerificationScheme, IncomeDeclarationScheme, ReferralScheme];
    };

    async testAuthorizationCodes({ code }) {
        return await AuthApi.TestAuthorizationCodes(code)
            .then(response => {
                if (response.status === 200) {
                    return response?.data?.data;
                }
            })
            .catch((err) => {
                console.log(err.message);
            })
    }

    async getAllData({ page, statusStatement, searchValue }) {
        let firebaseGateway = new FirebaseGateway();
        let latestToken = await firebaseGateway.getToken();

        return await UserApi.getUserWithoutIDShopVerifyByPage({
            page: page,
            statusStatement: statusStatement,
            id_token: latestToken,
            searchValue: searchValue
        })
            .then(response => {
                if (response.status === 200) {
                    return {
                        data: this.formatCustomersData(response.data.data.data),
                        last_page: response.data.data.last_page,
                        per_page: response.data.data.per_page,
                        total: response.data.data.total
                    };
                }
            })
    }

    async getOneUserData({ type = "firebase_id", id = 0, format = "" }) {
        //let firebaseGateway = new FirebaseGateway();
        //let latestToken = await firebaseGateway.getToken();

        if (type === "firebase_id") {
            return await UserApi.getOneUserByFid(id)
                .then(response => {
                    if (response.status === 200) {
                        let dataset = this.formatCustomerData(response.data.data);
                        if (format === "IdVerify") {
                            dataset.idVerification = this.formatIdVerifyData(response.data.data.id_verification);
                        }
                        if (format === "ShopVerify") {
                            dataset.shopVerification = this.formatShopVerifyData(response.data.data.shop_verification);
                        }
                        if (format === "Income") {
                            dataset.incomeDeclaration = this.formatIncomeDeclarationData(response.data.data.income_declaration);
                        }
                        return {
                            data: dataset
                        }
                    }
                })
        }
        else if (type === "account_id") {
            return await UserApi.getOneUserByAid(id)
                .then(response => {
                    if (response.status === 200) {
                        return {
                            data: this.formatCustomerData(response.data.data)
                        }
                    }
                })
        }
    }

    async getOneUserHistory(id) {
        return await UserApi.getOneUserHistoryBySqlId(id)
            .then(response => {
                if (response.status === 200) {
                    let email = response.data.data.filter((d) => d.data_type === "email")
                    let phone = response.data.data.filter((d) => d.data_type === "phone_number")
                    return {
                        data: {
                            email: email,
                            phone: phone
                        }
                    };
                }
            })
    }

    async getOneReferralData(id) {
        return await UserApi.getReferralById(id)
            .then(response => {
                if (response.status === 200) {
                    return {
                        data: this.formatReferralData(response.data.data)
                    }
                }
            })
    }

    async editIdVerifyStatusWithReason({ id, status, reject_reason }) {
        let firebaseGateway = new FirebaseGateway();
        let latestToken = await firebaseGateway.getToken();

        return await UserApi.editIdVerifyWithStatusAndReason({
            id: id,
            status: status,
            rejected_reason: reject_reason,
            id_token: latestToken
        }).then(response => {
            if (response.status === 200) {
                return true;
            }
            return false;
        }).catch(error => {
            console.log(error.message);
            return false;
        })
    }

    async checkHKID({ hkid }) {
        return await UserApi.hkidCheck({ hkid: hkid })
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.data.length > 1) {
                        let list = res.data.data.filter(function (value) {
                            if (value !== id) {
                                return value
                            }
                        });

                        return {
                            data: "The HKID has been registered by user with ID " + list.join(", ")
                        }
                    } else {
                        return {
                            data: "OK"
                        }
                    }
                }
            })
    }

    async depositAdCredit({ id, amount, reason }) {
        return await UserApi.depositAdCredit({
            id: id,
            amount: amount,
            reason: reason
        }).then((res) => {
            if (res.status === 200) {
                return true;
            }
        }).catch((e) => {
            return false;
        })
    }

    formatReferralData(apiData) {
        return {
            invitedBy: this.formatCustomerData(apiData.referral_user),
            BTC: apiData?.total_rewards.BTC,
            ETH: apiData?.total_rewards.ETH,
            USDC: apiData?.total_rewards.USDC,
            USDT: apiData?.total_rewards.USDT,
            friends: apiData?.friend_rewards
        }
    }

    formatIdVerifyData(apiData) {
        const {
            full_name, id_number, gender, address, birthday, district, id_back_image, id_front_image,
            address_image, internal_remarks, reject_reason, created_at, updated_at, handler_id
        } = apiData;

        return {
            fullName: full_name || IdVerificationScheme.fullName,
            idNumber: id_number || IdVerificationScheme.idNumber,
            gender: gender || IdVerificationScheme.gender,
            birthday: birthday || IdVerificationScheme.birthday,
            address: address || IdVerificationScheme.address,
            district: district || IdVerificationScheme.district,
            idBackImage: id_back_image || IdVerificationScheme.idBackImage,
            idFrontImage: id_front_image || IdVerificationScheme.idFrontImage,
            addrImage: address_image || IdVerificationScheme.addrImage,
            internalRemarks: internal_remarks || IdVerificationScheme.internalRemarks,
            rejectReason: reject_reason || IdVerificationScheme.rejectReason,
            createAt: created_at || IdVerificationScheme.createAt,
            updateAt: updated_at || IdVerificationScheme.updateAt,
            handler: handler_id || IdVerificationScheme.handler
        }
    }

    formatShopVerifyData(apiData) {
        const {
            address, district, br_image, br_number, company_name, company_phone, address_image, shop_qr_image, internal_remarks, reject_reason, created_at, updated_at, handler_id
        } = apiData;

        return {
            address: address || ShopVerificationScheme.address,
            district: district || ShopVerificationScheme.district,
            brImage: br_image || ShopVerificationScheme.brImage,
            brNumber: br_number || ShopVerificationScheme.brNumber,
            companyName: company_name || ShopVerificationScheme.companyName,
            companyPhone: company_phone || ShopVerificationScheme.companyPhone,
            addrImage: address_image || ShopVerificationScheme.addrImage,
            qrImage: shop_qr_image || ShopVerificationScheme.qrImage,
            createAt: created_at || ShopVerificationScheme.createAt,
            updateAt: updated_at || ShopVerificationScheme.updateAt,
            rejectReason: reject_reason || ShopVerificationScheme.rejectReason,
            internalRemarks: internal_remarks || ShopVerificationScheme.internalRemarks,
            handler: handler_id || ShopVerificationScheme.handler
        };
    }

    formatIncomeDeclarationData(apiData) {
        const {
            crypto_experience, industry, internationality, job_title, marital_status, personal_asset_hkd, source_of_income, working_status, created_at, updated_at
        } = apiData;

        return {
            cryptoExperience: crypto_experience || IncomeDeclarationScheme.cryptoExperience,
            industry: industry || IncomeDeclarationScheme.industry,
            internationality: internationality || IncomeDeclarationScheme.internationality,
            jobTitle: job_title || IncomeDeclarationScheme.jobTitle,
            maritalStatus: marital_status || IncomeDeclarationScheme.maritalStatus,
            personalAssetHKD: personal_asset_hkd || IncomeDeclarationScheme.personalAssetHKD,
            sourceOfIncome: source_of_income || IncomeDeclarationScheme.sourceOfIncome,
            workingStatus: working_status || IncomeDeclarationScheme.workingStatus,
            createAt: created_at || IncomeDeclarationScheme.createAt,
            updateAt: updated_at || IncomeDeclarationScheme.updateAt
        };
    }

    formatCustomerData(apiData) {
        const {
            UID, firebase_user_id, AccountId, account_id, id, UserName, name, Email, Phone, IdVerifyStatus, ShopVerifyStatus, AccountStatus, MySQLID, Referral, created_at, userIcon, UserIcon, headerImage, HeaderImage, gender, birthday, likes, description, address, district, isPermitBiometrics, isPermitSms, smsphone, isPermitGoogleAuth
        } = apiData;

        return {
            id: UID || firebase_user_id || BetaScheme.id,
            account_id: AccountId || account_id || BetaScheme.account_id,
            mysql_id: MySQLID || id || BetaScheme.mysql_id,
            username: UserName || name || BetaScheme.username,
            email: Email || BetaScheme.email,
            phone: Phone || BetaScheme.phone,
            idVerifyStatus: IdVerifyStatus || BetaScheme.IdVerifyStatus.None,
            shopVerifyStatus: ShopVerifyStatus || BetaScheme.ShopVerifyStatus.None,
            accountStatus: AccountStatus || BetaScheme.AccountStatus.None,
            referral: Referral || true,
            createAt: created_at || BetaScheme.createAt,
            userIcon: (userIcon || UserIcon) || BetaScheme.userIcon,
            headerImage: (headerImage || HeaderImage) || BetaScheme.headerImage,
            gender: gender || BetaScheme.gender,
            birthday: birthday || BetaScheme.birthday,
            likes: likes?.length || BetaScheme.likes,
            description: description || BetaScheme.description,
            address: address || BetaScheme.address,
            district: district || BetaScheme.district,
            isPermitBiometrics: isPermitBiometrics || BetaScheme.district,
            isPermitSms: isPermitSms || BetaScheme.isPermitSms,
            smsphone: smsphone || BetaScheme.smsphone,
            isPermitGoogleAuth: isPermitGoogleAuth || BetaScheme.isPermitGoogleAuth
        };
    }

    formatCustomersData(apiData) {
        return apiData.map(data => {
            return this.formatCustomerData(data);
        });
    }
}