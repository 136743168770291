import { FormControl, FormHelperText, Input } from '@mui/material';
import UserApi from 'api/user';
import SuiButton from 'components/Default/SuiButton';
import React, { useState } from 'react';

export default function EmailAuthHelper({ action, id }) {

    if (id) {
        const [input, setInput] = useState("");
        const [sendEmail, setSendEmail] = useState(false);
        const [match, setMatch] = useState(false);
        const [tried, setTried] = useState(false);
        const [error, setError] = useState();
        
        const handleSendEmail = () => {

            UserApi.userEmailAuth(id)
                .then((res) => {
                    //console.log(res);
                    if (res.status === 200) {
                        setError("");
                        setSendEmail(true);
                    }
                })
                .catch((e) => {
                    console.log(e);
                });

        };

        const handleMatchVerifyCode = () => {
            if(id && input){
                UserApi.userEmailAuthVerify({
                    id: id,
                    code: input,
                })
                    .then((res) => {
                        if (res && res.status === 200) {
                            action(input);
                        }
                    })
                    .catch((e) => {
                        setMatch(false);
                        setTried(true);
                    });
            }
        };

        const ErrorMessage = () => {
            if(error){
                return (<div>{error}</div>)
            }
            return <></>
        }

        const VerificationEmailPanel = () => {
            return (
                <>
                    <div className='popup-subtitle'>
                        The verification code has been sent to the customer email.
                    </div>

                    <FormControl error variant="standard" sx={{ width: 300, margin: "0 auto" }} >
                    <Input onChange={(e) => { setInput(e.target.value) }} error={!match && tried} />
                        { !match && tried ?
                            <FormHelperText id="component-error-text">Code does not match</FormHelperText>
                            : <></>
                        }
                    </FormControl>
                    <SuiButton buttonColor={"secondary"} sx={{ width: 300, margin: "0 auto" }} onClick={handleMatchVerifyCode}>Submit</SuiButton>
                </>
            );
        }

        const ResetEmailComponent = () => {
            return (
                <>
                    <ErrorMessage/>
                    <SuiButton buttonColor={"secondary"} sx={{ width: 300, margin: "0 auto" }} onClick={handleSendEmail}>Click to send email</SuiButton>
                </>
            );
        }

        return (
            <div className="popup-wrapper">
                <div className="popup-content">
                    <h4>{"Authorization with Email"}</h4>
                    {
                        sendEmail ?
                            <VerificationEmailPanel/>
                            : <ResetEmailComponent/>
                    }

                </div>
            </div>
        )
    } else {
        return (
            <div className="popup-wrapper">
                <div className="popup-content">
                    <h4>Authentication with Email</h4>
                    <div className="popup-subtitle">
                        User's email not found!
                    </div>
                    <div>
                        <SuiButton buttonColor={"secondary"} onClick={action()}>Back</SuiButton>
                    </div>
                </div>
            </div>
        )
    }

}
