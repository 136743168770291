import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateIcon from '@mui/icons-material/Update';
import { Grid } from "@mui/material";
import OTCApi from "api/otc";
import 'components/Custom/common.css';
import InputWrapper from 'components/Custom/input_wrapper';
import SuiButton from "components/Default/SuiButton";
import { useEffect, useState } from "react";
import { useAlert } from 'react-alert';

export default function AddUsers ({ title, id }) {
    const alert = useAlert();
    const [otcUserList, setOtcUserList] = useState([]);
    const [newOTCUserList, setNewOTCUserList] = useState([]);
    const [inputs, setInputs] = useState({}); // inputs
    const [count, setCount] = useState(1);

    function getOTCUserList(){
        OTCApi.getOTCUserList()
        .then((res) => {
            if (res.status === 200){
                setOtcUserList(res.data.data);
            }
        })
        .catch((err) => {
            console.log(err);
        })
    }

    const addItem = async (index) => {
        if(index !== false && index !== null){
            console.log(index);
            const data = newOTCUserList.filter((d, i) => i === index)[0];
            
            await OTCApi.addOTCUser(data)
                .then((res) => {
                    if(res.status === 200){
                        alert.success(`Add ${data.account_id} into list`);
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    }

    const removeItem = async (key, action) => {
        if(action === "call_api"){
            await OTCApi.removeOTCUser(key)
                .then((res) => {
                    if(res.status === 200){
                        alert.success(`Remove ${key} success`);
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
        }
        else if(action === "remove_from_list"){

        }
    }

    const actionEdit = e => {
        let new_user = newOTCUserList;
        new_user.push({
            account_id: ""
        });
        setCount(count + 1);
        setNewOTCUserList(new_user);
        console.log(newOTCUserList);
    }

    const handleStoreNewUser = (inputName, account_id, index) => {
        setInputs(prevState => ({ ...prevState, [inputName]: account_id }));

        if(account_id){
            let new_user = newOTCUserList;
            new_user.map((d, i) => {
                i === index ? d["account_id"] = account_id : ""
            })
            setNewOTCUserList(new_user);
            console.log(newOTCUserList);
        }
    }

    useEffect(()=>{
        if(otcUserList.length < 1){
            getOTCUserList();
        }   
    }, []);

    // View User List
    return (
        <div id="popup1" className="popup-wrapper">
            <div id="" className="popup1-flex-column">
                <h4>OTC User List</h4>
                <div className="popup-content">
                    {
                        otcUserList.length >= 1?
                            otcUserList.map((d, index) => {
                                return (
                                    <Grid container key={index}>
                                        <Grid item>
                                            <InputWrapper value={d.account_id} size="sm"/>
                                        </Grid>
                                        <Grid item>
                                            <SuiButton buttonColor="error" onClick={() => removeItem(d.account_id, "call_api")}>
                                                <DeleteIcon/>
                                            </SuiButton>
                                        </Grid>
                                    </Grid>
                                )
                            })
                            : <></>
                    }
                    <div style={{ display: "none" }}>{newOTCUserList.length}</div>
                    {   newOTCUserList.length >= 1 ? 
                            <h5 style={{ backgroundColor: "#17c1e8", fontWeight: 'normal', paddingLeft: '0.5rem', color: 'white' }}>
                                New Users
                            </h5> 
                            : <></>}
                    {
                        newOTCUserList.map((d, index) => {
                                return (
                                    <Grid container key={index}>
                                        <Grid item>
                                            <InputWrapper name={"accountID_"+index} value={inputs["accountID_"+index]} onChange={(e)=>{ handleStoreNewUser(e.target.name, e.target.value, index)}}  size="m" disable={false}/>
                                        </Grid>
                                        <Grid item>
                                            <SuiButton onClick={() => addItem(index)}>
                                                <UpdateIcon/>
                                            </SuiButton>
                                            <SuiButton buttonColor="error" onClick={() => removeItem(index, "remove_from_list")}>
                                                <DeleteIcon/>
                                            </SuiButton>
                                        </Grid>
                                    </Grid>
                                );
                            })
                    }

                    <SuiButton buttonColor="secondary" onClick={(e) => actionEdit(e.value)}>
                        <AddCircleIcon/>
                    </SuiButton>
                </div>
            </div>
        </div>
    );
}