import axios from "./index";

class TransactionApi {
  static typeCount = data => {
    return axios.get(`${base}/type_count`, {
      params: data
    });
  };

  static amountByType = data => {
    return axios.get(`${base}/amount_by_type`, {
      params: data
    });
  };
}

let base = "transactions";

export default TransactionApi;
