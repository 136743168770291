import ADManagement from './ADManagement';
import FAQ from './FAQ';
import AppNotificationPopup from './AppNotification';
import ServiceFeeSetting from './ServiceFeeSetting';
import SavingPlanPopup from './SavingPlan';
import ServiceAgreementPopup from './ServiceAgreement';
import CrisisMgtPopup from './CrisisMgt';
import AccessCodeMgtPopup from './AccessCodeMgt';
import VersionPopup from './Version';
import EmailTemplatePopup from './EmailTemplate';

function CustomPopup({ name, /* id */ type }) {
    if(name === "ServiceAgreementPopup"){
        return <ServiceAgreementPopup/>;
    } else if(name === "FAQPopup"){
        return <FAQ/>;
    } else if(name === "ADManagementPopup"){
        return <ADManagement/>;
    } else if (name === "AppNotificationPopup"){
        return <AppNotificationPopup/>;
    } else if (name === "ServiceFeeSettingPopup"){
        return <ServiceFeeSetting type={type}/>;
    } else if (name === "SavingPlanPopup"){
        return <SavingPlanPopup/>;
    } else if (name === "CrisisMgtPopup"){
        return <CrisisMgtPopup/>;
    } else if(name === "AccessCodeMgtPopup"){
        return <AccessCodeMgtPopup/>;
    }  else if(name === "VersionAnnouncePopup"){
        return <VersionPopup/>;
    }  else if(name === "EmailTemplatePopup"){
        return <EmailTemplatePopup/>;
    } else return <></>;
}

export default CustomPopup;