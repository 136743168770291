import WalletApi from "api/wallet";
import initalValue from "entities/base/initalValue";
import FirebaseGateway from "./firebaseGateway";

const Scheme = {
    id: initalValue.Int,
    senderId: initalValue.String,
    receiverId: initalValue.String,
    method: initalValue.String,
    cryptoType: initalValue.String,
    amount: initalValue.Double,
    status: {
        completed: "completed"
    },
    createAt: initalValue.Datetime
}

export default class WalletTransferGateway {
    constructor() { }

    getScheme() {
        return Scheme;
    };

    async getAllData({ page, statusStatement, searchValue }) {
        let firebaseGateway = new FirebaseGateway();
        let latestToken = await firebaseGateway.getToken();

        return await WalletApi.getTransferDetails({
            page: page,
            statusStatement: statusStatement,
            id_token: latestToken
        })
            .then(response => {
                if (response.status === 200) {
                    return {
                        data: this.formatWalletTransfersData(response.data.data.data),
                        last_page: response.data.data.meta.last_page,
                        per_page: response.data.data.meta.per_page,
                        total: response.data.data.meta.total
                    };
                }
            })
    }

    formatWalletTransferData(apiData, index) {
        const {
            withdraw, deposit, method, status, created_at
        } = apiData;

        return {
            id: index + 1,
            senderId: withdraw?.account_id,
            receiverId: deposit?.account_id,
            method: method,
            cryptoType: deposit?.crypto_type,
            amount: deposit?.amount,
            status: status,
            createAt: created_at
        };
    }

    formatWalletTransfersData(apiData) {
        return apiData.map((data, index) => {
            return this.formatWalletTransferData(data, index);
        });
    }
}