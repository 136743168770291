import axios from "./index";

class GameApi {
  static list = (page) => {
    const id_token = localStorage.getItem("id_token");
    return axios.get(`${base}?page=${page}`, { headers: {"Authorization" : `Bearer ${id_token}`} });
  };

  static getOneGameByFid = id => {
    const id_token = localStorage.getItem("id_token");
    if (id) {
      return axios.get(`${base}/${id}`, { headers: {"Authorization" : `Bearer ${id_token}`} });
    }
    throw "Game id is missing";
  };

  static getUsersByGameIdAndPage = data => {
    const id_token = localStorage.getItem("id_token");
    return axios.get(
      `${base}/${data.id}/all_users?${data.type}=1&page=${data.page}`, { headers: {"Authorization" : `Bearer ${id_token}`} }
    );
  };

  static getQuestions = () => {
    const id_token = localStorage.getItem("id_token");
    return axios.get(`${base}_questions`, { headers: {"Authorization" : `Bearer ${id_token}`} });
  };

  static getQuestionsById = (id) => {
    const id_token = localStorage.getItem("id_token");
    if(id){
      return axios.get(`${base}_questions/${id}`, { headers: {"Authorization" : `Bearer ${id_token}`} });
    }
  };

  static addQuestion = data => {
    const id_token = localStorage.getItem("id_token");
    if (data) {
      return axios.post(`${base}_questions`, data, { headers: {"Authorization" : `Bearer ${id_token}`} });
    }
    throw "request data is missing";
  };

  static updateQuestion = data => {
    const id_token = localStorage.getItem("id_token");
    if (
      data.title &&
      data.difficulty_level &&
      data.optionA &&
      data.optionB &&
      data.optionC &&
      data.optionD &&
      data.correct_option
    ) {
      const requestData = {
        title: data.title,
        difficulty_level: data.difficulty_level,
        optionA: data.optionA,
        optionB: data.optionB,
        optionC: data.optionC,
        optionD: data.optionD,
        correct_option: data.correct_option
      };
      return axios.put(`${base}_questions/${data.id}`, requestData, { headers: {"Authorization" : `Bearer ${id_token}`} });
    }
    throw "request data is missing";
  };

  static removeQuestion = id => {
    const id_token = localStorage.getItem("id_token");
    if (id) {
      return axios.delete(`${base}_questions/${id}`, { headers: {"Authorization" : `Bearer ${id_token}`} });
    }
    throw "question id is missing";
  };

  static addGame = data => {
    const id_token = localStorage.getItem("id_token");
    if (data) {
      console.log(data);
      return axios.post(`${base}`, data, { headers: {"Authorization" : `Bearer ${id_token}`} });
    }
    throw "request data is missing";
  };

  static updateGame = data => {
    const id_token = localStorage.getItem("id_token");
    if (data) {
      const requestData = {
        start_at: data.start_at,
        end_at: data.end_at,
        duration: data.duration,
        usdt_amount: data.usdt_amount,
        question1: data.question1,
        question2: data.question2,
        question3: data.question3,
        question4: data.question4,
        question5: data.question5
      };

      return axios.put(`${base}/${data.id}`, requestData, { headers: {"Authorization" : `Bearer ${id_token}`} });
    }
    throw "request data is missing";
  };

  static updateCompetitionResult = () => {
    const id_token = localStorage.getItem("id_token");
    return axios.post(`${base}_result`, { headers: {"Authorization" : `Bearer ${id_token}`} });
  };

  static getCompetitionPattern = () => {
    const id_token = localStorage.getItem("id_token");
    return axios.get(`${base}_pattern`, { headers: {"Authorization" : `Bearer ${id_token}`} });
  };

  static addCompetitionPattern = data => {
    const id_token = localStorage.getItem("id_token");
    return axios.post(`${base}_pattern`, data, { headers: {"Authorization" : `Bearer ${id_token}`} });
  };

  static updateCompetitionPattern = data => {
    const id_token = localStorage.getItem("id_token");
    const requestData = {
      start_time_hour: data.start_time_hour,
      start_time_minute: data.start_time_minute,
      end_time_hour: data.end_time_hour,
      end_time_minute: data.end_time_minute,
      duration: data.duration,
      usdt_amount: data.usdt_amount,
      is_active: data.is_active
    };
    return axios.put(`${base}_pattern/${data.id}`, requestData, { headers: {"Authorization" : `Bearer ${id_token}`} });
  };

  static deleteCompetitionPattern = id => {
    const id_token = localStorage.getItem("id_token");
    return axios.delete(`${base}_pattern/${id}`, { headers: {"Authorization" : `Bearer ${id_token}`} });
  };

}

let base = "/competition";

export default GameApi;
