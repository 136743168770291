import OTCApi from "api/otc";
import initalValue from "entities/base/initalValue";
import FirebaseGateway from "./firebaseGateway";

// const Scheme = {
//     id: initalValue.String,
//     shortId: initalValue.String,
//     sellerId: initalValue.String,
//     buyerId: initalValue.String,
//     shopOfferId: initalValue.String,
//     quantity: initalValue.Int,
//     price: initalValue.Int,
//     cryptoType: initalValue.String,
//     orderType: initalValue.String,
//     reviews: reviews,
//     refund: refund,
//     status: status,
//     createAt: createAt,
//     updateAt: updateAt
// }

export default class OTCGateway {
    constructor() { }

    // getScheme() {
    //     return Scheme;
    // };

    async getAllData({ page, statusStatement }) {
        let firebaseGateway = new FirebaseGateway();
        let latestToken = await firebaseGateway.getToken();

        return await OTCApi.list({
            page: page,
            statusStatement: statusStatement,
            id_token: latestToken
        })
            .then(response => {
                if (response.status === 200) {
                    return {
                        data: this.formatOTCsData(response.data.data),
                        // last_page: response.data.data.last_page,
                        // per_page: response.data.data.per_page,
                        // total: response.data.data.total
                    };
                }
            })
    }

    async getOneOTCById({ id }) {
        return await OTCApi.getOneOTCById(id)
            .then(response => {
                console.log(response);
                if (response.status === 200) {
                    return {
                        data: this.formatOTCDetailData(response.data.data)
                    };
                }
            })
    }

    async getAdvsData() {
        let firebaseGateway = new FirebaseGateway();
        let latestToken = await firebaseGateway.getToken();

        return await OTCApi.getOTCAdv({
            id_token: latestToken
        })
            .then(response => {
                if (response.status === 200) {
                    return {
                        data: this.formatOTCAdvsData(response.data.data),
                        // last_page: response.data.data.last_page,
                        // per_page: response.data.data.per_page,
                        // total: response.data.data.total
                    };
                }
            })
    }

    async getAdvsDataBySellerId({ id }) {
        let firebaseGateway = new FirebaseGateway();
        let latestToken = await firebaseGateway.getToken();

        return await OTCApi.getOTCAdv({
            id_token: latestToken
        })
            .then(response => {
                if (response.status === 200) {
                    const this_data = response.data.data.filter((d, i) => i === (id - 1))[0];
                    return {
                        data: this.formatOTCSellerData(this_data)
                    };
                }
            })
    }

    async getAdvsDataByBuyerId({ id }) {
        let firebaseGateway = new FirebaseGateway();
        let latestToken = await firebaseGateway.getToken();

        return await OTCApi.getOTCAdv({
            id_token: latestToken
        })
            .then(response => {
                if (response.status === 200) {
                    const this_data = response.data.data.filter((d, i) => i === (id - 1))[0];
                    return {
                        data: this.formatOTCBuyerData(this_data)
                    };
                }
            })
    }

    async getRegularOrderData() {
        return await OTCApi.getOTCRegularOrder()
            .then(response => {
                if (response.status === 200) {
                    return {
                        data: this.formatOTCRegularOrdersData(response.data.data),
                        // last_page: response.data.data.last_page,
                        // per_page: response.data.data.per_page,
                        // total: response.data.data.total
                    };
                }
            })
    }

    async getPrivateOrderData() {
        return await OTCApi.getOTCIncognitoOrder()
            .then(response => {
                if (response.status === 200) {
                    return {
                        data: this.formatOTCPrivateOrdersData(response.data.data),
                        // last_page: response.data.data.last_page,
                        // per_page: response.data.data.per_page,
                        // total: response.data.data.total
                    };
                }
            })
    }

    formatOTCData(apiData) {
        const {
            id, allow_incognito_over_the_counter_order, contact_count, payment_method_count, status
        } = apiData;

        return {
            id: id,
            allow_incognito_over_the_counter_order: allow_incognito_over_the_counter_order,
            contact_count: contact_count,
            payment_method_count: payment_method_count,
            status: status
        };
    }

    formatOTCDetailData(apiData) {
        const {
            id_verification_name, email, address, phone, whatsapp, description, is_enabled_email, is_enabled_phone, is_enabled_whatsapp, payment_methods, status
        } = apiData;

        return {
            account_id: 0,
            name: id_verification_name, //TODO shopverify name
            account_level: 0,
            is_enabled_phone: is_enabled_phone,
            phone: phone,
            is_enabled_whatsapp: is_enabled_whatsapp,
            whatsapp: whatsapp,
            is_enabled_email: is_enabled_email,
            email: email,
            trade_description: description,
            branch_address: address,
            payment_method: payment_methods
        };
    }

    formatOTCAdvData(apiData, index) {
        const {
            usdt_to_hkd_over_the_counter_advertisement, hkd_to_usdt_over_the_counter_advertisement
        } = apiData;

        const {
            account_id
        } = apiData.user;

        return {
            id: index + 1,
            otcId: account_id, // TODO
            buy_ad: hkd_to_usdt_over_the_counter_advertisement ? true : false,
            sell_ad: usdt_to_hkd_over_the_counter_advertisement ? true : false,
            buySales: hkd_to_usdt_over_the_counter_advertisement?.unit_price, // hkd to usdt
            sellSales: usdt_to_hkd_over_the_counter_advertisement?.unit_price,
            buyVolume: hkd_to_usdt_over_the_counter_advertisement?.initial_remaining_amount,
            sellVolume: usdt_to_hkd_over_the_counter_advertisement.initial_remaining_amount,
            createAt: usdt_to_hkd_over_the_counter_advertisement?.created_at,
        };
    }

    formatOTCRegularOrderData(apiData) {
        const {
            id, short_id, to_user_id, from_user_id, unit_price, created_at, updated_at, type, payment_method_id, status
        } = apiData;

        return {
            id: short_id,
            tId: 0,
            sellerId: from_user_id,
            buyerId: to_user_id,
            unit_price: unit_price,
            hkd: 0,
            usdt: 0,
            createAt: created_at,
            updateAt: updated_at,
            orderType: type,
            method: payment_method_id, //TODO
            complaint: "",
            commission: 0,
            commissionRate: 0,
            status: status
        };
    }

    formatOTCPrivateOrderData(apiData) {
        const {
            id, short_id, to_user_id, from_user_id, unit_price, created_at, updated_at, type, payment_method_id, status
        } = apiData;

        return {
            id: short_id,
            tId: 0,
            sellerId: from_user_id,
            buyerId: to_user_id,
            unit_price: unit_price,
            hkd: 0,
            usdt: 0,
            createAt: created_at,
            updateAt: updated_at,
            orderType: type,
            method: payment_method_id, //TODO
            complaint: "",
            commission: 0,
            commissionRate: 0,
            status: status
        };
    }

    formatOTCSellerData(apiData) {
        const {
            user
        } = apiData;

        const {
            id, initial_remaining_amount, unit_price, lower_limit, upper_limit, address, description, status
        } = apiData?.usdt_to_hkd_over_the_counter_advertisement;

        return {
            id: 0, //TODO
            seller_name: user?.name,
            account_level: 0, // TODO
            selling_amount: initial_remaining_amount,
            commission_pool: 0, //TODO
            remaining_amount: 0, //TODO
            unit_price: unit_price,
            lower_limit: lower_limit,
            upper_limit: upper_limit,
            address: address,
            description: description,
            status: status
        };
    }

    formatOTCBuyerData(apiData) {
        const {
            user
        } = apiData;

        const {
            id, initial_remaining_amount, unit_price, lower_limit, upper_limit, address, description, status
        } = apiData?.hkd_to_usdt_over_the_counter_advertisement;

        return {
            id: 0, //TODO
            seller_name: user?.name,
            account_level: 0, // TODO
            selling_amount: initial_remaining_amount,
            commission_pool: 0, //TODO
            remaining_amount: 0, //TODO
            unit_price: unit_price,
            lower_limit: lower_limit,
            upper_limit: upper_limit,
            address: address,
            description: description,
            status: status
        };
    }

    formatOTCsData(apiData) {
        return apiData.map(data => {
            return this.formatOTCData(data);
        });
    }

    formatOTCAdvsData(apiData) {
        return apiData.map((data, index) => {
            return this.formatOTCAdvData(data, index);
        });
    }

    formatOTCRegularOrdersData(apiData) {
        return apiData.map(data => {
            return this.formatOTCRegularOrderData(data);
        });
    }

    formatOTCPrivateOrdersData(apiData) {
        return apiData.map(data => {
            return this.formatOTCPrivateOrderData(data);
        });
    }
}