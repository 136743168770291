import { Grid, TextareaAutosize } from "@material-ui/core";
import InfoIcon from '@mui/icons-material/Info';
import VerifiedIcon from '@mui/icons-material/Verified';
import IconButton from "@mui/material/Icon";
import Tooltip from '@mui/material/Tooltip';
import OTCApi from "api/otc";
import 'components/Custom/common.css';
import InputWrapper from 'components/Custom/input_wrapper';
import SuiButton from "components/Default/SuiButton";
import { OTC_PERMISSION } from 'config/permission';
import OTCGateway from "entities/otcGateway";
import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import AuthCodeHelper from "util/AuthCodeHelper";
import { NullFormatUtils } from "util/index";
import "./style.css";

let otcGateway = new OTCGateway();

export default function OTCBuyAdPopup({ id, type }) {
    const alert = useAlert();

    const [page, setPage] = useState(1);
    const [auth, setAuth] = useState(false);
    const [otcData, setStaffData] = useState([]);
    const [inputs, setInputs] = useState({}); // inputs
    const [user, setUser] = useState();
    const [foundUser, setFoundUser] = useState(false);
    const [otcDetail, setOtcDetail] = useState();

    const [paymentMethod, setPaymentMethod] = useState({
        FPS: false,
        BankTransfer: false,
        Cash: false,
        Cheque: false
    });
    const [contact, setContact] = useState({
        Phone: false,
        Whatsapp: false,
        Email: false
    });

    const handleChange = e => setInputs(prevState => {
        if (["phone", "whatsapp", "email"].includes(e.target.name)) {
            setContact({ ...contact, [e.target.name.charAt(0).toUpperCase() + e.target.name.slice(1)]: true })
        }

        return ({ ...prevState, [e.target.name]: e.target.value })
    });
    const handlePaymentMethodChange = (event) => { setPaymentMethod({ ...paymentMethod, [event.target.name]: event.target.checked }) };
    const handleContactChange = (event) => { setContact({ ...contact, [event.target.name]: event.target.checked }) };
    const { FPS, BankTransfer, Cash, Cheque } = paymentMethod;
    const { Phone, Whatsapp, Email } = contact;

    async function getOTCDetails() {
        await otcGateway.getAdvsDataByBuyerId({ id: id })
            .then((res) => {
                if (res) {
                    setOtcDetail(res.data);
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        getOTCDetails();
    }, [])

    const handleEditOTC = () => {
        if (!auth) {
            setPage(0);
        } else {
            // edit otc api
        }
    };

    const handleOFFOTC = async () => {
        if (!auth) {
            setPage(0);
        } else {
            // edit otc api
            await OTCApi.changeStatus({ "Status": "Off" })
                .then((res) => {
                    if (res && res.status === 200) {
                        alert.success("Edit OTC success");
                    } else {
                        alert.error("Somethings error");
                    }
                })
                .catch((err) => {
                    alert.error(err.message);
                })
        }
    };

    const handleONOTC = async () => {
        if (!auth) {
            setPage(0);
        } else {
            // edit otc api
            await OTCApi.changeStatus({ "Status": "On" })
                .then((res) => {
                    if (res && res.status === 200) {
                        alert.success("Edit OTC success");
                    } else {
                        alert.error("Somethings error");
                    }
                })
                .catch((err) => {
                    alert.error(err.message);
                })
        }
    };

    const handleReleaseOTC = async () => {
        if (!auth) {
            setPage(0);
        } else {
            const payment_method = Object.entries(paymentMethod).filter(([k, v]) => v === true).map(([k, v]) => k);
            const contact_arr = Object.entries(contact).filter(([k, v]) => v === true).map(([k, v]) => k);
            for (let index = 0; index < payment_method.length; index++) {
                const element = payment_method[index];
                const payment_method_chin = {
                    "FPS": "FPS轉數快",
                    "Cheque": "支票",
                    "Cash": "現金交收",
                    "BankTransfer": "銀行轉帳"
                }
                payment_method[index] = payment_method_chin[element];
            }

            if (inputs['branch_addr']
                && inputs['lower_limit']
                && inputs['sell_amount']
                && inputs['trade_desc']
                && inputs['unit_price']
                && inputs['upper_limit']
                && inputs['seller_id'] && foundUser === true
                && inputs['buy_amount']
                && inputs['buy_price']
                && payment_method.length >= 1
                && contact_arr.length >= 1) {
                // create otc api
                await OTCApi.add({
                    "Email": inputs['email'] ?? null,
                    "BranchAddress": inputs['branch_addr'],
                    "Phone": inputs['phone'] ?? null,
                    "LowerLimit": inputs['lower_limit'],
                    "SellingAmount": inputs['sell_amount'],
                    "TradeDescription": inputs['trade_desc'],
                    "SellingPrice": inputs['sell_price'],
                    "UpperLimit": inputs['upper_limit'],
                    "WhatsApp": inputs['whatsapp'] ?? null,
                    "PaymentMethod": payment_method,
                    "AccountID": inputs['seller_id'],
                    "BuyingPrice": inputs['buy_price'],
                    "BuyingAmount": inputs['buy_amount']
                }).then((res) => {
                    if (res && res.status === 200) {
                        alert.success("Create OTC success");
                    } else {
                        alert.error("Somethings error");
                    }
                })
                    .catch((err) => {
                        alert.error(err.message);
                    })
            } else {
                alert.error('You need to fill in all the fields.');
            }
        }
    };

    const handleAuthCode = (resp) => {
        // console.log(resp);

        if (resp) {
            if (resp.isAuth) {
                setAuth(true);
                setPage(1);
            } else {
                alert.error(resp.message);
            }
        }
        else {
            alert.error(ERROR.callback);
        }
    }

    const attributes = {
        "sell_amount": "SellingAmount",
        "sell_price": "SellingPrice",
        "email": "Email",
        "branch_addr": "BranchAddress",
        "lower_limit": "LowerLimit",
        "phone": "Phone",
        "trade_desc": "TradeDescription",
        "upper_limit": "UpperLimit",
        "whatsapp": "WhatsApp",
        "buy_amount": "BuyingAmount",
        "buy_price": "BuyingPrice"

    }

    //get corresponding data in regards of edit or not
    function getData(attr) {
        if (otcDetail && !showEditOrNot[type]) {
            let temp = attributes[attr];
            if (temp) return otcDetail[temp];
        }
        else if (inputs[attr]) return inputs[attr];
    }

    const showEditOrNot = {
        1: false,
        2: false,
        3: true
    }

    const actionButtons = {
        1: <SuiButton buttonColor="secondary" onClick={handleReleaseOTC}>Release</SuiButton>,
        "On":
            <>
                {/* <SuiButton buttonColor="secondary" className="mr-1-5rem" onClick={handleEditOTC}>Edit</SuiButton> */}
                <SuiButton buttonColor="secondary" onClick={handleOFFOTC}>OFF</SuiButton>
            </>,
        "Off": <SuiButton buttonColor="secondary" onClick={handleONOTC}>On</SuiButton>
    }

    if (page === 0) {
        return (<AuthCodeHelper title="Admin Authentication" btnName="Confirm" permission={OTC_PERMISSION.VIEW} callback={handleAuthCode} />);
    }
    else if (page === 1) {
        return (
            <div id="popup1" className="popup-wrapper">
                    <div id="" className="popup1-flex-column">
                        <h4>OTC Offer Details</h4>
                        <Grid container spacing={2}>
                            <Grid item container alignItems="center" className="input-wrapper-rows" >
                                <Grid item xs={12} sm={8} md={6} xl={6} >
                                    <InputWrapper attr={
                                        <div className="icons-row">
                                            OTC ID

                                            <div>
                                                <Tooltip title="6 characters Account ID">
                                                    <IconButton color="secondary">
                                                        <InfoIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                            <div className="right"><VerifiedIcon color={foundUser ? "success" : "action"} /></div>
                                        </div>
                                    }
                                        name="seller_id" value={otcDetail?.id ?? ""} onChange={handleChange} size="lg" disable={showEditOrNot[type]} />

                                </Grid>
                            </Grid>

                            <Grid item container alignItems="center" className="input-wrapper-rows">
                                <Grid item xs={12} sm={8} md={6} xl={6} className="input-1">
                                    <InputWrapper attr="Seller Name" value={otcDetail?.seller_name ?? ""} size="lg" disable={showEditOrNot[type]} />
                                </Grid>
                                <Grid item xs={12} sm={8} md={6} xl={4} mr={6}>
                                    <InputWrapper attr="Account Level" value={otcDetail?.account_level ?? ""} size="lg" disable={showEditOrNot[type]} />
                                </Grid>
                            </Grid>

                            <Grid item container alignItems="center">
                                <Grid item xs={12} sm={8} md={6} xl={6}>
                                    <InputWrapper attr="Selling Amount" name="buy_amount" value={otcDetail?.selling_amount ?? ""} onChange={handleChange} size="lg" disable={showEditOrNot[type]} />
                                </Grid>
                                <Grid item xs={12} sm={8} md={6} xl={6}>
                                    <InputWrapper attr="Commission Pool" name="buy_price" value={getData("buy_price")} size="lg" onChange={handleChange} disable={showEditOrNot[type]} />
                                </Grid>
                            </Grid>

                            <Grid item container alignItems="center">
                                <Grid item xs={12} sm={8} md={6} xl={6}>
                                    <InputWrapper attr="Remaining Amount" name="remain_amount" value={otcDetail?.remaining_amount ?? ""} onChange={handleChange} size="lg" disable={showEditOrNot[type]} />
                                </Grid>
                            </Grid>

                            <Grid item container alignItems="center">
                                <Grid item xs={12} sm={8} md={6} xl={6}>
                                    <InputWrapper attr="Unit Price" name="account_level"
                                        value={otcDetail?.unit_price ?? ""}
                                        size="lg" onChange={handleChange} disable={true} />
                                </Grid>
                                <Grid item xs={12} sm={8} md={6} xl={6}>
                                    <InputWrapper attr="Crypto" name="crypto" value="USDT" size="lg" onChange={handleChange} disable={true} />
                                </Grid>
                            </Grid>

                            <Grid item container alignItems="center">
                                <Grid item xs={12} sm={8} md={6} xl={6}>
                                    <InputWrapper attr="Lower Limit" name="lower_limit" value={otcDetail?.lower_limit ?? ""} onChange={handleChange} size="lg" disable={showEditOrNot[type]} />
                                </Grid>
                                <Grid item xs={12} sm={8} md={6} xl={6}>
                                    <InputWrapper attr="Upper Limit" name="upper_limit" value={otcDetail?.upper_limit ?? ""} onChange={handleChange} size="lg" disable={showEditOrNot[type]} />
                                </Grid>
                            </Grid>

                            <Grid item container alignItems="center">
                                <Grid item xs={12} sm={8} md={6} xl={6}>
                                    <InputWrapper attr="Create At" value="" size="lg" disable={true} />
                                </Grid>
                                <Grid item xs={12} sm={8} md={6} xl={6}>
                                    <InputWrapper attr="Update At" value="" size="lg" disable={true} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>

                    <div id="" className="security">
                        <div className="popup-content">
                            <h4>Trade Description</h4>
                            <div id="offer-description">
                                <TextareaAutosize name="trade_desc" value={otcDetail?.description ?? ""} style={{ height: 200, width: 600 }} disabled={false} onChange={handleChange} />
                            </div>

                            <h4>Branch address</h4>
                            <div id="offer-description">
                                <TextareaAutosize name="branch_addr" value={otcDetail?.address ?? ""} style={{ height: 200, width: 600 }} disabled={false} onChange={handleChange} />
                            </div>

                            <div className="popup-form-row">
                                <InputWrapper attr="Status" value={otcDetail?.status ?? ""} size="sm" />
                                <InputWrapper attr="Handler" value={otcData.Handler ? NullFormatUtils(otcData.Handler.Name) : '-'} size="sm" />
                            </div>
                        </div>

                        <div style={{ float: 'right', marginTop: '1rem' }}>
                            <SuiButton buttonColor="error" onClick={handleReleaseOTC}>Delete</SuiButton>
                            <SuiButton buttonColor="secondary" onClick={handleReleaseOTC}>Block</SuiButton>
                        </div>
                    </div>
                </div>
        );
    }
}