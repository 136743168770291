import AccountDetails from 'components/Page/account_details';
import OTCBuyAdPopup from "./buy_ad";
import OTCSellAdPopup from './sell_ad';
import AddUsers from './addUsers';

function CustomPopup({ name, id, type  }) {
    if(name === "OTCBuyAd"){
        return <OTCBuyAdPopup id={id}/>
    }  else if (name === "OTCSellAd"){
        return <OTCSellAdPopup id={id}/>
    }  else if (name === "AddUser"){
        return <AddUsers/>
    }   else if (name === "AccountDetailsPopup") {
        return <AccountDetails id={id} />
    }  else return <></>
}

export default CustomPopup;