/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React components
import { CachedOutlined } from "@material-ui/icons";
import Card from "@mui/material/Card";
import Popup from "assets/theme/components/popup";
import { DataTable, Loader } from "components/Custom";
import { SuiBox, SuiButton } from "components/Default";
import Footer from "components/examples/Footer";
import DashboardLayout from "components/examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/examples/Navbars/DashboardNavbar";
import CryptoGateway from "entities/cryptoGateway";
import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { HTMLContentUtils, NullFormatUtils, NumberFormatUtils } from "util/index";
import { CustomPopup } from "./components";
import { table_columns_size } from "./variable";

let cryptoGateway = new CryptoGateway();

function Crypto() {
  const [page, setPage] = useState(0);
  const [openPopUp, setOpenPopup] = useState(false);
  const [popUpName, setPopUpName] = useState("");
  const [currentCrypto, setCurrentCrypto] = useState();
  const handlePopUp = (pName, id) => {
    setPopUpName(pName);
    setOpenPopup(true);
    setPage(1);
    if (id) setCurrentCrypto(id);
  };

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    await cryptoGateway.getAllData()
      .then((res) => {
        if (res) {
          setRows(res.data);
          setLoading(false);
        }

      }).catch((e) => {
        console.log(e);
      });
  };


  useEffect(() => {
    if (rows.length < 1) {
      fetchData();
    }
  }, []);

  const checkSearchKeyword = (SearchValue, rowData) => {
    if(SearchValue && rowData){
      //console.log(SearchValue);
      const new_rowData = rowData.replace(
        new RegExp(SearchValue, 'gi'),
        match => {
          if (match) {
            return `<mark style="background: #8D8DAA; color: white;">${match}</mark>`;
          }
        }
      );
      return HTMLContentUtils(new_rowData);
    }
    return rowData;
  };

  const dt_columns = [
    {
      headerName: 'CryptoID',
      field: 'shortName',
      width: table_columns_size.m,
      renderCell: ({ row }) => row.shortName ? <Link to="#" className="text-secondary" onClick={() => handlePopUp("CryptoDetailsPopup", row.shortName)}>{checkSearchKeyword(row.SearchValue, NullFormatUtils(row.shortName))}</Link> : "-",
      sortable: true,
    },
    {
      headerName: 'Name',
      field: 'cryptoName',
      width: table_columns_size.m,
      renderCell: ({ row }) => checkSearchKeyword(row.SearchValue, NullFormatUtils(row.cryptoName)),
      sortable: true,
    },
    {
      headerName: 'ChineseName',
      field: 'chineseName',
      width: table_columns_size.m,
      renderCell: ({ row }) => checkSearchKeyword(row.SearchValue, NullFormatUtils(row.chineseName)),
      sortable: true,
    },
    {
      headerName: 'ExchangeRate(HKD)',
      field: 'exchangeRate',
      width: table_columns_size.l,
      renderCell: ({ row }) => '$' + checkSearchKeyword(row.SearchValue, NumberFormatUtils(row.exchangeRate, 8)),
      sortable: true,
    },
    {
      headerName: 'Networks',
      field: 'networks',
      width: table_columns_size.m,
      renderCell: ({ row }) => row.networks ?? "-",
      sortable: true,
    },
  ];

  return (
    <div>
      {page > 0 ?
        <Popup
          openPopup={openPopUp}
          setOpenPopup={() => { setOpenPopup(false) }}
        >
          <CustomPopup name={popUpName} id={currentCrypto}></CustomPopup>
        </Popup> : <></>
      }

      <DashboardLayout>
        <DashboardNavbar />
        <Loader loading={loading}>
          <SuiBox py={3}>
            <SuiBox mb={3}>
              <SuiButton
                style={{ marginRight: '1rem' }}
                buttonColor="secondary"
                onClick={() => { fetchData() }}>
                <CachedOutlined />
                Refresh
              </SuiButton>
            </SuiBox>
            <SuiBox>
              <Card>
                <DataTable dt_columns={dt_columns} dt_rows={rows} />
              </Card>
            </SuiBox>
          </SuiBox>
        </Loader>
        
      </DashboardLayout>
    </div>
  );
}

export default Crypto;
