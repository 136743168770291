import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import WalletApi from "api/wallet";
import { CircularProgress, Pagination } from "@mui/material";
import { NumberFormatUtils } from "util/index";
import moment from "moment";

function TopupTabRow(props) {

    return (
        <div className="tab-row-wrapper">
            <div className="row-start">
                <div>
                    {props.currency}
                </div>
                <div>
                    {props.dateTime}
                </div>
            </div>
            <div className="row-end">
                <div>
                    {props.topupValue}
                </div>
                <div>
                    {props.status}
                </div>
            </div>
        </div>
    );
}

TopupTabRow.propTypes = {
    currency: PropTypes.string.isRequired,
    dateTime: PropTypes.string.isRequired,
    topupValue: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired
};

export default function TopupTab(id) {

    const [page, setPage] = useState(1);
    const [record, setRecord] = useState();
    const [loading, setLoading] = useState(true);
    const [totalPage, setTotalPage] = useState(1);
    const [rowPerPage, setRowPerPage] = useState(5);

    const handlePageChange = (event, value) => {
        if (value <= totalPage) {
            setPage(value);
        }
    };

    const handleRowPerPage = (v) => {
        setRowPerPage(v);
        let t = record.length / rowPerPage;
        if (record.length % rowPerPage > 0) t += 1;
        setTotalPage(t);
    };


    useEffect(() => {
        WalletApi.getDepositDetails({ id: id })
            .then((res) => {
                if (res.status === 200) {
                    let d = res?.data?.data?.data;
                    setRecord(d)
                    setLoading(false);

                    let t = parseInt(d.length / rowPerPage);
                    if (d.length % rowPerPage > 0) t += 1;
                    setTotalPage(t)
                }
            })
            .catch((e) => {
                console.log(e)
            })
    }, [])

    return (
        <div className="tab-wrapper">
            {
                loading ?
                    <CircularProgress />
                    : <>
                        {
                            record.map((r, key) => {
                                if (key >= rowPerPage * (page - 1) && key < rowPerPage * page)
                                    return (
                                        <TopupTabRow key={key} currency={r?.wallet?.crypto_type} dateTime={moment(r?.created_at).format("YYYY/MM/DD HH:mm")} topupValue={NumberFormatUtils(r?.amount, 8)} status={r?.status} />
                                    )
                            })
                        }
                        <div className="center">
                            <Pagination color={"secondary"} count={totalPage} page={page} onChange={handlePageChange} 
                            />
                        </div>
                    </>
            }

        </div>
    );
}

