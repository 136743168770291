import AccountDetails from 'components/Page/account_details';
import OfferDetails from 'components/Page/offer_details';
import { OrderDetailsPopup } from './order_details';
import RefundPopup from './refund';
import ReviewPopup from './review';

const Report = {
    "F5JVodwQeCRiF7dKSoJg": {
        "ReportDetails":"dnnd\nsjjs",
        "ReportProof1":"https://firebasestorage.googleapis.com/v0/b/intepayhk-bd35c.appspot.com/o/images%2FshopOfferReport%2F5lS0z8lAa1YGT3UBdXmR%2FZYJcBig4qYhHg5Q0s4VDyTHqLBw2%2Freport1_2021-12-23T18%3A51%3A48.434761.jpeg?alt=media&token=2c1ffd62-d1d2-4ef2-8905-efa789bb7cac",
        "ReportProof2":null,
        "ReportReason":"dnnd\nsjjs",
        "ShopOfferID":"5lS0z8lAa1YGT3UBdXmR",
        "Status": "Pending",
        "SubmitDate": "December 23, 2021 at 6:51:49 PM UTC+8",
        "UserID":"ZYJcBig4qYhHg5Q0s4VDyTHqLBw2"
    }
}

const Placeholder = () => {
    return(
        <div>
            Placeholder
        </div>
    );
}

function CustomPopup({ name, id }) {
    if(name === "OrderDetailsPopup"){
        return <OrderDetailsPopup id={id}/>;
    } else if(name === "RefundPopup"){
        return <RefundPopup id={id}/>;
    } else if(name === "ReviewPopup"){
        return <ReviewPopup id={id}/>;
    } else if(name === "AccountDetailsPopup"){
        return <AccountDetails id={id}/>;
    } else if(name === "OfferDetailsPopup"){
        return <OfferDetails id={id}/>;
    } else if(name === "Placeholder"){
        return Placeholder;
    } else return <></>
}

export default CustomPopup;