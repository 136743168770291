import className from "./style";
const walletstatus = [
    {
      label: "completed",
      value: 1,
      color: "success",
      class: className.success,
      param: {
        name: "status",
        value: "completed"
      }
    },
    {
      label: "failed",
      value: 2,
      color: "error",
      class: className.error,
      param: {
        name: "status",
        value: "failed"
      }
    },
    {
      label: "processing",
      value: 3,
      color: "primary",
      class: className.default,
      param: {
        name: "status",
        value: "processing"
      }
    }
];

export default walletstatus;