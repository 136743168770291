import { Grid } from "@material-ui/core";
import './styles.css';

import { Row } from "react-bootstrap";

import SuiBox from "components/Default/SuiBox";
import SuiButton from "components/Default/SuiButton";

import InputWrapper from 'components/Custom/input_wrapper';
import { useState, useEffect } from 'react';
import GameApi from 'api/game';
import AuthApi from "api/auth";

import { STAFF_PERMISSION } from 'config/permission';
import { useAlert } from 'react-alert';
import BasicSelect from 'components/Custom/custom_select';
import { difficulty } from "./options";
import { ToggleButton, ToggleButtonGroup, CircularProgress, Pagination } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import UpdateIcon from '@mui/icons-material/Update';
import AddIcon from "@mui/icons-material/Add";
import { BasicDateTimePicker, TimePicker } from "components/Custom";
import { DateFormatUtils } from "util/index";
import SwitchWrapper from 'components/Custom/switch_wrapper';
import AuthCodeHelper from "util/AuthCodeHelper";
import { Loader } from 'components/Custom';

export default function Questions ({ id, action }){
    //console.log(action);
    let testMode = false;
    const alert = useAlert();

    const [page, setPage] = useState(0);
    const [auth, setAuth] = useState(false);
    const [adminCodeInput, setAdminCodeInput] = useState("");

    const [old_id, setOid] = useState(0);
    const [newGame, setNewGame] = useState(null);
    
    const handleCreate = () => {
        setPage(2);
    };

    const handleQuestionPoll = (game, o_id) => {
        setNewGame(game);
        setOid(o_id);
        setPage(1);
    };

    const handleSelectQuestion = (id) => {
        //console.log(newGame);
        console.log('old_id:'+old_id);
        console.log('new_id:'+id);
        [...Array(5)].map((d, i) => i+1 === old_id ? newGame['question'+(i+1)] = id : newGame['question'+(i+1)])
        console.log(newGame);
        setPage(
            action === 3 ? 3
            :
            action === 4 ? 5
            : 3
            );
    };

    const handleCreateGame = () => {
        setPage(5);
    }

    const handleAuthCode = (resp) => {
        // console.log(resp);

        if(resp){
            if(resp.isAuth){
                setAuth(true);
                setPage(action);
            } else{
                alert.error(resp.message);
            }
        }
        else{
            alert.error(ERROR.callback);
        }
    }

    useEffect(()=>{
        if(action){
            switch (action) {
                case 0: setPage(0); break;
                case 1: setPage(0); break;
                case 2: setPage(2); break;
                case 3: setPage(3); break;
                case 4: setPage(0); break;
                case 5: setPage(5); break;
                default:
                    break;
            }
        }
    }, []);

    if(page === 0){
        return (<AuthCodeHelper title="Admin Authentication" btnName="Confirm" permission={STAFF_PERMISSION.DEFAULT} callback={handleAuthCode}/>        );
    }
    else if(page === 1){
        return (<QuestionPollPopup id={id} action={action} handleCreate={handleCreate} handleQuestionPoll={handleQuestionPoll} handleSelectQuestion={handleSelectQuestion}/>);
    }
    else if(page === 2){
        return (<QuestionCreatePopup id={id} action={action} handleCreate={handleCreate} handleQuestionPoll={handleQuestionPoll} handleSelectQuestion={handleSelectQuestion}/>);
    }
    else if(page === 3){
        return (<QuestionPopup id={id} action={action} newGame={newGame} handleCreate={handleCreate} handleQuestionPoll={handleQuestionPoll} handleSelectQuestion={handleSelectQuestion}/>);
    }
    else if(page === 4){
        return (<QuestionMgtPopup id={id} action={action} handleCreate={handleCreate} handleQuestionPoll={handleQuestionPoll} handleSelectQuestion={handleSelectQuestion} handleCreateGame={handleCreateGame}/>);
    }
    else if(page === 5){
        return (<GameCreatePopup id={id} action={action} newGame={newGame} handleCreate={handleCreate} handleQuestionPoll={handleQuestionPoll} handleSelectQuestion={handleSelectQuestion}/>)
    }
}

function QuestionPollPopup ({ id, action, handleCreate, handleQuestionPoll, handleSelectQuestion }) {
    const alert = useAlert();
    const [questionData, setQuestionData] = useState([]);
    const [inputs, setInputs] = useState({}); // inputs
    const [disable, setDisable] = useState([]);

    //pagination
    const [page, setPage] = useState(1);
    const [record, setRecord] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalPage, setTotalPage] = useState(1);
    const [rowPerPage, setRowPerPage] = useState(3);

    const handleChange = e => setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));

    const handlePageChange = (event, value) => {
        if (value <= totalPage) {
            setPage(value);
        }
    };

    const handleRowPerPage = (v) => {
        setRowPerPage(v);
        let t = record.length / rowPerPage;
        if (record.length % rowPerPage > 0) t += 1;
        setTotalPage(t);
    };

    const fetchQuestionData = async () =>{
        await GameApi.getQuestions()
            .then((res) => {    
                if(res.status === 200){
                    console.log(res.data.data);
                    setQuestionData(res.data.data);

                    let t = parseInt(res.data.data.length / rowPerPage);
                    if (res.data.data.length % rowPerPage > 0) t += 1;
                    setTotalPage(t)

                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const editItem = (key) => {
        console.log(disable[key]);
        setDisable(prevState => ({ ...prevState, [key]: (disable[key] === true || !disable[key]) ? false : true }));
        console.log(disable);
    }

    const updateItem = async (key, q_index) => {
        let level = 0;
        difficulty.map((d, index) => 
            index === questionData[q_index].difficulty_level 
                ? level = d.value : null);

        await GameApi.updateQuestion({
            id: key,
            title: inputs[`${key}_question`] ?? questionData[q_index].title,
            difficulty_level: inputs[`${key}_level`] ?? (level-1),
            optionA: inputs[`${key}_optionA`] ?? questionData[q_index].optionA,
            optionB: inputs[`${key}_optionB`] ?? questionData[q_index].optionB,
            optionC: inputs[`${key}_optionC`] ?? questionData[q_index].optionC,
            optionD: inputs[`${key}_optionD`] ?? questionData[q_index].optionD,
            correct_option: inputs[`${key}_correct_option`] ?? questionData[q_index].correct_option
        })
            .then((res) => {    
                if(res.status === 200){
                    alert.success(`question ${key} changed`);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const removeItem = async (key) => {
        await GameApi.removeQuestion(key)
            .then((res) => {    
                if(res.status === 200){
                    alert.success(`question ${key} deleted`);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(()=>{
        fetchQuestionData();
    }, [])

    return (
        <div id="popup1" className="popup-wrapper">
            <div id="" className="popup1-flex-column">
                <h4>Question Poll</h4>
                <SuiButton buttonColor="secondary" onClick={handleCreate}>Add New Question</SuiButton>
                <div className="popup-content">
                    {
                        questionData && !loading ?
                            questionData.map((data, index) => {
                                if (index >= rowPerPage * (page - 1) && index < rowPerPage * page)
                                    return (
                                        <Row>
                                            <SuiBox mb={1}>
                                                <Grid container alignItems="center" style={{ display: '-webkit-box' }}>
                                                    <Grid item xs={1} sm={1} md={1} xl={1}>
                                                        {data.id}
                                                    </Grid>
                                                    <Grid item xs={11} sm={11} md={11} xl={11}>
                                                        <SuiBox mb={1}>
                                                            <InputWrapper attr="Question" name={`${data.id}_question`} value={inputs[`${data.id}_question`] ?? data.title} onChange={handleChange} size="sm" disable={disable[data.id] ?? true}></InputWrapper>
                                                        </SuiBox>

                                                        <SuiBox mb={1}>
                                                            <Grid container alignItems="center">
                                                                <Grid item xs={6} sm={6} md={6} xl={6}>
                                                                    <InputWrapper attr="A" name={`${data.id}_optionA`} value={inputs[`${data.id}_optionA`] ?? data.optionA} onChange={handleChange} size="sm" disable={disable[data.id] ?? true}></InputWrapper>
                                                                </Grid>
                                                                <Grid item xs={6} sm={6} md={6} xl={6}>
                                                                    <InputWrapper attr="B" name={`${data.id}_optionB`} value={inputs[`${data.id}_optionB`] ?? data.optionB} onChange={handleChange} size="sm" disable={disable[data.id] ?? true}></InputWrapper>
                                                                </Grid>
                                                            </Grid>
                                                        </SuiBox>

                                                        <SuiBox mb={1}>
                                                            <Grid container alignItems="center">
                                                                <Grid item xs={6} sm={6} md={6} xl={6}>
                                                                    <InputWrapper attr="C" name={`${data.id}_optionC`} value={inputs[`${data.id}_optionC`] ?? data.optionC} onChange={handleChange} size="sm" disable={disable[data.id] ?? true}></InputWrapper>
                                                                </Grid>
                                                                <Grid item xs={6} sm={6} md={6} xl={6}>
                                                                    <InputWrapper attr="D" name={`${data.id}_optionD`} value={inputs[`${data.id}_optionD`] ?? data.optionD} onChange={handleChange} size="sm" disable={disable[data.id] ?? true}></InputWrapper>
                                                                </Grid>
                                                            </Grid>
                                                        </SuiBox>

                                                        <Grid container alignItems="center">
                                                            <Grid item xs={3} sm={3} md={3} xl={3}>
                                                                <InputWrapper attr="Answer" name={`${data.id}_correct_option`} value={inputs[`${data.id}_correct_option`] ?? data.correct_option} onChange={handleChange} size="sm" disable={disable[data.id] ?? true}></InputWrapper>
                                                            </Grid>
                                                            {
                                                                difficulty ? difficulty.map((d, index) => 
                                                                    index === data.difficulty_level ? 
                                                                        <Grid item xs={4} sm={4} md={4} xl={4}>
                                                                            <InputWrapper attr="Level" name={`${data.id}_level`} value={inputs[`${data.id}_level`] ?? d.label} onChange={handleChange} size="sm" disable={disable[data.id] ?? true}></InputWrapper>
                                                                        </Grid>
                                                                        : <></>
                                                                ) : <></>
                                                            }
                                                            
                                                            {
                                                                (action === 3 || action === 4) ?
                                                                <>
                                                                    <Grid item xs={3} sm={3} md={3} xl={3}></Grid>
                                                                    <Grid item xs={3} sm={3} md={3} xl={3}>
                                                                        <SuiButton buttonColor="secondary" onClick={() => handleSelectQuestion(data.id)}>Select</SuiButton>
                                                                    </Grid>
                                                                </>
                                                                : action === 1 ?
                                                                <>
                                                                    <ToggleButtonGroup
                                                                        color="primary"
                                                                        exclusive>
                                                                        
                                                                        {
                                                                            disable[data.id] === false ?
                                                                                <ToggleButton value="update" onClick={() => updateItem(data.id, index)}>
                                                                                    <UpdateIcon></UpdateIcon>
                                                                                </ToggleButton>
                                                                                :
                                                                                <ToggleButton value="edit" onClick={() => editItem(data.id)}>
                                                                                    <EditIcon></EditIcon>
                                                                                </ToggleButton>
                                                                        }
                            
                                                                        <ToggleButton value="delete" onClick={() => removeItem(data.id)}>
                                                                            <DeleteIcon></DeleteIcon>
                                                                        </ToggleButton>
                            
                                                                    </ToggleButtonGroup>
                                                                </>
                                                                : <></>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </SuiBox>
                                        </Row>
                                    )
                            }) : <CircularProgress/>
                    }
                    <div className="center">
                        <Pagination color={"secondary"} count={totalPage} page={page} onChange={handlePageChange} />
                    </div>
                </div>
            </div>
        </div>
    );
}

function QuestionCreatePopup ({ id, action, handleCreate, handleQuestionPoll }) {
    const alert = useAlert();
    const [inputs, setInputs] = useState({}); // inputs
    const [newQuestion, setNewQuestion] = useState([
        {
            question: "",
            optionA: "",
            optionB: "",
            optionC: "",
            optionD: "",
            correctOption: "",
            level: ""
        }
    ]);
    const [count, setCount] = useState(1);
    const [loading, setLoading] = useState(false);

    const handleChange = (e, index) => {
        setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }))
        let new_question = [];
        newQuestion.map((d, i) => {
            if(i === index){
                let field = e.target.name.split("_")[0];
                d[field] = e.target.value;
            }
            new_question.push(d);
        })
        //console.log(new_question);
        setNewQuestion(new_question);
    };

    const handleLevelChange = (name, value, index) => {
        setInputs(prevState => ({ ...prevState, [name]: value }))
        let new_question = [];
        newQuestion.map((d, i) => {
            if(i === index){
                let field = name.split("_")[0];
                d[field] = value;
            }
            new_question.push(d);
        })
        //console.log(new_question);
        setNewQuestion(new_question);
    }

    const actionEdit = e => {
        let new_question = newQuestion;
        new_question.push({
            question: "",
            optionA: "",
            optionB: "",
            optionC: "",
            optionD: "",
            correctOption: "",
            level: ""
        });
        setCount(count + 1);
        setNewQuestion(new_question);
    }

    const actionRemove = (index) => {
        let new_question = newQuestion;
        new_question = new_question.filter((d, i) => i !== index)
        let new_inputs = inputs;

        if(new_question.length >= 1){
            let keys = Object.keys(new_question[0]);
            for (const key in keys) {
                let back = parseInt(index + 1);
                new_inputs[keys[key]+"_"+index] = new_inputs[keys[key]+"_"+back]
                delete new_inputs[keys[key]+"_"+back]
            }
            setInputs(new_inputs);
        }
        setNewQuestion(new_question);
    }

    const handleCreateQuestion = async ({ data }) => {
        let level = data["level"] - 1;

        await GameApi.addQuestion({
            title: data["question"],
            difficulty_level: level,
            optionA: data["optionA"],
            optionB: data["optionB"],
            optionC: data["optionC"],
            optionD: data["optionD"],
            correct_option: data["correctOption"]
        })
        .then((res) => {    
            if(res.status === 200){
                alert.success("Create Question success");
            }
        })
        .catch((err) => {
            alert.error(err.message);
        });
    }

    const handleCreateAllQuestion = async () =>{
        let new_question = newQuestion;
        new_question.map((d, index) => {
            console.log(d);

            handleCreateQuestion({
                data: d
            });
        })
    };

    useEffect(()=>{
        setNewQuestion(newQuestion);
    }, [newQuestion])

    return (
        <div id="popup1" className="popup-wrapper">
            <div id="" className="popup1-flex-column">
                <h4>Question</h4>
                <div className="popup-content">
                    {/* <p>{count}</p> */}
                    {
                        newQuestion.map((d, index) => {
                                return (
                                    <Row key={index}>
                                        <SuiBox mb={1}>
                                            <Grid container alignItems="center" style={{ display: '-webkit-box' }}>
                                                <Grid item xs={11} sm={11} md={11} xl={11}>
                                                    <SuiBox mb={1}>
                                                        <InputWrapper attr="Question" name={"question_"+index} value={inputs[`question_${index}`]} onChange={(e) => handleChange(e, index)} size="sm" disable={false}></InputWrapper>
                                                    </SuiBox>
                
                                                    <SuiBox mb={1}>
                                                        <Grid container alignItems="center">
                                                            <Grid item xs={6} sm={6} md={6} xl={6}>
                                                                <InputWrapper attr="A" name={"optionA_"+index} value={inputs[`optionA_${index}`]} onChange={(e) => handleChange(e, index)} size="sm" disable={false}></InputWrapper>
                                                            </Grid>
                                                            <Grid item xs={6} sm={6} md={6} xl={6}>
                                                                <InputWrapper attr="B" name={"optionB_"+index} value={inputs[`optionB_${index}`]} onChange={(e) => handleChange(e, index)} size="sm" disable={false}></InputWrapper>
                                                            </Grid>
                                                        </Grid>
                                                    </SuiBox>
                
                                                    <SuiBox mb={1}>
                                                        <Grid container alignItems="center">
                                                            <Grid item xs={6} sm={6} md={6} xl={6}>
                                                                <InputWrapper attr="C" name={"optionC_"+index} value={inputs[`optionC_${index}`]} onChange={(e) => handleChange(e, index)} size="sm" disable={false}></InputWrapper>
                                                            </Grid>
                                                            <Grid item xs={6} sm={6} md={6} xl={6}>
                                                                <InputWrapper attr="D" name={"optionD_"+index} value={inputs[`optionD_${index}`]} onChange={(e) => handleChange(e, index)} size="sm" disable={false}></InputWrapper>
                                                            </Grid>
                                                        </Grid>
                                                    </SuiBox>
                
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={3} sm={3} md={3} xl={3}>
                                                            <InputWrapper attr="Answer" name={"correctOption_"+index} value={inputs[`correctOption_${index}`]} onChange={(e) => handleChange(e, index)} size="sm" disable={false}></InputWrapper>
                                                        </Grid>
                                                        <Grid item xs={3} sm={3} md={3} xl={3}>
                                                            <BasicSelect title="Level" items={difficulty} onChange={(e)=>{ e ? handleLevelChange(`level_${index}`, e.value, index) : handleLevelChange(`level_${index}`, null, index)}} position="row"/>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={1} sm={1} md={1} xl={1}>
                                                    <SuiButton buttonColor="error" onClick={(e) => actionRemove(index)} style={{ width: '100%'}}>
                                                        <DeleteIcon/>
                                                    </SuiButton>
                                                </Grid>
                                            </Grid>
                                        </SuiBox>
                                    </Row>
                                );
                            })
                    }

                    <Grid container alignItems="center" spacing={1}>
                        <Grid item xs={6} sm={6} md={6} xl={6}>
                            <SuiButton buttonColor="secondary" onClick={(e) => actionEdit(e.value)} style={{ width: '100%'}}>
                                <AddIcon/>
                            </SuiButton>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} xl={6}>
                            <SuiButton buttonColor="secondary" onClick={handleCreateAllQuestion} style={{ width: '100%'}}>
                                Insert All
                            </SuiButton>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
}

function QuestionPopup ({ id, action, newGame, handleCreate, handleQuestionPoll }) {
    console.log(newGame);
    const alert = useAlert();

    const [gameData, setGameData] = useState([]);
    const [questionData, setQuestionData] = useState([]);
    const [inputs, setInputs] = useState({}); // inputs
    const [selectedValue, setSelectedValue] = useState();
    const [disable, setDisable] = useState();

    const handleChange = e => setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));

    const fetchOneGameData = async (id) =>{
        await GameApi.getOneGameByFid(id)
            .then((res) => {    
                if(res && res.status === 200){
                    console.log(res.data.data);
                    setGameData(res.data.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const fetchQuestionData = async () =>{
        await GameApi.getQuestions()
            .then((res) => {    
                if(res.status === 200){
                    console.log(res.data.data);
                    setQuestionData(res.data.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleUpdateGame = async () => {
        await GameApi.updateGame({
            id: id,
            start_at: gameData.start_at,
            end_at: gameData.end_at,
            duration: gameData.duration,
            usdt_amount: gameData.usdt_amount,
            question1: gameData.question1,
            question2: gameData.question2,
            question3: gameData.question3,
            question4: gameData.question4,
            question5: gameData.question5
        })
            .then((res) => {    
                if(res.status === 200){
                    alert.success(`Game ${id} Updated`);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const findQuestion = (id, data) => Object.values(data).filter(d => d.id === id)[0]

    useEffect(()=>{
        if(newGame){
            setGameData(newGame);
        } else if(id && gameData.length < 1){
            fetchOneGameData(id);
        }
        if(questionData.length < 1){
            fetchQuestionData();
        }
    }, [])

    return (
        <div id="popup1" className="popup-wrapper">
            <div id="" className="popup1-flex-column">
                <h4>Question List</h4>
                <div className="popup-content">
                    <Row>
                            {[...Array(5)].map((x, i) =>
                            <SuiBox mb={1}>
                                <Grid container alignItems="center" style={{ display: '-webkit-box' }}>
                                    <Grid item xs={1} sm={1} md={1} xl={1}>
                                        {i+1}
                                    </Grid>
                                    <Grid item xs={11} sm={11} md={11} xl={11}>
                                        <SuiBox mb={1}>
                                            <InputWrapper attr="Question" name={""} value={findQuestion(gameData['question'+(i+1)], questionData)?.title} onChange={handleChange} size="sm" disable={true}></InputWrapper>
                                        </SuiBox>

                                        <SuiBox mb={1}>
                                            <Grid container alignItems="center">
                                                <Grid item xs={6} sm={6} md={6} xl={6}>
                                                    <InputWrapper attr="A" name={""} value={findQuestion(gameData['question'+(i+1)], questionData)?.optionA} onChange={handleChange} size="sm" disable={true}></InputWrapper>
                                                </Grid>
                                                <Grid item xs={6} sm={6} md={6} xl={6}>
                                                    <InputWrapper attr="B" name={""} value={findQuestion(gameData['question'+(i+1)], questionData)?.optionB} onChange={handleChange} size="sm" disable={true}></InputWrapper>
                                                </Grid>
                                            </Grid>
                                        </SuiBox>

                                        <SuiBox mb={1}>
                                            <Grid container alignItems="center">
                                                <Grid item xs={6} sm={6} md={6} xl={6}>
                                                    <InputWrapper attr="C" name={""} value={findQuestion(gameData['question'+(i+1)], questionData)?.optionC} onChange={handleChange} size="sm" disable={true}></InputWrapper>
                                                </Grid>
                                                <Grid item xs={6} sm={6} md={6} xl={6}>
                                                    <InputWrapper attr="D" name={""} value={findQuestion(gameData['question'+(i+1)], questionData)?.optionD} onChange={handleChange} size="sm" disable={true}></InputWrapper>
                                                </Grid>
                                            </Grid>
                                        </SuiBox>

                                        <Grid container alignItems="center">
                                            <Grid item xs={4} sm={4} md={4} xl={4}>
                                                <InputWrapper attr="Answer" name={""} value={findQuestion(gameData['question'+(i+1)], questionData)?.correct_option} onChange={handleChange} size="sm" disable={true}></InputWrapper>
                                            </Grid>
                                            <Grid item xs={3} sm={3} md={3} xl={3}></Grid>
                                            {
                                                gameData["status"] === "NOT_YET_STARTED"
                                                    ? <Grid item xs={3} sm={3} md={3} xl={3}>
                                                        <SuiButton buttonColor="secondary" onClick={() => handleQuestionPoll(gameData, i+1)}>Edit</SuiButton>
                                                    </Grid>
                                                    : <></>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </SuiBox>
                            )}
                        </Row>
                        
                        {
                            gameData["status"] === "NOT_YET_STARTED"
                                ? <SuiButton buttonColor="secondary" onClick={handleUpdateGame}>Save</SuiButton>
                                : <></>
                        }
                </div>
            </div>
        </div>
    );
}

function GameCreatePopup ({ id, action, newGame, handleCreate, handleQuestionPoll }) {
    console.log(newGame);
    const alert = useAlert();

    const [gameData, setGameData] = useState([]);
    const [questionData, setQuestionData] = useState([]);
    const [inputs, setInputs] = useState({}); // inputs
    const [startAt, setStartAt] = useState(null);
    const [endAt, setEndAt] = useState(null);

    const handleChange = e => setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));

    const fetchQuestionData = async () =>{
        await GameApi.getQuestions()
            .then((res) => {    
                if(res.status === 200){
                    console.log(res.data.data);
                    setQuestionData(res.data.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleCreateGame = async () => {
        await GameApi.addGame({
            start_at: DateFormatUtils(startAt, "YYYY-MM-DD HH:mm:ss") ?? gameData.start_at,
            end_at: DateFormatUtils(endAt, "YYYY-MM-DD HH:mm:ss") ?? gameData.end_at,
            duration: inputs["duration"] ?? gameData.duration,
            usdt_amount: inputs["fee"] ?? gameData.usdt_amount,
            question1: gameData.question1,
            question2: gameData.question2,
            question3: gameData.question3,
            question4: gameData.question4,
            question5: gameData.question5
        })
            .then((res) => {    
                if(res.status === 200){
                    alert.success(`New Game Created`);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const findQuestion = (id, data) => Object.values(data).filter(d => d.id === id)[0]

    useEffect(()=>{
        if(newGame){
            setGameData(newGame);
        }
        if(questionData.length < 1){
            fetchQuestionData();
        }
    }, [])

    return (
        <div id="popup1" className="popup-wrapper">
            <div id="" className="popup1-flex-column">
                <h4>New Game</h4>
                <Row>
                    <SuiBox mb={1}>
                        <Grid container alignItems="center" style={{ display: '-webkit-box' }}>
                            <Grid item xs={11} sm={11} md={11} xl={11}>
                                <SuiBox mb={1}>
                                    <Grid container alignItems="center" spacing={1}>
                                        <Grid item xs={6} sm={6} md={6} xl={6}>
                                            <div className="text-sm">Start Time</div>
                                            <BasicDateTimePicker value={startAt} onChange={(newValue) => setStartAt(newValue)}/>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6} xl={6}>
                                            <div className="text-sm">End Time</div>
                                            <BasicDateTimePicker value={endAt} onChange={(newValue) => setEndAt(newValue)}/>
                                        </Grid>
                                    </Grid>
                                </SuiBox>

                                <SuiBox mb={1}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={6} sm={6} md={6} xl={6}>
                                            <InputWrapper attr="Fee (USDT)" name="duration" value={inputs["duration"]} onChange={handleChange} size="m" disable={false}></InputWrapper>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6} xl={6}>
                                            <InputWrapper attr="Game Time (Seconds)" name="fee" value={inputs["fee"]} onChange={handleChange} size="xl" disable={false}></InputWrapper>
                                        </Grid>
                                    </Grid>
                                </SuiBox>
                            </Grid>
                        </Grid>
                    </SuiBox>
                </Row>
                <h4>Question List</h4>
                <div className="popup-content">
                    <Row>
                        {[...Array(5)].map((x, i) =>
                            <SuiBox mb={1}>
                                <Grid container alignItems="center" style={{ display: '-webkit-box' }}>
                                    <Grid item xs={1} sm={1} md={1} xl={1}>
                                        {i+1}
                                    </Grid>
                                    <Grid item xs={11} sm={11} md={11} xl={11}>
                                        <SuiBox mb={1}>
                                            <InputWrapper attr="Question" name={""} value={findQuestion(gameData['question'+(i+1)], questionData)?.title} onChange={handleChange} size="sm" disable={true}></InputWrapper>
                                        </SuiBox>

                                        <SuiBox mb={1}>
                                            <Grid container alignItems="center">
                                                <Grid item xs={6} sm={6} md={6} xl={6}>
                                                    <InputWrapper attr="A" name={""} value={findQuestion(gameData['question'+(i+1)], questionData)?.optionA} onChange={handleChange} size="sm" disable={true}></InputWrapper>
                                                </Grid>
                                                <Grid item xs={6} sm={6} md={6} xl={6}>
                                                    <InputWrapper attr="B" name={""} value={findQuestion(gameData['question'+(i+1)], questionData)?.optionB} onChange={handleChange} size="sm" disable={true}></InputWrapper>
                                                </Grid>
                                            </Grid>
                                        </SuiBox>

                                        <SuiBox mb={1}>
                                            <Grid container alignItems="center">
                                                <Grid item xs={6} sm={6} md={6} xl={6}>
                                                    <InputWrapper attr="C" name={""} value={findQuestion(gameData['question'+(i+1)], questionData)?.optionC} onChange={handleChange} size="sm" disable={true}></InputWrapper>
                                                </Grid>
                                                <Grid item xs={6} sm={6} md={6} xl={6}>
                                                    <InputWrapper attr="D" name={""} value={findQuestion(gameData['question'+(i+1)], questionData)?.optionD} onChange={handleChange} size="sm" disable={true}></InputWrapper>
                                                </Grid>
                                            </Grid>
                                        </SuiBox>

                                        <Grid container alignItems="center">
                                            <Grid item xs={4} sm={4} md={4} xl={4}>
                                                <InputWrapper attr="Answer" name={""} value={findQuestion(gameData['question'+(i+1)], questionData)?.correct_option} onChange={handleChange} size="sm" disable={true}></InputWrapper>
                                            </Grid>
                                            <Grid item xs={3} sm={3} md={3} xl={3}></Grid>
                                            <Grid item xs={3} sm={3} md={3} xl={3}>
                                                <SuiButton buttonColor="secondary" onClick={() => handleQuestionPoll(gameData, i+1)}>Edit</SuiButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </SuiBox>
                            )}
                        </Row>

                        <SuiButton buttonColor="secondary" onClick={handleCreateGame}>Save</SuiButton>
                </div>
            </div>
        </div>
    );
}

function QuestionMgtPopup ({ id, action, handleCreate, handleQuestionPoll, handleCreateGame }) {
    const [inputs, setInputs] = useState({}); // inputs
    const handleChange = e => setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    const [level, setLevel] = useState(null);
    const [pattern, setPattern] = useState([]);
    const [disable, setDisable] = useState([]);
    const [newPattern, setNewPattern] = useState(0);
    const alert = useAlert();

    const handleInputsChange = (key, newValue) => {
        setInputs(prevState => ({ ...prevState, [key]: newValue }))
    }

    const fetchData = async () => {
        await GameApi.getCompetitionPattern()
            .then((res) => {    
                if(res.status === 200){
                    //console.log(res.data.data);
                    setPattern(res.data.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const editItem = (key) => {
        setDisable(prevState => ({ ...prevState, [key]: disable[key] === false ? true : false }));
    }

    const updateItem = async (key) => {
        await GameApi.updateCompetitionPattern({
            id: key,
            start_time_hour: DateFormatUtils(inputs["startAt"], "HH"),
            start_time_minute: DateFormatUtils(inputs["startAt"], "mm"),
            end_time_hour: DateFormatUtils(inputs["endAt"], "HH"),
            end_time_minute: DateFormatUtils(inputs["endAt"], "mm"),
            duration: inputs["game_time"] ?? 0,
            usdt_amount: inputs["fee_usdt"] ?? 0,
            is_active: inputs["isActive"] ?? true
        })
        .then((res) => {
            if(res.status === 200){
                alert.success("Edit Data Success");
            }
        })
        .catch((err) => {
            alert.error(err.message);
        });
    }

    const createItem = async (key) => {
        await GameApi.addCompetitionPattern({
            start_time_hour: DateFormatUtils(inputs[key+"startAt"], "HH"),
            start_time_minute: DateFormatUtils(inputs[key+"startAt"], "mm"),
            end_time_hour: DateFormatUtils(inputs[key+"endAt"], "HH"),
            end_time_minute: DateFormatUtils(inputs[key+"endAt"], "mm"),
            duration: inputs[key+"game_time"] ?? 0,
            usdt_amount: inputs[key+"fee_usdt"] ?? 0,
            is_active: inputs[key+"isActive"] ?? true
        })
        .then((res) => {
            if(res.status === 200){
                alert.success("create Data Success");
            }
        })
        .catch((err) => {
            alert.error(err.message);
        });
    }

    const removeItem = async (key) => {
        await GameApi.deleteCompetitionPattern(key)
            .then((res) => {
                if(res && res.status === 200){
                    alert.success(`Remove competition pattern ${key} success`);
                }
            })
            .catch((err) => {
                alert.error(err.message);
            });
    }

    const handleNewPattern = () => {
        let num = newPattern + 1;
        setNewPattern(num);
    }

    const handleCreateQuestion = async () =>{
        if(inputs["question"] 
        && inputs["optionA"] 
        && inputs["optionB"]
        && inputs["optionC"] 
        && inputs["optionD"]
        && inputs["correct_option"]
        && level){
            await GameApi.addQuestion({
                title: inputs["question"],
                difficulty_level: level,
                optionA: inputs["optionA"],
                optionB: inputs["optionB"],
                optionC: inputs["optionC"],
                optionD: inputs["optionD"],
                correct_option: inputs["correct_option"]
            })
                .then((res) => {    
                    if(res.status === 200){
                        alert.success("Create Question success");
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else{
            alert.error("All fields needed");
        }
    };

    useEffect(()=>{
        fetchData();
    }, [])

    return (
        <div id="popup1" className="popup-wrapper">
            <div id="" className="popup1-flex-column">
                <h4>Game Mgt</h4>
                <div className="popup-content">
                    {
                        pattern.length >=1 ?
                            pattern.map((data, index) => {
                                return (
                                    <Row>
                                        <SuiBox mb={1}>
                                            <Grid container alignItems="center" style={{ display: '-webkit-box' }}>
                                                <Grid item xs={11} sm={11} md={11} xl={11}>
                                                    <SuiBox mb={1}>
                                                        <InputWrapper attr="Game ID" name="question" value={inputs["question"] ?? data.id} onChange={handleChange} size="sm" disable={true}></InputWrapper>
                                                    </SuiBox>

                                                    <SuiBox mb={1}>
                                                        <Grid container alignItems="center">
                                                            <Grid item xs={6} sm={6} md={6} xl={6}>
                                                                {
                                                                    disable[index] === false
                                                                        ? <TimePicker value={inputs["startAt"]} onChange={(newValue) => handleInputsChange("startAt", newValue)}/>
                                                                        : <InputWrapper attr="Start Time" name="startAt" value={inputs["startAt"] ?? data.start_time_hour +":"+ data.start_time_minute} onChange={handleChange} size="m" disable={disable[index] ?? true}></InputWrapper> 
                                                                }
                                                            </Grid>
                                                            <Grid item xs={6} sm={6} md={6} xl={6}>
                                                                {
                                                                    disable[index] === false
                                                                        ? <TimePicker value={inputs["endAt"]} onChange={(newValue) => handleInputsChange("endAt", newValue)}/>
                                                                        : <InputWrapper attr="End Time" name="endAt" value={inputs["endAt"] ?? data.end_time_hour +":"+ data.end_time_minute} onChange={handleChange} size="m" disable={disable[index] ?? true}></InputWrapper> 
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </SuiBox>

                                                    <SuiBox mb={1}>
                                                        <Grid container alignItems="center">
                                                            <Grid item xs={6} sm={6} md={6} xl={6}>
                                                                <InputWrapper attr="Fee (USDT)" name="fee_usdt" value={inputs["fee_usdt"] ?? data.usdt_amount} onChange={handleChange} size="m" disable={disable[index] ?? true}></InputWrapper>
                                                            </Grid>
                                                            <Grid item xs={6} sm={6} md={6} xl={6}>
                                                                <InputWrapper attr="Game Time (Seconds)" name="game_time" value={inputs["game_time"] ?? data.duration} onChange={handleChange} size="xl" disable={disable[index] ?? true}></InputWrapper>
                                                            </Grid>
                                                        </Grid>
                                                    </SuiBox>
                                                    <SwitchWrapper attr="" size="" checked={inputs["isActive"] ?? data.is_active} onChange={() => handleInputsChange("isActive", inputs["isActive"] === false ? true : false)}/>
                                                </Grid>
                                            </Grid>
                                        </SuiBox>

                                        <ToggleButtonGroup
                                            color="primary"
                                            exclusive>
                                                        
                                            {
                                                disable[index] === false ?
                                                    <ToggleButton value="update" onClick={() => updateItem(data.id)}>
                                                        <UpdateIcon></UpdateIcon>
                                                    </ToggleButton>

                                                :   <ToggleButton value="edit" onClick={() => editItem(index)}>
                                                        <EditIcon></EditIcon>
                                                    </ToggleButton>
                                            }

                                                    <ToggleButton value="delete" onClick={() => removeItem(data.id)}>
                                                        <DeleteIcon></DeleteIcon>
                                                    </ToggleButton>

                                        </ToggleButtonGroup>
                                    </Row>
                            )})
                        : <></>
                    }
                    {
                        [...Array(newPattern)].map((d, index) => {
                            return (
                                <Row>
                                    <SuiBox mb={1}>
                                        <Grid container alignItems="center" style={{ display: '-webkit-box' }}>
                                            <Grid item xs={11} sm={11} md={11} xl={11}>
                                                <SuiBox mb={1}>
                                                    <InputWrapper attr="Game ID" name={"new_pattern_"+index+"_question"} value={inputs["new_pattern_"+index+"_question"]} onChange={handleChange} size="sm" disable={true}></InputWrapper>
                                                </SuiBox>

                                                <SuiBox mb={1}>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={6} sm={6} md={6} xl={6}>
                                                            {
                                                                disable["new_pattern_"+index] === false
                                                                    ? <TimePicker value={inputs["new_pattern_"+index+"_startAt"]} onChange={(newValue) => handleInputsChange("new_pattern_"+index+"_startAt", newValue)}/>
                                                                    : <InputWrapper attr="Start Time" name={"new_pattern_"+index+"_startAt"} value={inputs["new_pattern_"+index+"_startAt"]} onChange={handleChange} size="m" disable={disable["new_pattern_"+index] ?? true}></InputWrapper> 
                                                            }
                                                        </Grid>
                                                        <Grid item xs={6} sm={6} md={6} xl={6}>
                                                            {
                                                                disable["new_pattern_"+index] === false
                                                                    ? <TimePicker value={inputs["new_pattern_"+index+"_endAt"]} onChange={(newValue) => handleInputsChange("new_pattern_"+index+"_endAt", newValue)}/>
                                                                    : <InputWrapper attr="End Time" name={"new_pattern_"+index+"_endAt"} value={inputs["new_pattern_"+index+"_endAt"]} onChange={handleChange} size="m" disable={disable["new_pattern_"+index] ?? true}></InputWrapper> 
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </SuiBox>

                                                <SuiBox mb={1}>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={6} sm={6} md={6} xl={6}>
                                                            <InputWrapper attr="Fee (USDT)" name={"new_pattern_"+index+"_fee_usdt"} value={inputs["new_pattern_"+index+"_fee_usdt"]} onChange={handleChange} size="m" disable={disable["new_pattern_"+index] ?? true}></InputWrapper>
                                                        </Grid>
                                                        <Grid item xs={6} sm={6} md={6} xl={6}>
                                                            <InputWrapper attr="Game Time (Seconds)" name={"new_pattern_"+index+"_game_time"} value={inputs["new_pattern_"+index+"_game_time"]} onChange={handleChange} size="xl" disable={disable["new_pattern_"+index] ?? true}></InputWrapper>
                                                        </Grid>
                                                    </Grid>
                                                </SuiBox>
                                                <SwitchWrapper attr="" size="" checked={inputs["new_pattern_"+index+"_isActive"]} onChange={() => handleInputsChange("new_pattern_"+index+"_isActive", inputs["new_pattern_"+index+"_isActive"] === false ? true : false)}/>
                                            </Grid>
                                        </Grid>
                                    </SuiBox>

                                    <ToggleButtonGroup
                                        color="primary"
                                        exclusive>
                                                    
                                        {
                                            disable["new_pattern_"+index] === false ?
                                                <ToggleButton value="update" onClick={() => createItem("new_pattern_"+index+"_")}>
                                                    <UpdateIcon></UpdateIcon>
                                                </ToggleButton>

                                            :   <ToggleButton value="edit" onClick={() => editItem("new_pattern_"+index)}>
                                                    <EditIcon></EditIcon>
                                                </ToggleButton>
                                        }

                                    </ToggleButtonGroup>
                                </Row>
                        )})
                    }
                    <SuiButton variant="outlined" buttonColor="secondary" onClick={handleNewPattern}>Add New Pattern</SuiButton>
                    
                    <SuiBox sm>
                        <SuiButton variant="outlined" buttonColor="secondary" onClick={handleCreateGame}>Add New Game</SuiButton>
                    </SuiBox>
                    <SuiButton buttonColor="secondary" onClick={handleCreateQuestion}>Save</SuiButton>
                </div>
            </div>
        </div>
    );
}