import CardContent from '@mui/material/CardContent';

function CardFormContent({ children }){
	return (
		<CardContent>
            {children}
		</CardContent>
	);
}

export default CardFormContent;