import { DateFormatUtils } from "util/index";
import { Typography, Card, CardContent, Grid } from "@mui/material";
import moment from "moment";
import "./style.css";

function DatePanel() {
  let temp = [];

  temp = getPastDay({ temp: temp, days: 3 });
  //console.log(temp);

  temp = getToday({ temp: temp });
  //console.log(temp);

  temp = getFutureDay({ temp: temp, days: 3 });
  //console.log(temp);

  return (
    <Grid container justifyContent="center">
      {temp.map((data, index) => {
        return (
          <Grid item xs={6} sm={6} md={2} xl={1} p={2} key={index}>
            <Card className={data.className}>
              <CardContent className="text-sm text-center">
                <Typography variant="body2" color="text.secondary">
                  {data.weekday}
                </Typography>
                <Typography variant="h2" color="text.secondary">
                  {data.day}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
}

function getPastDay({ temp, days = 3 }) {
  let date;

  for (let index = days; index >= 1; index--) {
    date = moment().subtract(index, "days");
    temp.push({
      day: DateFormatUtils(date, "DD"),
      weekday: DateFormatUtils(date, "ddd"),
      className: 'date--panel--past'
    });
  }

  return temp;
}

function getToday({ temp }) {
  let today = new Date();

  let day = DateFormatUtils(today, "DD");
  let weekday = DateFormatUtils(today, "ddd");

  temp.push({
    day: day,
    weekday: weekday,
    className: 'date--panel--today'
  });

  return temp;
}

function getFutureDay({ temp, days = 3 }) {
  let date;

  for (let index = 1; index <= days; index++) {
    date = moment().add(index, "days");
    temp.push({
      day: DateFormatUtils(date, "DD"),
      weekday: DateFormatUtils(date, "ddd"),
      className: 'date--panel--future'
    });
  }

  return temp;
}

export default DatePanel;