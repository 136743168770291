import { Grid } from "@material-ui/core";
import 'components/Custom/common.css';

import { useState, useEffect } from "react";

import { Row } from "react-bootstrap";

import SuiButton from "components/Default/SuiButton";
import SuiBox from "components/Default/SuiBox";

import InputWrapper from 'components/Custom/input_wrapper';
import Editor from 'components/Custom/editor';

import CryptoApi from 'api/crypto';
import AuthApi from "api/auth";
import AuthCodeHelper from "util/AuthCodeHelper";
import HTMLContainer from 'components/Custom/html_container';

import { CRYPTO_PERMISSION } from 'config/permission';
import { useAlert } from 'react-alert';

export default function CryptoDetailsPopup ({ id }) {
    const alert = useAlert();

    const [cryptoData, setCryptoData] = useState([]); 
    const [networkData, setNetworkData] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [inputs, setInputs] = useState({});
    const [auth, setAuth] = useState(false);
    const [adminCodeInput, setAdminCodeInput] = useState("");

    const handleChange = e => setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    const setContent = (name, value) => {setInputs(prevState => ({ ...prevState, [name]: value }))};

    const changeMode = (content) => {
        editMode ? /* setEditMode(false) */
            editCrypto(content)
            : setEditMode(true);
    };

    function editCrypto(editContent){
        if(editContent && id){
            //console.log(editContent);

            const title = {
                CryptoType: id,
                CryptoName: editContent["crypto_name"] ?? cryptoData.CryptoName,
                CryptoChineseName: editContent["chinese_name"] ?? cryptoData.CryptoChineseName
            };

            CryptoApi.editCryptoTypesTitle(title)
                .then((res) => {
                    alert.success("edit Crypto Types Title success");
                })
                .catch((err) => {
                    console.log(err);
                    alert.error(err.message);
                })

            for (let i = 0; i < networkData.length; i++) {
                const networks = networkData.map((cryptoData, index, key) => {
                    if(index === i){
                        const network = {
                            Networks: editContent["network" + index] ?? cryptoData.id,
                            TopUpAddress: editContent["topUpAddress" + index] ?? cryptoData.TopupAddress,
                            WithdrawFee: editContent["withdrawFee" + index] ?? cryptoData.WithdrawFee,
                            AccountNo: editContent["accountNo" + index] ?? cryptoData.AccountID,
                            AccountHolder: editContent["accountHolder" + index] ?? cryptoData.AccountHolder,
                            LowerLimit: editContent["lowerLimit" + index] ?? cryptoData.LowerLimit,
                            UpperLimit: editContent["upperLimit" + index] ?? cryptoData.UpperLimit,
                            TopUpRules: editContent["topUpRules" + index] ?? cryptoData.TopUpRules,
                            WithdrawRules: editContent["withDrawRules" + index] ?? cryptoData.WithdrawRules,
                            CryptoType: id
                        }
                        //console.log(network);

                        CryptoApi.editCryptoTypeNetworks(network)
                            .then((res) => {
                                if(res){
                                    alert.success(`Edit Crypto Network ${(i+1)} success`);
                                }
                            })
                            .catch((err) => {
                                console.log(err);
                                alert.error(err.message);
                            })
                    }
                })
            }
        }
    }

    const fetchOneCyptoData = async (id) => {

        await CryptoApi.getOneCryptoByFid(id)
            .then((res) => {
                if(res.status === 200){
                    setCryptoData(res.data.data);
                    //console.log(res.data.Networks);
                    /* const data = Object.entries(res.data.data.Networks)
                                .map(a => ({ id: Object.keys(a[1])[0], ...Object.entries(a[1])[0][1] })); */
        
                    setNetworkData(res.data.data.networks);
                    //console.log(data.map(b => b.id));
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleAuthCode = (resp) => {
        // console.log(resp);

        if(resp){
            if(resp.isAuth){
                setAuth(true);
            } else{
                alert.error(resp.message);
            }
        }
        else{
            alert.error(ERROR.callback);
        }
    }

    useEffect(()=>{
        if(cryptoData.length < 1 && networkData.length < 1){
            fetchOneCyptoData(id);
        }
    }, []) 

    return (
        <div id="popup1" className="popup-wrapper">
            {
                (!auth && editMode) ?
                    <AuthCodeHelper title="Admin Authentication" btnName="Confirm" permission={CRYPTO_PERMISSION.EDIT} callback={handleAuthCode}/>
                :   
                
                <Row id="" className="popup1-flex-column">
                <h4>Crypto Details</h4>
                <Row className="popup-content">
                    <SuiBox xs={2} lg={3} xl={6} width="100%">
                        <InputWrapper attr="Crypto ID" value={id} disable={true} size="lg"/> 
                    </SuiBox>
                    <SuiBox xs={2} lg={3} xl={6} width="100%">
                        <InputWrapper attr="Name" disable={!editMode} size="lg" name="crypto_name" value={inputs.crypto_name ?? cryptoData.full_name} onChange={handleChange}/> 
                    </SuiBox>
                    <SuiBox xs={2} lg={3} xl={6} width="100%">
                        <InputWrapper attr="Chinese" disable={!editMode} size="lg" name="chinese_name" value={inputs.chinese_name ?? cryptoData.chinese_name} onChange={handleChange}/> 
                    </SuiBox>
                </Row>

                <SuiBox mb={5}></SuiBox>
                <h4>Networks</h4>
                {networkData.length > 0 ? networkData.map((cryptoData, index, key) => 
                    <span key={key} className="popup-content">
                        <SuiBox xs={2} lg={3} xl={6} width="30%" >
                            <InputWrapper attr={"Network " + (index + 1)} name={"network" + index} value={inputs["network" + index] || cryptoData.id} onChange={handleChange}  disable={!editMode} size="lg"/>
                        </SuiBox>
        
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={8} md={6} xl={6}>
                                <InputWrapper attr="TopUpAddress" name={"topUpAddress" + index} value={inputs["topUpAddress" + index] || cryptoData.TopupAddress} onChange={handleChange} disable={!editMode} size="lg"/>
                            </Grid>
                            <Grid item xs={12} sm={8} md={6} xl={6}>
                                <InputWrapper attr="WithdrawFee" name={"withdrawFee" + index} value={inputs["withdrawFee" + index] || cryptoData.withdraw_fee} onChange={handleChange} disable={!editMode} size="lg"/>
                            </Grid>
                        </Grid>
        
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={8} md={6} xl={3}>
                                <InputWrapper attr="Account No." name={"accountNo" + index} value={inputs["accountNo" + index] || cryptoData.AccountID} onChange={handleChange} disable={!editMode} size="lg"/>
                            </Grid>
                            <Grid item xs={12} sm={8} md={6} xl={3}>
                                <InputWrapper attr="Account Holder" name={"accountHolder" + index} value={inputs["accountHolder" + index] || cryptoData.AccountHolder} onChange={handleChange} disable={!editMode} size="lg"/>
                            </Grid>
                            <Grid item xs={12} sm={8} md={6} xl={3}>
                                <InputWrapper attr="LowerLimit" name={"lowerLimit" + index} value={inputs["lowerLimit" + index] || cryptoData.lower_limit} onChange={handleChange} disable={!editMode} size="lg"/>
                            </Grid>
                            <Grid item xs={12} sm={8} md={6} xl={3}>
                                <InputWrapper attr="UpperLimit" name={"upperLimit" + index} value={inputs["upperLimit" + index] || cryptoData.upper_limit} onChange={handleChange} disable={!editMode}size="lg"/>
                            </Grid>
                        </Grid>
        
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={8} md={6} xl={5}>
                                {
                                    editMode ?
                                        <Editor content={inputs["topUpRules" + index] || cryptoData.top_up_rules} onChange={(newContent) => setContent("topUpRules" + index, newContent)}/> : <HTMLContainer attr="TopUpRules" html={cryptoData.top_up_rules}/>
                                }
                            </Grid>
                            <Grid item xs={12} sm={8} md={6} xl={2}></Grid>
                            <Grid item xs={12} sm={8} md={6} xl={5}>
                                {
                                    editMode ? 
                                        <Editor content={inputs["withdrawRules" + index] || cryptoData.withdraw_rules} onChange={(newContent) => setContent("withdrawRules" + index, newContent)}/> : <HTMLContainer attr="WithdrawRules" html={cryptoData.withdraw_rules}/>
                                }
                            </Grid>
                        </Grid>
                    </span>
                    ) : ""}

                <Row className="popup-form-row">
                    <SuiButton buttonColor="secondary" onClick={() => changeMode(inputs)}>
                        { editMode ? "Update" : "Edit" }
                    </SuiButton>
                </Row>
            </Row>

            }
          
        </div>
    );
}