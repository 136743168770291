import { Grid } from "@material-ui/core";
import UserApi from 'api/user';
import { BasicDateTimePicker, Loader } from "components/Custom";
import {
    EditableContainer, FieldsNotFoundError, IsExist, NotStatusContainer, PageController, StatusContainer
} from "components/Custom/custom_container";
import BasicSelect from 'components/Custom/custom_select';
import ImageViewer from "components/Custom/image_viewer";
import InputWrapper from 'components/Custom/input_wrapper';
import UploadImage from 'components/Custom/upload_image';
import { SuiBox } from "components/Default";
import SuiButton from "components/Default/SuiButton";
import { USER_PERMISSION } from "config/permission";
import CustomerGateway from 'entities/customerGateway';
import FirebaseGateway from "entities/firebaseGateway";
import moment from "moment";
import { useEffect, useState } from "react";
import { useAlert } from 'react-alert';
import ShopVerificationTemplate from "template/shopVerificationTemplate";
import AuthCodeHelper from "util/AuthCodeHelper";
import EmailAuthHelper from "util/EmailAuthHelper";
import { ERROR } from "util/ErrorHelper";
import HKIDAuthenticatinHelper from "util/HKIDAuthenticationHelper";
import { DateFormatUtils, ImageURLExistHelper, NullFormatUtils } from "util/index";
import Options from "./options";
import './styles.css';

let customerGateway = new CustomerGateway();
let firebaseGateway = new FirebaseGateway();

export default function ShopVerifyPopup({ id }) {
    const fields = [id];

    const alert = useAlert();
    const [shopVerifyData, setShopVerifyData] = useState();
    const [userData, setUserData] = useState([]);
    const [images, setImages] = useState([]);
    const [shopqrImages, setShopQRImages] = useState([]);
    const [edit, setEdit] = useState(false);
    const [rejectedReason, setRejectedReason] = useState();
    const [page, setPage] = useState(0);
    const [code, setCode] = useState();
    const [date, setDate] = useState();
    const [loading, setLoading] = useState(true);

    let testMode = false;

    const [auth, setAuth] = useState(false);

    const RejectReasons = [
        {
            label: "商業登記證件無效",
            value: 1
        },
        {
            label: "商業登記證件模糊",
            value: 2
        },
        {
            label: "商業登記證資料與提交的資料不一致",
            value: 3
        },
        {
            label: "商業登記證件上載失敗",
            value: 4
        },
        {
            label: "商業登記證件有效期不足1個月",
            value: 5
        },
        {
            label: "該公司已經被註冊",
            value: 6
        },
        {
            label: "該賬戶持有人並非該公司股東",
            value: 7
        },
        {
            label: "公司聯絡資料有誤",
            value: 8
        }
    ];

    function getRejectedReason() {
        let i;

        for (i = 0; i < RejectReasons.length; i++) {
            if (RejectReasons[i].value === rejectedReason) {
                return RejectReasons[i].label;
            }
        }
    }

    const handleStatusChange = async () => {
        let imageURL = null;
            if(shopqrImages !== shopVerifyData?.qrImage){
                await firebaseGateway.fireStorageHelper({
                    image: shopqrImages,
                    place: `images/shopQRImage/${id}/`,
                    prefix: "qrImage_",
                    customName: id
                }).then((res) => {
                    console.log(res);
                    imageURL = res.data;
                }).catch((err) => {
                    alert.error(err.message);
                })
            }

            await UserApi.editShopVerifyWithStatusAndReason({
                id: id,
                status: rejectedReason ? "Rejected" : "Approved",
                rejected_reason: rejectedReason ? getRejectedReason() : null,
                shop_qr_image: imageURL ?? shopVerifyData?.qrImage
            })
                .then((res) => {
                    console.log(res);
                    alert.success("Verify Success");
                }).catch((e) => {
                    console.log(e);
                });
    };

    const handleImageChange = (imageList, addUpdateIndex) => {
        console.log(imageList, addUpdateIndex);
        setImages(imageList);
    };

    const handleQRImageChange = (imageList, addUpdateIndex) => {
        console.log(imageList, addUpdateIndex);
        setShopQRImages(imageList);
    };

    const handleEditSubmit = () => {
        if (!edit) {
          //  setPage(2);
            setEdit(true);
        } else {
            //else handle submit change
        }

        return
    };
    
    /*
    const handleHKID = () => {
            setPage(1);
    }
    */

    const handleCode = () => {
            setPage(2);
    }

    const handleAuth = (e) => {
        if (e) {
            setPage(3);
        }
    }

    const handleSendEmail = () => {
        setPage(4);
    }

    const handleEmailAuth = (e) => {
        if (e) {
            setPage(3);
            setCode(e);
        } else setPage(0)
    }

    const handleAuthCode = (resp) => {
        // console.log(resp);

        if(resp){
            if(resp?.isAuth){
                setAuth(true);
                setPage(3);
            } else{
                alert.error(resp?.message);
            }
        }
        else{
            alert.error(ERROR?.callback);
        }
    }

    const fetchOneUserData = async (id) => {
        await customerGateway.getOneUserData({
            type: "firebase_id",
            id: id,
            format: "ShopVerify"
        })
            .then((res) => {
                if (res) {
                    setUserData(res.data);
                    setShopVerifyData(res.data.shopVerification);

                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        if(userData.length < 1){
            fetchOneUserData(id);
        }
    }, [])

    /* 
        Components
     */

    const Title = () => {
        return (
            <h4>Shop Verification</h4>
        );
    }

    const CompanyName = () => {
        return (
            <>
                <EditableContainer editMode={true} currentEditMode={edit} desc="">
                    <InputWrapper attr="Company Name" value={NullFormatUtils(shopVerifyData?.companyName)} size="lg" disable={false} />
                </EditableContainer>

                <EditableContainer editMode={false} currentEditMode={edit} desc="">
                    <InputWrapper attr="Company Name" value={NullFormatUtils(shopVerifyData?.companyName)} size="lg" disable={true} />
                </EditableContainer>
            </>
        );
    }

    const SubmitDate = () => {
        return (
            <InputWrapper attr="Submit Date" value={DateFormatUtils(shopVerifyData?.createAt)} size="lg" />
        );
    }

    const BRNo = () => {
        return (
            <>
                <EditableContainer editMode={true} currentEditMode={edit} desc="">
                    <InputWrapper attr="BR No." value={NullFormatUtils(shopVerifyData?.brNumber)} size="sm" disable={false} />
                </EditableContainer>

                <EditableContainer editMode={false} currentEditMode={edit} desc="">
                    <InputWrapper attr="BR No." value={NullFormatUtils(shopVerifyData?.brNumber)} size="sm" disable={true} />
                </EditableContainer>
            </>
        );
    }

    const DateOfExpiry = () => {
        return (
            <>
                <StatusContainer status="Processing" param={userData?.shopVerifyStatus}>
                    <EditableContainer editMode={true} currentEditMode={edit} desc="">
                        <BasicDateTimePicker onChange={(newValue) => { setDate(newValue) }}/>
                    </EditableContainer>
                </StatusContainer>

                <NotStatusContainer status="Processing" param={userData?.shopVerifyStatus}>
                    <InputWrapper attr="Date of Expiry" value={moment(shopVerifyData?.updateAt).format("YYYY-MM-DD HH:mm")} size="lg" />
                </NotStatusContainer>
            </>
        );
    }

    const BRImages = () => {
        return (
            <>
                <EditableContainer editMode={true} currentEditMode={edit} desc="">
                    <UploadImage images={images ?? ImageURLExistHelper(shopVerifyData?.brImage)} onChange={handleImageChange}/>
                </EditableContainer>

                <EditableContainer editMode={false} currentEditMode={edit} desc="">
                    <ImageViewer headerText="BR Image" clickText="Click to View" type={0} src={ImageURLExistHelper(shopVerifyData?.brImage)} title={true} />
                </EditableContainer>
            </>
        );
    }

    const AddrImage = () => {
        return (
            <>
                <EditableContainer editMode={true} currentEditMode={edit} desc="">
                    <div>
                        <div className="text-sm">Address Image</div>
                        <UploadImage images={images ?? ImageURLExistHelper(shopVerifyData?.addrImage)} onChange={handleImageChange}/>
                    </div>
                </EditableContainer>
                
                <EditableContainer editMode={false} currentEditMode={edit} desc="">
                    <ImageViewer headerText="Address Image" clickText="Click to View" type={1}
                        src={ImageURLExistHelper(shopVerifyData?.addrImage)} title={true} />
                </EditableContainer>
            </>
        )
    }

    const ContactNo = () => {
        return (
            <>
                <EditableContainer editMode={true} currentEditMode={edit} desc="">
                    <InputWrapper attr="Contact No." value={NullFormatUtils(shopVerifyData?.companyName)} size="lg" disable={false} />
                </EditableContainer>

                <EditableContainer editMode={false} currentEditMode={edit} desc="">
                    <InputWrapper attr="Contact No." value={NullFormatUtils(shopVerifyData?.companyName)} size="lg" disable={true} />
                </EditableContainer>
            </>
        );
    }

    const RejectReason = () =>  {
        return (
            <>
                <StatusContainer status="Rejected" param={userData?.shopVerifyStatus}>
                    <InputWrapper size="lg" attr="Rejected Reason" value={NullFormatUtils(shopVerifyData?.rejectReason)} />
                </StatusContainer>
            </>
        );
    }

    const SubTitle = () => {
        return (
            <>Office Address:</>
        );
    }

    const Address = () => {
        return (
            <>
                <EditableContainer editMode={true} currentEditMode={edit} desc="">
                    <InputWrapper attr="Address" value={NullFormatUtils(shopVerifyData?.address)} size="lg" disable={false} />
                </EditableContainer>

                <EditableContainer editMode={false} currentEditMode={edit} desc="">
                    <InputWrapper attr="Address" value={NullFormatUtils(shopVerifyData?.address)} size="lg" disable={true} />
                </EditableContainer>
            </>
        );
    }

    const District = () => {
        return (
            <>
                <EditableContainer editMode={true} currentEditMode={edit} desc="">
                    <InputWrapper attr="District" value={NullFormatUtils(shopVerifyData?.district)} size="lg" disable={false} /> 
                </EditableContainer>
            
                <EditableContainer editMode={false} currentEditMode={edit} desc="">
                    <InputWrapper attr="District" value={NullFormatUtils(shopVerifyData?.district)} size="lg" disable={true} /> 
                </EditableContainer>
            </>
        );
    }

    const Status = () => {
        return (
            <InputWrapper attr="Status" value={NullFormatUtils(userData?.shopVerifyStatus)} size="lg" />
        );
    }

    const RejectReasonSelector = () => {
        return (
            <BasicSelect title="Reject Reason" items={RejectReasons} onChange={(e) => { e ? setRejectedReason(e.value) : setRejectedReason(null) }}/>
        );
    }

    const Handler = () => {
        return (
            <InputWrapper attr="Handler" value={NullFormatUtils(shopVerifyData?.handler)} size="lg" />
        );
    }

    const ActionButton = () => {
        return (
            <>
                <EditableContainer editMode={true} currentEditMode={edit} desc="">
                    <Grid item className="popup-form-row">
                        <SuiBox mt={3}>
                            <SuiButton buttonColor="secondary" onClick={handleEditSubmit}>Save</SuiButton>
                            <SuiButton buttonColor="secondary" disabled={IsExist(rejectedReason)} onClick={handleStatusChange}>Approve</SuiButton>
                            <SuiButton disabled={IsExist(rejectedReason)} onClick={handleStatusChange}>Reject</SuiButton>
                        </SuiBox>
                    </Grid>
                </EditableContainer>

                <EditableContainer editMode={false} currentEditMode={edit} desc="">
                    <Grid item className="popup-form-row">
                        <SuiBox mt={3}>
                            <SuiButton buttonColor="secondary" onClick={handleEditSubmit}>Edit</SuiButton>
                            <SuiButton buttonColor="secondary" disabled={false} onClick={handleStatusChange}>Approve</SuiButton>
                            <SuiButton disabled={false} onClick={handleStatusChange}>Reject</SuiButton>
                        </SuiBox>
                    </Grid>
                </EditableContainer>
            </>
        );
    }

    const ShopQRImage = () => {
        return (
            <>
                <StatusContainer status="Processing" param={userData?.shopVerifyStatus}>
                    <UploadImage images={shopqrImages ?? ImageURLExistHelper(shopVerifyData?.qrImage)} onChange={handleQRImageChange}/>    
                </StatusContainer>
                <NotStatusContainer status="Processing" param={userData?.shopVerifyStatus}>
                    <ImageViewer headerText="ShopQR Image" clickText="Click to View" type={0} src={ImageURLExistHelper(shopVerifyData?.qrImage)} title={true} />
                </NotStatusContainer>
            </>
        );
    }

    return (
        <FieldsNotFoundError data={fields}>
            <Loader loading={loading}>

                <PageController targetPage={0} currentPage={page} CASE="">
                    <Options actionA={handleSendEmail} actionA_Name="Send Email" actionB={handleCode} actionB_Name="Code Authentication"/>
                </PageController>

                <PageController targetPage={1} currentPage={page} CASE="">
                    <HKIDAuthenticatinHelper id={id} getAuth={handleAuth} />
                </PageController>

                <PageController targetPage={2} currentPage={page} CASE="">
                    <AuthCodeHelper title="KYC Authentication" btnName="Confirm" permission={USER_PERMISSION.SHOP_VERIFY} callback={handleAuthCode}/>
                </PageController>

                <PageController targetPage={3} currentPage={page} CASE="">
                    <ShopVerificationTemplate
                        Title={Title}
                        CompanyName={CompanyName}
                        SubmitDate={SubmitDate}
                        BRNo={BRNo}
                        DateOfExpiry={DateOfExpiry}
                        BRImages={BRImages}
                        AddrImage={AddrImage}
                        ContactNo={ContactNo}
                        RejectReason={RejectReason}
                        SubTitle={SubTitle}
                        Address={Address}
                        District={District}
                        Status={Status}
                        RejectReasonSelector={RejectReasonSelector}
                        Handler={Handler}
                        ActionButton={ActionButton}
                        ShopQRImage={ShopQRImage}
                    />
                </PageController>


                <PageController targetPage={4} currentPage={page} CASE="">
                    <EmailAuthHelper action={handleEmailAuth} id={id} />
                </PageController>

            </Loader>
        </FieldsNotFoundError>
    )
}
