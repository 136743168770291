import axios from "./index";
class OrderApi {
  static list = () => {
    return axios.get(`${base}`);
  };

  static getListByPage = ({ page, statusStatement, searchValue }) => {
    const id_token = localStorage.getItem("id_token");
    if (page && searchValue) {
      return axios.get(`/beta${base}?page=${page}&search="${searchValue}"`, { headers: { "Authorization": `Bearer ${id_token}` } })
    }
    return axios.get(`${base}?page=${page}`,
      /* {
        filter: {
          refund_status: "客服處理中"
        }
      }, */
      { headers: { "Authorization": `Bearer ${id_token}` } });
  };

  static rejectRefund = id => {
    const id_token = localStorage.getItem("id_token");
    if (id) {
      return axios.put(`${base}/${id}/refund_reject`, { headers: { "Authorization": `Bearer ${id_token}` } });
    }
    throw "order id is missing";
  };

  static approveRefund = id => {
    const id_token = localStorage.getItem("id_token");
    if (id) {
      return axios.put(`${base}/${id}/refund_approve`, { headers: { "Authorization": `Bearer ${id_token}` } });
    }
    throw "order id is missing";
  };

  static showOrder = id => {
    const id_token = localStorage.getItem("id_token");
    if (id) {
      return axios.put(`/reviews/${id}/show`, { headers: { "Authorization": `Bearer ${id_token}` } });
    }
    throw "order id is missing";
  };

  static hideOrder = id => {
    const id_token = localStorage.getItem("id_token");
    if (id) {
      return axios.put(`/reviews/${id}/hide`, { headers: { "Authorization": `Bearer ${id_token}` } });
    }
    throw "order id is missing";
  };

  static getOneOrderByFid = id => {
    const id_token = localStorage.getItem("id_token");
    if (id) {
      return axios.get(`${base}/${id}`, { headers: { "Authorization": `Bearer ${id_token}` } });
    }
    throw "order id is missing";
  };
}

let base = "/orders";

export default OrderApi;
