import { Grid } from "@material-ui/core";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import '../styles.css';

import { useState, useEffect } from "react";

import SuiButton from "components/Default/SuiButton";
import SuiBox from "components/Default/SuiBox";
import HTMLContainer from 'components/Custom/html_container';
import Editor from 'components/Custom/editor';

import AuthCodeHelper from "util/AuthCodeHelper";
import { GENERAL_PERMISSION } from 'config/permission';
import { useAlert } from 'react-alert';

import "./style.css";

// Call API
import GeneralApi from "api/general";
import AuthApi from "api/auth";

export default function ServiceAgreementPopup () {
    const alert = useAlert();

    const [agreementData, setAgreementData] = useState([]); 
    const [editMode, setEditMode] = useState(false);
    const [content, setContent] = useState();
    const [auth, setAuth] = useState(false);

    const changeMode = (content) => {
        editMode ? /* setEditMode(false) */
            editServiceAgreement(content)
            : setEditMode(true);
    };

    async function editServiceAgreement(editData) {
      //console.log(editData.toString());
      await GeneralApi.editServiceAgreement({
        ServiceAgreement: 
          "<!DOCTYPE html> <html> <head> " + editData + "</body> </html>"
      })
        .then((res) => {
          if(res && res.status === 200){
            alert.success("Edit Agreement Success");
          }
        })
        .catch((err) => {
          alert.error(err.message);
        });
    }

    const fetchAgreementData = async () => {
      await GeneralApi.list()
        .then((res) => {
          if(res && res.status === 200){
            setAgreementData(res.data.data.service_agreements);
            //console.log(res.data.service_agreements);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const handleAuthCode = (resp) => {
      // console.log(resp);

      if(resp){
          if(resp.isAuth){
              setAuth(true);
          } else{
              alert.error(resp.message);
          }
      }
      else{
          alert.error(ERROR.callback);
      }
  }

    useEffect(()=>{
        fetchAgreementData();
    }, []);

    const rolldown = () => {
      var element = document.getElementById("popup1");
      element.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
    };

    return (
      <>
        {
          (!auth && editMode) ?
          <AuthCodeHelper title="Admin Authentication" btnName="Confirm" permission={GENERAL_PERMISSION.EDIT} callback={handleAuthCode}/>
          :
          <>
            <Grid Container id="popup1" className="popup-wrapper">
              <Grid item id="" className="popup1-flex-column">
                <h4>Service Agreement</h4>
                <div className="popup-content">
                  <SuiBox width="50rem">
                    {
                      editMode ? 
                        <Editor content={content ?? agreementData.ServiceAgreement} onChange={(newContent) => setContent(newContent)}/> : 
                        <HTMLContainer attr="" html={agreementData.ServiceAgreement}/>
                    }
                            
                  </SuiBox>
                </div>
                <div className="popup-form-row">
                  <SuiButton buttonColor="secondary" onClick={() => changeMode(content)}>{editMode ? "Update" : "Edit"}</SuiButton>
                </div>
              </Grid>
            </Grid>

            <div class="goDownbtn">
              <SuiButton
                color="secondary"
                onClick={()=>{ rolldown() }}>
                  <KeyboardArrowDownIcon />
              </SuiButton>
            </div>
          </>
        }
      </>
    );
}