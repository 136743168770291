import axios from "./index";

class DashboardApi {
    static assets = (data) => {
        const id_token = localStorage.getItem("id_token");
        return axios.get(`${base}/assets?start_at=${data.start_at}&end_at=${data.end_at}`, { headers: {"Authorization" : `Bearer ${id_token}`, "X-Authorization-Code": data.code} });
    }

    static display = (data) => {
        const id_token = localStorage.getItem("id_token");
        return axios.get(`${base}/display_data?start_at=${data.start_at}&end_at=${data.end_at}`, { headers: {"Authorization" : `Bearer ${id_token}`, "X-Authorization-Code": data.code} });
    }
}

let base = "/dashboard";

export default DashboardApi;