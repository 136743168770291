/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React components
import { CachedOutlined } from "@material-ui/icons";
import ClearIcon from '@mui/icons-material/Clear';
import { Chip, IconButton, Pagination } from "@mui/material";
import Card from "@mui/material/Card";
import Popup from "assets/theme/components/popup";
import { Loader } from "components/Custom";
import SuiBox from "components/Default/SuiBox";
import SuiButton from "components/Default/SuiButton";
import Footer from "components/examples/Footer";
import DashboardLayout from "components/examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/examples/Navbars/DashboardNavbar";
import WalletSavingPlanGateway from "entities/walletSavingPlanGateway";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { NullFormatUtils, NumberFormatUtils } from "util/index";
import DataTable from "../../components/Custom/dataTable";
import CustomPopup from "./components/custom_pop_up";

let walletSavingPlanGateway = new WalletSavingPlanGateway();

function WalletSavingPlan() {
  //pop up pages
  const [page, setPage] = useState(0);
  const [openPopUp, setOpenPopup] = useState(false);
  const [popUpName, setPopUpName] = useState("");
  const [userID, setUserID] = useState("");

  const [tbPage, setTbPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const [filterRows, setFilterRows] = useState([]);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentFilter, setCurrentFilter] = useState(0);

  const changeRows = (filter) => {
    //console.log(filter);
    const new_row = rows.filter(row => row.Status === filter);
    setCurrentFilter(filter)
    setFilterRows(new_row);
  };

  const countRows = (filter) => {
    //console.log(filter);
    const new_row = rows.filter(row => row.Status === filter);
    return new_row.length;
  }

  const handlePopUp = (pName, id) => {
    setPopUpName(pName, id);
    setOpenPopup(true);
    setUserID(id);
    setPage(1);
  }

  const handlePageChange = (event, value) => {
    if (value <= totalPage) {
      setTbPage(value);
      fetchAllData(value);
    }
  };

  const fetchAllData = async (page) => {
    setLoading(true);

    await walletSavingPlanGateway.getAllData({
      page: page ?? tbPage
    })
      .then((res) => {
        if (res) {
          setRows(res.data);
          setFilterRows(res.data);
          setTotalPage(res.last_page);
        }
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });

    if (page === 0) return data_length
  };

  useEffect(() => {
    if (rows.length < 1) {
      fetchAllData();
    }
  }, []);

  const selectColor = {
    "進行中": "primary",
    "已領取": "success",
    "已取消": "error"
  };

  const status = [
    {
      label: "進行中",
      value: 1,
      color: "warning",
      //   class: 'css-18udauo-MuiChip-root'
    },
    {
      label: "已領取",
      value: 2,
      color: "success",
      //   class: 'css-18udauo-MuiChip-root'
    },
    {
      label: "已取消",
      value: 3,
      color: "error",
      //ß  class: 'css-18udauo-MuiChip-root'
    },
  ]

  const wSavingPlan_columns = [
    {
      headerName: 'Saving Plan Record ID',
      field: 'id',
      renderCell: ({ row }) => `${row.id || "-"}`,
      sortable: true,
      width: 200
    },
    {
      headerName: 'User ID',
      field: 'account_id',
      renderCell: ({ row }) => row.account_id ? <Link to="#" className="text-secondary" onClick={() => { handlePopUp("AccountDetailsPopup", row.account_id) }}>{row.account_id}</Link> : "-",
      sortable: true,
      width: 100
    },
    {
      headerName: 'Create Date',
      field: 'createAt',
      renderCell: ({ row }) => `${moment(row.createAt).format("YYYY-MM-DD HH-mm") || "-"}`,
      sortable: true,
      width: 150
    },
    {
      headerName: 'End Date',
      field: 'endAt',
      renderCell: ({ row }) => `${moment(row.endAt).format("YYYY-MM-DD HH-mm") || "-"}`,
      sortable: true,
      width: 150
    },
    {
      headerName: 'Days',
      field: 'days',
      renderCell: ({ row }) => `${row.days || "-"}`,
      sortable: true,
      width: 100
    },
    {
      headerName: 'APR',
      field: 'APR',
      renderCell: ({ row }) => `${NumberFormatUtils(row.APR, 5) || "-"}`,
      sortable: true,
      width: 100
    },
    {
      headerName: 'Cypto Type',
      field: 'cryptoType',
      renderCell: ({ row }) => row.cryptoType ?? "-",
      sortable: true,
    },
    {
      headerName: 'Saving Amount',
      field: 'savingAmount',
      renderCell: ({ row }) => '$' + NumberFormatUtils(row.savingAmount, 5) ?? "-",
      sortable: true,
      width: 200
    },
    {
      headerName: 'Expected Return',
      field: 'expectedReturn',
      renderCell: ({ row }) => '$' + NumberFormatUtils(row.expectedReturn, 5) ?? "-",
      sortable: true,
      width: 200
    },
    {
      headerName: 'Status',
      field: 'status',
      renderCell: ({ row }) => <Chip label={NullFormatUtils(row.status)} color={selectColor[row.status] ?? "default"} />,
      sortable: true,
    }
  ];



  return (
    <div>
      {
        page > 0 ?
          <Popup
            openPopup={openPopUp}
            setOpenPopup={() => { setOpenPopup(false) }}
          >
            <CustomPopup name={popUpName} id={userID}></CustomPopup>
          </Popup> : <></>
      }
      <DashboardLayout>
        <DashboardNavbar />
        <Loader loading={loading}>
          <Card sx={{ p: 3, flexDirection: 'row', columnGap: '50', marginBottom: '1rem' }}>
            {
              currentFilter === 0 ?
                <></> :
                <IconButton color="error" onClick={() => { setFilterRows(rows); setCurrentFilter(0) }}>
                  <ClearIcon />
                </IconButton>

            }

            <SuiButton
              style={{ marginRight: '1rem' }}
              buttonColor="secondary"
              onClick={() => { fetchAllData() }}>
              <CachedOutlined />
              Refresh
            </SuiButton>

            {
              status.map((row, key) => {

                return (
                  <Chip label={row.label + " (" + countRows(row.label) + ")"} key={key} color={row.color ?? "default"} style={{ width: '10rem', marginRight: '1rem' }} onClick={() => changeRows(row.label)} />
                )
              })
            }
          </Card>
          <SuiBox py={3}>
            <SuiBox mb={3}>
              <h5>SavingPlan</h5>
              <Card>
                <DataTable dt_columns={wSavingPlan_columns} dt_rows={filterRows} />
                <Pagination color={"secondary"} count={totalPage} page={tbPage} onChange={handlePageChange} />
              </Card>
            </SuiBox>
          </SuiBox>
        </Loader>
        
      </DashboardLayout>
    </div>
  );
}

export default WalletSavingPlan;
