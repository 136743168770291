/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React components
import { Chip, Pagination } from "@mui/material";
import Card from "@mui/material/Card";
import Popup from "assets/theme/components/popup";
import { DataTable, Loader } from "components/Custom";
import { SuiBox } from "components/Default";
import DashboardLayout from "components/examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/examples/Navbars/DashboardNavbar";
import WalletTopUpGateway from "entities/walletTopUpGateway";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { NullFormatUtils, NumberFormatUtils } from "util/index";
import LabelFilterPanel from "util/LabelFilterPanel";
import status from "util/LabelFilterPanel/base";
import { handleFilterByKeyword, handleHighlightKeywordInTable } from "util/SearchFunction";
import CustomPopup from "./components/custom_pop_up";

let walletTopUpGateway = new WalletTopUpGateway();

export default function WalletTopUp() {
  //pop up pages
  const [page, setPage] = useState(0);
  const [openPopUp, setOpenPopup] = useState(false);
  const [popUpName, setPopUpName] = useState();
  const [passId, setPassId] = useState();
  const handlePopUp = (pName, id) => {
    setPopUpName(pName);
    setPassId(id);
    setOpenPopup(true);
    setPage(1);
  };

  const [rows, setRows] = useState([]);
  const [filterRows, setFilterRows] = useState([]);
  const [currentFilter, setCurrentFilter] = useState(0); // # of chip label filter
  const [allStatusWithCount, setAllStatusWithCount] = useState([]);
  const [statusStatement, setStatusStatement] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tbPage, setTbPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const handlePageChange = (event, value) => {
    if (value <= totalPage) {
      setTbPage(value);
      fetchAllData(value);
    }
  };

  const fetchAllData = async (page, _statusStatement) => {
    if (_statusStatement) {
      setStatusStatement(_statusStatement);
    }
    setLoading(true);

    const data_length = await walletTopUpGateway.getAllData({
      page: page ?? tbPage,
      statusStatement: _statusStatement ?? (statusStatement ?? null)
    })
      .then(res => {
        if (res) {
          if (page === 0) {
            return res.total;
          }
          setRows(res.data);
          setFilterRows(res.data);
          setTotalPage(res.last_page);
          // setRowPerPage(res.per_page);
          setLoading(false);
        } else {
          alert.error("Gateway response error");
        }
      });

    if (page === 0) return data_length
  };

  const fetchStatusCount = () => {
    let allStatus = [];
    status?.wallet?.map((status, key) => {
      let _status = status;
      fetchAllData(0, `${status?.param?.name}=${status?.param?.value}`)
        .then((res) => {
          _status.count = res;
        })
      allStatus.push(_status);
    })
    setStatusStatement(null);
    setAllStatusWithCount(allStatus);
  }

  useEffect(
    () => {
      if (rows.length < 1 && statusStatement === null) {
        fetchAllData();
        fetchStatusCount();
      }
    },
    [rows]
  );

  const dt_columns = [
    {
      headerName: 'History ID',
      field: 'id',
      renderCell: ({ row }) => row.id ? <Link to="#" className="text-secondary" onClick={() => handlePopUp("TopupDetailsPopup", row.id)}>{row.id}</Link> : "-",
      sortable: true,
      width: 200
    },
    {
      headerName: 'User ID',
      field: 'account_id',
      renderCell: ({ row }) => row.account_id ? <Link to="#" className="text-secondary" onClick={() => handlePopUp("AccountDetailsPopup", row.account_id)}>{row.account_id}</Link> : "-",
      sortable: true,
      width: 200,
    },
    {
      headerName: 'Create Date',
      field: 'createAt',
      renderCell: ({ row }) => row.createAt ? moment(row.createAt).format("YYYY-MM-DD HH:mm") : "-",
      sortable: true,
      width: 300
    },
    {
      headerName: 'Crypto Type',
      field: 'cryptoType',
      renderCell: ({ row }) => row.cryptoType,
      sortable: true,
      width: 120
    },
    {
      headerName: 'Networks',
      field: 'networks',
      renderCell: ({ row }) => row.networks,
      sortable: true,
      width: 100
    },
    {
      headerName: 'Amount',
      field: 'amount',
      renderCell: ({ row }) => NumberFormatUtils(row.amount, 8) ?? "-",
      sortable: true,
      width: 150
    },
    {
      headerName: 'Status',
      field: 'status',
      renderCell: ({ row }) =>
        <Chip label={handleHighlightKeywordInTable(row.SearchValue, NullFormatUtils(row.status))}
          className={handleFilterByKeyword(status.wallet, "label", row.status)} />,
      sortable: true,
      width: 150
    }
  ];

  const handleChangeRows = async (statusParam, statusValue) => {
    setCurrentFilter(statusValue);
    fetchAllData(1, `${statusParam}=${statusValue}`)
  };

  const handleClearAndRefresh = () => {
    setCurrentFilter(0);
    setStatusStatement(null);
    setRows([]);
  };

  return (
    <div>
      {
        page > 0 ?
          <Popup
            openPopup={openPopUp}
            setOpenPopup={() => { setOpenPopup(false) }}
          >
            <CustomPopup name={popUpName} id={passId}></CustomPopup>
          </Popup> : <></>
      }
      <DashboardLayout>
        <DashboardNavbar />

        <Loader loading={loading}>
          <>
            <SuiBox py={3}>
              <SuiBox mb={3}>
                <LabelFilterPanel
                  currentFilter={currentFilter}
                  handleClearAndRefresh={handleClearAndRefresh}
                  fetchAllData={fetchAllData}
                  allStatus={allStatusWithCount}
                  handleChangeRows={handleChangeRows}
                />
                <Card>
                  <DataTable dt_columns={dt_columns} dt_rows={filterRows} />
                  <Pagination color={"secondary"} count={totalPage} page={tbPage} onChange={handlePageChange} />
                </Card>
              </SuiBox>
            </SuiBox>
          </>
        </Loader>
      </DashboardLayout>
    </div >
  );
}