import { Grid, TextareaAutosize } from "@material-ui/core";
import { Card, CardContent } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import OTCApi from "api/otc";
import UserApi from "api/user";
import 'components/Custom/common.css';
import InputWrapper from 'components/Custom/input_wrapper';
import SuiButton from "components/Default/SuiButton";
import { OTC_PERMISSION } from 'config/permission';
import OTCGateway from "entities/otcGateway";
import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import AuthCodeHelper from "util/AuthCodeHelper";
import "./style.css";

let otcGateway = new OTCGateway();

export default function OTCUserSetting({ id, type }) {
    const alert = useAlert();

    const [page, setPage] = useState(1);
    const [auth, setAuth] = useState(false);
    const [otcData, setStaffData] = useState([]);
    const [inputs, setInputs] = useState({}); // inputs
    const [user, setUser] = useState();
    const [foundUser, setFoundUser] = useState(false);
    const [otcDetail, setOtcDetail] = useState([]);

    const [paymentMethod, setPaymentMethod] = useState({
        FPS: true,
        BankTransfer: true,
        Cash: true,
        Cheque: true
    });
    const [contact, setContact] = useState({
        Phone: false,
        Whatsapp: false,
        Email: false
    });

    const handleChange = e => setInputs(prevState => {
        if (["phone", "whatsapp", "email"].includes(e.target.name)) {
            setContact({ ...contact, [e.target.name.charAt(0).toUpperCase() + e.target.name.slice(1)]: true })
        }
        return ({ ...prevState, [e.target.name]: e.target.value })
    });
    const handlePaymentMethodChange = (event) => { setPaymentMethod({ ...paymentMethod, [event.target.name]: event.target.checked }) };
    const handleContactChange = (event) => { setContact({ ...contact, [event.target.name]: event.target.checked }) };
    const { FPS, BankTransfer, Cash, Cheque } = paymentMethod;
    const { Phone, Whatsapp, Email } = contact;

    const fetchOTCDetails = async () => {
        await otcGateway.getOneOTCById({
            id: id
        })
            .then((res) => {
                if (res) {
                    setOtcDetail(res.data);
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        fetchOTCDetails();
    }, [])

    const handleEditOTC = () => {
        if (!auth) {
            setPage(0);
        } else {
            // edit otc api
        }
    };

    const handleOFFOTC = async () => {
        if (!auth) {
            setPage(0);
        } else {
            // edit otc api
            await OTCApi.changeStatus({ "Status": "Off" })
                .then((res) => {
                    if (res && res.status === 200) {
                        alert.success("Edit OTC success");
                    } else {
                        alert.error("Somethings error");
                    }
                })
                .catch((err) => {
                    alert.error(err.message);
                })
        }
    };

    const handleONOTC = async () => {
        if (!auth) {
            setPage(0);
        } else {
            // edit otc api
            await OTCApi.changeStatus({ "Status": "On" })
                .then((res) => {
                    if (res && res.status === 200) {
                        alert.success("Edit OTC success");
                    } else {
                        alert.error("Somethings error");
                    }
                })
                .catch((err) => {
                    alert.error(err.message);
                })
        }
    };

    const handleReleaseOTC = async () => {
        if (!auth) {
            setPage(0);
        } else {
            const payment_method = Object.entries(paymentMethod).filter(([k, v]) => v === true).map(([k, v]) => k);
            const contact_arr = Object.entries(contact).filter(([k, v]) => v === true).map(([k, v]) => k);
            for (let index = 0; index < payment_method.length; index++) {
                const element = payment_method[index];
                const payment_method_chin = {
                    "FPS": "FPS轉數快",
                    "Cheque": "支票",
                    "Cash": "現金交收",
                    "BankTransfer": "銀行轉帳"
                }
                payment_method[index] = payment_method_chin[element];
            }

            if (inputs['branch_addr']
                && inputs['lower_limit']
                && inputs['sell_amount']
                && inputs['trade_desc']
                && inputs['unit_price']
                && inputs['upper_limit']
                && inputs['seller_id'] && foundUser === true
                && inputs['buy_amount']
                && inputs['buy_price']
                && payment_method.length >= 1
                && contact_arr.length >= 1) {
                // create otc api
                await OTCApi.add({
                    "Email": inputs['email'] ?? null,
                    "BranchAddress": inputs['branch_addr'],
                    "Phone": inputs['phone'] ?? null,
                    "LowerLimit": inputs['lower_limit'],
                    "SellingAmount": inputs['sell_amount'],
                    "TradeDescription": inputs['trade_desc'],
                    "SellingPrice": inputs['sell_price'],
                    "UpperLimit": inputs['upper_limit'],
                    "WhatsApp": inputs['whatsapp'] ?? null,
                    "PaymentMethod": payment_method,
                    "AccountID": inputs['seller_id'],
                    "BuyingPrice": inputs['buy_price'],
                    "BuyingAmount": inputs['buy_amount']
                }).then((res) => {
                    if (res && res.status === 200) {
                        alert.success("Create OTC success");
                    } else {
                        alert.error("Somethings error");
                    }
                })
                    .catch((err) => {
                        alert.error(err.message);
                    })
            } else {
                alert.error('You need to fill in all the fields.');
            }
        }
    };

    const handleAuthCode = (resp) => {
        // console.log(resp);

        if (resp) {
            if (resp.isAuth) {
                setAuth(true);
                setPage(1);
            } else {
                alert.error(resp.message);
            }
        }
        else {
            alert.error(ERROR.callback);
        }
    }

    const attributes = {
        "sell_amount": "SellingAmount",
        "sell_price": "SellingPrice",
        "email": "Email",
        "branch_addr": "BranchAddress",
        "lower_limit": "LowerLimit",
        "phone": "Phone",
        "trade_desc": "TradeDescription",
        "upper_limit": "UpperLimit",
        "whatsapp": "WhatsApp",
        "buy_amount": "BuyingAmount",
        "buy_price": "BuyingPrice"

    }

    //get corresponding data in regards of edit or not
    function getData(attr) {
        if (otcDetail && !showEditOrNot[type]) {
            let temp = attributes[attr];
            if (temp) return otcDetail[temp];
        }
        else if (inputs[attr]) return inputs[attr];
    }

    const showEditOrNot = {
        1: false,
        2: false,
        3: true
    }

    const actionButtons = {
        1: <SuiButton buttonColor="secondary" onClick={handleReleaseOTC}>Release</SuiButton>,
        "On":
            <>
                {/* <SuiButton buttonColor="secondary" className="mr-1-5rem" onClick={handleEditOTC}>Edit</SuiButton> */}
                <SuiButton buttonColor="secondary" onClick={handleOFFOTC}>OFF</SuiButton>
            </>,
        "Off": <SuiButton buttonColor="secondary" onClick={handleONOTC}>On</SuiButton>
    }

    function FPSForm({ data }) {
        return (
            <FormGroup>
                <div className="popup-content">
                    <h5>FPS</h5>
                    {/* <FormControlLabel
                        control={<Checkbox checked={true} name="FPS" />}
                        label="FPS"
                    /> */}
                    <Grid container style={{ width: '50rem' }}>
                        <Grid item>
                            <InputWrapper attr="Account Name" size="lg" disable={false} />
                        </Grid>
                        <Grid item>
                            <InputWrapper attr="Phone" size="sm" disable={false} />
                        </Grid>
                    </Grid>
                    <InputWrapper attr="Bank" size="m" disable={false} value={data?.bank?.name ?? ""}/>
                    <InputWrapper attr="Remark" size="m" disable={false} />
                </div>
            </FormGroup>
        );
    }

    function BankTransferForm({ data }) {
        return (
            <FormGroup>
                <div className="popup-content">
                    <h5>Bank Transfer</h5>
                    {/* <FormControlLabel
                        control={<Checkbox checked={true} name="BankTransfer" />}
                        label="Bank Transfer"
                    /> */}
                    <Grid container style={{ width: '50rem' }}>
                        <Grid item>
                            <InputWrapper attr="Account Name" size="lg" disable={false} value={""} />
                        </Grid>
                        <Grid item>
                            <InputWrapper attr="Account No." size="m" disable={false} value={data?.account_number ?? ""} />
                        </Grid>
                    </Grid>
                    <InputWrapper attr="Bank" size="m" disable={false} value={data?.bank?.name ?? ""} />
                    <InputWrapper attr="Remark" size="m" disable={false} />
                </div>
            </FormGroup>
        );
    }

    function CashForm({ data }) {
        return (
            <FormGroup>
                <div className="popup-content">
                    <h5>Cash</h5>
                    {/* <FormControlLabel
                        control={<Checkbox checked={true} name="Cash" />}
                        label="Cash"
                    /> */}
                    <InputWrapper attr="Account Name" size="lg" disable={false} />
                    <InputWrapper attr="Paper value" size="m" disable={false} value={data.cash_expected_denomination_each_note}/>
                    <InputWrapper attr="Location" size="m" disable={false} value={data.trading_address}/>
                    <InputWrapper attr="Remark" size="m" disable={false} />
                </div>
            </FormGroup>
        );
    }

    function ChequeForm({ data }) {
        return (
            <FormGroup>
                <div className="popup-content">
                    <h5>Cheque</h5>
                    {/* <FormControlLabel
                        control={<Checkbox checked={true} name="Cheque" />}
                        label="Cheque"
                    /> */}
                    <InputWrapper attr="Account Name" size="lg" disable={false} />
                    <InputWrapper attr="Location" size="m" disable={false} value={data.trading_address}/>
                    <InputWrapper attr="Remark" size="m" disable={false} />
                </div>
            </FormGroup>
        );
    }

    if (page === 0) {
        return (<AuthCodeHelper title="Admin Authentication" btnName="Confirm" permission={OTC_PERMISSION.VIEW} callback={handleAuthCode} />);
    }
    else if (page === 1) {
        return (
            <>
                <div>
                    <h4>User Setting</h4>
                    <Grid container>
                        <Grid item xs container>
                            <div>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={8} md={7} xl={7}>
                                        <InputWrapper attr="User ID" value={otcDetail.account_id} size="lg" disable={showEditOrNot[type]} />
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={6} xl={6}>
                                        <InputWrapper attr="ID Name" value={otcDetail.name} size="lg" disable={showEditOrNot[type]} />
                                    </Grid>
                                    <Grid item xs={12} sm={8} md={6} xl={6}>
                                        <InputWrapper attr="Account Level" value={otcDetail.account_level} size="lg" disable={showEditOrNot[type]} />
                                    </Grid>
                                </Grid>
                            </div>

                            <Grid item alignItems="center" className="input-wrapper-rows">
                                <div style={{ fontWeight: 'bold', fontSize: '1rem' }}>Contact</div>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox checked={otcDetail.is_enabled_phone || Phone} onChange={handleContactChange} name="Phone" />}
                                        label="Phone"
                                    />
                                    <div><InputWrapper attr="+852" name="phone" value={otcDetail.phone} onChange={handleChange} size="sm" disable={showEditOrNot[type]} /></div>

                                    <FormControlLabel
                                        control={<Checkbox checked={otcDetail.is_enabled_whatsapp || Whatsapp} onChange={handleContactChange} name="Whatsapp" />}
                                        label="Whatsapp"
                                    />
                                    <div><InputWrapper name="whatsapp" value={otcDetail.whatsapp} onChange={handleChange} disable={showEditOrNot[type]} /></div>

                                    <FormControlLabel
                                        control={<Checkbox checked={otcDetail.is_enabled_email || Email} onChange={handleContactChange} name="Email" />}
                                        label="Email"
                                    />
                                    <div><InputWrapper name="email" value={otcDetail.email} onChange={handleChange} disable={showEditOrNot[type]} /></div>
                                </FormGroup>
                            </Grid>
                        </Grid>

                        <Grid item xs>
                            <h4>Trade Description</h4>
                            <div id="offer-description">
                                <TextareaAutosize name="trade_desc" value={otcDetail.trade_description} style={{ height: 200, width: 600 }} disabled={false} onChange={handleChange} />
                            </div>

                            <h4>Branch address</h4>
                            <div id="offer-description">
                                <TextareaAutosize name="branch_addr" value={otcDetail.branch_address} style={{ height: 200, width: 600 }} disabled={false} onChange={handleChange} />
                            </div>
                        </Grid>
                    </Grid>
                </div>

                <div id="popup1" className="popup-wrapper">
                    <div id="" className="popup1-flex-column">
                        <div style={{ fontWeight: 'bold', fontSize: '1rem' }}>Payment Method</div>
                        <Grid container spacing={2}>
                            {
                                otcDetail.payment_method ? Object.values(otcDetail.payment_method).map((v, i) => {
                                    const size = otcDetail.payment_method.length > 1 ? 6 : 12;
                                    
                                    if (v.type === "bank_transfer") {
                                        return (
                                            <Grid item xs={12} sm={size} md={size} xl={size}>
                                                <Card>
                                                    <CardContent>
                                                        <BankTransferForm data={v} />
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        );
                                    }
                                    if (v.type === "fps") {
                                        return (
                                            <Grid item xs={12} sm={size} md={size} xl={size}>
                                                <Card>
                                                    <CardContent>
                                                        <FPSForm data={v}/>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        );
                                    }
                                    if (v.type === "cash") {
                                        return (
                                            <Grid item xs={12} sm={size} md={size} xl={size}>
                                                <Card>
                                                    <CardContent>
                                                        <CashForm data={v}/>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        );
                                    }
                                    if (v.type === "bank_cheque") {
                                        return (
                                            <Grid item xs={12} sm={size} md={size} xl={size}>
                                                <Card>
                                                    <CardContent>
                                                        <ChequeForm data={v}/>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        );
                                    }
                                }) : <></>
                            }
                        </Grid>
                    </div>
                </div>
            </>
        );
    }
}