import axios from "./index";
import { constant } from "config/constant";

class AuthApi {

  static Login = (data) => {
    return axios.post(`/staffs/login`, data);
  };

  static Register = (data) => {
    return axios.post(`${base}/register`, data);
  };

  static Logout = () => {
    return axios.put(constant.GOOGLE_ADMIN_SIGN_OUT);
  };

  static TestAuthorizationCodes = code => {
    return axios.get(`/authorization_codes/test_authorization_codes`, { headers: { "X-Authorization-Code": code } });
  };

  static loginCallback = (data) => {
    const requestData = {
      state : data.state,
      code: data.code
    };

    return axios.post(constant.GOOGLE_AUTH_CALLBACK_URL, requestData);
  };

  static getToken = () => {
    return axios.get(constant.CSRF_PATH);
  };

  // GET: authorized user account information
  static getUserData = () => {
    return axios.get(constant.USERINFO);
  };

  static getUserData = ({ id_token }) => {
    return axios.get(constant.USERINFO, { headers: {"Authorization" : `Bearer ${id_token}`} })
  };

  static getAuthCode = () => {
    const id_token = localStorage.getItem("id_token");
    return axios.get(`/authorization_codes`, { headers: {"Authorization" : `Bearer ${id_token}`} });
  };

  static updateAuthCode = (data) => {
    return axios.put(`/authorization_codes`, data);
  };

}

let base = "users";

export default AuthApi;
