/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React components
import { Chip, Pagination } from "@mui/material";
import Card from "@mui/material/Card";
import Popup from "assets/theme/components/popup";
import { Loader } from "components/Custom";
import SuiBox from "components/Default/SuiBox";
import DashboardLayout from "components/examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/examples/Navbars/DashboardNavbar";
import WalletSavingPlanGateway from "entities/walletSavingPlanGateway";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { NullFormatUtils, NumberFormatUtils } from "util/index";
import LabelFilterPanel from "util/LabelFilterPanel";
import status from "util/LabelFilterPanel/base";
import { handleFilterByKeyword } from "util/SearchFunction";
import DataTable from "../../components/Custom/dataTable";
import CustomPopup from "./components/custom_pop_up";

let walletSavingPlanGateway = new WalletSavingPlanGateway();

function WalletSavingPlan() {
  //pop up pages
  const [page, setPage] = useState(0);
  const [openPopUp, setOpenPopup] = useState(false);
  const [popUpName, setPopUpName] = useState("");
  const [userID, setUserID] = useState("");

  const [tbPage, setTbPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const [filterRows, setFilterRows] = useState([]);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentFilter, setCurrentFilter] = useState(0);
  const [allStatusWithCount, setAllStatusWithCount] = useState([]);
  const [statusStatement, setStatusStatement] = useState(null);

  const handlePopUp = (pName, id) => {
    setPopUpName(pName, id);
    setOpenPopup(true);
    setUserID(id);
    setPage(1);
  }

  const handlePageChange = (event, value) => {
    if (value <= totalPage) {
      setTbPage(value);
      fetchAllData(value);
    }
  };

  const fetchAllData = async (page, _statusStatement) => {
    setLoading(true);

    const data_length = await walletSavingPlanGateway.getAllData({
      page: page ?? tbPage,
      statusStatement: _statusStatement ?? (statusStatement ?? null)
    })
      .then((res) => {
        if (res) {
          if (page === 0) {
            return res.total;
          } else {
            setRows(res.data);
            setFilterRows(res.data);
            setTotalPage(res.last_page);
          }
        }
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });

    if (page === 0) return data_length
  };

  const handleChangeRows = async (statusParam, statusValue) => {
    setCurrentFilter(statusValue);
    fetchAllData(1, `${statusParam}=${statusValue}`)
  };

  const handleClearAndRefresh = () => {
    setCurrentFilter(0);
    setStatusStatement(null);
    setRows([]);
  };

  const fetchStatusCount = () => {
    let allStatus = [];
    status?.savingplan?.map((status, key) => {
      let _status = status;
      fetchAllData(0, `${status?.param?.name}=${status?.param?.value}`)
        .then((res) => {
          _status.count = res;
        })
      allStatus.push(_status);
    })
    setStatusStatement(null);
    setAllStatusWithCount(allStatus);
  }

  useEffect(() => {
    if (rows.length < 1) {
      fetchAllData();
      fetchStatusCount();
    }
  }, []);

  const wSavingPlan_columns = [
    {
      headerName: 'Saving Plan Record ID',
      field: 'id',
      renderCell: ({ row }) => `${row.id || "-"}`,
      sortable: true,
      width: 200
    },
    {
      headerName: 'User ID',
      field: 'account_id',
      renderCell: ({ row }) => row.account_id ? <Link to="#" className="text-secondary" onClick={() => { handlePopUp("AccountDetailsPopup", row.account_id) }}>{row.account_id}</Link> : "-",
      sortable: true,
      width: 100
    },
    {
      headerName: 'Create Date',
      field: 'createAt',
      renderCell: ({ row }) => `${moment(row.createAt).format("YYYY-MM-DD HH-mm") || "-"}`,
      sortable: true,
      width: 150
    },
    {
      headerName: 'End Date',
      field: 'endAt',
      renderCell: ({ row }) => `${moment(row.endAt).format("YYYY-MM-DD HH-mm") || "-"}`,
      sortable: true,
      width: 150
    },
    {
      headerName: 'Days',
      field: 'days',
      renderCell: ({ row }) => `${row.days || "-"}`,
      sortable: true,
      width: 100
    },
    {
      headerName: 'APR',
      field: 'APR',
      renderCell: ({ row }) => `${NumberFormatUtils(row.APR, 5) || "-"}`,
      sortable: true,
      width: 100
    },
    {
      headerName: 'Cypto Type',
      field: 'cryptoType',
      renderCell: ({ row }) => row.cryptoType ?? "-",
      sortable: true,
    },
    {
      headerName: 'Saving Amount',
      field: 'savingAmount',
      renderCell: ({ row }) => '$' + NumberFormatUtils(row.savingAmount, 5) ?? "-",
      sortable: true,
      width: 200
    },
    {
      headerName: 'Expected Return',
      field: 'expectedReturn',
      renderCell: ({ row }) => '$' + NumberFormatUtils(row.expectedReturn, 5) ?? "-",
      sortable: true,
      width: 200
    },
    {
      headerName: 'Status',
      field: 'status',
      renderCell: ({ row }) =>
        <Chip label={NullFormatUtils(row.status)}
          className={handleFilterByKeyword(status.savingplan, "label", row.status)} />,
      sortable: true,
    }
  ];



  return (
    <div>
      {
        page > 0 ?
          <Popup
            openPopup={openPopUp}
            setOpenPopup={() => { setOpenPopup(false) }}
          >
            <CustomPopup name={popUpName} id={userID}></CustomPopup>
          </Popup> : <></>
      }
      <DashboardLayout>
        <DashboardNavbar />
        <Loader loading={loading}>
          <LabelFilterPanel
            currentFilter={currentFilter}
            handleClearAndRefresh={handleClearAndRefresh}
            fetchAllData={fetchAllData}
            allStatus={allStatusWithCount}
            handleChangeRows={handleChangeRows}
          />
          <SuiBox py={3}>
            <SuiBox mb={3}>
              <Card>
                <DataTable dt_columns={wSavingPlan_columns} dt_rows={filterRows} />
                <Pagination color={"secondary"} count={totalPage} page={tbPage} onChange={handlePageChange} />
              </Card>
            </SuiBox>
          </SuiBox>
        </Loader>

      </DashboardLayout>
    </div>
  );
}

export default WalletSavingPlan;
