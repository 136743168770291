/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React components
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { Chip, Pagination } from '@mui/material';
import Card from "@mui/material/Card";
import Popup from "assets/theme/components/popup";
import { DataTable, Loader } from "components/Custom";
import table_columns_size from "components/Custom/dataTable/column_size";
import { SuiBox, SuiButton } from "components/Default";
import Footer from "components/examples/Footer";
import DashboardLayout from "components/examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/examples/Navbars/DashboardNavbar";
import { constant } from 'config/constant';
import OTCGateway from "entities/otcGateway";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Link } from 'react-router-dom';
import { NullFormatUtils, NumberFormatUtils } from "util/index";
import LabelFilterPanel from "util/LabelFilterPanel";
import status from "util/LabelFilterPanel/base";
import { handleFilterByKeyword } from "util/SearchFunction";
import { CustomPopup } from "./components";

let otcGateway = new OTCGateway();

function OTC() {
  const alert = useAlert();
  let testMode = constant.DEBUG;

  const [page, setPage] = useState(0);  //pop up pages
  const [openPopUp, setOpenPopup] = useState(false);
  const [popUpName, setPopUpName] = useState("");
  const [currentStaff, setCurrentStaff] = useState();
  const [type, setType] = useState(0);

  const [rows, setRows] = useState([]); // data-table
  const [filterRows, setFilterRows] = useState([]);
  const [allStatusWithCount, setAllStatusWithCount] = useState([]);
  const [currentFilter, setCurrentFilter] = useState(0); //status value
  const [statusStatement, setStatusStatement] = useState(null);
  const [loading, setLoading] = useState(true);

  const [tbPage, setTbPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const handlePageChange = (event, value) => {
    console.log(value);
    if (value <= totalPage) {
      setTbPage(value);
      fetchAllData(value);
    }
  };

  const handlePopUp = (pName, id, type, data) => {
    setPopUpName(pName);
    setOpenPopup(true);
    setPage(1);
    setType(type);
    if (id) setCurrentStaff(id);
  };

  const fetchAllData = async (page, _statusStatement) => {
    setLoading(true);

    const data_length = await otcGateway.getAllData({
      page: page ?? tbPage,
      statusStatement: _statusStatement ?? (statusStatement ?? null)
    })
      .then((res) => {
        if (res) {
          if (page === 0) {
            return res.total;
          }
          setRows(res.data);
          setFilterRows(res.data);
          setTotalPage(res.last_page);
        }
        setLoading(false);
      })
      .catch((err) => {
        alert.error(err.message);
      })

    if (page === 0) return data_length
  };

  const fetchStatusCount = () => {
    let allStatus = [];
    status?.otc?.map((status, key) => {
      let _status = status;
      fetchAllData(0, `${status?.param?.name}=${status?.param?.value}`)
        .then((res) => {
          _status.count = res;
        })
      allStatus.push(_status);
    })
    setAllStatusWithCount(allStatus);
  }

  useEffect(() => {
    if (statusStatement === null) {
      fetchAllData();
    }
    if (rows.length < 1) {
      fetchAllData();
      fetchStatusCount();
    }
  }, [statusStatement]);

  const checkSearchKeyword = (SearchValue, rowData) => {
    if(SearchValue && rowData){
      //console.log(SearchValue);
      const new_rowData = rowData.replace(
        new RegExp(SearchValue, 'gi'),
        match => {
          if (match) {
            return `<mark style="background: #8D8DAA; color: white;">${match}</mark>`;
          }
        }
      );
      return HTMLContentUtils(new_rowData);
    }
    return rowData;
  };

  const dt_columns = [
    {
      headerName: 'OTC id',
      field: 'id',
      width: table_columns_size.m,
      renderCell: row =>
        row.id ?
          <Link to="#" className="text-secondary" onClick={() => handlePopUp("OTCPopup", row.id, 1)}>{row.id}</Link> : "-",
      sortable: true,
    },
    {
      headerName: 'Seller ID',
      field: 'sellerId',
      width: table_columns_size.xxm,
      renderCell: ({ row }) => row.sellerId ? <Link to="#" className="text-secondary" onClick={() => handlePopUp("AccountDetailsPopup", row.sellerId)}>{row.sellerId}</Link> : "-",
      sortable: true,
    },
    {
      headerName: 'Seller Name',
      field: 'sellerName',
      width: table_columns_size.m,
      renderCell: ({ row }) => NullFormatUtils(row.sellerName),
      sortable: true,
    },
    {
      headerName: 'Selling Amount',
      field: 'sellingAmount',
      width: table_columns_size.m,
      renderCell: ({ row }) => NumberFormatUtils(row.sellingAmount, 8),
      sortable: true,
    },
    {
      headerName: 'Selling Price',
      field: 'sellingPrice',
      width: table_columns_size.m,
      renderCell: ({ row }) => '$' + NumberFormatUtils(row.sellingPrice, 8),
      sortable: true,
    },
    {
      headerName: 'Buying Amount',
      field: 'buyingAmount',
      width: table_columns_size.m,
      renderCell: ({ row }) => NumberFormatUtils(row.buyingAmount, 8),
      sortable: true,
    },
    {
      headerName: 'Buying Price',
      field: 'buyingPrice',
      width: table_columns_size.m,
      renderCell: ({ row }) => '$' + NumberFormatUtils(row.buyingPrice, 8),
      sortable: true,
    },
    {
      headerName: 'Status',
      field: 'status',
      width: table_columns_size.m,
      renderCell: ({ row }) =>
        <Chip label={checkSearchKeyword(row.SearchValue, NullFormatUtils(row.status))}
          className={handleFilterByKeyword(status.otc, "label", row.status)} />,
      sortable: true,
    }
  ];

  const handleChangeRows = async (statusParam, statusValue) => {
    setCurrentFilter(statusValue);
    setStatusStatement(`${statusParam}=${statusValue}`);
    fetchAllData(1, `${statusParam}=${statusValue}`);
    setLoading(false);
  };

  const handleClearAndRefresh = () => {
    setCurrentFilter(0);
    setStatusStatement(null);
  };

  return (
    <div>
      {
        page > 0 ?
          <Popup
            openPopup={openPopUp}
            setOpenPopup={() => { setOpenPopup(false) }}
          >
            <CustomPopup name={popUpName} id={currentStaff} type={type} />
          </Popup> : <></>
      }
      <DashboardLayout>
        <DashboardNavbar />

        <SuiButton
          buttonColor="secondary"
          onClick={() => handlePopUp("OTCPopup", 0, 2)}>
          <PersonOutlineIcon />
          {/* View OTC User List */}
        </SuiButton>

        <Loader loading={loading}>
          <SuiBox py={3}>
            <SuiBox mb={3}>
              <LabelFilterPanel
                currentFilter={currentFilter}
                handleClearAndRefresh={handleClearAndRefresh}
                fetchAllData={fetchAllData}
                allStatus={allStatusWithCount}
                handleChangeRows={handleChangeRows}
              />
              <Card>
                <DataTable dt_columns={dt_columns} dt_rows={filterRows} />
                <Pagination color={"secondary"} count={totalPage} page={tbPage} onChange={handlePageChange} />
              </Card>
            </SuiBox>
          </SuiBox>
        </Loader>

        
      </DashboardLayout>
    </div>
  );
}

export default OTC;
