import OrderApi from "api/order";
import initalValue from "entities/base/initalValue";
import FirebaseGateway from "./firebaseGateway";

const Scheme = {
    id: initalValue.String,
    shortId: initalValue.String,
    sellerId: initalValue.String,
    buyerId: initalValue.String,
    shopOfferId: initalValue.String,
    quantity: initalValue.Int,
    price: initalValue.Int,
    cryptoType: initalValue.String,
    orderType: initalValue.String,
    reviews: initalValue.Boolean,
    refund: initalValue.Boolean,
    status: {
        none: "None",
        waitingConfirm: "待確認",
        waitingSendGoods: "待發貨",
        waitingReceiveGoods: "待收貨",
        waitingPayment: "待付款",
        complete: "已完成",
        cancel: "已取消",
        reject: "已拒絕"
    },
    createAt: initalValue.Datetime,
    updateAt: initalValue.Datetime
}

export default class OrderGateway {
    constructor() { }

    getScheme() {
        return Scheme;
    };

    async getAllData({ page, statusStatement, searchValue }) {
        let firebaseGateway = new FirebaseGateway();
        let latestToken = await firebaseGateway.getToken();

        return await OrderApi.getListByPage({
            page: page,
            statusStatement: statusStatement,
            id_token: latestToken,
            searchValue: searchValue
        })
            .then(response => {
                if (response.status === 200) {
                    return {
                        data: this.formatOrdersData(response.data),
                        last_page: response.data.meta.last_page,
                        per_page: response.data.meta.per_page,
                        total: response.data.meta.total
                    };
                }
            })
    }

    formatOrderData(apiData) {
        const {
            id, ShortId, seller_account_id, buyer_account_id, ShopOfferId, Quantity, Price, CryptoType, OrderType, Reviews, refund_status, Status, CreateAt, UpdatedAt
        } = apiData;

        return {
            id: id,
            shortId: ShortId,
            sellerId: seller_account_id,
            buyerId: buyer_account_id,
            shopOfferId: ShopOfferId,
            quantity: Quantity,
            price: Price,
            cryptoType: CryptoType,
            orderType: OrderType,
            reviews: Reviews,
            refund: refund_status,
            status: Status,
            createAt: CreateAt,
            updateAt: UpdatedAt
        };
    }

    formatOrdersData(apiData) {
        const dataset = Object.values(apiData.data).map(a => ({
            id: Object.keys(a)[0],
            ...Object.values(a)[0]
        }));
        return dataset.map(data => {
            return this.formatOrderData(data);
        });
    }
}