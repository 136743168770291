import axios from "./index";

class GeneralApi {
  static list = () => {
    const id_token = localStorage.getItem("id_token");
    return axios.get(`${base}`, { headers: {"Authorization" : `Bearer ${id_token}`} });
  };

  static getfixedSavingPlanOffers = () => {
    const id_token = localStorage.getItem("id_token");
    return axios.get(`/fixed_saving_plan_offers`, { headers: {"Authorization" : `Bearer ${id_token}`} });
  };

  static getfixedSavingPlanOffersById = id => {
    return axios.get(`/fixed_saving_plan_offers/${id}`);
  };

  static updatefixedSavingPlanOffers = data => {
    const id_token = localStorage.getItem("id_token");
    if (data.id) {
      const requestData = {
        crypto_type: data.crypto_type,
        min_amount: data.min_amount,
        max_amount: data.max_amount,
        days: data.days,
        annual_percentage_rate: data.annual_percentage_rate,
        start_at: data.start_at,
        end_at: data.end_at
      };
      return axios.put(`/fixed_saving_plan_offers/${data.id}`, requestData, { headers: {"Authorization" : `Bearer ${id_token}`} });
    } else {
      return axios.post(`/fixed_saving_plan_offers`, data, { headers: {"Authorization" : `Bearer ${id_token}`} });
    }
  };

  static removefixedSavingPlanOffers = id => {
    if (id) {
      return axios.delete(`/fixed_saving_plan_offers/${data.id}`);
    }
  }

  static getExtraReward = () => {
    return axios.get(`/fixed_saving_plan_offers?type=ExtraReward`);
  }

  static getOffersHasTag = () => {
    return axios.get(`/fixed_saving_plans?hasTag=1`);
  }

  static markReceivedReward = id => {
    return axios.put(`/fixed_saving_plans/${id}/mark_received_reward`, {
      "received": true
    });
  }

  static cancelFixedSavingPlan = data => {
    return axios.post(`/fixed_saving_plans/${data.id}/cancel?disable_penalty=1&reason=${data.reason}`);
  }

  static setSalesperson = data => {
    return axios.post(`/fixed_saving_plans/${data.id}/set_salesperson`, {
      salesperson_account_id: data.account_id
    })
  }

  static createOrEditExtraReward = data => {
    if (data) {
      const requestData = {
        crypto_type: data.crypto_type,
        min_amount: data.min_amount,
        max_amount: data.max_amount,
        days: data.days,
        annual_percentage_rate: data.annual_percentage_rate,
        start_at: data.start_at,
        end_at: data.end_at,
        type: data.type,
        quota: data.quota,
        enabled: true,
        title: data.title,
        description: data.description,
        banner_url: data.banner_url,
        reward_options: data.reward_options
      };
      return axios.post(`/fixed_saving_plan_offers`, requestData);
    }
    throw "request data is missing";
  }

  static editFAQs = data => {
    const id_token = localStorage.getItem("id_token");
    if (data) {
      const requestData = {
        question: data.Question,
        answer: data.Answer,
        question_eng: data.Question_ENG,
        answer_eng: data.Answer_ENG
      };

      return axios.put(`${base}/faqs/${data.fid}`, requestData, { headers: {"Authorization" : `Bearer ${id_token}`} });
    }
    throw "request data is missing";
  };

  static addFAQs = data => {
    const id_token = localStorage.getItem("id_token");
    if (data) {
      const requestData = {
        question: data.Question,
        answer: data.Answer,
        question_eng: data.Question_ENG,
        answer_eng: data.Answer_ENG
      };

      return axios.post(`${base}/faqs/`, requestData, { headers: {"Authorization" : `Bearer ${id_token}`} });
    }
    throw "request data is missing";
  };

  static editAdsReminderDesc = data => {
    if(data){
      return axios.put(`${base}/advertisement_managements`, data);
    }
    throw "request data is missing";
  }

  static editAdsManagement = data => {
    const id_token = localStorage.getItem("id_token");
    if (data) {
      const requestData = {
        ads_image: data.ads_image,
        crypto_type: "USDT",
        fee: data.fee,
        position_description: data.position + "廣告位"
      };

      return axios.put(`${base}/ads_managements/${data.position}`, requestData, { headers: {"Authorization" : `Bearer ${id_token}`} });
    }
    throw "request data is missing";
  };

  static addAdsManagement = data => {
    const id_token = localStorage.getItem("id_token");
    if (data) {
      const requestData = {
        ADImage: data.ADImage,
        CryptoType: "USDT",
        Fee: data.Fee,
        Position: data.Position
      };

      return axios.post(`${base}/ads_managements`, requestData, { headers: {"Authorization" : `Bearer ${id_token}`} });
    }
    throw "request data is missing";
  };

  static editSavingPlan = data => {
    const id_token = localStorage.getItem("id_token");
    if (data) {
      return axios.put(`${base}/saving_plans`, data, { headers: {"Authorization" : `Bearer ${id_token}`} });
    }
    throw "request data is missing";
  };

  static editServiceFee = data => {
    const id_token = localStorage.getItem("id_token");
    if (data) {
      return axios.put(`${base}/service_fees`, data, { headers: {"Authorization" : `Bearer ${id_token}`} });
    }
    throw "request data is missing";
  };

  static addServiceFee = data => {
    const id_token = localStorage.getItem("id_token");
    if (data) {
      return axios.post(`${base}/service_fees`, data, { headers: {"Authorization" : `Bearer ${id_token}`} });
    }
    throw "request data is missing";
  };

  static editAppNotices = data => {
    const id_token = localStorage.getItem("id_token");
    if (data) {
      const requestData = {
        AnnounceAt: data.AnnounceAt,
        Content: data.Content,
        NoticeIcon: data.NoticeIcon,
        Title: data.Title
      };
      return axios.put(`${base}/app_notices`, requestData, { headers: {"Authorization" : `Bearer ${id_token}`} });
    }
    throw "request data is missing";
  };

  static createAppNotices = data => {
    const id_token = localStorage.getItem("id_token");
    if (data) {
      return axios.post(`/send_notification`, data, { headers: {"Authorization" : `Bearer ${id_token}`} });
    }
    throw "request data is missing";
  };

  static editServiceAgreement = data => {
    const id_token = localStorage.getItem("id_token");
    if (data) {
      return axios.put(`${base}/service_agreements`, data, { headers: {"Authorization" : `Bearer ${id_token}`} });
    }
    throw "request data is missing";
  };

  static editCrisisMgt = data => {
    const id_token = localStorage.getItem("id_token");
    if (data) {
      console.log(data);
      return axios.put(`${base}/crisis_managements`, data, { headers: {"Authorization" : `Bearer ${id_token}`} });
    }
    throw "request data is missing";
  };

  static addVersionUpdate = data => {
    const id_token = localStorage.getItem("id_token");
    if (data) {
      return axios.put(`${base}/version_update`, data, { headers: {"Authorization" : `Bearer ${id_token}`} });
    }
    throw "request data is missing";
  };

  static getEmailTemplate = () => {
    const id_token = localStorage.getItem("id_token");
    return axios.get(`/mail_template`, { headers: {"Authorization" : `Bearer ${id_token}`} });
  };

  static sendEmailByTemplateId = (id) => {
    const id_token = localStorage.getItem("id_token");
    if (id) {
      return axios.post(`/send_mail_template/${id}`, { headers: {"Authorization" : `Bearer ${id_token}`} });
    }
    throw "request id is missing";
  };

  static editEmailTemplateById = (data) => {
    const id_token = localStorage.getItem("id_token");
    if (data.id) {
      return axios.put(`/mail_template/${data.id}`, {
        body: data.body
      }, { headers: {"Authorization" : `Bearer ${id_token}`} });
    }
    throw "request id is missing";
  }

  static removeEmailTemplateById = (data) => {
    const id_token = localStorage.getItem("id_token");
    if (data.id) {
      return axios.delete(`/mail_template/${data.id}`, { headers: {"Authorization" : `Bearer ${id_token}`} });
    }
    throw "request id is missing";
  }

  static getLogs = () => {
    const id_token = localStorage.getItem("id_token");
    return axios.get(`/logs`, { headers: {"Authorization" : `Bearer ${id_token}`} });
  }
}

let base = "/generals";

export default GeneralApi;
