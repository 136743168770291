import UserApi from "api/user";
import initalValue from "entities/base/initalValue";
import FirebaseGateway from "./firebaseGateway";

const Scheme = {
    id: initalValue.String,
    account_id: initalValue.String,
    mysql_id: initalValue.Int,
    username: initalValue.String,
    phone: initalValue.String,
    whatsapp: initalValue.String,
    description: initalValue.String,
    officialWebpage: initalValue.String,
    headerImage: initalValue.Image,
    userIcon: initalValue.Image,
    address: initalValue.String,
    district: initalValue.String,
    IdVerifyStatus: {
        None: "None",
        Processing: "Processing",
        Approved: "Approved",
        Rejected: "Rejected"
    },
    ShopVerifyStatus: {
        None: "None",
        Processing: "Processing",
        Approved: "Approved",
        Rejected: "Rejected"
    },
    AccountStatus: {
        None: "None",
        Active: "Active",
        Deleted: "Deleted",
        EmailNotVerified: "EmailNotVerified",
        Blocked: "Blocked"
    },
    email: initalValue.Email,
    referralCount: initalValue.Int,
    adCreditUSDT: initalValue.Int
}

export default class ShopGateway {
    constructor() { }

    getScheme() {
        return Scheme;
    };

    async getAllData({ page, searchValue }) {
        let firebaseGateway = new FirebaseGateway();
        let latestToken = await firebaseGateway.getToken();

        return await UserApi.getShopsByPage({
            page: page,
            id_token: latestToken,
            searchValue: searchValue
        })
            .then(response => {
                if (response.status === 200) {
                    return {
                        data: this.formatShopsData(response.data.data.data),
                        last_page: response.data.data.last_page,
                        per_page: response.data.data.per_page,
                        total: response.data.data.total
                    };
                }
            })
    }

    formatShopData(apiData) {
        const {
            MySQLID, UID, UserName, Phone, WhatsApp, ShopVerifyStatus, Description, OfficialWebpage, HeaderImage, UserIcon, IdVerifyStatus, AccountId, Email, AccountStatus, ReferralCount, advertisement_credit_USDT
        } = apiData;

        const {
            Address1, District
        } = apiData?.DisplayAddress;

        return {
            id: UID,
            account_id: AccountId,
            mysql_id: MySQLID,
            username: UserName,
            phone: Phone,
            whatsapp: WhatsApp,
            description: Description,
            officialWebpage: OfficialWebpage,
            headerImage: HeaderImage,
            userIcon: UserIcon,
            address: Address1,
            district: District,
            idVerifyStatus: IdVerifyStatus,
            shopVerifyStatus: ShopVerifyStatus,
            accountStatus: AccountStatus,
            email: Email,
            referralCount: ReferralCount,
            adCreditUSDT: advertisement_credit_USDT
        };
    }

    formatShopsData(apiData) {
        return apiData.map(data => {
            return this.formatShopData(data);
        });
    }
}