import InputWrapper from 'components/Custom/input_wrapper';

import 'components/Custom/common.css';
import { useState, useEffect } from 'react';
import ImageViewer from "components/Custom/image_viewer";
import { TextareaAutosize } from '@material-ui/core';

import { Grid } from "@material-ui/core";
import SuiBox from "components/Default/SuiBox";
import { NullFormatUtils } from "util/index";
import { ImageURLExistHelper } from 'util/index';
import { useAlert } from 'react-alert';

import OfferApi from 'api/offer';

const fakeImg = "https://upload.wikimedia.org/wikipedia/commons/thumb/7/70/Checkerboard_pattern.svg/1200px-Checkerboard_pattern.svg.png";

const offer_details = {
    "040206584273": {
        "Category": "數碼電器",
        "ContentImages": [],
        "CreateAt": "February 8, 2022 at 10: 37: 35 AM UTC + 8",
        "Status": "待確認",
        "CryptoType": "USDT",
        "DeliveryMethod": "",
        "Description": "Ajsj\nDjjs\nSj",
        "Handler": {
            "CreateAt": "",
            "InternalRemark": "",
            "Name": ""
        },
        "HeaderImage": "https://firebasestorage.googleapis.com/v0/b/intepayhk-bd35c.appspot.com/o/images%2Fproducts%2FZYJcBig4qYhHg5Q0s4VDyTHqLBw2%2F5lS0z8lAa1YGT3UBdXmR%2Fheader_1.png?alt=media&token=a65aee09-a7d4-431d-9423-2826ca4d4b4d",
        "IsHidden": false,
        "Price": 250,
        "ProductCondition": "",
        "Sales": 0,
        "Stock": 20,
        "Title": "剃須刀",
        "UpdateAt": "February 8, 2022 at 10: 37: 35 AM UTC + 8",
        "UserID": "ZYJcBig4qYhHg5Q0s4VDyTHqLBw2",
        "Report": {
            "SZPOaVmXfynpqAjjHExa": {
                "CreateAt": "February 4, 2022 at 5: 33: 30 PM UTC + 8",
                "ReportDetails": null,
                "ReportProof1": null,
                "ReportProof2": null,
                "ReportReason": null,
                "Status": "Pending",
                "UserID": "ZYJcBig4qYhHg5Q0s4VDyTHqLBw2"
            }
        }
    },
}


export default function OfferDetails({ id }) {
    const alert = useAlert();

    const [offerData, setOfferData] = useState([]);
    const [liked, setLiked] = useState([]);

    //console.log(id);

    const fetchOneOfferData = async (id) => {

        await OfferApi.getOneOfferByFid(id)
            .then((res) => {
                if(res && res.status === 200){
                    setOfferData(res.data.data);
                    //console.log(offerData);
                    const likeData = Object.entries(res.data.data.Likes)
                        .map(a => ({ id: Object.keys(a[1])[0], ...Object.entries(a[1])[0][1] }));

                    let likeCount = 0;
                    likeData.map(data => {
                        if (data.IsLiked) { likeCount = likeCount + 1; }
                    })
                    setLiked(likeCount);
                }
            })
            .catch((err) => {
                console.log(err);
                alert.error(err.message);
            });
    };
    
    useEffect(() => {
        fetchOneOfferData(id);
    }, []);

    //console.log(offerData);

    return (
        <div id="popup1" className="popup-wrapper">
            <div id="" className="popup1-flex-column">
                <h4>Offer Details</h4>
                <div className="popup-content">
                    <SuiBox xs={2} lg={3} xl={6} width="100%">
                        <InputWrapper attr="Offer ID" value={id} size="sm" />
                    </SuiBox>
                    <Grid container alignItems="center">
                        <Grid item xs={12} sm={8} md={6} xl={5}>
                            <InputWrapper attr="Seller ID" value={offerData.User ? NullFormatUtils(offerData.User.account_id) : "Account ID"} size="sm" />
                        </Grid>
                        <Grid item xs={12} sm={8} md={6} xl={7}>
                            <InputWrapper attr="Account Level" value={offerData.User ? NullFormatUtils(offerData.User.ShopVerifyStatus) : "-"} size="lg" />
                        </Grid>
                    </Grid>
                    <SuiBox xs={2} lg={3} xl={6} width="100%">
                        <InputWrapper attr="Title" value={NullFormatUtils(offerData.Title)} size="sm" />
                    </SuiBox>
                    <Grid container alignItems="center">
                        <Grid item xs={12} sm={8} md={6} xl={6}>
                            <InputWrapper attr="Title" attr="Price" value={NullFormatUtils(offerData.Price)} size="sm" />
                        </Grid>
                        <Grid item xs={12} sm={8} md={6} xl={6}>
                            <InputWrapper attr="Crypto" value={NullFormatUtils(offerData.CryptoType)} size="sm" />
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center">
                        <Grid item xs={12} sm={8} md={6} xl={6}>
                            <InputWrapper attr="Inventory" value={NullFormatUtils(offerData.Stock)} size="sm" />
                        </Grid>
                        <Grid item xs={12} sm={8} md={6} xl={6}>
                            <InputWrapper attr="Likes" size="sm" value={liked} />
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center">
                        <Grid item xs={12} sm={8} md={6} xl={6}>
                            <InputWrapper attr="Category" value={NullFormatUtils(offerData.Category)} size="sm" />
                        </Grid>
                        <Grid item xs={12} sm={8} md={6} xl={6}>
                            <InputWrapper attr="Sales" value={NullFormatUtils(offerData.Sales)} size="sm" />
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center">
                        <SuiBox xs={2} lg={3} xl={6} width="100%">
                            <InputWrapper attr="Delivery" value={NullFormatUtils(offerData.DeliveryMethod)} size="lg" />
                        </SuiBox>
                    </Grid>
                    <SuiBox xs={2} lg={3} xl={6} width="100%">
                        <InputWrapper attr="Product Condition" value={NullFormatUtils(offerData.ProductCondition)} size="lg" />
                    </SuiBox>
                    <div>
                        <ImageViewer headerText="Header Image" clickText="Click to View" type={0} src={ImageURLExistHelper(offerData.HeaderImage)} title={true}/>
                        <ImageViewer headerText="Content Image" clickText="Click to View" type={1} src={ImageURLExistHelper(offerData.ContentImages)} title={true}/>
                    </div>
                </div>
            </div>

            <div id="" className="security">
                <h4>Offer Description</h4>
                <div className="popup-content">
                    <div id="offer-description">
                        <TextareaAutosize style={{ height: 500, width: 600 }} disabled={true} value={NullFormatUtils(offerData.Description)} />
                    </div>
                    <div id="popup-phone popup-sex" className="popup-form-row">
                        <InputWrapper attr="Status" value={offerData.IsHidden ? 'ON' : 'OFF'} size="sm" />
                        <InputWrapper attr="Handler" value={offerData.Handler ? NullFormatUtils(offerData.Handler.Name) : '-'} size="sm" />
                    </div>
                </div>
            </div>
        </div>
    );
}