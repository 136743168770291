import { Grid } from "@mui/material";
import Column from "template/base/column";
import Form from "template/base/form";
import { DateFormatUtils } from "util/index";

function ReferralTemplate(props) {
  const {
    Title,
    InvitedBy,
    SubTitle1,
    BTC,
    ETH,
    USDC,
    USDT,
    SubTitle2,
    AccountID,
    UserName,
    Email,
    CreateAt,
    ReferralData = []
  } = props;

  function RowsData({ data }) {
    return data.map((data, index) =>
      <div key={index}>
        <Grid container alignItems="center">
            <Grid item xs={12} sm={3} md={3} xl={3}>
                <AccountID accountId={data?.user_id}/>
            </Grid>
            <Grid item xs={12} sm={3} md={3} xl={3}>
                <UserName userName={data?.user_name}/>
            </Grid>
            <Grid item xs={12} sm={3} md={3} xl={3}>
                <Email email={data?.user_email}/>
            </Grid>
            <Grid item xs={12} sm={3} md={3} xl={3}>
                <CreateAt createAt={ DateFormatUtils(data?.user_register_date, "DD-MM-YYYY") }/>
            </Grid>
        </Grid>
        
        <Grid container alignItems="center">
            <Grid item xs={12} sm={6} md={6} xl={6}>
                <BTC BTC={data?.crypto_rewards?.BTC}/>
            </Grid>
            <Grid item xs={12} sm={6} md={6} xl={6}>
                <ETH ETH={data?.crypto_rewards?.ETH}/>
            </Grid>
            <Grid item xs={12} sm={6} md={6} xl={6}>
                <USDC USDC={data?.crypto_rewards?.USDC}/>
            </Grid>
            <Grid item xs={12} sm={6} md={6} xl={6}>
                <USDT USDT={data?.crypto_rewards?.USDT}/>
            </Grid>
        </Grid>
      </div>
    );
  }
  
  return (
    <Form>
        <Column>
            <Title />
            <InvitedBy/>
            <SubTitle1/>
            <Grid container alignItems="center">
                <Grid item xs={12} sm={6} md={6} xl={6}>
                    <BTC/>
                </Grid>
                <Grid item xs={12} sm={6} md={6} xl={6}>
                    <ETH/>
                </Grid>
                <Grid item xs={12} sm={6} md={6} xl={6}>
                    <USDC/>
                </Grid>
                <Grid item xs={12} sm={6} md={6} xl={6}>
                    <USDT/>
                </Grid>
            </Grid>

            <SubTitle2/>
            <RowsData data={ReferralData} />

        </Column>
    </Form>
  );
}

export default ReferralTemplate;
