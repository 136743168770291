import AuthCodeHelper from "util/AuthCodeHelper";
import { IconButton, Icon, TextField, InputLabel } from "@mui/material";
import { SuiButton } from "components/Default";
import { InputWrapper } from "components/Custom";
import { NumberFormatUtils } from 'util/index';
import { useEffect, useState } from "react";
import { WALLET_PERMISSION } from 'config/permission';
import CustomerGateway from 'entities/customerGateway';
import UserApi from "api/user";
import EditSuccessfulMsg from 'components/Custom/edit_successful';

let customerGateway = new CustomerGateway();

export default function EditAD({ id, adCredit }) {
    const [walletData, setWalletData] = useState(null);

    const unit = 8;
    const [auth, setAuth] = useState(false);
    const [authSuccess, setAuthSuccess] = useState(false);
    const [confirmPage, setConfirmPage] = useState(false);

    const [addValue, setAddValue] = useState(false);
    const [oldValue, setOldValue] = useState(NumberFormatUtils(adCredit, 8));
    const [deltaValue, setDeltaValue] = useState(0);
    const [negNewValue, setNegNewValue] = useState(false);
    const [newValue, setNewValue] = useState(oldValue);
    const [showDeltaValue, setShowDeltaValue] = useState(0);

    const [numError, setNumError] = useState(false);
    const [editReason, setEditReason] = useState("");

    const handleDeltaValue = (e) => {
        let delta = e.target.value;
        setShowDeltaValue(e.target.value);

        let del = NumberFormatUtils(delta, unit);
        //if user input is not numerical, set as 0
        if (isNaN(del)) {
            del = 0;
            setNumError(true);
            setDeltaValue(del);
        } else {
            setNumError(false);
            setDeltaValue(del);
        }

        if (addValue) {
            setNewValue(NumberFormatUtils((parseFloat(oldValue) + parseFloat(del)), unit))
        } else {
            setNewValue(NumberFormatUtils((parseFloat(oldValue) - parseFloat(del)), unit))
        }
    }

    function handleAddValue() {
        setAddValue(true);
        setNewValue(NumberFormatUtils((parseFloat(oldValue) + parseFloat(deltaValue)), unit))
    }

    function handleDeductValue() {
        setAddValue(false);
        setNewValue(NumberFormatUtils((parseFloat(oldValue) - parseFloat(deltaValue)), unit));
    }

    const handleProceed = () => {
        if (!negNewValue) {
            setConfirmPage(true)
        }
    }

    const handleAuthCode = (resp) => {
        if (resp) {
            if (resp.isAuth) {
                setAuth(false);
                setAuthSuccess(true);
            } else {
                alert.error(resp.message);
            }
        }
        else {
            alert.error(ERROR.callback);
        }
    }

    const fetchOneUserData = async (id) => {
        console.log(id);
        await customerGateway.getOneUserData({ type: "firebase_id", id: id })
            .then((res) => {
                if (res) {
                    console.log(res);
                    setWalletData(res.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        fetchOneUserData(id);
    },[])

    function ConfirmInfo() {
        const [editSuccess, setEditSuccess] = useState(false);
        const handleProceed = () => {
            if (addValue === true) {
                UserApi.depositAdCredit({
                    id: id,
                    amount: deltaValue,
                    reason: editReason
                })
                    .then((res) => {
                        if (res.status === 200) {
                            setEditSuccess(true);
                        }
                    })
                    .catch((e) => {
                        console.log(e)
                    })

            } else {
                UserApi.withdrawAdCredit(data)
                    .then((res) => {
                        if (res.status === 200) {
                            setEditSuccess(true);
                        }
                    })
                    .catch((e) => {
                        console.log(e)
                    })
            }
        }

        if (editSuccess) {
            return <EditSuccessfulMsg />
        } else return (
            <div className="popup-content">
                <h4>{"Edit confirmation"}</h4>
                <InputWrapper attr={"User ID"} value={walletData?.account_id} size={"lg"} />
                <InputWrapper attr={"User Name"} value={walletData?.username} size={"lg"} />
                <InputWrapper attr={"Crypto Type"} value={"USDT"} size={"lg"} />
                <InputWrapper attr={"Original Balance"} value={oldValue} size={"lg"} />
                <InputWrapper attr={"Adjusted Balance"} value={newValue} size={"lg"} />
                <InputWrapper attr={addValue ? "Add Value" : "Deduct Value"} value={deltaValue} size={"lg"} />
                <InputWrapper attr={"Edit Reason"} value={editReason} size={"lg"} />
                <div className="popup-form-row">
                    <SuiButton onClick={() => setConfirmPage(false)}>Back</SuiButton>
                    <SuiButton buttonColor={"secondary"} onClick={handleProceed}>Confirm</SuiButton>
                </div>
            </div>
        )
    }

    return (
        <div className='popup-wrapper'>
            {
                !authSuccess ?
                    <AuthCodeHelper title="Admin Authentication" btnName="Confirm" permission={WALLET_PERMISSION.BALANCE_EDIT} callback={handleAuthCode} />
                    :
                    (
                        confirmPage ?
                            <ConfirmInfo />
                            :
                            <div className="popup-content">
                                <h4>{"Adjust Ad Credit"}</h4>
                                <div className='popup-form-row'>
                                    <IconButton color={addValue ? "secondary" : "default"}
                                        onClick={handleAddValue}>
                                        <Icon>add</Icon>
                                    </IconButton>
                                    <IconButton color={addValue ? "default" : "secondary"}
                                        onClick={handleDeductValue}>
                                        <Icon>remove</Icon>
                                    </IconButton>
                                    <TextField
                                        value={showDeltaValue}
                                        onChange={handleDeltaValue}
                                        error={numError}
                                    //defaultValue={0}
                                    />
                                    <div className="popup-subtitle">{"USDT"}</div>
                                </div>

                                <div>
                                    <div className="popup-subtitle bold">Current Available Balance</div>
                                    <div className="popup-form-row">
                                        <InputWrapper attr="" value={oldValue} />
                                        <div className="popup-subtitle">{"USDT"}</div>
                                    </div>

                                </div>
                                <div>
                                    <div className="popup-subtitle bold">Adjusted Available Balance</div>
                                    <div className="popup-form-row">
                                        <InputWrapper attr="" value={newValue} />
                                        <div className="popup-subtitle">{"USDT"}</div>
                                    </div>
                                    {
                                        negNewValue ?
                                            <InputLabel error sx={{ fontSize: 12 }}>Adjusted Balance must be positive!</InputLabel>
                                            : <></>
                                    }

                                </div>
                                <InputWrapper attr="Edit Reason(Optional)" value={editReason} onChange={(e) => { setEditReason(e.target.value) }} disable={false} size="col" />
                                <div className="flex-end">
                                    <SuiButton buttonColor={"secondary"} onClick={handleProceed}>Proceed</SuiButton>
                                </div>
                            </div>
                    )
            }
        </div>
    );
}