import className from "./style";

const offerstatus = [
    {
      label: "On",
      value: 1,
      color: "success",
      class: className.success,
      param: {
        name: "status",
        value: "On"
      },
      count: 0
    },
    {
      label: "Deleted",
      value: 2,
      color: "error",
      class: className.error,
      param: {
        name: "status",
        value: "Deleted"
      },
      count: 0
    },
    {
      label: "Blocked",
      value: 3,
      color: "warning",
      class: className.warning,
      param: {
        name: "status",
        value: "Blocked"
      },
      count: 0
    },
    {
      label: "Off",
      value: 3,
      color: "default",
      class: className.default,
      param: {
        name: "status",
        value: "Off"
      },
      count: 0
    }
];

export default offerstatus;