import { Card, CardContent, CircularProgress, Grid } from "@mui/material";
import OrderApi from 'api/analysis/order';
import Popup from "assets/theme/components/popup";
import DraggableCardChart from 'components/Custom/card_chart';
import { constant } from 'config/constant';
import { addDays } from 'date-fns';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import ChartMaker from 'util/ChartMaker';
import { chartType, simplePieOptions, singleAxisBarOptions, singleAxisLineOptions, stackedBarOptions } from 'util/ChartMaker/constant';
import { DateFormatUtils } from 'util/index';
import './style.css';

export default function OrderTab() {
    let testMode = constant.DEBUG;
    // Pop Up Page
    const [page, setPage] = useState(0);
    const [openPopUp, setOpenPopup] = useState(false);
    const [popUpName, setPopUpName] = useState("");

    const [state, setState] = useState([
        {
          startDate: new Date(),
          endDate: addDays(new Date(), 7),
          key: 'selection'
        }
    ]);

    const [refundSum, setRefundSum] = useState([]);
    const [count, setCount] = useState([]);
    const [cryptoCount, setCryptoCount] = useState([]);
    const [commission, setCommission] = useState([]);
    const [priceAvg, setPriceAvg] = useState([]);
    const [loading, setLoading] = useState(true);

    const crypto_type_value = ["BTC", "ETH", "USDC", "USDT"];
    const status = ["已完成"];
    const order_type = ["Online", "Offline"];
    const transfer_status_type_value = ["transfer", "exchange"];
    const transactions_type_value = ["deposit","withdraw"];

    const [timeUnit, setTimeUnit] = useState([]);
    const handleTimeUnitChange = (title, value) => {
        setTimeUnit(prevState => ({ ...prevState, [title]: value }));
    }

    const handlePopUp = (pName) => {
        setPopUpName(pName);
        setOpenPopup(true);
        setPage(1);
    };

    const fetchRefundSum = async (startDate, endDate) => {
        await OrderApi.refundSum({
            "date1": startDate, 
            "date2": endDate
        })
          .then((res) => {
            console.log(res.data.data);
            setRefundSum(res.data.data);
          })
          .catch((err) => {
            //console.log(err);
          });
    };

    const fetchCount = async (startDate, endDate) => {
        await OrderApi.count({
            "date1": startDate, 
            "date2": endDate
        })
          .then((res) => {
            console.log(res.data.data);
            setCount(res.data.data);
          })
          .catch((err) => {
            //console.log(err);
          });
    };

    const fetchCryptoCount = async (startDate, endDate) => {
        await OrderApi.cryptoCount({
            "date1": startDate, 
            "date2": endDate
        })
            .then((res) => {
                console.log(res.data.data);
                setCryptoCount(res.data.data);
            })
            .catch((err) => {
                //console.log(err);
            });
    };

    const fetchCommission = async (startDate, endDate) => {
        await OrderApi.commission({
            "date1": startDate, 
            "date2": endDate
        })
            .then((res) => {
                console.log(res.data.data);
                setCommission(res.data.data);
            })
            .catch((err) => {
                //console.log(err);
            });
    };

    const fetchPriceAvg = async (startDate, endDate) => {
        await OrderApi.priceAvg({
            "date1": startDate, 
            "date2": endDate
        })
            .then((res) => {
                console.log(res.data.data);
                setPriceAvg(res.data.data);
            })
            .catch((err) => {
                //console.log(err);
            });
    };

    const fetchAllData = (startDate, endDate) => {
        if(startDate && endDate){
            fetchRefundSum(startDate, endDate);
            fetchCount(startDate, endDate);
            fetchCryptoCount(startDate, endDate);
            fetchCommission(startDate, endDate);
            fetchPriceAvg(startDate, endDate);
            setLoading(false);
        } else {
            fetchRefundSum();
            fetchCount();
            fetchCryptoCount();
            fetchCommission();
            fetchPriceAvg();
            setLoading(false);
        }
    }

    const handleDatePickerSelected = (date) => {
        //console.log(date);
        setState([date])

        let startDate = DateFormatUtils(date.startDate);
        let endDate = DateFormatUtils(date.endDate);

        //console.log(startDate);
        //console.log(endDate);

        setLoading(true);
        fetchAllData(startDate,endDate)
    }

    useEffect(() => {
        if(refundSum.length < 1 && 
            count.length < 1 && 
            cryptoCount.length < 1 && 
            commission.length < 1 &&
            priceAvg.length < 1)
        {
            fetchAllData();
        }
    }, []);

    return (
        <>
            {/* "Pop Up" */}
            { 
                page > 0 ?
                <Popup
                    openPopup={openPopUp}
                    setOpenPopup={() => { setOpenPopup(false) }}>

                    <DateRangePicker
                        onChange={item => handleDatePickerSelected(item.selection)}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        ranges={state}
                        direction="horizontal"
                        />
                </Popup>
                : <></>
            }

            <div className="tab-wrapper">
                {
                    loading ?
                        <CircularProgress />
                        : <>
                            <Grid container>
                                <Grid item xs={9} sm={9} md={9} xl={9}></Grid>
                                <Grid item xs={3} sm={3} md={3} xl={3}>
                                    <div onClick={() => handlePopUp("DateRange")}>
                                        <Card>
                                            <CardContent className='text-sm date_style text-center'>
                                                { moment(state[0].startDate).format("MMM Do, YYYY") + " - " + moment(state[0].endDate).format("MMM Do, YYYY") }
                                            </CardContent>
                                        </Card>
                                    </div>
                                </Grid>
                            </Grid>
                            
                            <Grid container spacing={2}>
                                <DraggableCardChart title="Daily Refund Amount" setTimeUnit={handleTimeUnitChange} tipsTitle="The amount of daily refund record" tipsContent="Amount of order record by refund_status(退款成功) and grouped by crypto type">
                                    <ChartMaker yLabel="crypto_type" type={chartType.Line} xKeyName="updated_at_date" yKeyName="crypto_type" valueKeyName="refund_sum" typeValues={crypto_type_value} data={refundSum} options={singleAxisLineOptions({ unit: timeUnit["Refund Sum"] })}/>
                                </DraggableCardChart>

                                <DraggableCardChart title="Daily Transaction Count" setTimeUnit={handleTimeUnitChange} tipsTitle="The number of transaction" tipsContent="Count of transaction record which status is finished">
                                    <ChartMaker yLabel="crypto_type" type={chartType.Line} xKeyName="updated_at_date" yKeyName="status" valueKeyName="orders_count" typeValues={status} data={count} options={singleAxisLineOptions({ unit: timeUnit["Count"] })}/>
                                </DraggableCardChart>

                                <DraggableCardChart title="Crypto Type" size={{xs:12, sm:4, md:4, xl:4}} showTimeUnit={false}>
                                    <ChartMaker type={chartType.Pie} typeKeyName="crypto_type" valueKeyName="crypto_count" data={cryptoCount} options={simplePieOptions}/>
                                </DraggableCardChart>

                                <DraggableCardChart title="Daily commission of transaction" showTimeUnit={false} tipsTitle="The total commission of daily orders" tipsContent="Count the sum of commission of finished orders and grouped by crypto type">
                                    <ChartMaker yLabel="crypto_type" type={chartType.Bar} xKeyName="created_at_date" yKeyName="crypto_type" valueKeyName="price" typeValues={crypto_type_value} data={commission} options={stackedBarOptions}/>
                                </DraggableCardChart> 

                                <DraggableCardChart title="Daily average transaction amount" showTimeUnit={false} tipsTitle="The average of transaction amount" tipsContent="Count the average price of finished orders and grouped by order type(online/ offline) and crypto type">
                                    <ChartMaker yLabel="crypto_type" type={chartType.Bar} xLabel={crypto_type_value} yKeyName="order_type" xKeyName="crypto_type" valueKeyName="price__avg" typeValues={order_type} data={priceAvg} options={singleAxisBarOptions({ indexAxis: "x" })}/>
                                </DraggableCardChart>
                            </Grid>
                        </>
                }
            </div>
        </>
    );
}