import AddIcon from "@mui/icons-material/Add";
import { CircularProgress, Grid } from "@mui/material";
import GeneralApi from "api/general";
import { InputWrapper } from "components/Custom";
import { PageController } from "components/Custom/custom_container";
import { SuiButton } from "components/Default";
import { GENERAL_PERMISSION } from 'config/permission';
import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import AuthCodeHelper from "util/AuthCodeHelper";

export default function CurrentDepositTab() {
    const alert = useAlert();

    const [page, setPage] = useState(1);
    const [record, setRecord] = useState([]);
    const [loading, setLoading] = useState(true);
    const [inputs, setInputs] = useState([]);
    const [savingPlanData, setSavingPlanData] = useState([]);
    const [auth, setAuth] = useState(false);

    const handleChange = e => setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));

    const handleAuthCode = (resp) => {
        if (resp) {
            if (resp.isAuth) {
                setAuth(true);
                setPage(1);
            } else {
                alert.error(resp.message);
            }
        }
        else {
            alert.error(ERROR.callback);
        }
    }

    const actionEdit = e => {
        let newData = savingPlanData;
        newData.push({
            question: "",
            optionA: "",
            optionB: "",
            optionC: "",
            optionD: "",
            correctOption: "",
            level: ""
        });
        setCount(count + 1);
        setNewQuestion(new_question);
    }

    async function updateSavingPlan(_refBonus, _BTC, _ETH, _USDC, _USDT) {
        if (auth) {
            await GeneralApi.editSavingPlan({
                referral_bonus_percentage: _refBonus,
                fixed_referral_bonus_percentage: savingPlanData.fixed_saving_plan_referral_bonus_percentage,
                saving_plan_btc: _BTC,
                saving_plan_eth: _ETH,
                saving_plan_usdc: _USDC,
                saving_plan_usdt: _USDT
            })
                .then((res) => {
                    if (res.status) {
                        alert.success("Edit data Successful");
                    }
                })
                .catch((err) => {
                    alert.error(err.message);
                });
        } else {
            setPage(0);
        }
    }


    const fetchSavingPlanData = async () => {

        await GeneralApi.list()
            .then((res) => {
                if (res.status === 200) {
                    setSavingPlanData(res.data.data.saving_plans);
                }
            })
            .catch((err) => {
                console.log(err);
                alert.error(err.message);
            });
    };


    useEffect(() => {
        setLoading(false);
        fetchSavingPlanData();
    }, [])

    return (
        <div className="tab-wrapper">
            {
                loading ?
                    <CircularProgress />
                    : <>
                        <PageController targetPage={0} currentPage={page} CASE="">
                            <AuthCodeHelper title="Admin Authentication" btnName="Confirm" permission={GENERAL_PERMISSION.EDIT} callback={handleAuthCode} />
                        </PageController>
                        <PageController targetPage={1} currentPage={page} CASE="">
                            <InputWrapper attr="Referral Bonus Percentage" name="refBonusPercent" value={inputs["refBonusPercent"] ?? savingPlanData.saving_plan_referral_bonus_percentage} size="col" onChange={handleChange} disable={false} />
                            <InputWrapper attr="BTC" name="btc" value={inputs["btc"] ?? savingPlanData.saving_plan_btc} size="m" onChange={handleChange} disable={false} />
                            <InputWrapper attr="ETH" name="eth" value={inputs["eth"] ?? savingPlanData.saving_plan_eth} size="m" onChange={handleChange} disable={false} />
                            <InputWrapper attr="USDC" name="usdc" value={inputs["usdc"] ?? savingPlanData.saving_plan_usdc} size="m" onChange={handleChange} disable={false} />
                            <InputWrapper attr="USDT" name="usdt" value={inputs["usdt"] ?? savingPlanData.saving_plan_usdt} size="m" onChange={handleChange} disable={false} />

                            <Grid container alignItems="center" spacing={1}>
                                <Grid item xs={6} sm={6} md={6} xl={6}>
                                    <SuiButton variant="outlined" buttonColor="secondary" onClick={(e) => actionEdit(e.value)} style={{ width: '100%' }}>
                                        <AddIcon />
                                    </SuiButton>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} xl={6}>
                                    <SuiButton buttonColor="secondary" onClick={() =>
                                        updateSavingPlan(
                                            inputs["refBonusPercent"] ?? savingPlanData.saving_plan_referral_bonus_percentage,
                                            inputs["btc"] ?? savingPlanData.saving_plan_btc,
                                            inputs["eth"] ?? savingPlanData.saving_plan_eth,
                                            inputs["usdc"] ?? savingPlanData.saving_plan_usdc,
                                            inputs["usdt"] ?? savingPlanData.saving_plan_usdt
                                        )} style={{ width: '100%' }}>
                                        Update All
                                    </SuiButton>
                                </Grid>
                            </Grid>
                        </PageController>

                    </>
            }

        </div>
    );
}