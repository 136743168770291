// Soft UI Dashboard React components
import { Grid } from "@material-ui/core";
import { Pagination } from "@mui/material";
import Card from "@mui/material/Card";
import Popup from "assets/theme/components/popup";
import { DataTable, Loader } from "components/Custom";
import { SuiBox, SuiButton, SuiTypography } from "components/Default";
import Footer from "components/examples/Footer";
import DashboardLayout from "components/examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/examples/Navbars/DashboardNavbar";
import WalletWelcomeGiftGateway from "entities/walletWelcomeGiftGateway";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Link } from 'react-router-dom';
import { NullFormatUtils } from "util/index";
import CustomPopup from "./components/custom_pop_up";

let welcomeGiftGateway = new WalletWelcomeGiftGateway();

export default function WalletWelcomeGift() {
  const alert = useAlert();

  const [rows, setRows] = useState([]);
  const [filterRows, setFilterRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const [tbPage, setTbPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const [quota, setQuota] = useState(0);
  const [count, setCount] = useState(0);

  const [page, setPage] = useState(0);
  const [openPopUp, setOpenPopup] = useState(false);
  const [popUpName, setPopUpName] = useState();
  const [currentUser, setCurrentUser] = useState();
  const handlePopUp = (pName, id) => {
    setPopUpName(pName);
    setCurrentUser(id);
    setOpenPopup(true);
    setPage(1);
  };

  const handlePageChange = (event, value) => {
    if (value <= totalPage) {
      setTbPage(value);
      fetchAllData(value);
    }
  };

  const fetchAllData = async (page) => {
    setLoading(true);

    await welcomeGiftGateway.getAllData({
      page: page ?? tbPage
    })
      .then((res) => {
        if (res) {
          setRows(res.data);
          setFilterRows(res.data);
          setQuota(res.quota);
          setCount(res.count);
          setTotalPage(res.last_page);
          setLoading(false);
        }
      })
      .catch((err) => {
        alert.error(err.message);
      })
  };

  const dt_columns = [
    {
      headerName: 'Welcome Gifts Record ID',
      field: 'id',
      renderCell: ({ row }) => NullFormatUtils(row.id),
      sortable: true,
      width: 300
    },
    {
      headerName: 'User ID',
      field: 'userID',
      renderCell: ({ row }) => row.account_id ? <Link to="#" className="text-secondary" onClick={() => { handlePopUp("AccountDetailsPopup", row.account_id) }}>{row.account_id}</Link> : "-",
      sortable: true,
      width: 150
    },
    {
      headerName: 'Collect At',
      field: 'collectAt',
      renderCell: ({ row }) => NullFormatUtils(row.redeemed_welcome_gift_at),
      sortable: true,
      width: 150
    }

  ]

  useEffect(() => {
    if (rows.length < 1) {
      fetchAllData();
    }
  }, []);

  return (
    <div>
      {
        page > 0 ?
          <Popup
            openPopup={openPopUp}
            setOpenPopup={() => { setOpenPopup(false) }}
          >
            <CustomPopup name={popUpName} id={currentUser}></CustomPopup>
          </Popup> : <></>
      }
      <DashboardLayout>
        <DashboardNavbar />

        <Loader loading={loading}>
          <SuiButton
            buttonColor="secondary"
            onClick={() => { fetchAllData() }}>
            Refresh
          </SuiButton>

          <SuiBox py={3}>
            <SuiBox mb={3}>
              <Card>
                <Grid container alignItems="center">
                  <Grid item xs={6} sm={6} md={6} xl={6}>
                    <SuiTypography
                      fontWeight="bold"
                      textTransform="capitalize"
                      variant="h6"
                      noWrap
                      p={3}>
                      Total Quotas: {quota + count}
                    </SuiTypography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} xl={6}>
                    <SuiTypography
                      fontWeight="bold"
                      textTransform="capitalize"
                      variant="h6"
                      noWrap
                      p={3}>
                      Remaining Quotas: {quota}
                    </SuiTypography>
                  </Grid>
                </Grid>
              </Card>
            </SuiBox>

            <SuiBox mb={3}>
              <Card>
                <DataTable dt_columns={dt_columns} dt_rows={rows} />
                <Pagination color={"secondary"} count={totalPage} page={tbPage} onChange={handlePageChange} />
              </Card>
            </SuiBox>
          </SuiBox>
        </Loader >

        
      </DashboardLayout>
    </div>
  );
}