/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React components
import { CachedOutlined } from "@material-ui/icons";
import AddIcon from "@mui/icons-material/Add";
import { Grid, Link, Pagination } from "@mui/material";
import Card from "@mui/material/Card";
import GeneralApi from "api/general";
import Popup from "assets/theme/components/popup";
import { DataTable, Loader } from "components/Custom";
import table_columns_size from "components/Custom/dataTable/column_size";
import SuiBox from "components/Default/SuiBox";
import SuiButton from "components/Default/SuiButton";
import DashboardLayout from "components/examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { NullFormatUtils } from "util/index";
import CustomPopup from "./components/custom_pop_up";

const sample = [
  {
    id: 1,
    user_id: 1,
    createAt: "YYYY/MM/DD",
    endDate: "YYYY/MM/DD",
    days: 1,
    APR: "0.5%",
    crypto_type: "USDT",
    saving_amount: 100,
    expected_return: 200,
    status: "進行中"
  }
];

export default function ExtraRewardSavingPlan() {
  const alert = useAlert();
  const [page, setPage] = useState(0);
  const [openPopUp, setOpenPopup] = useState(false);
  const [popUpName, setPopUpName] = useState("");
  const [userID, setUserID] = useState("");
  const [loading, setLoading] = useState(false);
  const [filterRows, setFilterRows] = useState(sample);
  const [tbPage, setTbPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [extraReward, setExtraReward] = useState([]);
  const [eventName, setEventName] = useState("Event Name");
  const [eventId, setEventId] = useState(0);

  const handlePopUp = (pName, id, page) => {
    setPopUpName(pName, id);
    setOpenPopup(true);
    setUserID(id);
    setPage(page);
  }

  const handlePageChange = (event, value) => {
    if (value <= totalPage) {
      setTbPage(value);
      fetchAllData(value);
    }
  };

  const handleChangePageByEventId = (id, event_name) => {
    setEventName(event_name);
    setEventId(id);
    fetchEventDetails(id);
    setPage(2);
  }

  const fetchEventDetails = async (id) => {
    await GeneralApi.getOffersHasTag()
      .then((res) => {
        if (res.status === 200) {
          const filterTags = res.data.data.data.filter((d, index) => {
            const tag = JSON.parse(d?.tags);
            if(parseInt(tag?.offer_id) === id){
              return d;
            }
          })
          setFilterRows(filterTags);
        }
      })
      .catch((err) => {
        alert.error(err.message);
      });
  }

  const fetchAllData = async () => {
    await GeneralApi.getExtraReward()
      .then((res) => {
        if (res.status === 200) {
          setExtraReward(res.data.data);
        }
      })
      .catch((err) => {
        alert.error(err.message);
      });
  }

  useEffect(() => {
    if (extraReward.length < 1) {
      fetchAllData();
    }
  }, []);

  let status = ["進行中", "已完成"];
  const dt_columns = [
    {
      headerName: 'Reward Plan Record ID',
      field: 'id',
      width: table_columns_size.m,
      renderCell: ({ row }) => row.id ? <Link to="#" className="text-secondary" onClick={() => handlePopUp("EventDetails", eventId, 3)}>{row.id}</Link> : "-",
      sortable: true,
    },
    {
      headerName: 'User ID',
      field: 'user_id',
      width: table_columns_size.l,
      renderCell: ({ row }) => row.user?.AccountID ? <Link to="#" className="text-secondary" onClick={() => handlePopUp("RewardDetails", row.id, 3)}>{row.user?.AccountID}</Link> : "-",
      sortable: true,
    },
    {
      headerName: 'CreateDate',
      field: 'createAt',
      width: table_columns_size.m,
      renderCell: ({ row }) => NullFormatUtils(row.created_at),
      sortable: true,
    },
    {
      headerName: 'EndDate',
      field: 'endDate',
      width: table_columns_size.m,
      renderCell: ({ row }) => NullFormatUtils(row.redeemed_at),
      sortable: true,
    },
    {
      headerName: 'Days',
      field: 'days',
      width: table_columns_size.m,
      renderCell: ({ row }) => NullFormatUtils(row.days),
      sortable: true,
    },
    {
      headerName: 'APR',
      field: 'apr',
      width: table_columns_size.m,
      renderCell: ({ row }) => NullFormatUtils(row.annual_percentage_rate),
      sortable: true,
    },
    {
      headerName: 'Crypto Type',
      field: 'crypto_type',
      width: table_columns_size.m,
      renderCell: ({ row }) => NullFormatUtils(row.crypto_type),
      sortable: true,
    },
    {
      headerName: 'Saving Amount',
      field: 'saving_amount',
      width: table_columns_size.m,
      renderCell: ({ row }) => NullFormatUtils(row.saving_amount),
      sortable: true,
    },
    {
      headerName: 'Expected Return',
      field: 'expected_return',
      width: table_columns_size.m,
      renderCell: ({ row }) => NullFormatUtils(row.expected_return),
      sortable: true,
    },
    {
      headerName: 'Status',
      field: 'status',
      width: table_columns_size.m,
      renderCell: ({ row }) => row.status,
      sortable: true,
    }
  ];

  const EventListPage = () => {
    return (
      <>
        <SuiButton
          style={{ marginRight: '1rem' }}
          buttonColor="secondary"
          onClick={() => { fetchSavingPlanData() }}>
          <CachedOutlined />
          Refresh
        </SuiButton>

        <SuiButton
          buttonColor="error"
          onClick={() => handlePopUp("ExtraReward", 0, 1)}>
          <AddIcon />
          Create New Campaign
        </SuiButton>

        {
          extraReward.length >= 1 ?
            extraReward.map((d, index) => {
              return (
                <div onClick={() => handleChangePageByEventId(d?.id, d?.title)} key={index}>
                  <SuiBox my={1}>
                    <Card>
                      <SuiBox p={3}>
                        <Grid container>
                          <Grid item xs={6} lg={3} xl={3}>
                            <h5>CreateAt {d?.createAt}</h5>
                          </Grid>
                          <Grid item xs={6} lg={6} xl={6}>
                            <h5>{d?.title}</h5>
                          </Grid>
                          <Grid item xs={6} lg={3} xl={3}>
                            <h5>進行中</h5>
                          </Grid>
                        </Grid>
                      </SuiBox>
                    </Card>
                  </SuiBox>
                </div>
              );
            })
            : <></>
        }
      </>
    );
  }

  const RewardRecordPage = ({ eventName }) => {
    return (
      <>
        <SuiButton
          style={{ marginRight: '1rem' }}
          buttonColor="secondary"
          onClick={() => { fetchSavingPlanData() }}>
          <CachedOutlined />
          Refresh
        </SuiButton>

        <SuiButton
          style={{ marginRight: '1rem' }}
          buttonColor="secondary"
          onClick={() => { setPage(0) }}>
          Back
        </SuiButton>

        <SuiBox py={3}>
          <SuiBox mb={3}>
            <h5>{eventName}</h5>
            <Card>
              <DataTable dt_columns={dt_columns} dt_rows={filterRows} />
              <Pagination color={"secondary"} count={totalPage} page={tbPage} onChange={handlePageChange} />
            </Card>
          </SuiBox>
        </SuiBox>
      </>
    );
  }

  return (
    <div>
      {
        page === 1 || page === 3 ?
          <Popup
            openPopup={openPopUp}
            setOpenPopup={() => { setOpenPopup(false) }}
          >
            <CustomPopup name={popUpName} id={userID}></CustomPopup>
          </Popup> : <></>
      }
      <DashboardLayout>
        <DashboardNavbar />
        <Loader loading={loading}>

          {
            page === 0 || page === 1 ?
              <EventListPage />
              : page === 2 || page == 3 ?
                <RewardRecordPage eventName={eventName} />
                : <></>
          }

        </Loader>
        
      </DashboardLayout>
    </div>
  );
}
