import { Grid } from "@mui/material";
import "components/Custom/common.css";

function FormContent({ xs, sm, md, xl, children }){
	return (
		<Grid item xs={xs} sm={sm} md={md} xl={xl} className="popup-content">
            {children}
		</Grid>
	);
}

export default FormContent;