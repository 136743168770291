import SuiButton from 'components/Default/SuiButton';
import "./styles.css";

export default function Options ({ actionA, actionA_Name, actionB, actionB_Name }){
    return(
        <div className="popup-wrapper">
            <div className="popup-content">
            <h4>Menu</h4>
            <h4>For Customer Service</h4>
                <SuiButton onClick={actionA}>{actionA_Name}</SuiButton>
                <h4>For Authorized staff only</h4>
                <SuiButton buttonColor="secondary" onClick={actionB}>{actionB_Name}</SuiButton>
            </div>
        </div>
    );
};