import { Grid } from "@mui/material";

function Form({ children }){
	return (
		<Grid container spacing={2}>
            {children}
		</Grid>
	);
}

export default Form;