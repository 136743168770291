import { Card, CardContent, CircularProgress, Grid } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import OfferApi from 'api/analysis/offer';
import Popup from "assets/theme/components/popup";
import DraggableCardChart from 'components/Custom/card_chart';
import { constant } from 'config/constant';
import { addDays } from 'date-fns';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateFormatUtils, NullFormatUtils } from 'util/index';
import './style.css';
import Table from "components/examples/Table";
import table_columns_size from "components/Custom/dataTable/column_size";

export default function OfferTab() {
    let testMode = constant.DEBUG;
    // Pop Up Page
    const [page, setPage] = useState(0);
    const [openPopUp, setOpenPopup] = useState(false);
    const [popUpName, setPopUpName] = useState("");

    const [state, setState] = useState([
        {
          startDate: new Date(),
          endDate: addDays(new Date(), 7),
          key: 'selection'
        }
    ]);

    const [numbersOfLikes, setNumbersOfLikes] = useState([]);
    const [loading, setLoading] = useState(true);

    const handlePopUp = (pName) => {
        setPopUpName(pName);
        setOpenPopup(true);
        setPage(1);
    };

    const [timeUnit, setTimeUnit] = useState([]);
    const handleTimeUnitChange = (title, value) => {
        setTimeUnit(prevState => ({ ...prevState, [title]: value }));
    }

    const fetchNumbersOfLikes = async (startDate, endDate) => {
        await OfferApi.mostLikes({
            "date1": startDate, 
            "date2": endDate
        })
          .then((res) => {
            console.log(res.data.data);
            const give_id_to_dataset = res.data.data.map((data, index) => ({ id: index, ...data}))
            setNumbersOfLikes(give_id_to_dataset);
          })
          .catch((err) => {
            //console.log(err);
          });
    };

    const fetchAllData = (startDate, endDate) => {
        if(startDate && endDate){
            fetchNumbersOfLikes(startDate, endDate);
            setLoading(false);
        } else {
            fetchNumbersOfLikes();
            setLoading(false);
        }
    }

    const handleDatePickerSelected = (date) => {
        //console.log(date);
        setState([date])

        let startDate = DateFormatUtils(date.startDate);
        let endDate = DateFormatUtils(date.endDate);

        //console.log(startDate);
        //console.log(endDate);

        setLoading(true);
        fetchAllData(startDate,endDate)
    }

    useEffect(() => {
        if(numbersOfLikes.length < 1)
        {
            fetchAllData();
        }
    }, []);

    const dt_columns = [
        { name: 'offer_id', align: 'left' },
        { name: 'Numbers_of_likes', align: 'center' }
      ];

    return (
        <>
            {/* "Pop Up" */}
            { 
                page > 0 ?
                <Popup
                    openPopup={openPopUp}
                    setOpenPopup={() => { setOpenPopup(false) }}>

                    <DateRangePicker
                        onChange={item => handleDatePickerSelected(item.selection)}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        ranges={state}
                        direction="horizontal"
                        />
                </Popup>
                : <></>
            }

            <div className="tab-wrapper">
                {
                    loading ?
                        <CircularProgress />
                        : <>
                            <Grid container>
                                <Grid item xs={9} sm={9} md={9} xl={9}></Grid>
                                <Grid item xs={3} sm={3} md={3} xl={3}>
                                    <div onClick={() => handlePopUp("DateRange")}>
                                        <Card>
                                            <CardContent className='text-sm date_style text-center'>
                                                { moment(state[0].startDate).format("MMM Do, YYYY") + " - " + moment(state[0].endDate).format("MMM Do, YYYY") }
                                            </CardContent>
                                        </Card>
                                    </div>
                                </Grid>
                            </Grid>
                            
                            <Grid container spacing={2}>
                                <DraggableCardChart title="Numbers of likes" showTimeUnit={false}>
                                    {/* <div style={{ height: 400, width: '100%' }}>
                                        <DataGrid
                                            rows={numbersOfLikes}
                                            columns={dt_columns}
                                            pageSize={5}
                                            rowsPerPageOptions={[5]}
                                        />
                                    </div> */}
                                    <Table
                                        columns={dt_columns}
                                        rows={numbersOfLikes}
                                    />
                                </DraggableCardChart>
                            </Grid>
                        </>
                }
            </div>
        </>
    );
}