/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React components
import { Chip, Pagination } from "@mui/material";
import Card from "@mui/material/Card";
import Popup from "assets/theme/components/popup";
import { DataTable, Loader } from "components/Custom";
import { SuiBox } from "components/Default";
import DashboardLayout from "components/examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/examples/Navbars/DashboardNavbar";
import WalletWithdrawGateway from "entities/walletWithdrawGateway";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { HTMLContentUtils } from "util/HTMLContentUtils";
import { NullFormatUtils, NumberFormatUtils } from "util/index";
import status from "util/LabelFilterPanel/base";
import { handleFilterByKeyword } from "util/SearchFunction";
import CustomPopup from "./components/custom_pop_up";
import LabelFilterPanel from "util/LabelFilterPanel";

let walletWithdrawGateway = new WalletWithdrawGateway();

export default function WalletWithdraw() {
  //pop up pages
  const [page, setPage] = useState(0);
  const [openPopUp, setOpenPopup] = useState(false);
  const [popUpName, setPopUpName] = useState("");
  const [currentUser, setCurrentUser] = useState("");
  const handlePopUp = (pName, id) => {
    setPopUpName(pName);
    setOpenPopup(true);
    setPage(1);
    if (id) setCurrentUser(id);
  };

  const [rows, setRows] = useState([]);
  const [filterRows, setFilterRows] = useState([]);
  const [statusStatement, setStatusStatement] = useState(null);
  const [allStatusWithCount, setAllStatusWithCount] = useState([]);
  const [currentFilter, setCurrentFilter] = useState(0); // # of chip label filter
  const [loading, setLoading] = useState(true);

  const [tbPage, setTbPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const handlePageChange = (event, value) => {
    if (value <= totalPage) {
      setTbPage(value);
      fetchAllData(value);
    }
  };

  const fetchAllData = async (page, _statusStatement) => {
    setLoading(true);

    const data_length = await walletWithdrawGateway.getAllData({
      page: page ?? tbPage,
      statusStatement: _statusStatement ?? (statusStatement ?? null)
    })
      .then((res) => {
        if (res) {
          if (page === 0) {
            return res.total;
          } else {
            setRows(res.data);
            setFilterRows(res.data);
            setTotalPage(res.last_page);
            setLoading(false);
          }
        }
      }).catch((e) => {
        console.log(e);
      });

    if (page === 0) return data_length
  };

  const checkSearchKeyword = (SearchValue, rowData) => {
    if(SearchValue && rowData){
      //console.log(SearchValue);
      if(isNaN(rowData)){
        const new_rowData = rowData.replace(
          new RegExp(SearchValue, 'gi'),
          match => {
            if (match) {
              return `<mark style="background: #8D8DAA; color: white;">${match}</mark>`;
            }
          }
        );
        return HTMLContentUtils(new_rowData);
      }
    }
    return rowData;
  };

  const handleChangeRows = async (statusParam, statusValue) => {
    setCurrentFilter(statusValue);
    fetchAllData(1, `${statusParam}=${statusValue}`)
  };

  const handleClearAndRefresh = () => {
    setCurrentFilter(0);
    setStatusStatement(null);
    setRows([]);
  };

  const fetchStatusCount = () => {
    let allStatus = [];
    status?.wallet?.map((status, key) => {
      let _status = status;
      fetchAllData(0, `${status?.param?.name}=${status?.param?.value}`)
        .then((res) => {
          _status.count = res;
        })
      allStatus.push(_status);
    })
    setStatusStatement(null);
    setAllStatusWithCount(allStatus);
  }

  useEffect(() => {
    if (rows.length < 1) {
      fetchAllData();
      fetchStatusCount();
    }
  }, []);

  const dt_columns = [
    {
      headerName: 'History ID',
      field: 'id',
      renderCell: ({ row }) => row.id ? <Link to="#" className="text-secondary" onClick={() => handlePopUp("WithdrawDetailsPopup", row.id)}>{checkSearchKeyword(row.SearchValue, NullFormatUtils(row.id))}</Link> : "-",
      sortable: true,
      width: 200
    },
    {
      headerName: 'User ID',
      field: 'account_id',
      renderCell: ({ row }) => row.account_id ? <Link to="#" className="text-secondary" onClick={() => handlePopUp("AccountDetailsPopup", row.account_id)}>{checkSearchKeyword(row.SearchValue, NullFormatUtils(row.account_id))}</Link> : "-",
      sortable: true,
      width: 200,
    },
    {
      headerName: 'Create Date',
      field: 'createAt',
      renderCell: ({ row }) => row.createAt ? moment(row.createAt).format("YYYY-MM-DD HH:mm") : "-",
      sortable: true,
      width: 300
    },
    {
      headerName: 'Crypto Type',
      field: 'cryptoType',
      renderCell: ({ row }) => checkSearchKeyword(row.SearchValue, NullFormatUtils(row.cryptoType)),
      sortable: true,
      width: 120
    },
    {
      headerName: 'Withdraw Fee',
      field: 'withdrawFee',
      renderCell: ({ row }) => row.withdrawFee ?? "-",
      sortable: true,
      width: 120
    },
    {
      headerName: 'Networks',
      field: 'networks',
      renderCell: ({ row }) => row.networks,
      sortable: true,
      width: 100
    },
    {
      headerName: 'Amount',
      field: 'amount',
      renderCell: ({ row }) => NumberFormatUtils(row.amount, 8),
      sortable: true,
      width: 150
    },
    {
      headerName: 'Status',
      field: 'status',
      renderCell: ({ row }) =>
        <Chip label={checkSearchKeyword(row.SearchValue, NullFormatUtils(row.status))}
          className={handleFilterByKeyword(status.wallet, "label", row.status)} />,
      sortable: true,
      width: 200
    }
  ];

  return (
    <div>
      {
        page > 0 ?
          <Popup
            openPopup={openPopUp}
            setOpenPopup={() => { setOpenPopup(false) }}
          >
            <CustomPopup name={popUpName} id={currentUser}></CustomPopup>
          </Popup> : <></>
      }
      <DashboardLayout>
        <DashboardNavbar />

        <Loader loading={loading}>
          <SuiBox p={0}>
            <LabelFilterPanel
              currentFilter={currentFilter}
              handleClearAndRefresh={handleClearAndRefresh}
              fetchAllData={fetchAllData}
              allStatus={allStatusWithCount}
              handleChangeRows={handleChangeRows}
            />
            <Card>
              <DataTable dt_columns={dt_columns} dt_rows={filterRows} />
              <Pagination color={"secondary"} count={totalPage} page={tbPage} onChange={handlePageChange} />
            </Card>
          </SuiBox>
        </Loader>

      </DashboardLayout>
    </div>
  );
}