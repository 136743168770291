/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useMemo, useState } from "react";

// react-router components
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

// jss components
//import { create } from "jss";

// jss-rtl components
//import rtl from "jss-rtl";

// @mui style components
//import { StylesProvider, jssPreset } from "@mui/styles";

// @mui material components
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";

// Soft UI Dashboard PRO React components
import SuiBox from "components/Default/SuiBox";

// Soft UI Dashboard PRO React example components
import Configurator from "components/examples/Configurator";
import Sidenav from "components/examples/Sidenav";

// Soft UI Dashboard PRO React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Soft UI Dashboard PRO React routes
import routes from "routes";

// Soft UI Dashboard PRO React contexts
import createCache from "@emotion/cache";

import { ProtectedRoute } from "./ProtectedRoute";
//import PageNotFound from "components/Page/page_not_found";
import { CacheProvider } from "@emotion/react";
import AdvsApi from "api/advs";
import UserApi from "api/user";
import WalletApi from "api/wallet";
import bithon_icon from "assets/images/bithon.png";
import { useAuth } from "auth-context/auth.context";
import { constant } from "config/constant";
import { useSoftUIController } from "context";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import rtlPlugin from "stylis-plugin-rtl";
import { DateFormatUtils } from "util/index";

export default function App() {
  const alert = useAlert();
  const history = useHistory();
  const { setUser } = useAuth();

  const [controller, dispatch] = useSoftUIController();
  const { direction, layout, openConfigurator } = controller;
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const [open, setOpen] = useState(true);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [prevX, setPrevX] = useState();
  const [prevY, setPrevY] = useState();
  const [notificationList, setNotificationList] = useState([]);

  let testMode = constant.DEBUG_MODE;

  const handleClick = () => {
    setOpen(!open);
  };

  // JSS presets for the rtl
  /* const jss = create({
    plugins: [...jssPreset().plugins, rtl()],
  }); */

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      prepend: true,
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => {
    dispatch({ type: "OPEN_CONFIGURATOR", value: !openConfigurator });
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const pushNotification = ({ title, body }) => {
    new Notification(title, {
      body: body,
      icon: bithon_icon
    });
  }

  const notificationCookie = ({ icon, title }) => {
    const now = DateFormatUtils(new Date(), "YYYY/MM/DD HH:mm:ss")

    let notification_old = notificationList;
    console.log(notification_old);
    notification_old.push({
      icon: icon,
      title: title,
      createAt: now
    });
    setNotificationList(notification_old);
    localStorage.setItem("notification", JSON.stringify(notification_old));
  }

  const notifyAdminListener = async () => {
    let accumulate_time = 1;

    const hasIdVerifyProcessing = await UserApi.getUserWithoutIDShopVerifyByPage({ statusStatement: `id_verification_status=Processing` })
      .then((res) => {
        if (res.status === 200) {
          return res.data.data.total;
        }
      })
      .catch((err) => {
        alert.error(err.message);
      })

    const hasShopVerifyProcessing = await UserApi.getUserWithoutIDShopVerifyByPage({ statusStatement: `shop_verification_status=Processing` })
      .then((res) => {
        if (res.status === 200) {
          return res.data.data.total;
        }
      })
      .catch((err) => {
        alert.error(err.message);
      })

    const hasAdv1Processing = await AdvsApi.getListByPosition({ position: "A1", statusStatement: `status=審核中` })
      .then((res) => {
        if (res.status === 200) {
          return res.data.data.total;
        }
      })
      .catch((err) => {
        alert.error(err.message);
      })

    const hasAdv2Processing = await AdvsApi.getListByPosition({ position: "A2", statusStatement: `status=審核中` })
      .then((res) => {
        if (res.status === 200) {
          return res.data.data.total;
        }
      })
      .catch((err) => {
        alert.error(err.message);
      })

    const hasWithdrawProcessing = await WalletApi.getWithDrawDetails({ statusStatement: `status=processing` })
      .then((res) => {
        if (res.status === 200) {
          return res.data.data.total;
        }
      })
      .catch((err) => {
        alert.error(err.message);
      })

    const hasTopupCompletedToday = await WalletApi.getDepositDetails({ statusStatement: `status=completed` })
      .then((res) => {
        if (res.status === 200) {
          return res.data.data.data.filter((d) => DateFormatUtils(d.created_at, "YYYY-MM-DD") === DateFormatUtils(new Date(), "YYYY-MM-DD")).length;
        }
      })
      .catch((err) => {
        alert.error(err.message);
      })
    
    if (hasIdVerifyProcessing >= 1) {
      accumulate_time = accumulate_time + 3
      notificationCookie({ icon: "PersonOutlineIcon", title: ["新的IdVerify等待Approve"]});
      setTimeout(() => pushNotification({ title: "New Id Verify", body: "Please check in admin dashboard" }), accumulate_time * 1000);
    }
    if (hasShopVerifyProcessing >= 1) {
      accumulate_time = accumulate_time + 3
      notificationCookie({ icon: "VerifiedUserIcon", title: ["新的ShopVerify等待Approve"]});
      setTimeout(() => pushNotification({ title: "New Shop Verify", body: "Please check in admin dashboard" }), accumulate_time * 1000);
    }
    if (hasAdv1Processing >= 1) {
      accumulate_time = accumulate_time + 3
      notificationCookie({ icon: "FeaturedVideoIcon", title: ["新的廣告A1等待審批"]});
      setTimeout(() => pushNotification({ title: "New advertisement A1", body: "Please check in admin dashboard" }), accumulate_time * 1000);
    }
    if (hasAdv2Processing >= 1) {
      accumulate_time = accumulate_time + 3
      notificationCookie({ icon: "FeaturedVideoIcon", title: ["新的廣告A2等待審批"]});
      setTimeout(() => pushNotification({ title: "New advertisement A2", body: "Please check in admin dashboard" }), accumulate_time * 1000);
    }
    if (hasWithdrawProcessing >= 1) {
      accumulate_time = accumulate_time + 3
      notificationCookie({ icon: "FileUploadIcon", title: ["New Withdraw"]});
      setTimeout(() => pushNotification({ title: "New Withdraw", body: "Please check in admin dashboard" }), accumulate_time * 1000);
    }
    if (hasTopupCompletedToday >= 1) {
      accumulate_time = accumulate_time + 3
      notificationCookie({ icon: "PaidIcon", title: ["New Topup"]});
      setTimeout(() => pushNotification({ title: "New Topup Completed today", body: "Please check in admin dashboard" }), accumulate_time * 1000);
    }
  }

  useEffect(() => {
    // notifyAdminListener();
  }, [])

  //Detect mouse move
  const [time, setTime] = useState(0);
  useEffect(() => {
    // window.addEventListener('beforeunload', function (e) {
    //   e.preventDefault();
    //   e.returnValue = '';
    //   localStorage.removeItem("user");
    // });

    const user = localStorage.getItem("user");
    if (user) {
      document.addEventListener('mousemove', (e) => {
        setTime(0);
      });
      setTimeout(() => {
        // console.log(time);
        if (time > 15) {
          history.push("/authentication/sign-out")
        }

        let t = time + 1;
        setTime(t);
      }, 1 * 60 * 1000);
    }
  });

  const getRoutes = (allRoutes) =>
    allRoutes.map((route, key) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        if (route.protected) {
          return <ProtectedRoute path={route.route} component={route.component} key={/*route.*/key} />;
        }
        return <Route exact path={route.route} component={route.component} key={/*route.*/key} />;
      }
      {/* <Route path="*" component={PageNotFound}/> */ }
      return null;
    });

  const configsButton = (
    <SuiBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.5rem"
      height="3.5rem"
      backgroundColor="white"
      boxShadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      customClass="cursor-pointer"
      onClick={handleConfiguratorOpen}
    >
      <Icon className=" text-dark" fontSize="default">
        settings
      </Icon>
    </SuiBox>
  );

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      {/* <StylesProvider jss={jss}> */}
      <ThemeProvider theme={themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav routes={routes} />
            <Configurator />
            {configsButton}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Switch>
          {getRoutes(routes)}
          {/* {
              queryString ?
                <Redirect from="*" to="users" />
                : <></>
            } */}
        </Switch>
      </ThemeProvider>
      {/* </StylesProvider> */}
    </CacheProvider>
  ) : (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav routes={routes} />
            <Configurator />
            {configsButton}
          </>
        )}

        {layout === "vr" && <Configurator />}
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="authentication/sign-in" />
        </Switch>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
