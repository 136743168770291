import { Grid } from "@mui/material";
import "components/Custom/common.css";

function Row({ children }){
    return (
        <Grid item className="popup-form-row">
            { children }
        </Grid>
    );
}

export default Row;