import { TextField } from '@mui/material';
import 'components/Custom/common.css';

function InputWrapper({ attr, value, disable, ref, width, size, onChange, placeholder, name, style, type }) {
    return (
        <div className={size === "sm" || size === "m" || size === "lg" || size === "xl" || size === "col" || size === "tips" ? "popup-input-wrapper-" + size : "popup-input-wrapper"} style={style}>
            {attr ? <label htmlFor="title" className="popup-subtitle left">{attr}</label> : <></>}
            <TextField
                className="right border"
                sx={{
                    maxWidth: width,
                    alignSelf: 'flex-end',
                    "& .css-dotx8v-MuiInputBase-input-MuiOutlinedInput-input":
                    {
                        height: "1.3rem",
                        minWidth: width ?? "100%",
                    }
                }}
                type={type}
                fullWidth={width ? false : true}
                disabled={disable ?? true}
                name={name ?? ""}
                value={value ?? " "}
                size="small"
                //readOnly
                onChange={onChange}
                placeholder={placeholder}
            />
        </div>
    );
}

export default InputWrapper;