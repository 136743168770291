import axios from "./index";

class UserApi {
  static userInfo = () => {
    return axios.get(`${base}`);
  };

  static getUserWithoutIDShopVerify = () => {
    return axios.get(`/beta${base}`);
  };

  static getReferralById = id => {
    return axios.get(`${base}/referral_info/${id}`);
  }

  static getUserWithoutIDShopVerifyByPage = ({ page, statusStatement, id_token, searchValue }) => {
    if (page && searchValue) {
      return axios.get(`/beta${base}?page=${page}&search=${searchValue}`, { headers: { "Authorization": `Bearer ${id_token}` } })
    }
    else if (page && !statusStatement) {
      return axios.get(`/beta${base}?page=${page}`, { headers: { "Authorization": `Bearer ${id_token}` } });
    }
    else if (!page && statusStatement) {
      return axios.get(`/beta${base}?${statusStatement}`, { headers: { "Authorization": `Bearer ${id_token}` } });
    }
    else if (page && statusStatement) {
      return axios.get(`/beta${base}?page=${page}&${statusStatement}`, { headers: { "Authorization": `Bearer ${id_token}` } });
    }
    throw "page and status not found";
  };

  static getShopsByPage = ({ page, searchValue }) => {
    if(searchValue){
      return axios.get(`/shops?page=${page}&search=${searchValue}`);
    }
    return axios.get(`/shops?page=${page}`);
  };

  static depositAdCredit = data => {
    return axios.put(`/shops/${data.id}/deposit_advertisement_credit`, {
      amount: data.amount,
      reason: data.reason
    });
  }

  static withdrawAdCredit = data => {
    return axios.put(`/shops/${data.id}/withdraw_advertisement_credit`, {
      amount: data.amount,
      reason: data.reason
    });
  }

  static getOneUserByFid = id => {
    const id_token = localStorage.getItem("id_token");
    if (id) {
      return axios.get(`${base}/${id}`, { headers: { "Authorization": `Bearer ${id_token}` } });
    }
    throw "user id is missing";
  };

  static getOneUserByAid = id => {
    const id_token = localStorage.getItem("id_token");
    if (id) {
      return axios.get(`${base}/${id}?primary_key_type=account_id`, { headers: { "Authorization": `Bearer ${id_token}` } });
    }
    throw "user account id is missing";
  };

  static getOneUserHistoryBySqlId = id => {
    if (id) {
      return axios.get(`${base}/contact_change_histories/${id}`);
    }
    throw "user mysql id is missing";
  };

  static disableSecurity = data => {
    const id_token = localStorage.getItem("id_token");
    if (data.id && data.code && data.type) {
      const requestData = {
        code: data.code,
        type: data.type
      };
      return axios.put(`${base}/${data.id}/security/disable`, requestData, { headers: { "Authorization": `Bearer ${id_token}` } });
    }
  
    return {
      error: "Something is missing"
    };
  };

  static userEmailAuth = data => {
    return axios.post(`${base}/${data}/security/verify_email`, { headers: { "Authorization": `Bearer ${id_token}` } });
  };

  static userEmailAuthVerify = data => {
    const id_token = localStorage.getItem("id_token");
    if (data.id && data.code) {
      const requestData = {
        code: data.code
      };
      return axios.post(`${base}/${data.id}/security/verify`, requestData, { headers: { "Authorization": `Bearer ${id_token}` } });
    }
  
    return {
      error: "Something is missing"
    };
  };

  static editIdVerifyWithStatusAndReason = data => {
    const id_token = localStorage.getItem("id_token");
    if (data.id && data.status) {
      const requestData = {
        status: data.status,
        rejected_reason: data.rejected_reason
      };
      return axios.put(
        `${base}/${data.id}/id_verifications/status`,
        requestData, { headers: { "Authorization": `Bearer ${id_token}` } }
      );
    }
  
    return {
      error: "Something is missing"
    };
  };

  static editShopVerifyWithStatusAndReason = data => {
    const id_token = localStorage.getItem("id_token");
    if (data.status && data.id) {
      const requestData = {
        status: data.status,
        rejected_reason: data.rejected_reason,
        shop_qr_image: data.shop_qr_image
      };
      return axios.put(
        `${base}/${data.id}/shop_verifications/status`,
        requestData, { headers: { "Authorization": `Bearer ${id_token}` } }
      );
    }
  
    return {
      error: "Something is missing"
    };
  };

  static deleteAccount = data => {
    const id_token = localStorage.getItem("id_token");
    if (data.id) {
      return axios.delete(`${base}/${data.id}`, { headers: { "Authorization": `Bearer ${id_token}` } });
    }
    throw "user id is missing";
  };

  static blockAccount = data => {
    const id_token = localStorage.getItem("id_token");
    if (data.id) {
      return axios.delete(`${base}/${data.id}/block`, { headers: { "Authorization": `Bearer ${id_token}` } });
    }
    throw "user id is missing";
  };

  static unBlockAccount = data => {
    const id_token = localStorage.getItem("id_token");
    if (data.id) {
      return axios.put(`${base}/${data.id}/unblock`, { headers: { "Authorization": `Bearer ${id_token}` } });
    }
    throw "user id is missing";
  };

  static deposit = data => {
    const id_token = localStorage.getItem("id_token");
    if (data.amount && data.crypto_type && data.id) {
      const requestData = {
        amount: data.amount,
        crypto_type: data.crypto_type,
        reason: data.reason
      };
      return axios.put(`${base}/${data.id}/deposit`, requestData, { headers: { "Authorization": `Bearer ${id_token}` } });
    }
    throw "request data is missing";
  };

  static withdraw = data => {
    const id_token = localStorage.getItem("id_token");
    if (data.amount && data.crypto_type && data.id) {
      const requestData = {
        amount: data.amount,
        crypto_type: data.crypto_type,
        reason: data.reason
      };
      return axios.put(`${base}/${data.id}/withdraw`, requestData, { headers: { "Authorization": `Bearer ${id_token}` } });
    }
    throw "request data is missing";
  };

  static freeze = data => {
    const id_token = localStorage.getItem("id_token");
    if (data.amount && data.crypto_type && data.id) {
      const requestData = {
        amount: data.amount,
        crypto_type: data.crypto_type,
        reason: data.reason
      };
      return axios.put(`${base}/${data.id}/freeze`, requestData, { headers: { "Authorization": `Bearer ${id_token}` } });
    }
    throw "request data is missing";
  };

  static unfreeze = data => {
    const id_token = localStorage.getItem("id_token");
    if (data.amount && data.crypto_type && data.id) {
      const requestData = {
        amount: data.amount,
        crypto_type: data.crypto_type,
        reason: data.reason
      };
      return axios.put(`${base}/${data.id}/unfreeze`, requestData, { headers: { "Authorization": `Bearer ${id_token}` } });
    }
    throw "request data is missing";
  };

  static hkidCheck = data => {
    const id_token = localStorage.getItem("id_token");
    if (data.hkid) {
      return axios.get(`${base}/search_hkid?hkid=${data.hkid}`, { headers: { "Authorization": `Bearer ${id_token}` } });
    }
    throw "hkid is missing";
  };

  static generateGoogleAuthKey = () => {
    const id_token = localStorage.getItem("id_token");
    return axios.post(
      `https://api.bithon.com.hk/api/google_authenticators/setup_key`, { headers: { "Authorization": `Bearer ${id_token}` } }
    );
  };
}

let base = "/customers";

export default UserApi;
