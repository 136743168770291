import axios from "./index";

class OTCApi {
  static list = ({ page, statusStatement }) => {
    const id_token = localStorage.getItem("id_token");
    if (page && statusStatement) {
      return axios.get(`/over_the_counters?page=${page}&${statusStatement}`, { headers: { "Authorization": `Bearer ${id_token}` } });
    }
    if (page) {
      return axios.get(`/over_the_counters?page=${page}`, { headers: { "Authorization": `Bearer ${id_token}` } });
    }
    if (statusStatement) {
      return axios.get(`/over_the_counters?${statusStatement}`, { headers: { "Authorization": `Bearer ${id_token}` } });
    }

    return axios.get(`/over_the_counters`, { headers: { "Authorization": `Bearer ${id_token}` } });
  };

  static getOTCAdv = () => {
    return axios.get(`/over_the_counter_advertisements`);
  }

  static getOTCRegularOrder = () => {
    return axios.get(`/over_the_counter_orders?type=regular`);
  }

  static getOTCIncognitoOrder = () => {
    return axios.get(`/over_the_counter_orders?type=incognito`);
  }

  static getOneOTCById = (id) => {
    const id_token = localStorage.getItem("id_token");
    if (id) {
      return axios.get(`/over_the_counters/${id}`, { headers: { "Authorization": `Bearer ${id_token}` } });
    }
    throw "OTC id is missing";
  };

  static getOTCUserList = () => {
    const id_token = localStorage.getItem("id_token");
    return axios.get(`/otc_users`, { headers: { "Authorization": `Bearer ${id_token}` } });
  };

  static addOTCUser = (data) => {
    const id_token = localStorage.getItem("id_token");
    return axios.post(`/otc_users`, data, { headers: { "Authorization": `Bearer ${id_token}` } });
  };

  static removeOTCUser = (id) => {
    const id_token = localStorage.getItem("id_token");
    return axios.delete(`/otc_users/${id}`, { headers: { "Authorization": `Bearer ${id_token}` } });
  };

  static add = (data) => {
    const id_token = localStorage.getItem("id_token");
    if (data) {
      //console.log(data);
      return axios.post(`/over_the_counters`, data, { headers: { "Authorization": `Bearer ${id_token}` } });
    }
    throw "request data is missing";
  };

  static changeStatus = (data) => {
    const id_token = localStorage.getItem("id_token");
    if (data) {
      const requestData = {
        status: data.status
      };
      return axios.put(`/over_the_counters/${data.id}`, requestData, { headers: { "Authorization": `Bearer ${id_token}` } });
    }
    throw "request data is missing";
  };
}

let base = "/otc";

export default OTCApi;