import SuiInput from "components/Default/SuiInput";
import "components/Custom/common.css";

export default function CommentWrapper({ attr, value, disable, rows, size, ref, width, onChange, name }) {
    return (
        <div className={size === "sm" || size === "m" || size === "lg" || size === "xl" || size === "col" ? "popup-input-wrapper-" + size : "popup-input-wrapper"+" commentInput"}>
            { attr ?
                <>
                    <div className="popup-subtitle">{attr} </div>
                    <SuiInput name={name} value={value} multiline rows={rows} disabled={disable} width={width} onChange={onChange}/>
                </>
                :
                <SuiInput name={name} value={value} multiline rows={rows} disabled={disable} width={width} onChange={onChange}/>
            }
        </div>
    );
}