import "components/Custom/common.css";
import { Input, Button, InputAdornment, IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { constant } from "config/constant";
import { useState, useEffect } from "react";
import AuthApi from "api/auth";

/* 
    import { ERROR } from "util/ErrorHelper";
    import AuthCodeHelper from "util/AuthCodeHelper";

    <AuthCodeHelper title="Admin Authentication" btnName="Confirm" permission={XXX_PERMISSION.YYY} callback={handleAuthCode} />

    const handleAuthCode = (resp) => {
        // console.log(resp);

        if(resp){
            if(resp.isAuth){
                setAuth(true);
                --> do other function here
            } else{
                alert.error(resp.message);
            }
        }
        else{
            alert.error(ERROR.callback);
        }
    }
*/

let testMode = constant.DEBUG;

export default function AuthCodeHelper({
  title,
  btnName,
  permission,
  callback
}) {
  const [adminCodeInput, setAdminCodeInput] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const handleAdminCodeInput = e => {
    setAdminCodeInput(e.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleAdminCode = async () => {
    callback({
      isAuth: true
    });

    // await AuthApi.getAuthCode()
    //   .then(res => {
    //     if (res.status === 200) {
    //       const admin = res.data.data.filter(a => permission.includes(a.id));
    //       const matched = admin.filter(d => d.code === adminCodeInput);

    //       if (matched.length >= 1) {
    //         callback({
    //           isAuth: true
    //         });
    //       } else {
    //         callback({
    //           isAuth: false,
    //           message: "Auth code is incorrect"
    //         });
    //       }
    //     }
    //   })
    //   .catch(err => {
    //     console.log(err);

    //     if (testMode) {
    //       if (adminCodeInput === "test") {
    //         callback({
    //           isAuth: true
    //         });
    //       }
    //     }
    //   });
  };

  useEffect(() => {
    document.onkeyup = function(event) {
      if (event.which == 13 || event.keyCode == 13) {
        handleAdminCode();
      }
    };
  }, [adminCodeInput]);

  return (
    <div className="popup-wrapper">
      <div className="popup-content">
        <h4>{title}</h4>
        <Input
          id="standard-adornment-password"
          type={showPassword ? "text" : "password"}
          value={adminCodeInput}
          onChange={handleAdminCodeInput}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
        <Button onClick={handleAdminCode}>
          {btnName}
        </Button>
      </div>
    </div>
  );
}
