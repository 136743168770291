import { Grid } from "@mui/material";
import "components/Custom/common.css";

function ControlPanel({ children }){
	return (
		<>
            {children}
		</>
	);
}

export default ControlPanel;