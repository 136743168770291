import '../styles.css';

import { useState, useEffect } from "react";
import { Box, Tabs, Tab } from '@mui/material';
import TabPanel from 'components/Custom/tab_panel';

import { GENERAL_PERMISSION } from 'config/permission';
import { useAlert } from 'react-alert';
import AuthCodeHelper from "util/AuthCodeHelper";
import CurrentDepositTab from './components/current_deposit';
import FixedDepositTab from './components/fixed_deposit';

// Call API
import GeneralApi from "api/general";

export default function SavingPlanPopup () {
  const alert = useAlert();

  const columns = [
    {
        "name": "Current Deposit",
        "tab": CurrentDepositTab()
    },
    {
        "name": "Fixed Deposit",
        "tab": FixedDepositTab()
    }
  ];
  
  const [currentTab, setCurrentTab] = useState(0);
  const [savingPlanData, setSavingPlanData] = useState([]); 
  const [auth, setAuth] = useState(false);
  const [update, setUpdate] = useState(false);
  const [BTC, setBTC] = useState(0); 
  const [ETH, setETH] = useState(0); 
  const [USDT, setUSDT] = useState(0); 
  const [USDC, setUSDC] = useState(0); 

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

    async function updateSavingPlan(_BTC, _ETH, _USDC, _USDT) {
      if(auth){

        await GeneralApi.editSavingPlan({ BTC: _BTC,  ETH: _ETH,  USDC: _USDC,  USDT: _USDT })
          .then((res) => {
            if(res && res.status){
              alert.success("Edit data Successful");
            }
          })
          .catch((err) => {
            alert.error(err.message);
          });

      } else{
        setUpdate(true);
      }
    }

    const fetchSavingPlanData = async () => {

      await GeneralApi.list()
        .then((res) => {
          if(res && res.status === 200){
            setSavingPlanData(res.data.data.saving_plans);
            //console.log(res.data.saving_plans);
            setBTC(res.data.data.saving_plans.saving_plan_btc);
            setETH(res.data.data.saving_plans.saving_plan_eth);
            setUSDC(res.data.data.saving_plans.saving_plan_usdc);
            setUSDT(res.data.data.saving_plans.saving_plan_usdt);
          }
        })
        .catch((err) => {
          console.log(err);
          alert.error(err.message);
        });
    };

      

    useEffect(()=>{
        fetchSavingPlanData();
    }, []);

    return (
        <>
          {
            (!auth && update) ?
            <AuthCodeHelper title="Admin Authentication" btnName="Confirm" permission={GENERAL_PERMISSION.EDIT} callback={handleAuthCode}/>
            :   
            <div id="popup1" className="popup-wrapper">
              <div id="" className="popup1-flex-column">
                  <h4>Saving Plan</h4>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs aria-label="wrapped tabs example" value={currentTab} onChange={handleTabChange} color={"secondary"}>
                      {
                        columns.map((c, key) => {
                          return (
                            <Tab value={key} key={key} label={c.name} />
                          );
                        })
                      }
                    </Tabs>
                  </Box>

                  {
                    columns.map((c, key) => {
                      return (
                        <TabPanel value={currentTab} key={key} index={key}>
                          {c.tab}
                        </TabPanel>
                      );
                    })
                  }
              </div>
          </div>
          }
        </>
    );
}